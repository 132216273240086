import mutations from './mutations';
import getters from './getters';
import actions from './actions';

export default {
  namespaced: true,
  state: {
    patients: [],
    patientsDeleted: [],
    detailConsultations: {
      isAllItemsLoaded: false,
      isLoading: false,
      activeConsultation: null,
      consultations: [],
    },
    detailAppeals: {
      isAllItemsLoaded: false,
      isLoading: false,
      activeAppeal: null,
      appeals: [],
      responsibles: [],
    },
    policy: null,
    activePolicyId: null,
    insuranceSubprograms: [],
    insuranceCompanies: [],
    patientServices: [],
    patientPolicyId: null,
  },
  actions,
  getters,
  mutations,
};
