<template>
  <div class="appeal-item">
    <div class="crm-row">
      <div class="crm-date">
        {{ date }}
      </div>
      <div
        v-if="appealFromChat"
        class="chat-label"
      >
        Чат
      </div>
    </div>
    <div class="crm-title">
      {{ purposeTitle }}
    </div>
    <div
      class="crm-status"
    >
      <span
        class="crm-status-text"
      >
        Статус:
      </span>
      <span
        :style="{color: statusColor}"
        class="crm-status-text"
      >
        {{ statusText }}
      </span>
    </div>
  </div>
</template>

<script>
import { APPEALS_PURPOSES_TEXTS, APPEALS_STATUSES_TEXTS } from '@/services/appeals/appeals.const';
import { parseISO, format } from '@evd3v/date-fns';

export default {
  name: 'PatientDetailAppealsItem',
  props: {
    appeal: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      statuses: {
        0: 'В работе',
        1: 'Отложено',
        2: 'Готово',
      },
      purposes: {
        0: 'Информационное',
        1: 'Чекап',
        2: 'Лечение / плановая госпитализация',
        3: 'Экстренная госпитализация',
      },
    };
  },
  computed: {
    date() {
      return format(parseISO(this.appeal.dateTime), 'dd.MM.yyyy');
    },
    status() {
      return APPEALS_STATUSES_TEXTS.find((item) => item.id === this.appeal.status);
    },
    statusColor() {
      return this.status?.color;
    },
    statusText() {
      return this.status?.title;
    },
    purposeTitle() {
      if (this.appeal.isOldAppeal) {
        return this.purposes[this.appeal.oldPurpose];
      }
      return APPEALS_PURPOSES_TEXTS.find((item) => item.id === this.appeal.purpose)?.title;
    },
    appealFromChat() {
      return this.appeal?.chatAppealId;
    },
  },
};
</script>

<style lang="scss" scoped>
.appeal-item {
  cursor: pointer;
  padding: 20px 10px;
  width: 100%;
  border-bottom: 1px solid #E0E0E0;

  &.active {
    background: #6E88F3;
    color: #ffffff;

    .date, .cmr-status, .crm-status-text {
      color: #ffffff!important;
    }
    .chat-label{
      color: #fff!important;
      outline-color: #fff!important;
    }
  }

  .crm-row {
    display: flex;
    justify-content: space-between;
    .chat-label{
      font-weight: 500;
      font-size: 12px;
      border-radius: 2px;
      color: rgb(133, 133, 248);
      outline: 1px solid rgb(133, 133, 248);
      outline-offset: 1px;
      padding: 0 2px;
      margin-right: 10px;
      &.active {
        color: #fff
      }
    }
  }

  .crm-date {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }
  .crm-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-top: 10px;
  }

  .crm-status {
    margin-top: 10px;
    font-size: 14px;
    line-height: 18px;
    color: #908F8D;
  }

  .crm-status-text {
    &.green {
      color: $green;
    }
    &.yellow {
      color: #F2994A;
    }
    &.red {
      color: $red;
    }
  }
}
</style>
