<template>
  <b-modal
    v-model="isOpen"
    centered
    hide-footer
    no-close-on-backdrop
    title="Удаление специализации"
    @hidden="onClose"
  >
    <div
      class="crm-container specialization-container"
    >
      <div class="description">
        Вы уверены, что хотите удалить специализацию <br>
        «<span>{{ specialization.title }}</span>»?
      </div>
      <div class="crm-wrapper-buttons">
        <b-button
          variant="danger"
          :type="$const.PRIMARY_BUTTON"
          :loading="isDeleting"
          class="crm-button"
          @click="onClickDelete"
        >
          Удалить
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Bus from '@/eventBus';
import { mixinRoles } from '@/mixins';

export default {
  name: 'SpecializationDeleteModal',
  components: {
  },
  mixins: [mixinRoles],
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    specialization: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isDeleting: false,
    };
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onClickDelete() {
      this.isDeleting = true;

      try {
        await this.$store.dispatch(this.$types.SPECIALIZATION_DELETE, this.specialization.id);
        Bus.$emit('specializations:update');
        this.onClose();
      } finally {
        this.isDeleting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.specialization-container {
  flex-direction: column;
  align-items: center;
}
.crm-wrapper-buttons {
  margin-top: 35px;
}
.description {
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  span {
    color: $blue-dark;
    font-weight: 500;
  }
}
</style>
