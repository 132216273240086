<template>
  <b-modal
    v-model="isOpen"
    centered
    title="Открепление полиса"
    hide-footer
    no-close-on-backdrop
    @hidden="onClose"
  >
    <div class="policy-detach">
      <custom-scrollbar>
        <div
          class="crm-container policy-container"
        >
          <div class="description">
            Вы уверены, что хотите открепить полис?
          </div>
          <div class="crm-wrapper-buttons">
            <b-button
              variant="outline-primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="crm-button"
              @click="onClickClose"
            >
              Отменить
            </b-button>

            <b-button
              variant="danger"
              :type="$const.PRIMARY_BUTTON"
              :loading="isDetaching"
              class="crm-button"
              @click="onClickDetach"
            >
              Открепить
            </b-button>
          </div>
        </div>
      </custom-scrollbar>
    </div>
  </b-modal>
</template>

<script>
import { mixinRoles } from '@/mixins';
import Bus from '@/eventBus';

export default {
  name: 'PolicyDetachDopModal',
  components: {
  },
  mixins: [mixinRoles],
  props: {
    personId: {
      type: Number,
      default: null,
    },
    policyId: {
      type: Number,
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isDetaching: false,
    };
  },
  methods: {
    onClickClose() {
      this.onClose();
    },
    async onClickDetach() {
      this.isDetaching = true;

      if (!this.policyId) {
        Bus.$emit('patient:update');
        this.onClose();
      } else {
        try {
          await this.$store.dispatch(this.$types.PATIENT_POLICY_DETACH_DOP, { policyId: this.policyId, personId: this.personId });
        } catch (e) {
          console.log(e);
        } finally {
          this.isDetaching = false;
          Bus.$emit('patient:update');
          Bus.$emit('patients:update');
          this.onClose();
        }
      }
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
  },
};
</script>

<style lang="scss" scoped>
.policy-container {
  flex-direction: column;
  align-items: center;
}
.crm-wrapper-buttons {
  margin-top: 35px;
}
.description {
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
}
</style>
