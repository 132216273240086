<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    :title="'Создание пакета клиник'"
    hide-footer
    no-close-on-backdrop
    @hidden="onClose"
  >
    <template v-if="isLoading">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      <div>
        <div class="crm-label">
          Название пакета клиник
        </div>
        <base-input
          v-model="packageName"
          :input-style="{
            padding: '11px 10px 12px',
            resize: 'none',
            backgroundColor: '#ffffff',
            marginBottom: '15px',
          }"
          class="crm-form-field"
          placeholder="Введите название"
        />

        <div class="crm-label">
          Клиники
        </div>
        <div class="crm-form-field-filter">
          <base-checkbox-select
            :container-class="'clinics-filter'"
            placeholder="Выберите клиники"
            fluid
          >
            <template
              v-slot:default="{ query }"
            >
              <base-checkbox-list
                v-model="selectedClinics"
                :query="query"
                :choices="allClinicsByGroup"
              />
            </template>
          </base-checkbox-select>
        </div>
        <div
          v-if="selectedClinics && selectedClinics.length"
          class="crm-item-scrollbar-wrapper"
        >
          <custom-scrollbar>
            <div
              class="crm-item-wrapper"
            >
              <doctor-speciality-item
                v-for="(clinic, idx) in selectedClinics"
                :key="idx"
                :speciality="clinic"
                edit
                class="crm-item"
                @delete="deleteSelectedPackageClinic(clinic)"
              />
            </div>
          </custom-scrollbar>
        </div>
      </div>
      <div class="crm-container-buttons">
        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          class="button-import"
          @click="createPackage"
        >
          <span>Сохранить</span>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BaseCheckboxSelect,
  BaseCheckboxList,
  BaseInput,
} from '@/components/base';
import Preloader from '@/components/Preloader';
import { api } from '@/helpers/api';
import { showCustomMessage } from '@/helpers/messages';
import DoctorSpecialityItem from '@/components/Doctors/DoctorSpecialityItem';

export default {
  name: 'ClinicPackageModal',
  components: {
    Preloader,
    BaseCheckboxSelect,
    BaseCheckboxList,
    DoctorSpecialityItem,
    BaseInput,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    allClinicsByGroup: {
      type: Object,
      default: () => ({}),
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isOpen: true,
      selectedClinics: [],
      packageName: '',
      isLoading: false,
    };
  },
  methods: {
    deleteSelectedPackageClinic(clinic) {
      this.selectedClinics = this.selectedClinics.filter((item) => item.id !== clinic.id);
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async createPackage() {
      if (!this.packageName || this.selectedClinics.length === 0) {
        showCustomMessage('warning', 'Ошибка валидации', 'Укажите название пакета и выберите клиники');
        return;
      }

      try {
        this.isLoading = true;
        const params = {
          id: 0,
          name: this.packageName,
          clinicIds: this.selectedClinics.map((item) => item.id),
        };
        await api.post('v2/clinicPackages', params);
        showCustomMessage('success', 'Успешно', 'Пакет создан');

        this.callback();
        this.onClose();
      } catch (e) {
        showCustomMessage('warning', 'Ошибка при создании пакета', 'Не удалось создать пакет');
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .crm-container-buttons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
  }

.crm-item-wrapper {
  margin-top: 10px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .crm-item {
    margin-right: 15px;
    margin-bottom: 10px;

    &:last-child {
      margin-right: auto;
    }
  }
}

.crm-label {
  color: $gray-dark;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 8px;
  font-weight: 400;
}

.crm-item-scrollbar-wrapper {
  height: 200px;
}
</style>
