<template>
  <div
    class="crm-info-item"
  >
    <div class="d-flex justify-content-between w-100">
      <div class="d-flex flex-column">
        <div class="d-flex">
          <div class="mr-3">
            <img
              :src="doctorImageUrl"
              alt="avatar"
              class="avatar"
            >

            <div class="d-flex mt-2 w-100">
              <b-button
                v-if="checkFeature(FEATURES_FOR_CHAT.avatar, FEATURES_FOR_DOCTORS.avatar)"
                variant="outline-primary"
                :type="$const.PRIMARY_BUTTON"
                size="sm"
                class="avatar-button mr-1"
                @click="openDoctorAvatarAddModal"
              >
                Аватарка
              </b-button>
              <icon-delete
                v-if="allowDeleteAvatarButton"
                class="crm-icon icon-delete mb-0 ml-auto"
                @click="openDoctorAvatarDeleteModal"
              />
            </div>
          </div>

          <div class="name-wrapper">
            <p class="font-weight-bold">
              {{ fullname }}
            </p>

            <!-- null тоже не подходит, поэтому - строгое равенство -->
            <p
              v-if="doctor.settings?.isActive === false"
              class="text-danger small font-weigt-bold"
            >
              Деактивирован
            </p>

            <p class="small">
              {{ specializations }}
            </p>
          </div>
        </div>
        <div
          v-if="doctor.comment"
          class="d-flex flex-column mt-2"
        >
          <p class="text-sm font-bold mb-1">
            Комментарий
          </p>

          <div class="crm-info-title">
            {{ doctor.comment }}
          </div>
        </div>
        <div
          v-if="showDetailDeactivateInfo"
          class="d-flex flex-column mt-3"
        >
          <div class="d-flex flex-column">
            <p class="crm-info-title">
              Причина отключения учетной записи
            </p>

            <div class="crm-info-text">
              {{ isDeactivateReason }}
            </div>
          </div>
          <div class="d-flex flex-column mt-2">
            <p class="crm-info-title">
              Комментарий
            </p>

            <div class="crm-info-text">
              {{ doctor.settings.deactivationComment || '—' }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="doctor.isTest"
        class="d-flex flex-column mr-3"
      >
        <span class="status-badge status-test">
          TEST
        </span>
      </div>
      <div class="divider-doctor mr-3" />

      <div class="doctor-info-container">
        <div class="d-flex flex-column mr-3">
          <p class="crm-info-title">
            Часовой пояс
          </p>

          <div class="crm-info-text">
            {{ timeZone }}
          </div>
        </div>

        <div class="d-flex flex-column mr-3">
          <p class="crm-info-title">
            Cтоимость консультации
          </p>

          <div class="crm-info-text fw-bold">
            {{ doctor.settings.consultationPrice }}
          </div>
        </div>

        <div class="crm-buttons-wrapper mr-3">
          <b-button
            v-if="modePin"
            class="crm-button"
            size="sm"
            variant="outline-primary"
            :type="$const.PRIMARY_BUTTON"
            @click="$emit('toggle-pin')"
          >
            {{ isPinned ? 'Открепить' : 'Прикрепить' }}
          </b-button>

          <template v-else>
            <b-button
              v-if="checkFeature(FEATURES_FOR_CHAT.consultations, FEATURES_FOR_DOCTORS.seeConsultationBtn)"
              variant="outline-primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              @click="openConsultationsModal"
            >
              Консультации
            </b-button>

            <b-button
              v-if="checkFeature(FEATURES_FOR_CHAT.schedule, FEATURES_FOR_DOCTORS.seeScheduleBtn)"
              variant="outline-primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="mt-2"
              @click="openScheduleModal"
            >
              Расписание
            </b-button>
            <b-button
              v-if="checkFeature(FEATURES_FOR_CHAT.login, FEATURES_FOR_DOCTORS.seeLoginBtn)"
              variant="outline-primary"
              size="sm"
              class="mt-2"
              :disabled="!doctor.settings?.isActive"
              @click="openDoctorAuth"
            >
              Авторизоваться
            </b-button>
            <b-button
              v-if="checkFeature(FEATURES_FOR_CHAT.devices, FEATURES_FOR_DOCTORS.seeDeviceBtn)"
              variant="outline-primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="mt-2"
              @click="openDoctorDeviceModal"
            >
              Устройства
            </b-button>
            <b-button
              v-if="checkFeatureAccess({ name: 'Видеть кнопку Восстановить', url: '/doctors' }) && doctor.isDeleted"
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="mt-2 recovery-button"
              @click="openDoctorReestablishModal"
            >
              Восстановить
            </b-button>
            <b-button
              v-if="checkFeatureAccess(FEATURES_FOR_DOCTORS.seeSignBtn)"
              variant="outline-primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="mt-2"
              @click="getWidgetAuth(doctor)"
            >
              <div v-if="!getTokenForWidgetAuth">
                SIGN
              </div>
              <div v-else>
                D/M ❤️
              </div>
            </b-button>
          </template>
        </div>
      </div>

      <div
        v-if="!modePin"
        class="divider-doctor-2 mr-3"
      />

      <div
        v-if="!modePin"
        class="controls"
        :style="{'justifyContent': showDetailDeactivateInfo
          ? 'start' : 'center'}"
      >
        <icon-watch
          v-if="checkFeature(FEATURES_FOR_CHAT.detailInfoEye, FEATURES_FOR_DOCTORS.doctorDetailInfo)"
          class="crm-icon"
          @click="openWatchModal"
        />
        <icon-clouds
          v-if="checkFeature(FEATURES_FOR_CHAT.appeals, FEATURES_FOR_DOCTORS.doctorAppealsBtn)"
          class="crm-icon clouds-icon crm-border"
          @click="openAppealEditor"
        />
        <icon-history
          v-if="checkFeatureAccess({ name: 'История изменений', url: '/doctors' })"
          class="crm-icon"
          @click="openHistoryModal"
        />
        <icon-delete
          v-if="checkFeature(FEATURES_FOR_CHAT.delete, FEATURES_FOR_DOCTORS.deleteDoctorBtn)"
          :class="{ 'progress': isDoctorDeleting }"
          class="crm-icon icon-delete"
          @click="deleteDoctor"
        />
      </div>
    </div>
    <div
      v-if="doctor.isDeleted"
      class="deleted-overlay"
    />
  </div>
</template>

<script>
import { formatTimeZone } from '@/helpers/utils';
import { showCustomMessage, showErrorCustomMessage } from '@/helpers/messages';
import { FEATURES_FOR_CHAT, FEATURES_FOR_DOCTORS, DOCTOR_INACTIVE_REASONS } from '@/helpers/consts';
import { parseISO, format } from '@evd3v/date-fns';
import { mixinRoles } from '@/mixins';
import Bus from '@/eventBus';
import IconWatch from 'assets/images/watch_icon.svg';
import IconDelete from 'assets/images/delete_icon.svg';
import IconClouds from 'assets/images/clouds-icon.svg';
import IconHistory from 'assets/images/icon_history.svg';
import doctorAvatar from 'assets/images/empty-avatar.svg?inline';
import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

import { APP_DOCTOR_URL, APP_DOCTIS_FILE_SERVER_URL } from '@/app.config';

import { authService } from '@/services';

export default {
  name: 'DoctorInfo',
  components: {
    IconWatch,
    IconDelete,
    IconClouds,
    IconHistory,
  },
  mixins: [mixinRoles],
  props: {
    doctor: {
      type: Object,
      default: () => ({}),
    },
    modePin: {
      type: Boolean,
      default: false,
    },
    isPinned: {
      type: Boolean,
      default: false,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
    patientId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      avatarUrl: null,
      isDoctorDeleting: false,
      FEATURES_FOR_CHAT,
      FEATURES_FOR_DOCTORS,
      getTokenForWidgetAuth: false,
    };
  },
  computed: {
    doctorImageUrl() {
      return this.doctor.avatarCloudKey
        ? `${APP_DOCTIS_FILE_SERVER_URL}/${this.doctor.avatarCloudKey}`
        : doctorAvatar;
    },
    fullname() {
      return this.doctor.fullName.split(' ').join('\n');
    },
    specializations() {
      return this.doctor.doctorSpecializations.map((doctorSpecialization) => doctorSpecialization.specialization.title).join(', ');
    },
    averageTime() {
      if (!this.doctor.averageSeveralTime) return '-';

      const array = this.doctor.averageSeveralTime.match(/[0-9]+/gmi);

      return {
        h: array[0],
        m: array[1],
        s: array[2],
      };
    },
    experience() {
      const mainSpec = this.doctor.mainDoctorSpecialization;

      return mainSpec ? this.parseData(mainSpec.experience) : '—';
    },
    timeZone() {
      const { timeZone } = this.doctor;

      return timeZone ? formatTimeZone(timeZone) : '—';
    },
    allowDeleteAvatarButton() {
      return this.checkFeature(FEATURES_FOR_CHAT.deleteAvatarBtn, FEATURES_FOR_DOCTORS.deleteAvatarBtn) && this.doctor.avatarCloudKey;
    },
    showDetailDeactivateInfo() {
      return this.doctor.settings?.isActive === false && this.isDeactivateReason;
    },
    isDeactivateReason() {
      return DOCTOR_INACTIVE_REASONS[this.doctor.settings.deactivationReason]?.title;
    },
  },
  async created() {
    // if (this.doctor.avatarCloudKey) {
    //   try {
    //     const data = await this.$store.dispatch(this.$types.DOCTOR_AVATAR_GET, { avatarCloudKey: this.doctor.avatarCloudKey });
    //     this.avatarUrl = URL.createObjectURL(data);

    //     console.log(this.avatarUrl);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
  },
  methods: {
    checkFeature(keyOne, keyTwo) {
      return this.modalName ? this.checkFeatureAccess({ name: keyOne.name, url: keyOne.url })
        : this.checkFeatureAccess({ name: keyTwo.name, url: keyTwo.url });
    },
    parseData(ISODate) {
      return format(parseISO(ISODate), 'dd.MM.yyyy');
    },
    openWatchModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'DoctorInfoModal',
        props: {
          doctorId: this.doctor.id,
          title: this.doctor.fullName,
          parentModalName: this.modalName,
        },
      });
    },
    openEditModal() {
      uiService.showModal(MODALS.DOCTOR_EDIT_MODAL, {
        name: 'DoctorEditModal',
        props: {
          doctorId: this.doctor.id,
          title: 'Редактирование врач',
        },
      });
    },
    openConsultationsModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'DoctorConsultationsInfoModal',
        props: {
          doctorId: this.doctor.id,
        },
      });
    },
    openScheduleModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ScheduleModal',
        props: {
          doctorId: this.doctor.id,
          parentModalName: this.modalName,
          patientId: this.patientId,
        },
      });
    },
    openAppealEditor() {
      uiService.showModal(MODALS.APPEAL_EDITOR_MODAL, {
        name: 'AppealEditorModal',
        props: {
          initDoctorData: {
            firstName: this.doctor.firstName,
            lastName: this.doctor.lastName,
            middleName: this.doctor.middleName,
            phoneNumber: this.doctor.phoneNumber,
            birthDate: this.doctor.birthDate,
          },
          personId: this.doctor.id,
        },
      });
    },
    openHistoryModal() {
      uiService.showModal(MODALS.DOCTOR_HISTORY_OF_CHANGES_MODAL, {
        name: 'DoctorHistoryOfChangesModal',
        props: {
          doctorId: this.doctor.id,
        },
      });
    },
    async deleteDoctor() {
      /* eslint-disable no-restricted-globals */
      /* eslint-disable-next-line no-alert */
      if (!confirm(`Вы уверены, что хотите удалить врача ${this.doctor.fullName}`)) return;

      this.isDoctorDeleting = true;

      try {
        await this.$store.dispatch(this.$types.DOCTOR_DELETE, { id: this.doctor.id });

        this.$store.commit(this.$types.DOCTOR_REMOVE_BY_ID, this.doctor.id);
      } finally {
        this.isDoctorDeleting = false;
      }
    },
    openDoctorReestablishModal() {
      this.$store.commit(this.$types.OPEN_MODAL, { name: 'DoctorReestablishModal', props: { doctorId: this.doctor.id } });
    },
    async openDoctorAuth() {
      try {
        const { token, refreshToken } = await this.$store.dispatch(this.$types.DOCTOR_AUTH_TOKEN_FETCH, this.doctor.id);
        const doctorUrl = APP_DOCTOR_URL;
        const url = `${doctorUrl}auth?token=${token}&refreshToken=${refreshToken}`;

        window.open(url, '_blank');
      } catch (error) {
        showErrorCustomMessage('Не удалось авторизовать врача');
        console.error(error);
      }
    },
    openDoctorDeviceModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'DoctorDeviceModal',
        props: {
          personId: this.doctor.id,
          fio: `${this.doctor.lastName} ${this.doctor.firstName} ${this.doctor.middleName}`,
        },
      });
    },
    openDoctorAvatarAddModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'DoctorAvatarAddModal',
        props: {
          doctor: this.doctor,
          // afterSave: this.onClose,
          afterSave: () => Bus.$emit('doctors:fetch'),
        },
      });
    },
    openDoctorAvatarDeleteModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'DoctorAvatarDeleteModal',
        props: {
          doctorId: this.doctor.id,
          // personId: this.personId,
          afterDelete: () => Bus.$emit('doctors:fetch'),
        },
      });
    },
    async getWidgetAuth(obj) {
      try {
        const { token } = await authService.getSignatureForAuth(obj.id);
        navigator.clipboard.writeText(token);
        showCustomMessage('success', 'Успешно', 'Подпись скопирована в буфер обмена');
        this.getTokenForWidgetAuth = true;
        return token;
      } catch (e) {
        console.warn(e);
        showCustomMessage('error', '', 'Выбранный доктор не является партнером');
        throw e;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-info-item {
  padding: 20px !important;
  border-radius: 0.25rem !important;
  box-shadow: rgba(84, 89, 94, 0.2) 0px 8px 24px !important;
  margin-top: 10px;
  position: relative;
}
.avatar {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
}
.name-wrapper {
  width: 230px;
  max-width: 230px;
  min-width: 230px;
  white-space: pre-line;

  @media (max-width: 1400px) {
    width: 150px;
    max-width: 150px;
    min-width: 150px;
  }
}

.divider-doctor,
.divider-doctor-2 {
  height: 100%;
  border-right: 1px solid #E0E0E0;
}

.crm-buttons-wrapper {
  display: flex;
  flex-direction: column;

  .crm-button {
    width: auto;
    padding: 8px;
    height: 35px;
    //flex-basis: 130px;
    font-size: 14px;
    line-height: 17px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}
.controls {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.crm-info-title {
  font-size: 14px;
  line-height: 17px;
  color: #908F8D;
  margin: 0 0 5px 0;

  &_text {
    margin: 0 5px 0 0 ;
  }
}
.crm-info-text {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}
::v-deep.crm-icon {
  cursor: pointer;

  &:not(last-child) {
    margin-bottom: 13px;
  }

  &.progress {
    cursor: wait;
  }

  &.icon-delete:hover {
    rect {
      stroke: $red;
    }
  }

  &.crm-border {
    border: 1px solid #c7c7c7;
    border-radius: 5px;
    &:hover {
      border: 1px solid $blue;
    }
  }

  &.clouds-icon:hover {
    path {
      fill: $blue-dark;
    }
  }

  &:not(.icon-delete):hover {
    rect {
      stroke: $blue;
    }
    path {
      fill: $blue;
    }
  }
}
.clouds-icon {
  padding: 6px 5px 6px 5px;
  path {
    fill: #868686;
  }
}

.deleted-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #FFFFFF;
  opacity: 0.7;
  border-radius: 4px;
  z-index: 1;
}

.recovery-button {
  position: relative;
  z-index: 10;
}

.avatar-button {
  flex-grow: 1;
}
.doctor-info-container{
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  justify-items:center;
}

.status-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 42px;
  height: 23px;
  border: 1px solid #dc3545;
  font-size: 14px;
  color: #dc3545;
}
</style>
