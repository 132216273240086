v<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    :title="'Изменение Юр.лица'"
    @hidden="onClose"
    @ok="onClose"
  >
    <b-overlay
      v-if="isLoading"
      :show="isLoading"
      variant="transparent"
      rounded="lg"
      style="height: 10rem"
      opacity="0.6"
    />
    <div v-else>
      <b-row>
        <b-col>
          <b-form-group
            label="Название юр.лица"
          >
            <b-form-input
              v-model="$v.legalPartnerData.legalName.$model"
              type="text"
              :state="validateState('legalName')"
              :placeholder="'Введите название юр.лица'"
              trim
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Партнер">
            <b-form-select
              v-model="$v.legalPartnerData.partner.id.$model"
              value-field="id"
              text-field="name"
              :state="validateState('partnerId')"
              label="name"
              trim
              :options="optionsPartner"
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Адрес"
          >
            <b-form-input
              v-model="$v.legalPartnerData.address.$model"
              type="text"
              :state="validateState('address')"
              :placeholder="'Введите адрес'"
              trim
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >
              Данное поле обязательно и должно быть не менее 5 символов*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="ИНН"
          >
            <b-form-input
              v-model="$v.legalPartnerData.inn.$model"
              :state="validateState('inn')"
              :placeholder="'Введите инн'"
              trim
              :formatter="formatInn"
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >
              Данное поле обязательно и должно быть не менее 10 цифр*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="КПП"
          >
            <b-form-input
              v-model="$v.legalPartnerData.kpp.$model"
              :state="validateState('kpp')"
              :placeholder="'Введите кпп'"
              trim
              :formatter="formatKpp"
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >
              Данное поле обязательно и должно быть не менее 9 цифр*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Лицензия"
          >
            <b-form-input
              v-model="$v.legalPartnerData.license.$model"
              type="text"
              :state="validateState('license')"
              :placeholder="'Введите лицензию'"
              trim
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >
              Данное поле обязательно и должно быть не менее 5 символов*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Активирован">
            <b-form-select
              v-model="legalPartnerData.isActive"
              :options="optionsActivate"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <b-row>
      <b-col>
        <b-form-group label="Тип договора">
          <b-form-select
            v-model="$v.legalPartnerData.contractType.$model"
            :state="validateState('contractType')"
            :options="optionsContract"
          />
          <b-form-invalid-feedback
            id="input-1-live-feedback"
          >
            Данное поле обязательно*
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row
      v-if="legalPartnerData.contractType == 0"
    >
      <b-col>
        <b-form-group
          label="Процент выплат"
        >
          <b-form-input
            v-model="$v.legalPartnerData.remunerationPercentage.$model"
            placeholder="Введите процент"
            max="100"
            type="number"
            :state="validateState('remunerationPercentage')"
            :formatter="formatRemunerationPercentage"
            trim
          />
          <b-form-invalid-feedback
            id="input-1-live-feedback"
          >
            Данное поле обязательно и должно быть не более 3 цифр*
          </b-form-invalid-feedback>
        </b-form-group>
        <b-alert
          v-if="legalPartnerData.remunerationPercentage > 100"
          show
          variant="danger"
        >
          Процент не должен превышать значения 100
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Шапка документа">
          <b-form-textarea
            id="textarea"
            v-model="$v.legalPartnerData.createOrderGuaranteeHeader.$model"
            :state="validateState('createOrderGuaranteeHeader')"
            placeholder="Введите текст..."
            rows="3"
            max-rows="6"
          />
          <b-form-invalid-feedback
            id="input-1-live-feedback"
          >
            Данное поле обязательно*
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Тема письма">
          <b-form-input
            v-model="$v.legalPartnerData.createOrderGuaranteeEmailSubject.$model"
            :state="validateState('createOrderGuaranteeEmailSubject')"
            placeholder="Введите текст..."
          />
          <b-form-invalid-feedback
            id="input-1-live-feedback"
          >
            Данное поле обязательно*
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Email адреса получателей"
        >
          <b-button
            size="sm"
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            class="mb-2"
            @click="addOrderGuaranteeEmailAddress"
          >
            Добавить email
          </b-button>

          <b-alert
            v-if="!legalPartnerData?.createOrderGuaranteeEmailAddresses?.length && !validateState('createOrderGuaranteeEmailAddresses')"
            show
            variant="danger"
          >
            Данное поле обязательно*
          </b-alert>
          <div
            v-for="(item, idx) in legalPartnerData.createOrderGuaranteeEmailAddresses"
            :key="idx"
            class="d-flex items-center gap-3 mb-2"
          >
            <b-form-input
              v-model="legalPartnerData.createOrderGuaranteeEmailAddresses[idx]"
              type="email"
              placeholder="Введите email..."
            />
            <b-button
              size="sm"
              variant="danger"
              :type="$const.PRIMARY_BUTTON"
              class="w-full ml-2 mr-2"
              @click="deleteOrderGuaranteeEmailAddress(idx)"
            >
              <b-icon icon="x-square" />
            </b-button>
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Шаблон письма клиенту">
          <b-form-file
            v-model="legalPartnerData.createOrderClientEmailTemplate"
            size="sm"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Шаблон ГП на создание заказа">
          <b-form-file
            v-model="legalPartnerData.createOrderGuaranteeTemplate"
            size="sm"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <template #modal-footer>
      <div>
        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          @click="onClickUpdatePartner"
        >
          Изменить
        </b-button>
        <b-button
          variant="danger"
          :type="$const.PRIMARY_BUTTON"
          class="ml-2"
          @click="onClose"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { maxLength, minLength, required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { showValidationErrorMessage } from '@/helpers/messages';
import emailMask from 'text-mask-addons/dist/emailMask';

export default {
  name: 'EditLegalPartnerModal',
  components: {},
  mixins: [validationMixin],
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    legalPartnerId: {
      type: [String, Number],
      default: null,
    },
    params: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      userData: {},
      rows: [],
      name: '',
      isActive: null,
      emailMask,
      legalPartnerData: {
        legalName: '',
        inn: '',
        kpp: '',
        address: '',
        license: '',
        isActive: null,
        partner: {
          id: '',
        },
        contractType: null,
        remunerationPercentage: null,
        createOrderGuaranteeEmailSubject: '',
        createOrderGuaranteeHeader: '',
        createOrderGuaranteeEmailAddresses: [],
        createOrderGuaranteeTemplate: null,
        createOrderClientEmailTemplate: null,
      },
      partnerId: '',
      optionsActivate: [
        { value: null, text: 'Выберите состояние', disabled: true },
        { value: true, text: 'Активирован' },
        { value: false, text: 'Деактивирован' },
      ],
      optionsPartner: [],
      contractType: null,
      optionsContract: [
        { value: null, text: 'Выберите тип договора', disabled: true },
        { value: 0, text: 'Агентский' },
        { value: 1, text: 'Оказания услуг' },
      ],
      remunerationPercentage: null,
    };
  },
  validations() {
    return {
      legalPartnerData: {
        legalName: { required },
        inn: {
          required,
          minLength: minLength(10),
        },
        kpp: {
          required,
          minLength: minLength(9),
        },
        contractType: {
          required,
        },
        createOrderGuaranteeHeader: {
          required,
        },
        createOrderGuaranteeEmailSubject: {
          required,
        },
        createOrderGuaranteeEmailAddresses: {
          required,
          minLength: minLength(1),
        },
        remunerationPercentage: {
          // eslint-disable-next-line eqeqeq
          required: this.legalPartnerData.contractType == 0 ? required : () => true,
          maxLength: maxLength(3),
        },
        partner: {
          id: { required },
        },
        address: {
          required,
          minLength: minLength(5),
        },
        license: {
          required,
          minLength: minLength(5),
        },
      },
    };
  },
  computed: {
    partnerListStore() {
      return this.$store.state.Laboratories.partnerList;
    },
  },
  watch: {
    legalPartnerData: {
      handler() {
        // eslint-disable-next-line eqeqeq
        if (this.legalPartnerData.contractType != 0) {
          this.legalPartnerData.remunerationPercentage = null;
        }
      },
      deep: true,
    },
  },
  async created() {
    await this.fetchLegalPartnerInfo();
    await this.$store.dispatch(this.$types.GET_LABORATORY_PARTNERS, {});
    this.optionsPartner = this.partnerListStore.data;
  },
  methods: {
    async onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    formatRemunerationPercentage(e) {
      return String(e).substring(0, 3);
    },
    async fetchLegalPartnerInfo() {
      this.isLoading = true;
      const { data } = await this.$store.dispatch(this.$types.GET_LABORATORY_LEGAL_PARTNER_FOR_ID, this.legalPartnerId);
      this.legalPartnerData = data;
      if (this.legalPartnerData.contractType === 'Service') this.legalPartnerData.contractType = 1;
      if (this.legalPartnerData.contractType === 'Agency') this.legalPartnerData.contractType = 0;
      this.isLoading = false;
    },
    async onClickUpdatePartner() {
      if (!this.onCheckValidation()) return;

      const params = {
        id: this.legalPartnerData.id,
        legalName: this.legalPartnerData.legalName,
        partnerId: this.legalPartnerData.partner.id,
        address: this.legalPartnerData.address,
        inn: this.legalPartnerData.inn,
        kpp: this.legalPartnerData.kpp,
        license: this.legalPartnerData.license,
        isActive: this.legalPartnerData.isActive,
        remunerationPercentage: this.legalPartnerData.remunerationPercentage,
        contractType: this.legalPartnerData.contractType,
        createOrderGuaranteeEmailAddresses: this.legalPartnerData.createOrderGuaranteeEmailAddresses,
        createOrderGuaranteeHeader: this.legalPartnerData.createOrderGuaranteeHeader,
        createOrderGuaranteeEmailSubject: this.legalPartnerData.createOrderGuaranteeEmailSubject,
        createOrderGuaranteeTemplate: this.legalPartnerData.createOrderGuaranteeTemplate,
        createOrderClientEmailTemplate: this.legalPartnerData.createOrderClientEmailTemplate,
      };

      // eslint-disable-next-line eqeqeq
      if (params.contractType == 1) {
        delete params.remunerationPercentage;
      }

      const fd = Object.entries(params).reduce((formData, [key, value]) => {
        formData.append(key, value);
        return formData;
      }, new FormData());

      await this.$store.dispatch(this.$types.LABORATORY_LEGAL_PARTNER_UPDATE, { fd, id: params.id });

      await this.$store.dispatch(this.$types.GET_LABORATORY_LEGAL_PARTNERS, this.params);
      this.onClose();
    },
    validateState(name) {
      if (name === 'partnerId') {
        const { $dirty, $error } = this.$v.legalPartnerData.partner.id;
        return $dirty ? !$error : null;
      }
      const { $dirty, $error } = this.$v.legalPartnerData[name];
      return $dirty ? !$error : null;
    },
    onCheckValidation() {
      this.$v.$touch();
      if (this.$v.$error) {
        console.log(this.$v.error);

        showValidationErrorMessage();
        return false;
      }

      return true;
    },
    formatInn(e) {
      return String(e).substring(0, 10);
    },
    formatKpp(e) {
      return String(e).substring(0, 9);
    },
    addOrderGuaranteeEmailAddress() {
      if (!this.legalPartnerData.createOrderGuaranteeEmailAddresses) this.legalPartnerData.createOrderGuaranteeEmailAddresses = [];
      this.legalPartnerData.createOrderGuaranteeEmailAddresses.push('');
    },
    deleteOrderGuaranteeEmailAddress(index) {
      if (this.legalPartnerData.createOrderGuaranteeEmailAddresses && this.legalPartnerData.createOrderGuaranteeEmailAddresses.length > index) {
        this.legalPartnerData.createOrderGuaranteeEmailAddresses.splice(index, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.crm-table {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  &-row {
    display: flex;

    &--header {
      background: #F3F3F3;
      color: #6E88F3;
    }

    & + & {
      border-top: 1px solid #E0E0E0;
    }
  }

  &-cell {
    padding: 10px;
    width: 100%;

    &--date {
      flex-shrink: 0;
      width: 150px;
    }

    & + & {
      border-left: 1px solid #E0E0E0;
    }
  }
}

</style>
