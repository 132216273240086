import { APP_HOST } from '@/app.config';

export default class UsersApi {
  constructor($axios) {
    this.api = $axios.instance;
  }

  async getUsers(params) {
    const { data } = await this.api.get(`${APP_HOST}v2/account/users`, { params });
    return data;
  }

  async getUser(params) {
    const { data } = await this.api.get(`${APP_HOST}v2/account`, { params });
    return data;
  }
}
