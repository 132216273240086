<template>
  <b-modal
    id="status-request-modal"
    v-model="isOpen"
    title="Статус файлов"
    centered
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <div>
      {{ text }}
    </div>

    <div
      v-if="fileErrorKey"
      class="mt-3"
    >
      <div>
        Некоторые прайсы загрузились с ошибками
      </div>
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        size="sm"
        :disabled="errorFileLoad"
        class="mt-2"
        @click="saveErrorFile"
      >
        Скачать файл с ошибками
      </b-button>
    </div>

    <div v-else>
      <div>
        Все прайсы загружены без ошибок
      </div>
    </div>

    <template #modal-footer>
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        @click="onClose"
      >
        Ок
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { api } from '@/helpers/api';
import { saveFile } from '@/helpers/utils';
import { showCustomMessage } from '@/helpers/messages';

export default {
  name: 'StatusRequestModal',

  props: {
    text: {
      type: String,
      default: '',
    },
    modalName: {
      type: String,
      default: null,
    },
    fileErrorKey: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    isOpen: true,
    errorFileLoad: false,
  }),

  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async saveErrorFile() {
      this.errorFileLoad = true;
      try {
        const { data } = await api.get('v2/price/get-file-errors', {
          params: {
            fileKey: this.fileErrorKey,
          },
          responseType: 'blob',
        });
        saveFile(data, { name: 'Файл с ошибками.xlsx' });
      } catch (e) {
        console.error(e);
        showCustomMessage('error', 'Ошибка', `Что-то пошло не так, ${e}`);
      } finally {
        this.errorFileLoad = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
