<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    :title="title"
    size="md"
    no-close-on-backdrop
    hide-footer
    @hidden="onClose"
    @ok="onClose"
  >
    <loading
      v-if="isLoading"
      class="schedule-templates"
    >
      Загрузка шаблонов
    </loading>

    <div
      v-else
      class="p-1"
    >
      <div class="d-flex mb-3 justify-content-between">
        <div class="font-weight-bold">
          Выберите шаблон расписания
        </div>

        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          size="sm"
          @click="openEditModal"
        >
          Новый шаблон
        </b-button>
      </div>

      <span v-if="!scheduleTemplates.length">
        Нет доступных шаблонов
      </span>

      <div v-else>
        <schedule-template-item
          v-for="template in scheduleTemplates"
          :key="template.id"
          :doctor="doctor"
          :template="template"
          class="mb-2"
        />
      </div>
    </div>

    <div class="schedule-buttons">
      <!-- <base-button
        :type="$const.SECONDARY_BUTTON"
        :loading="isLoading"
        class="without-border deactivate-button"
        @click="deactivateAll"
      >
        Деактивировать все
      </base-button> -->
    </div>
  </b-modal>
</template>

<script>
import Loading from '@/components/Loading';
import ScheduleTemplateItem from './ScheduleTemplateItem';

export default {
  name: 'ScheduleTemplatesModal',
  components: {
    Loading,
    ScheduleTemplateItem,
  },
  props: {
    doctor: {
      type: Object,
      default: () => ({}),
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Редактирование шаблона расписания',
    },
  },
  data: () => ({
    isOpen: true,
  }),
  computed: {
    isLoading() {
      return this.$store.state.Schedule.isScheduleTemplatesLoading;
    },
    scheduleTemplates() {
      return this.$store.state.Schedule.scheduleTemplates;
    },
  },
  created() {
    this.loadTemplates();
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    openEditModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ScheduleTemplateEditModal',
        props: {
          templateProp: null,
          minuteInterval: this.doctor?.settings?.consultationDurationInMinutes || 25,
        },
      });
      // Bus.$emit('open-modal:schedule-edit', null);
    },
    async loadTemplates() {
      try {
        await this.$store.dispatch(this.$types.SCHEDULE_TEMPLATES_FETCH, this.doctor.mainDoctorSpecialization.staffId);
      } catch (e) {
        console.warn(e);
      }
    },
    async deactivateAll() {
      try {
        await this.$store.dispatch(this.$types.SCHEDULE_TEMPLATES_DEACTIVATE_ALL, this.doctor.id);

        if (this.$store.state.SchedulePage.schedule.length) {
          this.$store.dispatch(this.$types.SCHEDULE_PAGE_FETCH, true);
        }
      } catch (e) {
        console.warn(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.schedule-templates-items {
  margin: 0 -10px;
  height: 300px;
  max-height: calc(100vh - 320px);
  min-height: 100px;
}
.schedule-title-text {
  color: #474747;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
.button_transparent {
  padding: 0;
  background-color: transparent;
  color: $blue;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  flex-basis: 144px;
}
.without-border {
  border: none;
}
.schedule-title-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
::v-deep.crm-icon-cross {
  margin-right: 15px;

  path {
    fill: $blue;
  }
}
.schedule-templates {
  margin-bottom: 30px;
}
.schedule-buttons {
  display: flex;
  justify-content: space-around;
}
.deactivate-button {
  font-weight: normal;
  width: 170px;
}
.crm-container-items {
  width: 100%;
  flex-direction: column;
}
</style>
