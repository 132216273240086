import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.MESSAGES_SET](state, payload) {
    state.messages = payload;
  },
  [localTypes.MESSAGES_ADD](state, payload) {
    state.messages = [...state.messages, ...payload];
  },
  [localTypes.MESSAGES_TOTAL_COUNT_SET](state, payload) {
    state.totalCount = payload;
  },
};
