import { APP_HOST } from '@/app.config';
import { saveFile } from '@/helpers/utils';

export default class RegistryApi {
  constructor($axios) {
    this.api = $axios.instance;
  }

  async getImportHistory(obj) {
    const { data } = await this.api.get(`${APP_HOST}v2/registry/import-history`, {
      params: {
        skip: obj.skip,
        take: obj.take,
      },
    });
    return data;
  }

  async getFile({ id, name }) {
    const { data } = await this.api.get(`${APP_HOST}file/${id}`, { responseType: 'blob' });
    saveFile(data, { name: `${name}.xlsx` });
  }
}
