import PersonApi from '@/services/person/person.api';

export default class PersonService {
  constructor($axios) {
    this.api = new PersonApi($axios);
  }

  getHistoryOfActions(params) {
    return this.api.getHistoryOfActions(params);
  }

  getPatients(params) {
    return this.api.getPatients(params);
  }
}
