<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="md"
    :title="'Удаление гарантийного письма'"
    hide-footer
    @hidden="onClose"
    @ok="onClose"
  >
    <div class="policy-delete">
      <custom-scrollbar>
        <div
          class="crm-container policy-container"
        >
          <div class="description">
            Вы уверены, что хотите удалить {{ isReferral ? 'направление': 'гарантийное письмо' }}?
          </div>
          <div class="crm-wrapper-buttons">
            <b-button
              variant="outline-primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="mr-3"
              @click="onClickClose"
            >
              Отменить
            </b-button>

            <b-button
              variant="danger"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              :loading="isDeleting"
              @click="onClickDelete"
            >
              Удалить
            </b-button>
          </div>
        </div>
      </custom-scrollbar>
    </div>
  </b-modal>
</template>

<script>
import { mixinRoles } from '@/mixins';
import Bus from '@/eventBus';

export default {
  name: 'IndemnityLetterDeleteModal',
  mixins: [mixinRoles],
  props: {
    indemnityLetterId: {
      type: String,
      default: null,
    },
    policyId: {
      type: Number,
      default: null,
    },
    clinicId: {
      type: String,
      default: '',
    },
    isReferral: {
      type: Boolean,
      default: false,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isDeleting: false,
    };
  },
  methods: {
    onClickClose() {
      this.onClose();
    },
    async onClickDelete() {
      this.isDeleting = true;

      try {
        await this.$store.dispatch(this.$types.INDEMNITY_LETTER_DELETE, { id: this.indemnityLetterId, isReferral: this.isReferral });
      } catch (e) {
        console.log(e);
      } finally {
        this.isDeleting = false;
        this.onClose();

        if (this.$store.state.Indemnity.letters.length) Bus.$emit('indemnity-history-modal:fetch-letters');

        if (this.clinicId) {
          await this.$store.dispatch(this.$types.INDEMNITY_LETTERS_GET_BY_CLINIC, this.clinicId);
        } else {
          await this.$store.dispatch(this.$types.INDEMNITY_LIST_NAMES_FETCH, {
            personId: this.$store.state.Indemnity.personId,
            policyId: this.policyId,
            isReferral: this.isReferral,
          });
        }
      }
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
  },
};
</script>

<style lang="scss" scoped>
.policy-container {
  flex-direction: column;
  align-items: center;
}
.crm-wrapper-buttons {
  margin-top: 35px;
}
.description {
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
}
</style>
