import NProgress from 'nprogress/nprogress';

import * as types from '@/store/types';
import {
  removeNamespaces,
  showMessage,
} from '@/helpers/utils';
import { api } from '@/helpers/api';

const localTypes = removeNamespaces(types);

export default {
  async [localTypes.CONFIGURATION_TEMPLATE_BY_CLINICS_FETCH](_, {
    clinicIds,
  }) {
    NProgress.start();
    try {
      const params = new URLSearchParams();
      if (clinicIds) params.append('clinicIds', clinicIds);

      const { data } = await api.get('v2/configurationTemplate/listByClinics', { params });
      return data;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.CONFIGURATION_TEMPLATE_CREATE](ctx, template) {
    NProgress.start();

    try {
      await api.post('v2/configurationTemplate', template);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Шаблон успешно добавлен!',
      });
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
};
