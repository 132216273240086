var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-step"},[_c('div',{staticClass:"crm-scrollbar-wrapper"},[_c('custom-scrollbar',[_c('div',{staticClass:"crm-container indemtity-wrapper"},[_c('div',{staticClass:"crm-form-fields"},[_c('div',{staticClass:"crm-form-field"},[_c('indemnity-arow-left-button',{on:{"click":_vm.clickPrev}}),_c('p',{staticClass:"page-title"},[_vm._v(" Добавление шаблона ")])],1)]),_c('div',{staticClass:"crm-header-block"},[_c('div',{staticClass:"crm-search-row"},[_c('base-input',{attrs:{"input-style":{
                padding: '18px 30px',
                boxShadow: '0px 1px 2px #C4C4C4',
                borderRadius: '50px',
              },"guide":false,"placeholder":"Введите название шаблона","fluid":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.templateFetch.apply(null, arguments)}},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_c('icon-search',{staticClass:"crm-icon-search",on:{"click":_vm.templateFetch}})]},proxy:true}]),model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}),_c('b-button',{staticClass:"add-button d-flex align-items-center p-3",attrs:{"pill":"","variant":"primary","type":_vm.$const.PRIMARY_BUTTON},on:{"click":_vm.addNewTemplate}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"plus","font-scale":"1.8"}}),_vm._v(" Новый шаблон ")],1)],1)]),_c('div',{staticClass:"template-wrapper"},[(!_vm.isSearched)?_c('div',{staticClass:"page-subtitle"},[_vm._v(" Здесь будут отображены результаты поиска ")]):_vm._e(),(_vm.isSearched && !_vm.isLoading && _vm.templates.length === 0)?_c('div',{staticClass:"page-subtitle"},[_vm._v(" Ничего не найдено ")]):_vm._e(),(_vm.isLoading)?_c('Loading',{staticClass:"crm-loading-wrapper"}):_vm._e(),_c('div',{staticClass:"template-list"},_vm._l((_vm.templates),function(template,index){return _c('div',{key:index,staticClass:"template-item",class:{
                active: _vm.activeTemplateId === template.id
              }},[_c('div',{staticClass:"template-name"},[_vm._v(" "+_vm._s(template.name)+" "),_c('div',{staticClass:"control-icon-wrapper"},[_c('icon-edit',{staticClass:"crm-icon-edit",on:{"click":function($event){return _vm.editTemplate(template)}}}),(_vm.showDeleteTemplate)?_c('icon-delete',{staticClass:"crm-icon-delete",on:{"click":function($event){return _vm.openTemplateDeleteModal(template.id)}}}):_vm._e()],1)]),_c('div',{staticClass:"template-service-list"},_vm._l((template.rows.filter((r) => (typeof r) != 'string').slice(0, 4)),function(row,i){return _c('div',{key:i,staticClass:"template-service-item"},[_c('div',{staticClass:"template-service-code"},[_vm._v(" "+_vm._s(row.code.value)+" ")]),_c('div',{staticClass:"template-service-name"},[_vm._v(" "+_vm._s(row.name.value)+" ")]),_c('div',{staticClass:"template-service-sum"},[_vm._v(" "+_vm._s(row.sum.value)+" ")])])}),0),_c('div',{staticClass:"template-comment"},[_c('span',[_vm._v("Комментарий")]),_c('span',[_vm._v(" + "+_vm._s(_vm.servicesLength(template.rows))+" услуг ")])]),(_vm.activeTemplateId === template.id)?_c('base-button',{staticClass:"template-apply-btn disabled",attrs:{"type":_vm.$const.PRIMARY_BUTTON}},[_vm._v(" Выбрано ")]):_c('base-button',{staticClass:"template-apply-btn button_transparent",attrs:{"type":_vm.$const.SECONDARY_BUTTON},on:{"click":function($event){return _vm.applyTemplate(template)}}},[_vm._v(" Выбрать ")])],1)}),0)],1)])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }