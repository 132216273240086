<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    title="Экспорт чекапов"
    :hide-footer="isLoading"
    @hidden="onClose"
    @ok="onClose"
  >
    <template v-if="isLoading">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      <b-row>
        <b-col>
          <b-form-group
            label="От"
          >
            <base-date-picker v-model="exportData.dateFrom" />
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
            label="До"
          >
            <base-date-picker v-model="exportData.dateTo" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Город"
          >
            <b-form-select
              v-model="exportData.cityId"
              :options="cities"
              value-field="id"
              text-field="name"
              @input="onChangeCity"
              @keyup.native.backspace="exportData.cityId = null"
            >
              <b-form-select-option
                :value="null"
              >
                Выберите город
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
            label="Клиника"
          >
            <b-form-select
              v-model="exportData.clinicId"
              :options="clinicsByCity"
              value-field="id"
              text-field="name"
              :disabled="!exportData.cityId"
              @keyup.native.backspace="exportData.clinicId = null"
            >
              <b-form-select-option
                :value="null"
                disabled
              >
                Выберите клинику
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Страховая компания"
          >
            <b-form-select
              v-model="exportData.insuranceCompanyId"
              :options="insuranceCompanies"
              value-field="id"
              text-field="title"
              @input="onChangeCompany"
              @keyup.native.backspace="exportData.insuranceCompanyId = null"
            >
              <b-form-select-option
                :value="null"
                disabled
              >
                Выберите страховую компанию
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Страховая программа"
          >
            <b-form-select
              v-model="exportData.insuranceProgramId"
              :options="insurancePrograms"
              value-field="id"
              text-field="name"
              @input="onChangeProgram"
              @keyup.native.backspace="exportData.insuranceProgramId = null"
            >
              <b-form-select-option
                :value="null"
                disabled
              >
                Выберите страховую программу
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Страховая подпрограмма"
          >
            <b-form-select
              v-model="exportData.insuranceSubprogramId"
              :options="insuranceSubprograms"
              value-field="subprogramId"
              text-field="name"
              @keyup.native.backspace="exportData.insuranceSubprogramId = null"
            >
              <b-form-select-option
                :value="null"
                disabled
              >
                Выберите страховую подпрограмму
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </template>

    <template #modal-footer>
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        :disabled="isLoading"
        @click="onExport"
      >
        Экспортировать
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Preloader from '@/components/Preloader';
import { formatISO } from '@evd3v/date-fns';

import { BaseDatePicker } from '@/components/base';
import { onBInputDatePaste, dateWithoutTime } from '@/helpers/utils';

import { clinicService, locationService } from '@/services';

export default {
  name: 'CheckupExportModal',
  components: {
    Preloader,
    BaseDatePicker,
  },
  props: {
    taskId: {
      type: [String, Number],
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      exportData: {
        dateFrom: null,
        dateTo: null,
        insuranceCompanyId: null,
        insuranceProgramId: null,
        insuranceSubprogramId: null,
        clinicId: null,
        cityId: null,
      },
      insuranceData: null,
      cities: null,
      clinics: null,
    };
  },
  computed: {
    clinicsByCity() {
      if (!this.exportData.cityId) return this.clinics;
      return this.clinics.filter((c) => c.cityId === this.exportData.cityId);
    },
    insuranceCompanies() {
      if (!this.insuranceData) return [];
      return this.insuranceData.map(({ company }) => company);
    },
    insurancePrograms() {
      if (!this.insuranceData) return [];

      const programs = this.insuranceData
        .map(({ programsWithSubprograms }) => programsWithSubprograms)
        .reduce((prev, next) => prev.concat(next))
        .map(({ program }) => program);

      if (!this.exportData.insuranceCompanyId) return programs;

      return programs.filter((p) => p.companyId === this.exportData.insuranceCompanyId);
    },
    insuranceSubprograms() {
      if (!this.insuranceData) return [];

      const programs = this.insuranceData
        .map(({ programsWithSubprograms }) => programsWithSubprograms)
        .reduce((prev, next) => prev.concat(next));

      if (!this.exportData.insuranceProgramId) {
        return programs
          .map(({ subPrograms }) => subPrograms)
          .reduce((prev, next) => prev.concat(next));
      }

      return programs
        .filter(({ program }) => program.id === this.exportData.insuranceProgramId)
        .map(({ subPrograms }) => subPrograms)
        .reduce((prev, next) => prev.concat(next));
    },
  },
  async created() {
    this.isLoading = true;
    try {
      this.insuranceData = await this.$store.dispatch(this.$types.COMPANIES_FETCH);
      this.cities = (await locationService.getCities({ query: '' })).sort((a, b) => a.name.localeCompare(b.name));
      this.clinics = (await clinicService.getListNames()).filter((c) => c.name);
    } catch (e) {
      console.error(e);
      // this.onClose();
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    onPaste(e) {
      onBInputDatePaste(e);
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    onChangeCity() {
      this.exportData.clinicId = null;
    },
    onChangeCompany() {
      this.exportData.insuranceProgramId = null;
      this.exportData.insuranceSubprogramId = null;
    },
    onChangeProgram() {
      this.exportData.insuranceSubprogramId = null;
    },
    async onExport() {
      try {
        const data = {
          dateFrom: this.exportData.dateFrom ? dateWithoutTime(formatISO(this.exportData.dateFrom)) : null,
          dateTo: this.exportData.dateTo ? dateWithoutTime(formatISO(this.exportData.dateTo)) : null,
          insuranceCompanyId: this.exportData.insuranceCompanyId || null,
          insuranceProgramId: this.exportData.insuranceProgramId || null,
          insuranceSubprogramId: this.exportData.insuranceSubprogramId || null,
          clinicId: this.exportData.clinicId || null,
          cityId: this.exportData.cityId || null,
        };

        await this.$store.dispatch(this.$types.CHECKUP_EXPORT, data);

        this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
 ::v-deep.custom-select option {
  width: 200px!important;
  max-width: 200px!important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
 }
</style>
