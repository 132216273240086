import { APP_HOST } from '@/app.config';
import { saveFile } from '@/helpers/utils';

export default class ClinicApi {
  constructor($axios) {
    this.api = $axios.instance;
  }

  async getAdmin() {
    const { data: clinics } = await this.api.get(`${APP_HOST}v2/clinic/adminClinics`);
    return clinics;
  }

  async getHistory(id) {
    const { data } = await this.api.get(`${APP_HOST}v2/clinic/history`, { params: { clinicId: id } });
    return data;
  }

  async sendPrice(formData, params) {
    const { data } = await this.api.post(`${APP_HOST}v2/price/import`, formData, {
      'Content-Type': 'multipart/form-data',
      params,
    });
    return data;
  }

  async getImportHistory(obj) {
    const { data: { data } } = await this.api.get(`${APP_HOST}v2/price/import-history`, {
      params: {
        skip: obj.skip,
        take: obj.take,
      },
    });
    return data;
  }

  async updateGeolocations() {
    const data = await this.api.put(`${APP_HOST}v2/clinic/geocoordinates/update-all`);
    return data;
  }

  async getFile({ id, name }) {
    const { data } = await this.api.get(`${APP_HOST}v2/price/get-file`, { params: { fileKey: id }, responseType: 'blob' });
    saveFile(data, { name });
  }

  async getPricesList(obj) {
    const { data: { data } } = await this.api.post(`${APP_HOST}v2/price/list-prices`, obj);
    return data;
  }

  async getPricesListForOne(obj) {
    const { data: { data } } = await this.api.post(`${APP_HOST}v2/price/list-clinic-prices`, obj);
    return data;
  }

  async getPricesHistory(id) {
    const { data } = await this.api.get(`${APP_HOST}v2/clinic/services/history`, { params: { clinicId: id } });
    return data;
  }

  async createCity(params) {
    await this.api.post(`${APP_HOST}Cities`, params);
  }

  async search(params) {
    const { data: clinics } = await this.api.post(`${APP_HOST}v2/clinic/search`, params);
    return clinics;
  }

  async getLaboratories(params) {
    const { data: { data } } = await this.api.get(`${APP_HOST}v3/laboratory/branch/list`, { params });
    return data;
  }

  async searchforMap(params) {
    const { data } = await this.api.get(`${APP_HOST}v2/clinic/for-map`, { params });
    return data;
  }

  async getListNames(params) {
    const { data: clinics } = await this.api.get(`${APP_HOST}v2/clinic/getListNames`, { params });
    return clinics;
  }

  async searchNetworks(params) {
    const { data } = await this.api.get(`${APP_HOST}v2/clinicNetworks/search`, { params });
    return data;
  }

  async getNetworkList() {
    const { data } = await this.api.get(`${APP_HOST}v2/clinicNetworks/list`);
    return data;
  }

  async getLegalPersons(params) {
    const { data } = await this.api.get(`${APP_HOST}v2/clinic/listLegalPerson`, { params });
    return data;
  }

  async getOne(id) {
    const { data: clinic } = await this.api.get(`${APP_HOST}v2/clinic/${id}`);
    return clinic;
  }

  async remove(clinicId) {
    await this.api.post(`${APP_HOST}v2/clinic/remove`, null, { params: { clinicId } });
  }

  async create(obj) {
    const { data: { data: createdClinic } } = await this.api.post(`${APP_HOST}v2/clinic`, obj);
    return createdClinic;
  }

  async update(obj) {
    const { data: { data: updatedClinic } } = await this.api.put(`${APP_HOST}v2/clinic`, obj);
    return updatedClinic;
  }

  // legacy code
  async dismonitoringBindToggle(params) {
    await this.api.put(`${APP_HOST}v2/account/bind-or-remove-clinic-to-dismonitoring-operator`, null, { params });
  }
}
