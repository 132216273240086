export default {
  // * 'AppealsTemplates/FETCH_TRIGGER_TEMPLATES_APPEALS'
  FETCH_TRIGGER_TEMPLATES_APPEALS(state) {
    return state.fetchAppealsTemplatesTrigger;
  },
  // * 'AppealsTemplates/FETCH_TRIGGER_TEMPLATES_APPEALS_REJECT'
  FETCH_TRIGGER_TEMPLATES_APPEALS_REJECT(state) {
    return state.fetchAppealsTemplatesRejectTrigger;
  },
  // * 'AppealsTemplates/FETCH_TRIGGER_TEMPLATES_APPEALS_CHAT'
  FETCH_TRIGGER_TEMPLATES_APPEALS_CHAT(state) {
    return state.fetchChatAppealsTemplates;
  },
};
