<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    :title="title"
    size="lg"
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <b-skeleton-img
      v-if="isLoading"
      no-aspect
      width="100%"
      height="84px"
      class="rounded"
    />
    <div
      v-else
      class="schedule-modal-inner"
    >
      <schedule-wrapper
        v-if="allSlots.length"
        :doctor-id="doctorId"
        :all-slots="allSlots"
      >
        <schedule
          :doctor-id="doctorId"
          @change-active-tab="activeTimeTab = $event"
        />
      </schedule-wrapper>

      <div v-else>
        У врача нет ни одного слота для записи. Проверьте шаблоны расписания врача
      </div>
    </div>

    <template #modal-footer>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        size="sm"
        @click="onClose"
      >
        Закрыть
      </b-button>

      <b-button
        v-if="!isDoctorLoading && !doctor.settings.useExternalSchedule && allowSheduleEditBtn"
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        size="sm"
        :disabled="isDoctorLoading"
        @click="openTemplatesModal"
      >
        Редактировать
      </b-button>
      <b-button
        v-else-if="!isDoctorLoading && doctor.settings.useExternalSchedule"
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        title="Нет доступа к редактированию, используется расписание МиД"
        size="sm"
        disabled
      >
        Используется расписание МиД
      </b-button>

      <b-button
        v-if="checkFeature(FEATURES_FOR_CHAT.possibilityOfPatientRegistration, FEATURES_FOR_DOCTORS.possibilityOfPatientRegistration)
          && activeTimeTab"
        variant="success"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        size="sm"
        :disabled="isDoctorLoading"
        @click="openCheckupModal"
      >
        Записать пациента
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mixinRoles } from '@/mixins';

import { mapGetters } from 'vuex';
import * as types from '@/store/types';
import { FEATURES_FOR_CHAT, FEATURES_FOR_DOCTORS } from '@/helpers/consts';
import ScheduleWrapper from './ScheduleWrapper';
import Schedule from './Schedule';

export default {
  name: 'ScheduleModal',
  components: {
    Schedule,
    ScheduleWrapper,
  },
  mixins: [mixinRoles],
  props: {
    doctorId: {
      type: Number,
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
    patientId: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Расписание',
    },
    parentModalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isDoctorLoading: true,
      activeTimeTab: null,
      doctor: null,
      isLoading: false,
      allSlots: [],
      FEATURES_FOR_DOCTORS,
      FEATURES_FOR_CHAT,
    };
  },
  computed: {
    ...mapGetters({
      selectedDoctor: types.SCHEDULE_DOCTOR_GET,
    }),
    allowSheduleEditBtn() {
      return this.parentModalName
        ? this.checkFeatureAccess({ name: FEATURES_FOR_CHAT.editSchedule.name, url: FEATURES_FOR_CHAT.editSchedule.url })
        : true;
    },
  },
  async created() {
    this.isLoading = true;

    try {
      this.doctor = await this.$store.dispatch(this.$types.DOCTOR_FETCH, this.doctorId);
      this.allSlots = await this.$store.dispatch(this.$types.FETCH_DOCTOR_ALL_SLOTS, this.doctor.id);
      this.$store.commit(this.$types.SCHEDULE_DOCTOR_SET, this.doctor);
      this.isDoctorLoading = false;
    } catch (e) {
      console.log('Редактирование расписания отключено');
    } finally {
      this.isLoading = false;
    }
  },
  beforeDestroy() {
    this.$store.commit(this.$types.SCHEDULE_DOCTOR_SET, null);
    this.$store.commit(this.$types.SCHEDULE_DATE_SET, null);
    this.$store.commit(this.$types.SCHEDULE_SET, []);
  },
  methods: {
    checkFeature(keyOne, keyTwo) {
      return this.parentModalName ? this.checkFeatureAccess({ name: keyOne.name, url: keyOne.url })
        : this.checkFeatureAccess({ name: keyTwo.name, url: keyTwo.url });
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    openTemplatesModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ScheduleTemplatesModal',
        props: {
          doctor: this.doctor,
        },
      });
    },
    openCheckupModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ScheduleMakeAnAppointmentModal',
        props: {
          time: this.activeTimeTab,
          doctorId: this.selectedDoctor.id,
          parentModalName: this.parentModalName,
          patientIdFromChat: this.patientId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.without-border {
  border: none;
}

.schedule-modal {
  height: 500px;
}

.schedule-modal-inner {
  padding: 0 10px;
  box-sizing: border-box;
}

::v-deep .schedule-block {
  border-bottom: none;
}

::v-deep .schedule-wrapper {
  min-height: unset;
  height: unset;
}
</style>
