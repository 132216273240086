<template>
  <label class="crm-file-input">
    <input
      ref="file"
      type="file"
      class="crm-file-input-element"
      :accept="type"
      :multiple="multiple"
      @change="onChangeFiles"
    >

    <b-button
      variant="outline-primary"
      :type="$const.PRIMARY_BUTTON"
      :size="size"
      class="crm-button"
      @click="onClickAddFiles"
    >
      <b-icon
        icon="plus"
      />
      <slot />
    </b-button>
  </label>
</template>

<script>
import { showCustomMessage } from '@/helpers/messages';

export default {
  name: 'BaseFileInput',
  components: {
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [Array, Object, File],
      default: () => ([]),
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'sm',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    files() {
      if (Array.isArray(this.value)) return this.value;

      return (this.value !== null) ? [this.value] : [];
    },
  },
  methods: {
    clearInputValue() {
      const fileElement = this.$refs.file;

      if (fileElement) fileElement.value = '';
    },
    checkFileUniq(file) {
      return !this.files.some((item) => ((item.name === file.name) && (+item.size === +file.size)));
    },
    onClickAddFiles() {
      if (this.disabled) return;

      const fileElement = this.$refs.file;

      if (fileElement) fileElement.click();
    },
    onChangeFiles(event) {
      const files = [...event.target.files];
      const newFiles = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const file of files) {
        if (!this.checkFileUniq(file)) {
          showCustomMessage('warning', '', `Файл ${file.name} уже добавлен`);
        } else {
          newFiles.push(file);
        }
      }
      let resValue = [];

      if (this.multiple) {
        resValue = this.files.concat(newFiles);
      } else {
        resValue = newFiles;
      }

      this.$emit('change', resValue);

      this.clearInputValue();
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-file-input {
  position: relative;
  display: flex;
  margin-top: 20px;
}
.crm-file-input-element {
  position: absolute;
  opacity: 0;
}
.crm-button {
  // font-weight: 300;
  // width: auto;
  // padding: 7px 20px;
  margin: 0 auto;
}
::v-deep.crm-icon-cross {
  margin-right: 15px;

  path {
    fill: $blue;
  }
}
</style>
