<template>
  <b-modal
    v-model="isOpen"
    centered
    hide-footer
    no-close-on-backdrop
    title="Удаление шаблона ГП"
    @hidden="onClose"
  >
    <custom-scrollbar>
      <div
        class="crm-container policy-container"
      >
        <div class="description">
          Вы уверены, что хотите удалить шаблон ГП?
        </div>
        <div class="crm-wrapper-buttons">
          <b-button
            variant="outline-danger"
            :type="$const.PRIMARY_BUTTON"
            class="mr-4"
            @click="onClose"
          >
            Отменить
          </b-button>

          <b-button
            variant="danger"
            :type="$const.PRIMARY_BUTTON"
            :loading="isDeleting"
            @click="onClickDelete"
          >
            Удалить
          </b-button>
        </div>
      </div>
    </custom-scrollbar>
  </b-modal>
</template>

<script>
import { mixinRoles } from '@/mixins';

export default {
  name: 'IndemnityTemplateDeleteModal',
  mixins: [mixinRoles],
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    templateId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isDeleting: false,
    };
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onClickDelete() {
      this.isDeleting = true;

      try {
        await this.$store.dispatch(this.$types.INDEMNITY_TEMPLATE_DELETE, this.templateId);
      } catch (e) {
        console.log(e);
      } finally {
        this.isDeleting = false;
        this.onClose();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.policy-container {
  flex-direction: column;
  align-items: center;
}
.crm-wrapper-buttons {
  margin-top: 35px;
}
.description {
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
}
</style>
