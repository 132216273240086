export function validateData(field, value, schema) {
  let result;

  const pathArray = field.split('.');
  if (pathArray.length > 1) {
    result = schema.shape[pathArray[0]].shape[pathArray[1]].safeParse(value);
  } else {
    const partialSchema = schema.pick({ [field]: true });
    result = partialSchema.safeParse({ [field]: value });
  }

  if (!result.success) {
    return result.error.errors.map((e) => e.message);
  }
  return null;
}

function nestedValue(obj, pathArray) {
  let currentValue = obj;
  for (let i = 0; i < pathArray.length; i += 1) {
    if (!currentValue) {
      return null;
    }
    currentValue = currentValue[pathArray[i]];
  }
  return currentValue;
}

export function makeValidate(fields, data, schema) {
  const errors = {};
  fields.forEach((field) => {
    const pathArray = field.split('.');
    const fieldValue = nestedValue(data, pathArray);

    const error = validateData(field, fieldValue, schema);
    if (error) {
      errors[field] = error;
    }
  });
  return errors;
}
