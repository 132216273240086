<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    :title="tag.id ? 'Редактирование тэга' : 'Добавление тэга'"
    :hide-footer="isLoading"
    @hidden="onClose"
    @ok="onClose"
  >
    <template v-if="isLoading">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      <b-form-group
        label="Название"
        label-class="required"
        invalid-feedback="Поле является обязательным"
        :state="!$v.tagData.name.$error"
      >
        <b-form-input
          v-model="$v.tagData.name.$model"
          placeholder="Введите название"
          required
        />
      </b-form-group>

      <b-form-group
        label="Комментарий"
        label-class="required"
        invalid-feedback="Поле является обязательным"
        :state="!$v.tagData.comment.$error"
      >
        <b-form-input
          v-model="$v.tagData.comment.$model"
          placeholder="Введите комментарий"
          required
        />
      </b-form-group>
    </template>

    <template #modal-footer>
      <b-button
        variant="success"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        @click="onSave"
      >
        Сохранить
      </b-button>
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        @click="onClose"
      >
        Отменить
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { showValidationErrorMessage } from '@/helpers/messages';

import Preloader from '@/components/Preloader';

export default {
  name: 'QueuesTagEditModal',
  components: {
    Preloader,
  },
  mixins: [validationMixin],
  props: {
    tag: {
      type: Object,
      default: () => ({}),
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
    afterSave: {
      type: [Function],
      default: () => {},
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      tagData: {
        id: null,
        name: null,
        comment: null,
      },
    };
  },
  validations() {
    return {
      tagData: {
        id: {},
        name: { required },
        comment: { required },
      },
    };
  },
  created() {
    if (this.tag.id) this.tagData = { ...this.tag };
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onSave() {
      this.$v.$touch();

      if (this.$v.$error) {
        showValidationErrorMessage();
        return;
      }

      try {
        this.isLoading = true;
        await this.$store.dispatch(this.$types[this.tag.id ? 'QUEUES_TAG_UPDATE' : 'QUEUES_TAG_CREATE'], this.tagData);
        this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
        this.afterSave();
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
