import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.INDEMNITY_LETTER_SET](state, payload) {
    state.letter = payload;
  },
  [localTypes.INDEMNITY_IS_LETTER_SAVING_SET](state, payload) {
    state.isLetterSaving = payload;
  },
  [localTypes.INDEMNITY_TEMPLATE_REMOVE_BY_ID](state, payload) {
    state.templates = state.templates.filter((template) => template.id !== payload);
  },
  [localTypes.INDEMNITY_TEMPLATE_SET](state, payload) {
    state.templates = payload;
  },
  [localTypes.INDEMNITY_IS_LETTER_SENDING_SET](state, payload) {
    state.isLetterSending = payload;
  },
  [localTypes.INDEMNITY_IS_LETTER_EDITED_SET](state, payload) {
    state.isLetterEdited = payload;
  },
  [localTypes.INDEMNITY_LETTERS_SET](state, payload) {
    state.letters = payload;
  },
  [localTypes.INDEMNITY_CHECKVALIDATION](state) {
    state.isCheckValidation = !state.isCheckValidation;
  },
  [localTypes.INDEMNITY_IS_EDIT_TEMPLATE_TOGLE](state) {
    state.isEditIndemnity = !state.isEditIndemnity;
  },
  [localTypes.INDEMNITY_NEW_TEMPLATE_VALIDATION](state, payload) {
    state.isCreateNewTemplateValidate = payload;
  },
  [localTypes.INDEMNITY_PERSON_ID_SET](state, payload) {
    state.personId = payload;
  },
  [localTypes.INDEMNITY_HISTORY_WITHOUT_POLICY_SET](state, payload) {
    state.historyWithoutPolicy = payload;
  },
  [localTypes.INDEMNITY_ANALYSIS_LETTERS_SET](state, payload) {
    state.analysisLetters = payload;
  },
  [localTypes.INDEMNITY_LETTERS_LOADING_SET](state, payload) {
    state.isLettersLoading = payload;
  },
  [localTypes.INDEMNITY_EDIT_TEMPLATE_SET](state, payload) {
    state.editTemplate = payload;
  },
  [localTypes.INDEMNITY_IS_EDIT_TEMPLATE_SAVING_SET](state, payload) {
    state.isTemplateSaving = payload;
  },
  [localTypes.INDEMNITY_ANNULMENT_REASON_SET](state, payload) {
    state.annulmentReason = payload;
  },
};
