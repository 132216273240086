<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    :title="title"
    size="lg"
    @hidden="onClose"
    @ok="onClose"
  >
    <div class="d-flex mb-1">
      <div class="font-weight-bold">
        Тип оценки
      </div>

      <div class="ml-2">
        <div v-if="!!doctorEvaluation.id">
          {{ evaluationTypesEnum[doctorEvaluation.type] }}
        </div>

        <div v-else>
          <b-select
            v-model="evaluationType"
            value-field="value"
            text-field="name"
            :options="evaluationTypes"
            size="sm"
          >
            <template #first>
              <b-form-select-option :value="null">
                Выберите тип
              </b-form-select-option>
            </template>
          </b-select>
        </div>
      </div>
    </div>

    <div class="d-flex mb-1">
      <div class="font-weight-bold">
        Номер консультации
      </div>

      <div class="ml-2">
        {{ doctorEvaluation.consultationId }}
      </div>
    </div>

    <div class="d-flex mb-1">
      <div class="font-weight-bold">
        ФИО врача
      </div>

      <div class="ml-2">
        {{ doctorEvaluation.doctorName }}
      </div>
    </div>

    <div class="d-flex mb-1">
      <div class="font-weight-bold">
        Дата закрытия
      </div>

      <div class="ml-2">
        {{ closeDate }}
      </div>
    </div>

    <div
      v-if="doctorEvaluation.agreetDate"
      class="d-flex mb-1"
    >
      <div class="font-weight-bold">
        Дата согласия
      </div>

      <div class="ml-2">
        {{ agreetDate }}
      </div>
    </div>

    <div
      v-if="doctorEvaluation.isAgree != null"
      class="d-flex mb-1"
    >
      <div class="font-weight-bold">
        Статус согласия
      </div>

      <div
        class="ml-2"
        :class="doctorEvaluationColor"
      >
        {{ doctorEvaluationText }}
      </div>
    </div>

    <div
      v-if="doctorEvaluation.agreeComment"
      class="d-flex mb-1"
    >
      <div class="font-weight-bold">
        Комментарий согласия
      </div>

      <div class="ml-2">
        {{ doctorEvaluation.agreeComment || '-' }}
      </div>
    </div>

    <div
      v-if="doctorEvaluation.points"
      class="d-flex mb-1"
    >
      <div class="font-weight-bold">
        Ошибки
      </div>

      <div
        v-if="doctorEvaluation.points"
        class="ml-2"
      >
        Общий балл {{ doctorEvaluation.points }}
      </div>
    </div>

    <div v-if="mistakeList?.length">
      <b-table

        :fields="fields"
        :items="mistakeList"
        class="mt-2"
        bordered
        size="sm"
      >
        <template #cell(action)="row">
          <b-form-radio-group
            v-model="mistakeData[row.item.id]"
            :options="mistakeOptions"
            :state="getMistakeRadioState(mistakeData[row.item.id], row.item.isShowIfTrue)"
            :disabled="isHundredPoint || !!doctorEvaluation.id"
            class="d-flex"
          />
        </template>

        <template #cell(mistakeType)="row">
          {{ mistakeTypes[row.item.mistakeType] }}
        </template>
      </b-table>

      <b-form-checkbox
        v-if="!doctorEvaluation.id"
        v-model="isHundredPoint"
        switch
        @change="mistakeData = []"
      >
        100 баллов
      </b-form-checkbox>

      <b-form-group
        label="Комментарий"
        class="mt-3"
      >
        <b-form-textarea
          v-model.trim="mistakeComment"
          placeholder="Введите текст"
          rows="3"
          size="sm"
          :state="isRequiredState"
        />
      </b-form-group>
    </div>
    <div
      v-else
      class="mt-2"
    >
      Нет ошибок
    </div>

    <div
      v-if="!mistakeList?.length"
      class="d-flex mb-1"
    >
      <div class="font-weight-bold">
        Комментарий
      </div>

      <div
        class="ml-2 w-100"
      >
        {{ doctorEvaluation.comment }}
      </div>
    </div>

    <template #modal-footer>
      <b-button
        v-if="!!doctorEvaluation.id"
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        :disabled="isLoading"
        @click="onDelete"
      >
        Удалить
      </b-button>

      <template v-else>
        <b-button
          variant="success"
          :type="$const.PRIMARY_BUTTON"
          class="float-right"
          :disabled="disableSaveButton"
          @click="onSave"
        >
          Сохранить
        </b-button>
      </template>

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="float-right ml-2"
        :disabled="isLoading"
        @click="onClose"
      >
        Отмена
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Bus from '@/eventBus';
import {
  format,
  parseISO,
} from '@evd3v/date-fns';

import { DOCTORS_EVALUATION_MISTAKE_TYPES } from '@/helpers/consts';

export default {
  name: 'DoctorEvaluationModal',
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Оценка консультации врача',
    },
    doctorEvaluation: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      isHundredPoint: false,
      evaluationType: null,
      mistakeComment: '',
      mistakeData: [],
      mistakeList: [],
      mistakeTypes: DOCTORS_EVALUATION_MISTAKE_TYPES,
      fields: [
        {
          key: 'action',
          label: '',
          class: 'p-1',
        },
        {
          key: 'mistake',
          label: 'Название',
          class: 'p-1 ',
        },
        {
          key: 'mistakeComment',
          label: 'Комментарий',
          class: 'p-1',
        },
        {
          key: 'mistakeType',
          label: 'Тип ошибки',
          class: 'p-1',
        },
      ],
      mistakeOptions: [
        { text: 'Да', value: true },
        { text: 'Нет', value: false },
      ],
      evaluationTypes: [
        {
          value: '0',
          name: 'Жалоба обоснованная',
        },
        {
          value: '1',
          name: 'Жалоба необоснованная',
        },
        {
          value: '2',
          name: 'Отрицательный отзыв',
        },
        {
          value: '3',
          name: 'Положительный отзыв',
        },
        {
          value: '4',
          name: 'Свободная выборка',
        },
        {
          value: '5',
          name: 'Маркетинг',
        },
      ],
      evaluationTypesEnum: {
        JustifiedComplaint: 'Жалоба обоснованная',
        UnfoundedComplaint: 'Жалоба необоснованная',
        NegativeFeedback: 'Отрицательный отзыв',
        PositiveFeedback: 'Положительный отзыв',
        FreeSample: 'Свободная выборка',
        Marketing: 'Маркетинг',
      },
    };
  },
  computed: {
    closeDate() {
      return this.doctorEvaluation.closeDate
        ? `${format(parseISO(this.doctorEvaluation.closeDate), 'dd.MM.yyyy HH:mm')}` : '-';
    },
    agreetDate() {
      if (!this.doctorEvaluation.agreetDate) return '-';
      const date = new Date(this.doctorEvaluation.agreetDate);
      const localTimeZoneOffsetMilliseconds = new Date().getTimezoneOffset() * -1 * 60 * 1000;
      const dateInLocalTimeZome = new Date(+date + localTimeZoneOffsetMilliseconds);

      return format(dateInLocalTimeZome, 'dd.MM.yyyy HH:mm');
    },
    doctorEvaluationText() {
      if (this.doctorEvaluation.isAgree === true) return 'Согласен';
      if (this.doctorEvaluation.isAgree === false) return 'Не согласен';
      return '-';
    },
    doctorEvaluationColor() {
      if (this.doctorEvaluation.isAgree === true) return 'text-success';
      if (this.doctorEvaluation.isAgree === false) return 'text-danger';
      return '';
    },
    allMistakesChecked() {
      return !this.mistakeList.find((m) => this.mistakeData[m.id] == null);
    },
    disableSaveButton() {
      return this.isLoading || this.evaluationType === null || this.isHundredPoint ? !this.mistakeComment : !this.allMistakesChecked;
    },
    isRequiredState() {
      return this.isHundredPoint ? !!this.mistakeComment : undefined;
    },
  },
  async created() {
    this.isLoading = true;
    this.mistakeData = {};

    try {
      if (this.doctorEvaluation.id) {
        this.mistakeList = this.doctorEvaluation.mistakes?.map((m) => ({ ...m }));
        this.mistakeComment = this.doctorEvaluation.comment;
        this.mistakeList.forEach((m) => {
          this.mistakeData[m.id] = m.isMistake;
        });
      } else {
        this.mistakeList = await this.$store.dispatch(this.$types.DOCTORS_EVALUATION_MISTAKES_FETCH, this.doctorEvaluation.doctorId);
      }
      this.mistakeList.sort((a, b) => a.mistakeType - b.mistakeType);
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    getMistakeRadioState(value, isError) {
      if (value == null) return undefined;

      if (value === true && isError) return false;
      if (value === false && !isError) return false;

      return true;
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onSave() {
      try {
        this.isLoading = true;

        await this.$store.dispatch(this.$types.DOCTORS_EVALUATION_EVALUATE, {
          type: this.evaluationType,
          consultationId: this.doctorEvaluation.consultationId,
          mistakes: this.isHundredPoint ? [] : Object.entries(this.mistakeData).map(([mistakeId, isMistake]) => ({ mistakeId, isMistake })),
          comment: this.mistakeComment,
        });

        this.onClose();
        Bus.$emit('doctor-evaluation:fetch');
      } finally {
        this.isLoading = false;
      }
    },
    async onDelete() {
      try {
        this.isLoading = true;
        await this.$store.dispatch(this.$types.DOCTORS_EVALUATION_DELETE, this.doctorEvaluation.consultationId);

        this.onClose();
        Bus.$emit('doctor-evaluation:fetch');
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>

</style>
