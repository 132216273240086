import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.IS_LOADING_GET](state) {
    return state.isLoading;
  },
  [localTypes.FETCH_USERS_TRIGGER](state) {
    return state.fetchUsersTrigger;
  },
};
