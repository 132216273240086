import LocationApi from '@/services/location/location.api';

export default class LocationService {
  constructor($axios) {
    this.api = new LocationApi($axios);
  }

  async getCities(params) {
    try {
      return this.api.getCities(params);
    } catch (error) {
      console.warn(error);
      return [];
    }
  }

  async getRegions(params) {
    try {
      return this.api.getRegions(params);
    } catch (error) {
      console.warn(error);
      return [];
    }
  }

  async getAllCities(query) {
    try {
      return this.api.getAllCities(query);
    } catch (error) {
      console.warn(error);
      return [];
    }
  }

  createRegion(params) {
    return this.api.createRegion(params);
  }

  updateRegion(params) {
    return this.api.updateRegion(params);
  }

  deleteRegion(params) {
    return this.api.deleteRegion(params);
  }
}
