<template>
  <b-modal
    v-model="isOpen"
    size="lg"
    title="Прикрепление пациента"
    centered
    @hidden="onClose"
    @ok="onClose"
  >
    <template v-if="isLoading">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      <div class="">
        <div class="mb-3">
          <div>Выберите полис, к которому хотите прикрепить</div>

          <b-form-select
            v-model="policyId"
            :options="listAvailablePolicies"
            value-field="id"
            text-field="policyId"
            size="sm"
            class="mt-2"
          />
        </div>

        <div class=" mb-4">
          <div>Выберите юзера, которого хотите прикрепить</div>

          <div class="d-flex mt-2">
            <b-form-input
              v-model="patientId"
              type="number"
              :disabled="!policyId"
              placeholder="Введите id пациента"
              @keyup.native.enter="onSearch"
            />

            <b-button
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              class="ml-2"
              :disabled="!policyId"
              @click="onSearch"
            >
              <b-icon icon="search" />
            </b-button>
          </div>
        </div>

        <template v-if="isSearching">
          <preloader />
        </template>

        <template v-else-if="!isSearching && patient">
          <div class="d-flex align-items-center rounded p-2 border">
            <div class="w-100">
              <div>
                <span class="text-secondary">
                  ФИО:
                </span>
                {{ patient.lastName }} {{ patient.firstName }} {{ patient.middleName }}
              </div>
              <div v-if="patient.birthDate">
                <span class="text-secondary">
                  Дата рождения:
                </span>
                {{ birthday }}
              </div>
              <b-button
                class="float-right ml-2"
                size="sm"
                :disabled="disabled"
                :variant="disabled ? '' : 'primary'"
                :type="$const.PRIMARY_BUTTON"
                @click="onAttach(patient.id)"
              >
                {{ disabled ? 'Пациент добавлен' : 'Добавить' }}
              </b-button>
            </div>
          </div>
        </template>
      </div>
    </template>

    <template #modal-footer>
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        @click="onClose"
      >
        Отменить
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Preloader from '@/components/Preloader';
import { api } from '@/helpers/api';
import { showCustomMessage, showErrorCustomMessage } from '@/helpers/messages';
import { format } from '@evd3v/date-fns';

export default {
  name: 'AttachPatientModal',
  components: {
    Preloader,
  },

  props: {
    skipIdLIst: {
      type: Array,
      default: () => ([]),
    },
    listAvailablePolicies: {
      type: Array,
      default: () => ([]),
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
    mainPersonId: {
      type: Number,
      required: true,
    },
    cb: {
      type: Function,
      default: () => {},
    },
    cbArgs: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({
    isOpen: true,
    isLoading: false,
    isSearching: false,
    patientId: '',
    patient: null,
    policyId: null,
  }),
  computed: {
    birthday() {
      if (!this.patient || !this.patient.birthDate) return;

      return this.getFormatDate(this.patient.birthDate, 'dd-MM-yyyy');
    },
    disabled() {
      if (!this.skipIdLIst.length) return;
      if (!this.patient) return;

      return this.skipIdLIst.some((item) => item === this.patient.id);
    },
  },

  methods: {
    getFormatDate(date, dateFormat) {
      return date && dateFormat ? format(new Date(date), dateFormat) : null;
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onSearch() {
      this.isSearching = true;

      try {
        const { data } = await api.get(`v2/person/${this.patientId}`);

        if (data.isSuccess) {
          if (data.data) this.patient = data.data;
        } else if (data.errors) {
          data.errors.forEach((item) => {
            showErrorCustomMessage(item.message);
          });
        }
      } finally {
        this.isSearching = false;
      }
    },
    async onAttach(id) {
      const requestData = {
        mainPolicyId: this.policyId,
        personId: id,
        mainPersonId: this.mainPersonId,
        isBind: true,
      };

      try {
        const response = await api.post('v2/policy/bind-or-delete-family-person', requestData);

        if (response.status === 200) {
          showCustomMessage('success', 'Успех!', '');

          await this.cb(...this.cbArgs);

          this.onClose();
        }
      } catch {
        showCustomMessage('warning', 'Что-то пошло не так', '');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  min-height: 350px;
}
</style>
