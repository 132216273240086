import StoriesApi from '@/services/stories/stories.api';

export default class StoriesService {
  constructor($axios) {
    this.api = new StoriesApi($axios);
  }

  getStoriesList(params) {
    return this.api.getStoriesList(params);
  }

  deleteStory(id) {
    return this.api.deleteStory(id);
  }

  deactivateStory(id) {
    return this.api.deactivateStory(id);
  }

  activateStory(id) {
    return this.api.activateStory(id);
  }

  getDictionaryEvent() {
    return this.api.getDictionaryEvent();
  }

  getStoryInfo(params) {
    return this.api.getStoryInfo(params);
  }

  storyCreate(params) {
    return this.api.storyCreate(params);
  }

  storyUpdate(params) {
    return this.api.storyUpdate(params);
  }

  storyFileUpload(file) {
    const formData = new FormData();
    formData.append('file', file);

    return this.api.storyFileUpload(formData);
  }

  getStoryImage(fileName) {
    return this.api.getStoryImage(fileName);
  }

  async getMkbList(params) {
    const data = await this.api.getMkbList(params);
    return data;
  }
}
