<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="xl"
    title="Обработка консультации"
    :hide-header-close="true"
    :no-close-on-backdrop="true"
    :hide-footer="isLoading"
    @hidden="onClose"
    @ok="onClose"
  >
    <template v-if="isLoading">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      <b-row>
        <b-col>
          <div class="mb-1 font-weight-bold">
            ID
          </div>
          <div class="mt-1 text-muted">
            {{ consultation.id }}
          </div>
        </b-col>

        <b-col>
          <div class="mb-1 font-weight-bold">
            Статус
          </div>
          <div class="mt-1 text-muted">
            {{ statuses.find((s) => s.index === consultation.status).title }}
          </div>
        </b-col>

        <b-col>
          <div class="mb-1 font-weight-bold">
            Дата создания
          </div>
          <div class="mt-1 text-muted">
            {{ formatDate(consultation.date) }}
          </div>
        </b-col>

        <b-col>
          <div class="mb-1 font-weight-bold">
            Запись на
          </div>
          <div class="mt-1 text-muted">
            {{ formatDate(consultation.slotDate) }}
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col>
          <div class="mb-1 font-weight-bold">
            Время взятия в работу оператором
          </div>
          <div class="mt-1 text-muted">
            {{ formatDate(consultation.problemInfo.startProcessingDate) || '-' }}
          </div>
        </b-col>

        <b-col>
          <div class="mb-1 font-weight-bold">
            Время ожидания обработки
          </div>
          <div class="mt-1 text-muted">
            {{ consultation.problemInfo.pendingTime ? consultation.problemInfo.pendingTime.split('.')[0] : '-' }}
          </div>
        </b-col>

        <b-col>
          <div class="mb-1 font-weight-bold">
            Время завершения консультации
          </div>
          <div class="mt-1 text-muted">
            {{ formatDate(consultation.problemInfo.endProcessingDate) || '-' }}
          </div>
        </b-col>
      </b-row>

      <hr>

      <b-row class="mt-4">
        <b-col>
          <div class="mb-1 font-weight-bold">
            ФИО пациента
          </div>
          <div class="mt-1 text-muted">
            {{ consultation.patientPerson.fullName }}
          </div>
        </b-col>

        <b-col>
          <div class="mb-1 font-weight-bold">
            Телефон пациента
          </div>
          <div class="mt-1 text-muted">
            {{ consultation.patientPerson.phoneNumber || 'не указан' }}
          </div>
        </b-col>

        <b-col>
          <div class="mb-1 font-weight-bold">
            Текст консультации
          </div>
          <div class="mt-1 text-muted">
            {{ consultation.body }}
          </div>
        </b-col>
      </b-row>

      <hr>

      <b-row class="mt-4">
        <b-col>
          <div class="mb-1 font-weight-bold">
            ФИО врача
          </div>
          <div class="mt-1 text-muted">
            {{ consultation.targetDoctor.lastName }} {{ consultation.targetDoctor.firstName }} {{ consultation.targetDoctor.middleName }}
          </div>
        </b-col>

        <b-col>
          <div class="mb-1 font-weight-bold">
            Телефон врача
          </div>
          <div class="mt-1 text-muted">
            {{ consultation.targetDoctor.phoneNumber }}
          </div>
        </b-col>

        <b-col>
          <div class="mb-1 font-weight-bold">
            Специальность врача
          </div>
          <div class="mt-1 text-muted">
            {{ consultation.targetDoctor.specialization }}
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col v-if="consultation.targetDoctor.regions">
          <div class="mb-1 font-weight-bold">
            Регионы
          </div>
          <div>
            <div
              v-for="region in consultation.targetDoctor.regions"
              :key="region.id"
              class="mt-1 text-muted"
            >
              {{ region }}
            </div>
          </div>
        </b-col>

        <b-col>
          <div class="mb-1 font-weight-bold">
            Название клиники
          </div>
          <div class="mt-1 text-muted">
            {{ consultation.clinic.clinicName }}
          </div>
        </b-col>

        <b-col>
          <div class="mb-1 font-weight-bold">
            Платформа
          </div>
          <div class="mt-1 text-muted">
            {{ clientType }}
          </div>
        </b-col>
      </b-row>

      <div class="mt-4">
        <div class="mb-1 font-weight-bold">
          Контакты клиники
        </div>
        <b-table
          v-if="consultation.clinic.contacts"
          :fields="contactsFields"
          :items="consultation.clinic.contacts"
          bordered
          hover
          small
        />
      </div>

      <div
        v-if="watchProccessingComments"
        class="mt-5"
      >
        <!-- <div class="mb-2 font-weight-bold">
          Причина попадания в этот раздел
        </div> -->
        <div>
          <div class="mb-1 small font-weight-bold">
            Комментарий от врача
          </div>

          <b-form-textarea
            v-model="proccessingComment"
            placeholder="Введите комментарий врача"
            rows="3"
            :state="!!proccessingComment"
          />
        </div>
        <div class="mt-2">
          <div class="mb-1 small font-weight-bold">
            Комментарий от пациента
          </div>

          <b-form-textarea
            v-model="proccessingPatientComment"
            placeholder="Введите комментарий пациента"
            rows="3"
            :state="!!proccessingPatientComment"
          />
        </div>
      </div>

      <!-- <div class="h6 mt-2">
        Консультация закрылась в течении 5 минут. Врач оставил заключение.
      </div> -->
    </template>

    <template #modal-footer>
      <div class="w-100 d-flex align-items-center justify-content-between">
        <b-button
          variant="outline-primary"
          :type="$const.PRIMARY_BUTTON"
          :disabled="isLoading"
          size="sm"
          class="float-right"
          @click="onAction(1)"
        >
          Сохранить данные (без обработки)
        </b-button>
        <div class="d-flex align-items-center">
          <div class="mr-4">
            {{ statusText }}
          </div>

          <b-button
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            class="float-right"
            :disabled="actionDisabled"
            @click="onAction(2)"
          >
            Обработать
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  format,
} from '@evd3v/date-fns';
import { mixinRoles } from '@/mixins';
import { showSuccessCustomMessage } from '@/helpers/messages';
import { CONSULTATION_STATUSES, CLIENT_TYPES } from '@/helpers/consts';

import Preloader from '@/components/Preloader';

export default {
  name: 'ConsultationControlModal',
  components: {
    Preloader,
  },
  mixins: [mixinRoles],

  props: {
    consultation: {
      type: [Object],
      default: null,
    },
    afterFunc: {
      type: Function,
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      proccessingComment: null,
      proccessingPatientComment: null,
      statuses: CONSULTATION_STATUSES,
      contactsFields: [
        {
          key: 'fio',
          label: 'ФИО',
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'phoneNumber',
          label: 'Номер',
          tdClass: 'align-middle',
          class: 'text-center',
        },
      ],
    };
  },
  computed: {
    statusText() {
      switch (this.consultation.problemInfo.status) {
        case 1:
          return 'В ожидании';
        case 2:
          return 'Закрыто';
        case 3:
          return 'Просрочено';

        default:
          return 'В обработке';
      }
    },
    watchProccessingComments() {
      return this.checkFeatureAccess({ name: 'Отображать Комментарий врача и пациента', url: '/consultations-control' });
    },
    actionDisabled() {
      if (!this.watchProccessingComments) {
        return this.isLoading;
      }

      return this.isLoading
        || !this.proccessingComment
        || !this.proccessingPatientComment;
    },
    clientType() {
      const { title } = CLIENT_TYPES.find((item) => item.id === this.consultation.clientType);
      return title;
    },
  },

  created() {
    this.proccessingComment = this.consultation.problemInfo.doctorComment;
    this.proccessingPatientComment = this.consultation.problemInfo.patientComment;
  },

  methods: {
    formatDate(date) {
      return date && format(new Date(date), 'dd.MM.yyyy HH:mm');
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onAction(status = 2) {
      try {
        const { consultation: { id: consultationId }, proccessingComment, proccessingPatientComment } = this;
        await this.$store.dispatch(
          this.$types.CONSULATION_CONTROL_PROBLEM_RESOLVE,
          {
            consultationId, proccessingComment, proccessingPatientComment, status,
          },
        );
        // this.$store.commit(this.$types.TOGGLE_CONSULTATIONS_PROBLEMS_TRIGGER);
        this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
        showSuccessCustomMessage('Консультация обработана');

        this.$store.commit(this.$types.CONSULTATIONS_CONTROL_LOADING_SET, true);
        this.$store.commit(this.$types.TOGGLE_CONSULTATIONS_PROBLEMS_TRIGGER);
        if (this.afterFunc) this.afterFunc();
        this.$store.commit(this.$types.CONSULTATIONS_CONTROL_LOADING_SET, false);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
