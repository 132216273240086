import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.IS_LOADING_CALC](state) {
    return state.isLoading;
  },
};
