<template>
  <div class="preparation-analyses-card">
    <div class="block-icon">
      <img
        v-if="icon"
        :src="icon"
        alt=""
        class="icon"
      >
    </div>
    <p
      v-if="text"
      class="text"
    >{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: 'PreparationAnalysesCard',
  props: {
    icon: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
  .preparation-analyses-card {
    @apply flex items-center justify-start gap-x-2 p-4 rounded-lg bg-v-ui-background-monochrome-0;
  }

  .block-icon {
    width: 40px;
    height: 40px;
    @apply grid items-center justify-center shrink-0 rounded-lg bg-v-ui-light-service-info-monochrome;
  }

  .icon {
    width: 24px;
    height: 24px;
  }

  .text {
    margin-bottom: 0;
    @apply text-16-reg-400 text-v-ui-text-monochrome-80;
  }
</style>
