export const APPEAL_STATUSES = {
  NotSelected: null,
  InProgress: 0, // Передано
  Postponed: 1, // Отложено
  Closed: 2, // Закрыто
  Returned: 3, // Возвращено
};

export const APPEALS_STATUSES_TEXTS = [
  { id: APPEAL_STATUSES.NotSelected, title: 'Выберите статус' },
  { id: APPEAL_STATUSES.InProgress, title: 'Передано', color: 'green' },
  { id: APPEAL_STATUSES.Postponed, title: 'Отложено', color: 'orange' },
  { id: APPEAL_STATUSES.Closed, title: 'Закрыто', color: 'red' },
  { id: APPEAL_STATUSES.Returned, title: 'Возвращено', color: 'orange' },
];

export const APPEAL_RECONCILIATION_STATUSES = {
  Closed: 2, // Готово
  InProgress: 3, // Передано
  Postponed: 4, // Отложено
};

export const APPEAL_RECONCILIATION_STATUSES_TEXTS = [
  {
    id: APPEAL_RECONCILIATION_STATUSES.Closed,
    title: 'Готово',
  },
  {
    id: APPEAL_RECONCILIATION_STATUSES.InProgress,
    title: 'Передано',
  },
  {
    id: APPEAL_RECONCILIATION_STATUSES.Postponed,
    title: 'Отложено',
  },
];

export const APPEALS_TECHSUPPORT_WHERE_TO_SEND = {
  It: 0,
  CustomerService: 1,
  QualityControlService: 2,
};

export const APPEALS_TECHSUPPORT_WHERE_TO_SEND_TEXTS = [
  {
    id: APPEALS_TECHSUPPORT_WHERE_TO_SEND.It,
    title: 'It',
  },
  {
    id: APPEALS_TECHSUPPORT_WHERE_TO_SEND.CustomerService,
    title: 'Клиентская служба',
  },
  {
    id: 2,
    title: APPEALS_TECHSUPPORT_WHERE_TO_SEND.QualityControlService,
  },
];

export const APPEALS_QUALITY_CONTROL_WHERE_TO_SEND = {
  CustomerService: 0,
};

export const APPEALS_QUALITY_CONTROL_WHERE_TO_SEND_TEXTS = [
  {
    id: APPEALS_QUALITY_CONTROL_WHERE_TO_SEND.CustomerService,
    title: 'Клиентская служба',
  },
];

export const APPEALS_PURPOSES = {
  Information: 0,
  Checkup: 1,
  ServiceCoordination: 2,
  TechnicalSupport: 3,
  Gostelemed: 4,
  QualityControlDoctors: 5,
  ContractDepartament: 6,
  Complaint: 7,
};

export const APPEALS_PURPOSES_TEXTS = [
  {
    id: APPEALS_PURPOSES.Information,
    title: 'Информационное',
    key: 'information',
  },
  {
    id: APPEALS_PURPOSES.Checkup,
    title: 'Чекап',
    key: 'checkup',
  },
  {
    id: APPEALS_PURPOSES.ServiceCoordination,
    title: 'Согласование услуг',
    key: 'serviceCoordination',
  },
  {
    id: APPEALS_PURPOSES.Gostelemed,
    title: 'Гостелемед',
    key: 'Gostelemed',
  },
  {
    id: APPEALS_PURPOSES.QualityControlDoctors,
    title: 'Контроль качества врачей',
    key: 'QualityControlDoctors',
  },
  {
    id: APPEALS_PURPOSES.ContractDepartament,
    title: 'Договорной отдел',
    key: 'ContractDepartament',
  },
  {
    id: APPEALS_PURPOSES.Complaint,
    title: 'Жалоба',
    key: 'Complaint',
  },
];

export const APPEALS_CATEGORIES = {
  Other: 'Другое',

  InformationCommon: 'Общая информация',
  InformationDoctisApplication: 'Приложение/сайт Доктис',
  InformationAnalyzes: 'Анализы',
  InformationRentDevice: 'Аренда прибора',

  ServiceCoordinationTreatmentPolicy: 'Лечение по полису',
  ServiceCoordinationPlannedEmergencyHospitalization: 'Плановая/экстренная госпитализация',
  ServiceCoordinationTransportation: 'Транспортировка',
  ServiceCoordinationSignUpForTreatment: 'Запись на лечение',
  ServiceCoordinationAdjustmentExtensionGP: 'Корректировка/Продление ГП',
  ServiceCoordinationOther: 'Другое',

  GostelemedQualityControlDoctorsOMS: 'Контроль качества врачей ОМС',
  GostelemedPatientAppRequest: 'Запрос по приложению Гостелемед пациент',
  GostelemedPatientSiteRequest: 'Запрос по сайту Гостелемед пациент',
  GostelemedClinicDoctorRequest: 'Запрос от врачей/администраторов клиник',
  GostelemedRemoteMonitoringRequests: 'Запросы по дистанционному Мониторингу',

  QualityControlDoctorsProtocol: 'Протокол',
  QualityControlDoctorsCheck: 'Чек',
  QualityControlDoctorsRefund: 'Возврат средств',
  QualityControlDoctorsCancelRescheduleConsultation: 'Отмена/перенос консультации',
  QualityControlDoctorsPhysicianRegistrationRequest: 'Заявка на регистрацию врачей',

  ContractDepartamentSearchClinicServices: 'Поиск клиники/услуг',
  ContractDepartamentNoCustomerInCRM: 'Нет клиента в СРМ',
  ContractDepartamentInsuranceCompanyRequest: 'Запрос в страховую компанию',

  ContractDepartamentNoPolicyInCRM: 'Нет полиса в СРМ',
  ContractDepartamentUpdatePolicyDataInCRM: 'Изменить данные полиса в СРМ',
  ContractDepartamentFindClinicInCity: 'Найти клинику в городе',
  ContractDepartamentUpdateClinicData: 'Изменить данные клиники',
  ContractDepartamentPolicyNotVisibleInClinic: 'Полис не видят в клинике',
  ContractDepartamentPatientProtocolRequestLPU: 'Запрос протокола на Пациента в ЛПУ',

  ComplaintDMS: 'ДМС',
  ComplaintOMS: 'ОМС',
};

export const APPEALS_TYPES = {
  Other: 'Другое',

  Rent: 'Аренда',
  Extension: 'Продление',
  Return: 'Возврат',
  DoctorDidNotTakeRequest: 'Врач не взял в работу обращение',
  DoctorDidNotContactPatient: 'Врач взял в работу обращение, но не связался с пациентом',
  PatientDidNotRespond: 'Пациент не вышел на связь',
  QuestionsAfterConsultation: 'Остались вопросы после консультации',
  Protocol: 'Протокол',
  RescheduleCancelConsultation: 'Перенос/отмена консультации',
  Consultation: 'Консультация',
  OpeningClosingSickLeave: 'Открытие/закрытие больничного листа',
  DoctorVisitHome: 'Выезд врача на дом',
  NeedInPersonConsultation: 'Требуется очный прием',
  OpenScheduleDoctorCabinet: 'Открыть расписание врача/кабинета льготной выписки',
  CreateEditCancelSchedule: 'Создание/редактирование/аннулирование расписания',
  AddRemoveDoctors: 'Добавление/удаление врачей',
  ConsultationSchedule: 'Консультация',
  Monitors: 'Моники',
  RefusalMonitoring: 'Отказ от мониторинга',
  ChangeMonitoringTime: 'Изменение времени мониторинга',
  MonitoringConsultation: 'Консультация',
  TelemedicineConsultation: 'Телемедицинская консультация',
  Analysis: 'Анализы',
  ComplaintsDoctor: 'Жалоба на врача',
  ComplaintsServiceAgreement: 'Жалоба на согласование услуг',
  ComplaintsClinic: 'Жалоба на клинику',
  ComplaintsServiceProvision: 'Жалоба на оказание услуг',
  ComplaintsService: 'Жалоба на сервис',
};

export const TYPES = {
  INFORMATION: 0,
  TREATMENT: 1,
  GOSTELEMED: 2,
};
