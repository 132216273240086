<template>
  <b-modal
    v-model="isOpen"
    centered
    size="lg"
    title="Отчет по врачам"
    hide-footer
    no-close-on-backdrop
    @hidden="onClose"
  >
    <div class="crm-form-fields">
      <div class="crm-form-field">
        <span class="crm-title">
          Фильтры:
        </span>
      </div>
    </div>

    <div class="crm-form-fields">
      <div class="crm-form-field">
        <base-radio-button
          v-model="selectedType"
          :name="1"
        >
          Новые записи, добавленные за период:
        </base-radio-button>
      </div>
    </div>

    <div
      :class="{ disabled: shouldBeDisabled(1) }"
      class="crm-form-fields"
    >
      <div class="crm-form-field">
        <span class="preposition">с</span>
        <base-date-picker
          v-model="$v.unloadingFrom.$model"
          :error="$v.unloadingFrom.$error"
          :errors="errorsValidation.unloadingFrom"
          :max-date="maxUnloadingDateFrom"
          :disabled="shouldBeDisabled(1)"
        />

        <div class="divider">
          <div class="divider-row" />
        </div>

        <span class="preposition">по</span>
        <base-date-picker
          v-model="$v.unloadingTo.$model"
          :error="$v.unloadingTo.$error"
          :errors="errorsValidation.unloadingTo"
          :min-date="minUnloadingDateTo"
          :max-date="maxDateTo"
          :disabled="shouldBeDisabled(1)"
        />
      </div>
    </div>

    <template v-if="checkFeatureAccess({ name: 'Возможность получить выгрузку по медицинской организации', url: '/doctors' })">
      <div class="crm-form-fields">
        <div class="crm-form-field">
          <base-radio-button
            v-model="selectedType"
            :name="2"
          >
            По медицинской организации:
          </base-radio-button>
        </div>
      </div>

      <div
        :class="{ disabled: shouldBeDisabled(2) }"
        class="crm-form-fields"
      >
        <div class="crm-form-field">
          <base-async-select
            v-model="$v.clinic.$model"
            :error="$v.clinic.$error"
            :errors="errorsValidation.clinic"
            :disabled="shouldBeDisabled(2)"
            :fetch-function="fetchClinic"
            class="crm-fluid"
            server-paginated
          />
        </div>
      </div>
    </template>

    <div class="crm-form-fields">
      <div class="crm-wrapper-buttons">
        <!-- <base-button
          :type="$const.SECONDARY_BUTTON"
          class="crm-button"
          @click="onClickClose"
        >
          Отменить
        </base-button> -->

        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          :disabled="isSaving"
          class="crm-button"
          @click="onClickSave"
        >
          Сформировать
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { isValid, formatISO } from '@evd3v/date-fns';
import { showValidationErrorMessage } from '@/helpers/messages';
import {
  BaseRadioButton, BaseDatePicker, BaseAsyncSelect,
} from '@/components/base';
import { mixinRoles } from '@/mixins';

export default {
  name: 'DoctorUnloadModal',
  components: {

    BaseRadioButton,
    BaseDatePicker,
    BaseAsyncSelect,
  },
  mixins: [validationMixin, mixinRoles],
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      selectedType: 1,

      unloadingFrom: null,
      unloadingTo: null,

      clinic: null,

      isSaving: false,
    };
  },
  validations() {
    return {
      unloadingFrom: {
        required: this.selectedType === 1 ? required : () => true,
        isValid: this.selectedType === 1 ? isValid : () => true,
      },
      unloadingTo: {
        required: this.selectedType === 1 ? required : () => true,
        isValid: this.selectedType === 1 ? isValid : () => true,
      },
      clinic: {
        required: this.selectedType === 2 ? required : () => true,
      },
    };
  },
  computed: {
    errorsValidation() {
      const errors = {};

      errors.unloadingTo = [];
      if (!this.$v.unloadingTo.required) {
        errors.unloadingTo.push('Поле не может быть пустым');
      }
      if (!this.$v.unloadingTo.isValid) {
        errors.unloadingTo.push('Некорректное значение поля');
      }

      errors.unloadingFrom = [];
      if (!this.$v.unloadingFrom.required) {
        errors.unloadingFrom.push('Поле не может быть пустым');
      }
      if (!this.$v.unloadingFrom.isValid) {
        errors.unloadingFrom.push('Некорректное значение поля');
      }

      errors.clinic = [];
      if (!this.$v.clinic.required) {
        errors.clinic.push('Поле не может быть пустым');
      }

      return errors;
    },
    maxUnloadingDateFrom() {
      return this.unloadingTo || new Date();
    },
    minUnloadingDateTo() {
      return this.unloadingFrom || null;
    },
    maxDateTo() {
      return new Date();
    },
  },
  methods: {
    shouldBeDisabled(index) {
      return this.selectedType !== index;
    },
    async fetchClinic({ query, skip, take }) {
      const clinics = await this.$store.dispatch(this.$types.CLINICS_FETCH, {
        skip,
        take,
        filter: {
          query,
          network: null,
          city: null,
        },
      });

      return clinics.map((clinic) => ({ value: clinic.id, name: clinic.title }));
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onClickSave() {
      this.$v.$touch();

      if (this.$v.$error) {
        showValidationErrorMessage();
        return;
      }

      const filter = {};

      if (this.selectedType === 1) {
        filter.endDate = this.unloadingTo;
        filter.endDate = filter.endDate
          ? formatISO(filter.endDate.setHours(23, 59, 0, 0)) // set time to 23:59
          : null;
        filter.startDate = formatISO(this.unloadingFrom);
        filter.endDate = formatISO(this.unloadingTo);
      } else if (this.selectedType === 2) {
        filter.clinicId = this.clinic.value;
        filter.clinicName = this.clinic.name;
      }

      try {
        this.isSaving = true;
        await this.$store.dispatch(this.$types.DOCTOR_UNLOAD_FETCH, filter);
        this.onClose();
      } catch (e) {
        console.log('Ошибка выгрузки');
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
.crm-form-fields {
  &.disabled {
    opacity: 0.4;
  }

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  .crm-form-field {
    align-items: center;
  }
}
.preposition {
  margin-right: 10px;
}
</style>
