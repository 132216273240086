import { APP_HOST } from '@/app.config';

export default class DoctorsApi {
  constructor($axios) {
    this.api = $axios.instance;
  }

  async getDoctorsHistory(id) {
    const { data } = await this.api.get(`${APP_HOST}v2/doctors/${id}/history`);
    return data;
  }
}
