<script>
import { intersection } from 'lodash';
import { BaseCheckbox } from '@/components/base';

export default {
  name: 'BaseCheckboxList',
  components: {
    BaseCheckbox,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    choices: {
      type: Object,
      default: () => ({}),
    },
    query: {
      type: String,
      default: '',
    },
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('change', newValue);
      },
    },
    allChoices() {
      return this.parseList(this.choices);
    },
    allGroups() {
      return this.parseGroups(this.choices);
    },
    selectedGroups() {
      const groups = {};

      Object.keys(this.allGroups).forEach((key) => {
        groups[key] = this.allGroups[key].every((item) => this.localValue.includes(item));
      });

      return groups;
    },
  },
  methods: {
    parseGroups(list) {
      const groups = {};
      const { title = null } = list;

      if (title) {
        groups[title] = this.parseList(list);
      }

      return list.nested ? list.nested.reduce((acc, item) => ({ ...acc, ...this.parseGroups(item) }), groups) : groups;
    },
    parseList(list) {
      const choices = list.choices.filter((item) => item.title.toLowerCase().indexOf(this.query.toLowerCase()) !== -1);

      return list.nested ? list.nested.reduce((acc, item) => [...acc, ...this.parseList(item)], choices) : choices;
    },
    selectGroup(key, event) {
      const groupItems = this.allGroups[key];
      const intersectionGroups = intersection(this.localValue, groupItems);
      const localValue = this.localValue.filter((item) => !intersectionGroups.includes(item));

      if (event) {
        this.localValue = [...localValue, ...groupItems];
      } else {
        this.localValue = localValue;
      }
    },
  },
  // eslint-disable-next-line no-unused-vars
  render(h) {
    const renderList = (list) => {
      const choices = list.choices.filter((item) => item.title.toLowerCase().indexOf(this.query.toLowerCase()) !== -1);

      return (
        this.allGroups[list.title]?.length
          ? <ul class="crm-list">
            {
              this.allGroups[list.title]?.length
                ? <li class="list-item list-item-header">
                    <base-checkbox
                      value={this.selectedGroups[list.title]}
                      onChange={this.selectGroup.bind(this, list.title)}
                      class="crm-checkbox-item crm-checkbox-item_header"
                    >
                      { `${list.title} (${this.allGroups[list.title].length})` }
                    </base-checkbox>
                  </li>
                : null
            }
            {
              choices.map((item) => (
                  <li key={item.id} class="list-item">
                    <base-checkbox
                      vModel={this.localValue}
                      label={item}
                      class="crm-checkbox-item"
                    >
                      { item.title }
                    </base-checkbox>
                  </li>
              ))
            }
          {
            list.nested ? <li class="list-item"> {list.nested.map((item) => renderList(item)) } </li> : null
          }
          </ul>
          : null
      );
    };

    return (
      <div class="crm-checkbox-list">
        {renderList(this.choices)}
      </div>);
  },
};
</script>

<style lang="scss" scoped>
.crm-list {
  margin: 0;
  padding: 0;
  list-style: none;

  &:not(:last-child) {
    padding-bottom: 10px;
  }
}

.crm-checkbox-list {
  & > .crm-list {
    padding: 0;

    & > .list-item {
      padding-left: 0;
      text-align: initial;

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.list-item {
  &:first-child {
    padding-top: 0;
  }

  &:not(&-header) {
    padding-left: 30px;
  }

  padding: 10px 0 6px;
  border-bottom: 1px solid $gray;
}

::v-deep.crm-checkbox-item_header {
  font-weight: 600;
}

::v-deep.crm-checkbox-item {
  font-size: 14px;
  line-height: 17px;
}
</style>
