import UsersApi from '@/services/users/users.api';
import { UNLIMITED_REQUEST_SIZE } from '@/services/users/users.const';

export default class UsersService {
  constructor($axios) {
    this.api = new UsersApi($axios);
  }

  getUser(params) {
    return this.api.getUser(params);
  }

  getUsers(params) {
    return this.api.getUsers(params);
  }

  getAllUsers(params = {}) {
    return this.getUsers({ ...params, take: UNLIMITED_REQUEST_SIZE });
  }
}
