<template>
  <div class="schedule-wrapper">
    <swiper
      class="swiper"
      :options="swiperOption"
    >
      <swiper-slide
        v-for="(tab, index) in dateTabs"
        :key="index"
      >
        <div class="crm-tab-slide">
          <base-tabs
            v-model="activeTab"
            class="date-tabs"
            :items="tab"
            :disabled="isScheduleLoading"
          />
        </div>
      </swiper-slide>
      <div
        slot="button-prev"
        class="swiper-button-prev"
      >
        <b-icon icon="caret-left-fill" />
      </div>
      <div
        slot="button-next"
        class="swiper-button-next"
      >
        <b-icon icon="caret-right-fill" />
      </div>
    </swiper>

    <p class="current-date-title">
      {{ currentDateTitle }}
    </p>

    <slot />
  </div>
</template>

<script>
import 'swiper/css/swiper.css';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';

import {
  addDays, format, parseISO, differenceInCalendarDays, isSameDay,
} from '@evd3v/date-fns';
import { ru } from '@evd3v/date-fns/locale';

import { BaseTabs } from '@/components/base';

export default {
  name: 'ScheduleWrapper',
  components: {
    Swiper,
    SwiperSlide,
    BaseTabs,
  },
  directives: {
    swiper: directive,
  },
  props: {
    doctorId: {
      type: Number,
      default: null,
    },
    allSlots: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      swiperOption: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    };
  },
  computed: {
    activeTab: {
      get() {
        return this.$store.state.Schedule.selectedDate;
      },
      set(newValue) {
        this.$store.commit(this.$types.SCHEDULE_DATE_SET, newValue);
      },
    },
    isScheduleLoading() {
      return this.$store.state.Schedule.isScheduleLoading;
    },
    dateTabs() {
      const today = new Date();
      const startTimeInLastDate = this.allSlots[this.allSlots.length - 1]?.startDateTime;
      const lastDay = new Date(startTimeInLastDate);

      const daysQuantity = differenceInCalendarDays(lastDay, today);
      const dateTabs = [];

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < daysQuantity + 1; i++) {
        const date = addDays(new Date(), i);

        const dateTab = {
          value: format(date, 'yyyy-MM-dd'),
          description: format(date, 'EEEEEE', { locale: ru }),
          title: format(date, 'dd'),
          disabled: false,
        };
        dateTab.disabled = !(this.allSlots.some((item) => {
          const slot = new Date(item.startDateTime);

          return isSameDay(slot, date);
        }));
        if (dateTabs.length) {
          const lastArr = dateTabs[dateTabs.length - 1];

          if (lastArr.length === 7) {
            dateTabs.push([dateTab]);
            // eslint-disable-next-line no-continue
            continue;
          }
          lastArr.push(dateTab);
          // eslint-disable-next-line no-continue
          continue;
        }
        dateTabs.push([dateTab]);
      }

      return dateTabs;
    },
    currentDateTitle() {
      if (!this.activeTab) return;

      const date = parseISO(this.activeTab);

      return format(date, 'EEEE, d MMMM y', { locale: ru });
    },
  },
  watch: {
    async activeTab(newVal) {
      try {
        await this.$store.dispatch(this.$types.SCHEDULE_FETCH, {
          id: this.doctorId,
          date: newVal,
        });
      } catch (e) {
        console.warn(e);
      }
    },
    doctorId: {
      async handler(newVal) {
        this.activeTab = this.dateTabs[0][0].value;
        if (newVal) {
          try {
            await this.$store.dispatch(this.$types.SCHEDULE_FETCH, {
              id: newVal,
              date: this.activeTab,
            });
          } catch (e) {
            console.warn(e);
          }
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.schedule-wrapper {
  width: 100%;
}
.crm-tab-slide {
  width: calc(100% - 80px);
}
::v-deep.swiper {
  margin-bottom: 30px;

  .swiper-slide {
    display: flex;
    justify-content: center;
  }

  .swiper-button-prev {
    left: 0;

    &::after {
      display: none;
    }
  }

  .swiper-button-next {
    right: 0;

    &::after {
      display: none;
    }
  }
}
.current-date-title {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 20px;
  color: $blue;
  text-align: center;

  &::first-letter {
    text-transform: uppercase;
  }
}

::v-deep .crm-tab-wrapper {
  width: calc(100% / 7);
}

::v-deep.date-tabs {

  &.disabled {
    opacity: 0.4;
  }

  .crm-tab {
    border-radius: 50%;
    background-color: $blue-light;
    width: 50px;
    height: 50px;
    padding: 0;
    border: none;
    color: $blue-dark;

    &.active {
      color: $white;
      background-color: $blue-dark;
    }
  }
}
</style>
