<template>
  <div class="map-container">
    <div class="d-flex justify-content-between mb-2">
      <v-select
        v-model="city"
        :options="cities"
        placeholder="Выберите город"
        label="name"
        :clearable="true"
        class="map-select"
        :style="{ width: '70%'}"
        @input="fetchBranches"
      />
      <v-select
        v-model="laboratoryId"
        :reduce="lab => lab.id"
        :options="laboratoryPartners"
        label="legalName"
        placeholder="Выберите лабораторию"
        class="map-select ml-2"
        :style="{ width: '30%'}"
        @input="fetchBranches"
      >
        <template #no-options>
          Ничего не найдено
        </template>
      </v-select>
      <b-button
        ref="clinicBtn"
        v-b-tooltip.hover.bottomleft.v-light
        title="Перейти на карту клиник"
        variant="light"
        :type="$const.PRIMARY_BUTTON"
        class="ml-2 map-btn"
        @click="click"
      >
        Clinics
      </b-button>
    </div>
    <div class="map-wrapper">
      <div class="map">
        <yandex-map
          ref="myMap"
          :coords="coords"
          :zoom="10"
          :scroll-zoom="true"
          :cluster-options="clusterOptions"
          @click="onClick"
        >
          <LaboratoriesMapMarker
            v-for="laboratory in laboratories"
            :id="laboratory.id"
            :key="laboratory.id"
            :laboratory="laboratory"
          />
        </yandex-map>
      </div>
    </div>
  </div>
</template>
<script>
import { clinicService, locationService } from '@/services';
import { showErrorCustomMessage } from '@/helpers/messages';
import LaboratoriesMapMarker from '../map/LaboratoriesMapMarker.vue';

const MOSCOW_LATITUDE = 55.755826;
const MOSCOW_LONGITUDE = 37.6172999;
export default {
  name: 'LaboratoriesMap',
  components: {
    LaboratoriesMapMarker,
    yandexMap: () => import('vue-yandex-maps').then(({ yandexMap }) => yandexMap),
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      city: null,
      cities: [],
      laboratoryId: null,
      laboratories: [],
      laboratoryPartners: [],
      isActive: true,
      coords: [],
      clusterOptions: {
        1: {
          clusterDisableClickZoom: false,
          clusterOpenBalloonOnClick: true,
          clusterBalloonContentLayout: 'cluster#balloonTwoColumns',
          clusterBalloonPagerType: 'marker',
          clusterBalloonItemContentLayout: '',
          clusterBalloonLeftColumnWidth: 250,
        },
      },
    };
  },
  computed: {
    params() {
      return {
        isActive: this.isActive,
        CityId: this.city?.id,
        LegalPartnerId: this.laboratoryId,
      };
    },
  },
  watch: {
    async city(e) {
      if (e) {
        this.coords = [this.city?.latitude, this.city?.longitude];
      } else {
        this.coords = [MOSCOW_LATITUDE, MOSCOW_LONGITUDE];
      }
    },
  },
  async created() {
    this.isLoading = true;
    this.coords = [MOSCOW_LATITUDE, MOSCOW_LONGITUDE];
    await this.fetchBranches();
    await this.fetchCities();
    await this.fetchPartners();
    this.isLoading = false;
  },
  methods: {
    async fetchBranches() {
      try {
        const data = await clinicService.getLaboratories(this.params);
        this.laboratories = data;
      } catch (err) {
        showErrorCustomMessage('Не удалось получить список лабораторий');
        console.warn(err);
        throw err;
      }
    },
    async fetchCities() {
      this.cities = (await locationService.getCities({ query: '' }))
        .filter((c) => c.longitude !== null && c.latitude !== null)
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    async fetchPartners() {
      const { data: { data } } = await this.$store.dispatch(this.$types.GET_LABORATORY_LEGAL_PARTNERS, {});
      this.laboratoryPartners = data;
    },
    click() {
      this.$emit('click');
    },
    onClick(e) {
      this.coords = e.get('coords');
    },
  },

};
</script>
<style lang="scss" scoped>
.map-select {
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, .5);
}

.map-container {
  width: 100%;
  height: 100%;

  .map-input {
    outline: none;

    &:focus {
      box-shadow: none;
      border: none;
      outline: 3px solid rgb(231, 226, 226);
    }
  }

  .map-btn {
    box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
  }

  .map-wrapper {
    display: flex;
    height: 73vh;
    width: 100%;

    .map {
      width: 100%;
      box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
    }
  }
}

.ymap-container {
  height: 73vh;
}
</style>
