import mutations from './mutations';
import getters from './getters';
import actions from './actions';

export default {
  namespaced: true,
  state: {
    promocodes: [],
    query: '',
    filter: {
      isActual: false,
      attribute: null,
    },
    isAllItemsLoaded: false,
    isLoading: false,
    limit: 15,
  },
  actions,
  getters,
  mutations,
};
