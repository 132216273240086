import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    fetchAppealsTemplatesTrigger: false,
    fetchAppealsTemplatesRejectTrigger: false,
    fetchChatAppealsTemplates: false,
  },
  getters,
  mutations,
};
