import { APP_HOST } from '@/app.config';

export default class AuthApi {
  constructor($axios) {
    this.api = $axios.instance;
  }

  async login(loginData) {
    const { data: { token } } = await this.api.post(`${APP_HOST}account/login/`, loginData);
    return token;
  }

  async getUser() {
    const { data: user, status } = await this.api.get(`${APP_HOST}account/`);

    return { user, status };
  }

  // получение подписи для авторизации в виджетах партнеров
  async getSignatureForAuth(id) {
    const { data: { data } } = await this.api.post(`${APP_HOST}v3/widget/doctor/auth`, { doctorId: id });
    return data;
  }
}
