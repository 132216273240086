import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.GET_PATIENT_SERVICES](state) {
    return state.patientServices;
  },
  [localTypes.GET_PATIENT_POLICY_ID](state) {
    return state.patientPolicyId;
  },
};
