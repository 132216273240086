<template>
  <div class="relative z-50">
    <v-modal-container
      :modals="modals"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import {
  VModalContainer,
} from '@doctis.front/doctis.designsystem';
import { uiService } from '@/services/core/ui';

export default {
  name: 'CommonModalContainer',
  components: { VModalContainer },
  computed: {
    ...mapState('UIStore', ['modals']),
  },
  methods: {
    closeModal(modal) {
      uiService.closeModalById(modal.id);
    },
  },
};
</script>
