import mutations from './mutations';
import getters from './getters';
import actions from './actions';

export default {
  namespaced: true,
  state: {
    consultations: [],
    consultationsProblemsTrigger: false,
    control: {
      isLoading: false,
      consultations: [],
    },
  },
  actions,
  getters,
  mutations,
};
