import NProgress from 'nprogress/nprogress';
// import axios from 'axios';

import * as types from '@/store/types';
import {
  removeNamespaces, showMessage,
} from '@/helpers/utils';
import { api } from '@/helpers/api';

const localTypes = removeNamespaces(types);

export default {
  async [localTypes.MESSAGES_FETCH]({ commit }, {
    query,
    startDate,
    endDate,
    startTimeMinutes,
    endTimeMinutes,
    skip,
    take,
    clear,
    save,
  }) {
    NProgress.start();

    if (save && clear) {
      commit(localTypes.MESSAGES_SET, []);
    }

    try {
      const params = new URLSearchParams();

      if (query) {
        params.append('phoneNumber', query.replace(/\D/, ''));
      }
      if (startDate) {
        params.append('startDate', startDate);
      }
      if (endDate) {
        params.append('endDate', endDate);
      }
      if (startTimeMinutes) {
        params.append('startTimeMinutes', startTimeMinutes);
      }
      if (endTimeMinutes) {
        params.append('endTimeMinutes', endTimeMinutes);
      }

      params.append('skip', skip);
      params.append('take', take);

      const { data: messages, headers } = await api.get('Sms/List', { params });
      const xTotalCount = headers['x-total-count'] || headers.get('x-total-count');

      if (save) {
        commit(localTypes.MESSAGES_ADD, messages);
        commit(localTypes.MESSAGES_TOTAL_COUNT_SET, xTotalCount);
      }

      return { messages, xTotalCount };
    } catch (err) {
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить сообщения',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.MESSAGES_SEND_MESSAGE](ctx, {
    number: phoneNumber,
    text,
  }) {
    NProgress.start();

    try {
      await api.post('Sms/Send', {
        phoneNumber,
        text,
      });

      showMessage({
        type: 'success',
        message: 'Сообщение успешно отправлено',
      });
    } catch (err) {
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось отправить сообщение',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.SMS_SENDING](ctx, {
    phoneNumbers,
    text,
  }) {
    NProgress.start();

    try {
      const params = new URLSearchParams();
      if (text) params.append('text', text);

      await api.post('v2/sms/sending', phoneNumbers, { params });

      showMessage({
        type: 'success',
        message: 'Сообщение успешно отправлено в рассылку',
      });
    } catch (err) {
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось отправить сообщение в рассылку',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.SMS_IMPORT_PHONES](_, params) {
    NProgress.start();
    try {
      const fd = new FormData();

      if (params.importFile) {
        fd.append('file', params.importFile);
      }

      const { data } = await api.post(
        'v2/sms/import-phones',
        fd,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Номера загружены!',
      });
      NProgress.done();
      return data;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось загрузить номера',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.SEND_CHECK_CODE](_, {
    phoneNumber,
  }) {
    NProgress.start();
    try {
      const params = new URLSearchParams();

      params.append('phoneNumber', phoneNumber);

      await api.get('v2/sms/send_code', { params });
    } catch (err) {
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось отправить проверочный код',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.SENDED_CODE_CHECK](_, {
    phoneNumber,
    code,
  }) {
    NProgress.start();
    try {
      const params = new URLSearchParams();

      params.append('phoneNumber', phoneNumber);
      params.append('code', code);

      await api.get('v2/sms/check_code', { params });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.SMS_TEMPLATES_FETCH]() {
    NProgress.start();
    try {
      const { data: { data } } = await api.get('v3/sms-template/list');
      return data;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.SMS_TEMPLATE_CREATE](_, templateData) {
    NProgress.start();
    try {
      await api.post('v3/sms-template', templateData);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Шаблон успешно добавлен',
      });
    } catch (e) {
      showMessage({ type: 'error', message: 'Не удалось добавить шаблон' });
      throw (e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.SMS_TEMPLATE_UPDATE](_, templateData) {
    NProgress.start();
    try {
      await api.put('v3/sms-template', templateData);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Шаблон успешно сохранен',
      });
    } catch (e) {
      showMessage({ type: 'error', message: 'Не удалось сохранить шаблон' });
      throw (e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.SMS_TEMPLATE_DELETE](_, templateId) {
    NProgress.start();
    try {
      const params = new URLSearchParams();
      params.append('id', templateId);

      await api.delete('v3/sms-template', { params });

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Шаблон успешно удален',
      });
    } finally {
      NProgress.done();
    }
  },
};
