export default {
  TOGGLE_FETCH_APPEALS_TEMPLATES_TRIGGER(state) {
    console.log(1);
    state.fetchAppealsTemplatesTrigger = !state.fetchAppealsTemplatesTrigger;
  },
  TOGGLE_FETCH_APPEALS_TEMPLATES_REJECT_TRIGGER(state) {
    console.log(2);
    state.fetchAppealsTemplatesRejectTrigger = !state.fetchAppealsTemplatesRejectTrigger;
  },
  TOGGLE_FETCH_CHAT_APPEALS_TEMPLATES_TRIGGER(state) {
    console.log(3);
    state.fetchChatAppealsTemplates = !state.fetchChatAppealsTemplates;
  },
};
