import mutations from './mutations';
import getters from './getters';
import actions from './actions';

export default {
  namespaced: true,
  state: {
    partnerList: null,
    legalPartnerList: null,
    serviceList: null,
    similarServiceList: null,
    currentPartnerServiceData: null,
    nextPartnerServiceData: null,
    orderList: null,
    currentOrder: null,
    branchesList: null,
    partnerServiceList: null,
    servicesBindOffset: null,
    isLoading: false,
    isLoadingButton: false,
    promocodes: [],
    promocodesTotalCount: 0,
    filter: {
      isActual: false,
      attribute: null,
    },
    attachedFiles: [], // [{orderId, files: [] }]
    isAllItemsLoaded: false,
    partnersServicesTrigger: false,
    ourServicesTrigger: false,
    // isLoading: false,
  },
  actions,
  getters,
  mutations,
};
