import { conformToMask } from 'vue-text-mask';

/* eslint-disable import/prefer-default-export */export const phoneMixins = {
  data: () => ({
    phoneMaskString: '+7 (###) ### ##-##',
    phoneMask: ['+', /\d/, ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, '-', /\d/, /\d/],
  }),
  methods: {
    async formatingPhoneNumber(newValue) {
      let checkingValue = newValue;
      if (newValue[0] === '+') {
        checkingValue = checkingValue.slice(1, newValue.length);
      }
      const clearValue = checkingValue.replace(/\D/g, '');

      if (checkingValue.length === 11 && clearValue.length === 11) {
        await this.$nextTick();
        let replacedValue = clearValue.slice(1, checkingValue.length);
        replacedValue = `7${replacedValue}`;
        const maskValue = conformToMask(replacedValue, this.phoneMask).conformedValue;
        return maskValue;
      }

      // console.log(`
      //   checkingValue: ${checkingValue},
      //   checkingValue.length: ${checkingValue.length},
      //   clearValue: ${clearValue},
      //   clearValue.length: ${clearValue.length}
      // `);

      // если просто вставить и не удалять +7 (
      const validatetLength = [14, 15, 18, 19, 20, 21];
      if (
        validatetLength.includes(checkingValue.length)
        && clearValue.length === 12
      ) {
        // причина по которой стоит проверка на 18 - если номер валиден - его длина 17. если добавить еще одно число = 18, в этом случае номер начинает ломаться
        if (checkingValue.length === 18 && clearValue.slice(0, 2) === '79') return;

        await this.$nextTick();
        let replacedValue = clearValue.slice(2, checkingValue.length);
        replacedValue = `7${replacedValue}`;
        const maskValue = conformToMask(replacedValue, this.phoneMask).conformedValue;
        return maskValue;
      }
    },
  },
};
