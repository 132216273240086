import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.CONSULTATIONS_PROBLEMS_TRIGGER](state) {
    return state.consultationsProblemsTrigger;
  },
};
