import { APP_HOST } from '@/app.config';

export default class InsuranceApi {
  constructor($axios) {
    this.api = $axios.instance;
  }

  async deactivateSubProgram(params) {
    const data = await this.api.put(`${APP_HOST}v2/insuranceCompanies/deactivationSubProgram`, null, { params });
    return data;
  }
}
