<template>
  <div class="base-dropdown-wrapper">
    <div
      class="crm-dropdown-header"
      @click="toggleStatus"
    >
      <p class="crm-dropdown-text">
        {{ header }}
      </p>

      <icon-arrow-dropdown
        :class="{ 'crm-icon-dropdown__opened': isOpened }"
        class="crm-icon-dropdown"
      />

      <div
        v-if="showLine"
        class="crm-dropdown-row-wrapper"
      >
        <div class="crm-dropdown-row" />
      </div>
    </div>

    <div
      ref="content"
      class="crm-dropdown-content"
      :class="{ collapse: !isOpened }"
    >
      <p class="crm-dropdown-content-inner">
        <slot />
      </p>
    </div>
  </div>
</template>

<script>
import IconArrowDropdown from 'assets/images/arrow-dropdown.svg';

export default {
  name: 'BaseDropdown',
  components: {
    IconArrowDropdown,
  },
  props: {
    header: {
      type: String,
      default: 'Test',
    },
    showLine: {
      type: Boolean,
      default: true,
    },
    open: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isOpened: true,
      contentHeight: 'auto',
    };
  },
  mounted() {
    this.isOpened = this.open;
  },
  methods: {
    toggleStatus() {
      this.isOpened = !this.isOpened;
      this.$emit('toggleShowStatus');
    },
  },
};
</script>

<style lang="scss" scoped>
.base-dropdown-wrapper {
  width: 100%;
  margin-bottom: 30px;
}
.crm-dropdown-header {
  width: 100%;
  display: flex;
  cursor: pointer;
}
.crm-dropdown-text {
  color: $black-light;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin: 0 15px 0 0;
}
.crm-icon-dropdown {
  margin-right: 15px;
  transition: transform 0.2s linear;
  align-self: center;

  &__opened {
    transform: rotate(-180deg);
  }
}
.crm-dropdown-row-wrapper {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;

  .crm-dropdown-row {
    background-color: #E0E0E0;
    height: 2px;
    width: 100%;
  }
}

.crm-dropdown-content {
  transition: all 0.2s;
  box-sizing: border-box;
  height: 100%;
  max-height: auto;
  margin-top: 10px;

  &.collapse {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
  }
}
</style>
