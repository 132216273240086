<template>
  <section class="synonyms">
    <v-attribute
      v-for="synonym in getSynonyms"
      :key="synonym"
      :content="synonym"
      size="s"
      type="default"
      variant="info"
    />
  </section>
</template>

<script>
import { VAttribute } from '@doctis.front/doctis.designsystem';

export default {
  name: 'Synonyms',
  components: { VAttribute },
  props: {
    synonyms: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    getSynonyms() {
      if (!this.synonyms) {
        return [];
      }

      return this.synonyms;
    },
  },
};
</script>

<style scoped>
.synonyms {
  @apply flex flex-wrap gap-2 p-4 rounded-lg bg-v-ui-background-monochrome-0;
}
</style>
