<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="xl"
    :title="showClinicsMap ? 'Карта клиник' : 'Карта лабораторий'"
    hide-footer
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <component
      :is="wrapperComponent"
      @click="toggleComponent"
    />
  </b-modal>
</template>
<script>
import ClinicsMap from '../ClinicPage/ClinicsMap';
import LaboratoriesMap from '../ClinicPage/LaboratoriesMap';

export default {
  name: 'YandexMapModal',
  components: {
    ClinicsMap,
    LaboratoriesMap,
  },
  data() {
    return {
      isOpen: true,
      showClinicsMap: true,
    };
  },
  computed: {
    wrapperComponent() {
      return this.showClinicsMap ? 'ClinicsMap' : 'LaboratoriesMap';
    },
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    toggleComponent() {
      this.showClinicsMap = !this.showClinicsMap;
    },
  },
};
</script>
