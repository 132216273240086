import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.REVIEW_ITEMS_SET](state, payload) {
    state.consultationReviewList = payload;
  },
  [localTypes.CURRENT_REVIEW_SET](state, payload) {
    state.currentReview = payload;
  },
};
