import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

import {
  set, addMinutes, format,
} from '@evd3v/date-fns';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.SCHEDULE_PAGE_TIME_CELL_GET]() {
    const slots = [];
    let date = set(new Date(), { hours: 0, minutes: 0, seconds: 0 });

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 96; i++) {
      slots.push(format(date, 'HH:mm'));
      date = addMinutes(date, 15);
    }

    return slots;
  },
  [localTypes.SCHEDULE_PAGE_SCHEDULE_TOTAL_COUNT_GET](state) {
    return state.schedule.length;
  },
};
