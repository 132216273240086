import { render, staticRenderFns } from "./PatientDetailAppealsItem.vue?vue&type=template&id=657c6b1e&scoped=true"
import script from "./PatientDetailAppealsItem.vue?vue&type=script&lang=js"
export * from "./PatientDetailAppealsItem.vue?vue&type=script&lang=js"
import style0 from "./PatientDetailAppealsItem.vue?vue&type=style&index=0&id=657c6b1e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "657c6b1e",
  null
  
)

export default component.exports