import { render, staticRenderFns } from "./IndemnityModalStepNewTemplate.vue?vue&type=template&id=4ce8d936&scoped=true"
import script from "./IndemnityModalStepNewTemplate.vue?vue&type=script&lang=js"
export * from "./IndemnityModalStepNewTemplate.vue?vue&type=script&lang=js"
import style0 from "./IndemnityModalStepNewTemplate.vue?vue&type=style&index=0&id=4ce8d936&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ce8d936",
  null
  
)

export default component.exports