import { APP_HOST } from '@/app.config';

export default class SipUserApi {
  constructor($axios) {
    this.api = $axios.instance;
  }

  async setSipStatus(params) {
    const { data } = await this.api.post(`${APP_HOST}v2/account/sip-status`, params);
    return data;
  }
}
