<template>
  <div v-if="editor">
    <ckeditor
      v-model="model"
      class="ck-content"
      :editor="editor"
      :disabled="disabled"
      :config="disabled ? editorConfigForDisabled : editorConfig"
    />
  </div>
</template>

<script>
export default {
  name: 'ClassicEditor',
  components: {
    ckeditor: () => import('@ckeditor/ckeditor5-vue2').then((module) => module.component),
  },
  props: {
    value: {
      type: String,

      default: '<p></p>',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editor: null,
      isCkeditorLoaded: false,
      editorConfig: {
        toolbar: {
          items: [
            'undo', 'redo',
            '|', 'heading',
            '|', 'bold', 'italic',
            '|', 'link', 'insertTable', 'blockQuote',
            '|', 'numberedList', 'bulletedList',
          ],
        },
      },
      editorConfigForDisabled: {
        toolbar: {
          items: [],
        },
      },
    };
  },
  computed: {
    model: {
      get() {
        return this.value ? this.value : '';
      },
      set(newValue) {
        return this.$emit('input', newValue);
      },
    },
  },
  mounted() {
    import('@ckeditor/ckeditor5-build-classic')
      .then((ClassicEditor) => {
        this.editor = ClassicEditor.default;
      })
      .catch((error) => {
        console.error('Error loading CKEditor:', error);
      });
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/css/ckeditor-reset.css';
@import '../../../assets/css/content-styles-ckeditor.css';
</style>
