import NProgress from 'nprogress/nprogress';

import * as types from '@/store/types';
import {
  removeNamespaces,
  showMessage,
  saveFile,
} from '@/helpers/utils';
import { api } from '@/helpers/api';

const localTypes = removeNamespaces(types);

export default {
  async [localTypes.PROMOCODE_FETCH](ctx, id) {
    NProgress.start();

    try {
      const { data: promocode } = await api.get(`PromoCode/getById?id=${id}`);

      return promocode;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить информацию о промокоде',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PROMOCODE_CREATE](ctx, promocode) {
    NProgress.start();

    try {
      await api.post('PromoCode', promocode);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Промокод успешно добавлен!',
      });
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PROMOCODE_UPDATE](ctx, promocode) {
    NProgress.start();

    try {
      await api.put(`PromoCode/${promocode.id}`, promocode);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Промокод успешно изменен!',
      });
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PROMOCODE_DELETE](ctx, id) {
    NProgress.start();

    try {
      await api.delete(`PromoCode?id=${id}`);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Промокод успешно удален!',
      });
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PROMOCODE_EXPORT](ctx, { promocodeId, code }) {
    NProgress.start();

    try {
      const { data } = await api.post(`v2/promocode/export/${promocodeId}`, null, {
        // params,
        responseType: 'blob',
      });

      saveFile(data, { name: `Экспорт промокода ${code}.xlsx` });
    } catch (e) {
      console.warn(e);
      showMessage({ type: 'error', message: 'Не удалось экпортировать промокод' });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PROMOCODES_FETCH](
    { commit, state },
    {
      clear, query, isActual, attribute,
    },
  ) {
    NProgress.start();

    if (state.isLoading) return;

    commit(localTypes.PROMOCODES_IS_LOADING_SET, true);
    commit(localTypes.PROMOCODES_IS_ALL_ITEMS_LOADED_SET, false);

    const prevTotal = clear ? 0 : state.promocodes.length;

    if (clear) {
      commit(localTypes.PROMOCODES_SET, []);
    }

    try {
      const params = new URLSearchParams();

      params.append('searchString', query || '');
      params.append('skip', prevTotal);
      params.append('take', state.limit);
      params.append('isActual', isActual || false);

      // 3 = не указывать атрибут, его мы не отправляем
      if (attribute !== 3 && (attribute || attribute === 0)) {
        params.append('attribute', attribute);
      }

      const { data: promocodes } = await api.get('PromoCode/search', {
        params,
      });

      commit(localTypes.PROMOCODES_ADD, promocodes);

      if (prevTotal + state.limit > state.promocodes.length) {
        commit(localTypes.PROMOCODES_IS_ALL_ITEMS_LOADED_SET, true);
      }
    } finally {
      NProgress.done();

      commit(localTypes.PROMOCODES_IS_LOADING_SET, false);
    }
  },
  async [localTypes.PROMOCODE_GENERATE](ctx, { count, promocode }) {
    NProgress.start();

    try {
      const { data } = await api.post(`v2/promocode/generate?count=${count}`, promocode, { responseType: 'blob' });
      saveFile(data, { name: 'Коды промокодов' });

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Промокоды успешно сгенерированы!',
      });
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
};
