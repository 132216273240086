<template>
  <div>
    <label
      :class="{disabled}"
      class="custom-radio"
    >
      <input
        :checked="shouldBeChecked"
        :disabled="disabled"
        type="radio"
        class="crm-radio-input"
        @change="onChange"
      >
      <span
        :class="{disabled}"
        :style="{ alignItems : alignTop ? 'flex-start' : 'center' }"
        class="crm-radio-text"
      >
        <slot />
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'BaseRadioButton',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    name: {
      type: [String, Number, Boolean],
      default: null,
    },
    value: {
      type: [String, Number, Boolean],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    alignTop: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    shouldBeChecked() {
      return this.name === this.value;
    },
  },
  methods: {
    onChange() {
      this.$emit('change', this.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-radio {
  display: flex;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  line-height: 17px;
}

.crm-radio-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 10px 0 0 7px;

}

.crm-radio-text {
  position: relative;
  padding: 0 0 0 25px;
  cursor: pointer;
}

.crm-radio-text:before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0;
  width: 14px;
  height: 14px;
  border: 1px solid #DADADA;
  border-radius: 50%;
  background: #FFF;
}

.crm-radio-text:after {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: $blue;
  opacity: 0;
  transition: .2s;
}

.disabled {
  cursor: not-allowed;
}

// .crm-radio-input[disabled] + .crm-radio-text:after {
//   background-color: $gray;
// }
.crm-radio-input[disabled] + .crm-radio-text:before {
  background-color: #eee;
}

.crm-radio-input:checked + .crm-radio-text:after {
  opacity: 1;
}

label {
  display: block;
}
</style>
