import NProgress from 'nprogress/nprogress';
import {
  set, getUnixTime, fromUnixTime, format, addDays,
} from '@evd3v/date-fns';
import * as types from '@/store/types';
import { removeNamespaces, showMessage } from '@/helpers/utils';
import { api } from '@/helpers/api';

const localTypes = removeNamespaces(types);
const LIMIT = 25;
export default {
  async [localTypes.SCHEDULE_PAGE_FETCH]({ dispatch, commit, getters }, clear) {
    commit(localTypes.SCHEDULE_PAGE_ALL_ITEMS_LOADED_SET, false);
    commit(localTypes.SCHEDULE_PAGE_LOADING_SET, true);

    const prevTotal = clear ? 0 : getters[localTypes.SCHEDULE_PAGE_SCHEDULE_TOTAL_COUNT_GET];

    try {
      await dispatch(localTypes.SCHEDULE_PAGE_SCHEDULE_FETCH, {
        skip: prevTotal,
        take: LIMIT,
        clear: !!clear,
      });

      if (prevTotal + LIMIT > getters[localTypes.SCHEDULE_PAGE_SCHEDULE_TOTAL_COUNT_GET]) {
        commit(localTypes.SCHEDULE_PAGE_ALL_ITEMS_LOADED_SET, true);
      }
    } catch (e) {
      console.warn(e);
    } finally {
      commit(localTypes.SCHEDULE_PAGE_LOADING_SET, false);
    }
  },
  async [localTypes.SCHEDULE_PAGE_SCHEDULE_FETCH]({ commit, state, dispatch }, {
    clear,
    skip,
    take,
  }) {
    NProgress.start();

    if (clear) {
      commit(localTypes.SCHEDULE_PAGE_SCHEDULE_SET, []);
      commit(localTypes.SCHEDULE_PAGE_DOCTORS_SET, []);
    }
    const params = {
      offset: skip,
      limit: take,
      regionIds: state.filterRegion ? [state.filterRegion] : [],
      clinicIds: state.filterClinic ? [state.filterClinic.value || state.filterClinic] : null,
      specializationIds: state.filterSpecializations.map((specialization) => specialization.id),
      doctorIds: state.filterDoctors.map((doctor) => doctor.id),
      startUnixTime: getUnixTime(set(state.date, { hours: 0, minutes: 0, seconds: 0 })),
      endUnixTime: getUnixTime(set(addDays(state.date, 1), { hours: 0, minutes: 0, seconds: 0 })),
    };

    try {
      const { data: schedule } = await api.post('v2/schedule/search', params);

      await dispatch(localTypes.SCHEDULE_PAGE_SCHEDULE_ADD, schedule.map((item) => item.slots));
      await dispatch(localTypes.SCHEDULE_PAGE_DOCTORS_ADD, schedule.map((item) => item.doctor));
    } catch (e) {
      commit(localTypes.SCHEDULE_PAGE_SCHEDULE_SET, []);
      commit(localTypes.SCHEDULE_PAGE_DOCTORS_SET, []);

      showMessage({
        type: 'error',
        message: 'Не удалось загрузить расписание',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.SCHEDULE_PAGE_SCHEDULE_FETCH_BY_DOCTORS]({ state, commit, dispatch }, idDoctors) {
    NProgress.start();

    const formattedIdDoctors = [...new Set(idDoctors)];

    const params = {
      offset: 0,
      limit: formattedIdDoctors.length,
      clinicId: state.filterClinic,
      regionIds: state.filterRegion ? [state.filterRegion] : [],
      doctorIds: formattedIdDoctors,
      startUnixTime: getUnixTime(set(state.date, { hours: 0, minutes: 0, seconds: 0 })),
      endUnixTime: getUnixTime(set(addDays(state.date, 1), { hours: 0, minutes: 0, seconds: 0 })),
    };

    try {
      const { data: schedule } = await api.post('v2/schedule/search', params);

      formattedIdDoctors.forEach(async (id) => {
        const localSchedule = schedule.find((scheduleElem) => scheduleElem.doctor.id === id);
        const preformattedRow = await dispatch(localTypes.SCHEDULE_PAGE_SCHEDULE_ROW_FORM, localSchedule.slots);
        const index = state.doctors.findIndex((doctor) => doctor.id === id);

        commit(localTypes.SCHEDULE_PAGE_SCHEDULE_SET, state.schedule.map((scheduleElem, i) => (i === index ? preformattedRow : scheduleElem)));
      });
    } catch (e) {
      showMessage({
        type: 'error',
        message: `Не удалось загрузить расписание на врачей ${formattedIdDoctors}`,
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.SCHEDULE_PAGE_SCHEDULE_ROW_FORM]({ getters }, row) {
    return getters[localTypes.SCHEDULE_PAGE_TIME_CELL_GET].map((time) => {
      const cell = row.find((slot) => format(fromUnixTime(slot.slot), 'HH:mm') === time);

      return cell || null;
    });
  },
  async [localTypes.SCHEDULE_PAGE_SCHEDULE_ADD]({ state, commit, dispatch }, schedule) {
    try {
      const scheduleRows = await Promise.all(schedule.map((row) => dispatch(localTypes.SCHEDULE_PAGE_SCHEDULE_ROW_FORM, row)));

      commit(localTypes.SCHEDULE_PAGE_SCHEDULE_SET, [...state.schedule, ...scheduleRows]);
    } catch (e) {
      console.log(e);
    }
  },
  [localTypes.SCHEDULE_PAGE_DOCTORS_ADD]({ state, commit }, doctors) {
    commit(localTypes.SCHEDULE_PAGE_DOCTORS_SET, [...state.doctors, ...doctors]);
  },
};
