<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    :title="titleModal"
    hide-footer
    @hidden="onClose"
    @ok="onClose"
  >
    <!-- filters -->
    <div class="mb-4 flex items-center">
      <v-select
        v-model="filters.CityId"
        :reduce="city => city.id"
        :options="cities"
        placeholder="Выберите город"
        label="name"
        :clearable="true"
        class="bg-white h-full w-50 mr-2"
        @input="getBranchesList"
      />
      <b-form-input
        v-model="filters.Address"
        placeholder="Поиск по адресу"
        @keyup.native.enter="getBranchesList"
      />
      <b-button
        variant="primary"
        class="ml-2"
        :type="$const.PRIMARY_BUTTON"
        @click="getBranchesList"
      >
        <b-icon icon="search" />
      </b-button>
    </div>
    <!-- filters -->

    <!-- list -->
    <div v-if="!isLoading">
      <div
        v-for="(item, index) of branches"
        :key="index"
        class="bg-v-ui-root-monochrome-5 p-3 rounded-lg mb-2 cursor-pointer flex items-center justify-between"
      >
        <div class="mr-6">
          <div class="mb-1 font-medium">
            {{ item.name }}
          </div>
          <div class="text-sm text-v-ui-text-monochrome-55">
            {{ item.address }}
          </div>
        </div>
        <div>
          <b-button
            v-if="!item.price"
            size="sm"
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            class="text-nowrap"
            @click="onGetPrice(item)"
          >
            Получить цену
          </b-button>
          <b-badge
            v-else
            class="p-2"
            pill
            variant="primary"
          >
            {{ item.price }}
          </b-badge>
        </div>
      </div>
      <div
        v-if="!branches.length"
        class="flex items-center justify-center h-[400px] text-v-ui-text-monochrome-55"
      >
        Ничего не найдено
      </div>
    </div>
    <preloader
      v-else
      class="h-[400px] flex items-center justify-center"
    />
    <!-- ./list -->
  </b-modal>
</template>

<script>
import { laboratoryService, locationService } from '@/services';
import Preloader from '@/components/Preloader';

export default {
  name: 'ServicePricesModal',
  components: { Preloader },
  props: {
    service: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      isOpen: true,
      branches: [],
      cities: [],
      filters: {
        Address: null,
        CityId: null,
      },
    };
  },
  computed: {
    titleModal() {
      return `Цены ${this.service.name}`;
    },
  },
  async mounted() {
    await this.getCity();
    await this.getBranchesList();
  },
  methods: {
    async getBranchesList() {
      this.isLoading = true;
      const { legalPartnerId: LegalPartnerId } = this.service;

      this.branches = await laboratoryService.getLaboratoryBranchList({
        LegalPartnerId,
        limit: 1000,
        ...this.filters,
      });

      this.isLoading = false;
    },
    async onGetPrice(item) {
      const price = await laboratoryService.getLaboratoryServicePrice({
        id: this.service.id,
        branchId: item.id,
      });
      if (price) {
        item.price = `${price.toLocaleString('ru')} ₽`;
      } else {
        item.price = 'Цена не установлена';
      }
    },
    async getCity() {
      this.cities = (await locationService.getCities({ query: '' })).sort((a, b) => a.name.localeCompare(b.name));
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: 'ServicePricesModal' });
    },
  },
};
</script>
