import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.OPEN_MODAL](state, { name, props = {} }) {
    const modal = state.modals.find((m) => m.name === name);

    if (modal) {
      const modalName = name + state.openedModals.length;
      state.openedModals.push({ ...modal, modalName, props: { ...props, modalName } });
    }
  },
  [localTypes.CLOSE_MODAL](state, { modalName }) {
    setTimeout(() => {
      state.openedModals = state.openedModals.filter((m) => {
        if (modalName != null) return !(m.modalName === modalName);
        return true;
      });
    }, 300);
  },
};
