import AppealsTemplatesChatApi from '@/services/appeals-templates-chat/appeals-templates-chat.api';

export default class AppealsTemplatesChatService {
  constructor($axios) {
    this.api = new AppealsTemplatesChatApi($axios);
  }

  getAll() {
    return this.api.getAll();
  }

  // метод есть, но на фронте пока не юзаем его
  getOne(id) {
    return this.api.getOne(id);
  }

  getWithSearch(text, deleted) {
    return this.api.getWithSearch(text, deleted);
  }

  create(data) {
    return this.api.create(data);
  }

  update(id, data) {
    return this.api.update(id, data);
  }

  delete(id) {
    return this.api.delete(id);
  }
}
