<template>
  <doctor-call
    v-if="user"
    v-slot="{ options, actions }"
    :user-id="user.userId"
    :user-name="user.lastName"
  >
    <d-call
      v-bind="options"
      @accept="actions.acceptCall"
      @hang-up="actions.hangUp"
      @reject="actions.reject"
      @update:stream-state="actions.updateStreamState"
    />
  </doctor-call>
</template>

<script>
import DCall from '@doctis.front/library.vuecomponent.call';
import '@doctis.front/library.vuecomponent.call/dist/lib.css';

export default {
  name: 'TheCallWrapper',
  components: { DCall },
  computed: {
    user() {
      return this.$store.state.Auth.user;
    },
  },
};
</script>

<style lang="scss">
.modal-doctis {
  position: fixed;
  z-index: 1300;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity .3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  overflow: auto;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: #0c0c0c;
    opacity: .4;
  }

  .modal-wrapper {
    overflow: auto;
    position: relative;
    background: #ececec;
    padding: 16px;
    border: 1px solid rgb(227, 227, 227);
    box-shadow: none;
    border-radius: 16px;
    z-index: 1080;
    max-height: 80%;
  }
}
</style>
