import NProgress from 'nprogress/nprogress';

import * as types from '@/store/types';
import {
  removeNamespaces,
  showMessage,
} from '@/helpers/utils';
import { api } from '@/helpers/api';

const localTypes = removeNamespaces(types);

// function mockApi(object, time) {
//   return new Promise((resolve) => {
//     setTimeout(() => resolve(object), time);
//   });
// }

export default {
  async [localTypes.GET_POLICY_BY_NUMBER]({ commit }, params) {
    NProgress.start();
    commit(localTypes.POLICY_IS_LOADING, { isLoading: true });
    try {
      const { data, headers } = await api.post('v2/policy/list', params);
      const xTotalCount = headers['x-total-count'] || headers.get('x-total-count');

      commit(localTypes.POLICY_SET, data);

      return { data, xTotalCount };
    } catch (e) {
      console.warn(e);

      throw (e);
    } finally {
      NProgress.done();
      commit(localTypes.POLICY_IS_LOADING, { isLoading: false });
    }
  },
  async [localTypes.POLICY_ATTACH_LIST_FETCH](_, { policyNumber }) {
    NProgress.start();
    try {
      const { data } = await api.get(`v2/policy/listToAttach?policyNumber=${policyNumber}`);
      // console.log(data);
      return data;
    } catch (e) {
      console.warn(e);

      throw (e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PUT_POLICY_EDIT]({ commit }, params) {
    NProgress.start();
    commit(localTypes.POLICY_IS_LOADING, { isLoadingButton: true });
    try {
      await api.put('/Policy', params);

      showMessage({
        type: 'success',
        message: 'Полис успешно обновлен',
      });
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось изменить полис',
      });
      throw (e);
    } finally {
      NProgress.done();
      commit(localTypes.POLICY_IS_LOADING, { isLoadingButton: false });
    }
  },
  async [localTypes.USER_DELETE](_, id) {
    NProgress.start();
    try {
      await api.delete(`v2/account?id=${id}`);
    } catch (e) {
      console.warn(e);
      throw (e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.USER_EDIT](_, params) {
    NProgress.start();

    try {
      const data = await api.put('v2/account', params);

      showMessage({
        type: 'success',
        message: 'Данные о пользователе успешно изменены',
      });

      return data;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось изменить данные',
      });

      throw (e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.POLICY_ATTACH](_, { personId, policyId }) {
    NProgress.start();

    try {
      const data = await api.post(`v2/policy/attach?policyId=${policyId}&personId=${personId}`);

      showMessage({
        type: 'success',
        message: 'Полис успешно прикреплен',
      });

      return data;
    } catch (e) {
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: e?.response?.data?.message || 'Не удалось прикрепить полис',
      });

      throw (e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.POLICY_DETACH]({ commit }, params) {
    NProgress.start();

    try {
      const { data } = await api.post(`v2/policy/detach?policyId=${params.id}`);

      showMessage({
        type: 'success',
        message: 'Полис успешно откреплен',
      });

      if (data) {
        commit(types.OPEN_MODAL, {
          name: 'PolicyHasIndemnityModal',
          props: {
            policyId: params.id,
            text: data,
          },
        }, { root: true });
      }

      return data;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось открепить полис',
      });

      throw (e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.POLICY_DETACH_BY_DATE]({ commit }, args) {
    NProgress.start();

    const params = new URLSearchParams();
    if (args.policyId) params.append('policyId', args.policyId);
    if (args.detachDate) params.append('detachDate', args.detachDate);

    try {
      const { data } = await api.post('v2/policy/detach-by-date', null, { params });

      if (data) {
        commit(types.OPEN_MODAL, {
          name: 'PolicyHasIndemnityModal',
          props: {
            policyId: args.policyId,
            text: data,
          },
        }, { root: true });
      }

      showMessage({
        type: 'success',
        message: 'Полис откреплен по дате',
      });

      return data;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось открепить полис по дате',
      });

      throw (e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.POLICY_PROLONGATION_FETCH](_, param) {
    try {
      const { data } = await api.get(`v2/policy/${param}/prolongation`);
      return data;
    } catch (e) {
      console.warn(e);
      return [];
    }
  },
};
