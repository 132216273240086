<template>
  <b-modal
    id="attach-policy"
    v-model="isOpen"
    modal-class="attach-policy-modal"
    scrollable
    centered
    size="lg"
    title="Прикрепление полиса"
    :hide-footer="isLoading"
    @hidden="onClose"
    @ok="onClose"
  >
    <template v-if="isLoading">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      <div class="content-wrapper">
        <div class="d-flex mb-4">
          <b-form-input
            v-model="policyNumber"
            type="text"
            placeholder="Введите номер полиса"
            @keyup.native.enter="onPolicySearch"
          />

          <b-button
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            class="ml-2"
            @click="onPolicySearch"
          >
            <b-icon icon="search" />
          </b-button>
        </div>

        <template v-if="isSearching">
          <preloader />
        </template>

        <template v-else>
          <div
            v-if="policyList.length"
          >
            <div
              v-for="policy in policyList"
              :key="policy.id"
              :class="`d-flex align-items-center rounded p-2 border border-${getStatus(policy.policyStatus).color}`"
            >
              <div class="w-100">
                <div>
                  <span class="text-secondary">ФИО:</span> {{ policy.lastName }} {{ policy.firstName }} {{ policy.middleName }}
                </div>
                <div>
                  <span class="text-secondary">Дата рождения:</span> {{ getFormatDate(policy.birthday, 'dd-MM-yyyy') }}
                </div>
                <div>
                  <span class="text-secondary">Номер полиса:</span> {{ policy.policyId }}
                </div>
                <div>
                  <span class="text-secondary">Статус полиса:</span>
                  {{ ' ' }}
                  <span :class="`text-${getStatus(policy.policyStatus).color}`">{{ getStatus(policy.policyStatus).title }}</span>
                </div>
                <div>
                  <span class="text-secondary">Программа:</span> {{ policy.companyName + ', ' + policy.programName + ', ' + policy.insuranceName }}
                </div>
                <!-- <div
                  v-if="policy.lastName || policy.firstName || policy.middleName"
                >
                  <span class="text-secondary">ФИО пациента:</span> {{ policy.lastName + ' ' + policy.firstName + ' ' + policy.middleName }}
                </div> -->
                <div>
                  <span class="text-secondary">Дата покупки:</span> {{ getFormatDate(policy.buyDate, 'dd-MM-yyyy') }}
                </div>
                <div>
                  <span class="text-secondary">Дата начала:</span> {{ getFormatDate(policy.startDate, 'dd-MM-yyyy') }}
                </div>
                <div>
                  <span class="text-secondary">Дата окончания:</span> {{ getFormatDate(policy.endDate, 'dd-MM-yyyy') }}
                </div>
              </div>
              <b-button
                class="float-right ml-2"
                size="sm"
                :disabled="policy.personId === personId"
                :variant="policy.personId === personId ? '' : 'primary'"
                :type="$const.PRIMARY_BUTTON"
                @click="onAttach(policy.id)"
              >
                {{ policy.personId === personId ? 'Полис прикреплен' : 'Прикрепить' }}
              </b-button>
            </div>
          </div>

          <div
            v-else
            class="px-3"
          >
            По данному номеру полиса ничего не найдено
          </div>
        </template>
      </div>
    </template>

    <template #modal-footer>
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        @click="onClose"
      >
        Отменить
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Preloader from '@/components/Preloader';
import Bus from '@/eventBus';

import {
  format,
  // parseISO,
} from '@evd3v/date-fns';

export default {
  name: 'AttachPolicyModal',
  components: {
    Preloader,
  },
  props: {
    personId: {
      type: [String, Number],
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      isSearching: false,
      policyNumber: null,
      policyList: [],
      policyStatuses: [
        {
          title: 'неактивный',
          value: [0, 1, 4, 5],
          color: 'danger',
        },
        {
          title: 'в ожидании',
          value: [2],
          color: 'warning',
        },
        {
          title: 'активный',
          value: [3],
          color: 'success',
        },
      ],
    };
  },
  computed: {},
  methods: {
    getStatus(value) {
      return this.policyStatuses.find((s) => s.value.includes(value));
    },
    getFormatDate(date, dateFormat) {
      return date && dateFormat ? format(new Date(date), dateFormat) : null;
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onPolicySearch() {
      this.isSearching = true;
      this.policyList = await this.$store.dispatch(this.$types.POLICY_ATTACH_LIST_FETCH, { policyNumber: this.policyNumber });
      this.isSearching = false;
    },
    async onAttach(policyId) {
      try {
        this.isSearching = true;

        await this.$store.dispatch(this.$types.POLICY_ATTACH, { policyId, personId: this.personId });
        this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });

        Bus.$emit('patient:update');
        Bus.$emit('patients:update');
      } catch (error) {
        console.error(error);
      } finally {
        this.isSearching = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep#attach-policy___BV_modal_outer_ {
  z-index: 1060!important;
}
.content-wrapper {
  min-height: 300px;
}
</style>
