import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.STATISTICS_CONSULTATIONS_LABELS](state) {
    let result = state.statisticsConsultations.map((item) => item.dateRange.title);

    if (result.length === 1) result = ['', ...result, ''];
    return result;
  },
  [localTypes.STATISTICS_CONSULTATIONS_COUNT](state) {
    let result = state.statisticsConsultations.map((item) => item.consultationsCount);

    if (result.length === 1) result = ['', ...result, ''];
    return result;
  },
  [localTypes.STATISTICS_ACTIVE_USERS_COUNT](state) {
    let result = state.statisticsConsultations.map((item) => item.activeUsersCount);

    if (result.length === 1) result = ['', ...result, ''];
    return result;
  },
  [localTypes.TOTAL_ACTIVE_USERS_COUNT](state) {
    return state.statisticsConsultationsCommon.totalActiveUsersCount || 0;
  },
  [localTypes.TOTAL_CONSULTATIONS_COUNT](state) {
    return state.statisticsConsultationsCommon.totalConsultationsCount || 0;
  },
  [localTypes.STATISTICS_SERVICES_LABELS](state) {
    let result = state.statisticsService.map((item) => item.dateRange.title);

    if (result.length === 1) result = ['', ...result, ''];
    return result;
  },
  [localTypes.STATISTICS_SERVICES_THERAPIST_AVERAGE_TAKE_ON_TIME](state) {
    let result = state.statisticsService.map((item) => {
      const time = item.therapistAverageTakeOnTime;
      return time && parseFloat(time.replace(',', '.'));
    });

    if (result.length === 1) result = ['', ...result, ''];
    return result;
  },
  [localTypes.STATISTICS_SERVICES_PEDIATRICIAN_AVERAGE_TAKE_ON_TIME](state) {
    let result = state.statisticsService.map((item) => {
      const time = item.pediatricianAverageTakeOnTime;
      return time && parseFloat(time.replace(',', '.'));
    });

    if (result.length === 1) result = ['', ...result, ''];
    return result;
  },
  [localTypes.STATISTICS_SERVICES_SPECIALIZED_AVERAGE_TAKE_ON_TIME](state) {
    let result = state.statisticsService.map((item) => {
      const time = item.specializedAverageTakeOnTime;
      return time && parseFloat(time.replace(',', '.'));
    });

    if (result.length === 1) result = ['', ...result, ''];
    return result;
  },
  [localTypes.TOTAL_DUTY_AVERAGE_TAKE_ON_TIME](state) {
    return state.statisticsServiceCommon.totalDutyAverageTakeOnTime || 0;
  },
  [localTypes.TOTAL_THERAPIST_AVERAGE_TAKE_ON_TIME](state) {
    return state.statisticsServiceCommon.totalTherapistAverageTakeOnTime || 0;
  },
  [localTypes.TOTAL_PEDIATRICIAN_AVERAGE_TAKE_ON_TIME](state) {
    return state.statisticsServiceCommon.totalPediatricianAverageTakeOnTime || 0;
  },
  [localTypes.TOTAL_SPECIALIZED_AVERAGE_TAKE_ON_TIME](state) {
    return state.statisticsServiceCommon.totalSpecializedAverageTakeOnTime || 0;
  },
};
