<template>
  <div
    v-if="originName"
    class="file-container"
  >
    <div @click="handleFile">
      <div
        class="file-message"
        :title="originName"
      >
        <div
          class="file-message-preview"
          :style="{
            width: shouldShowImage ? '100px' : '35px',
            height: shouldShowImage ? '' : '35px'
          }"
        >
          <template v-if="shouldShowImage">
            <img
              alt="Изображение"
              :src="message.imageUrl"
            >
          </template>
          <template v-else>
            <component
              :is="fileIcon"
              width="32"
              height="32"
            />
          </template>
        </div>

        <div
          v-if="isDetailInfo"
          class="file-message-content"
        >
          <div class="file-message-content__filename">
            {{ preparedNameFile }}
          </div>
          <div class="file-message-content-info">
            <span class="file-message-content-info__ext">{{ ext }}</span>
            <span class="file-message-content-info__size">{{ totalSize }}</span>
          </div>
        </div>
        <div class="info">
          <div class="info-time">
            {{ createdAtFormatted }}
          </div>
          <b-icon
            v-if="message?.member?.memberType === 2"
            class="info-read"
            :icon="isWasRead ? 'check-all' : 'check'"
          />
        </div>
      </div>
    </div>
    <transition name="preview">
      <ChatPreviewFile
        v-if="showFilePreview"
        :show="showFilePreview"
        :actual-image-preview="fileUrl"
        :extention="ext"
        :active="true"
        @hide-preview="hideImagePreview"
      />
    </transition>
  </div>
</template>

<script>
import { format, parseISO } from '@evd3v/date-fns';
import MP4Icon from '@/assets/file/mp4.svg';
import MP3Icon from '@/assets/file/mp3.svg';
import PDFIcon from '@/assets/file/pdf.svg';
import DefaultIcon from '@/assets/file/file.svg';
import { saveFile } from '@/helpers/utils';
import { FORMATS_FOR_PREVIEW } from '@/helpers/consts';
import { chatService } from '@/services';
import ChatPreviewFile from './ChatPreviewFile';

export default {
  name: 'ChatFileMessage',
  components: {
    MP3Icon,
    MP4Icon,
    PDFIcon,
    DefaultIcon,
    ChatPreviewFile,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    appealId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showFilePreview: false,
      fileUrl: null,
    };
  },
  computed: {
    isDetailInfo() {
      return !FORMATS_FOR_PREVIEW.every((ext) => ext === 'MP4');
    },
    shouldShowImage() {
      return this.message.imageUrl && this.ext !== 'MP4';
    },
    originName() {
      return this.payload?.filesInformation[0].originName;
    },
    preparedNameFile() {
      return this.originName.length > 10 ? `${this.originName.substring(0, 10)}...` : this.originName;
    },
    payload() {
      return JSON.parse(this.message.payload);
    },
    file() {
      return this.payload?.filesInformation[0];
    },
    totalSize() {
      const b = this.file.totalSize;
      const kb = b / 1000;
      const mb = kb / 1000;
      if (b < 1000) return ` ${b} B`;
      if (kb < 1000) return ` ${kb.toFixed(2)} KB`;
      return ` ${mb.toFixed(2)} MB`;
    },
    ext() {
      return this.file.extension.slice(0).toUpperCase();
    },
    createdAtFormatted() {
      return format(parseISO(this.message.createdAt), 'HH:mm');
    },
    fileIcon() {
      let fileComponent = '';

      if (this.ext === 'MP3' || this.ext === 'MP4' || this.ext === 'PDF') {
        fileComponent += this.ext;
      }
      if (!fileComponent) fileComponent += 'Default';

      return `${fileComponent}-icon`;
    },
    isWasRead() {
      return this.message.wasRead;
    },
  },
  methods: {
    async showPreviewImage() {
      this.fileUrl = this.message.imageUrl;

      if (this.fileUrl) { this.showFilePreview = true; }
    },
    async downloadFile() {
      const data = await chatService.getFile(
        {
          chatAppealId: this.appealId,
          fileName: JSON.parse(this.message.payload)?.fileNames[0],
        },
      );
      saveFile(data, { name: this.originName });
    },
    async showPdfFile() {
      const fileName = JSON.parse(this.message.payload)?.fileNames[0];
      const data = await chatService.getFile(
        {
          chatAppealId: this.appealId,
          fileName,
        },
      );

      const blob = new Blob([data], { type: 'application/pdf' });
      saveFile(blob, { name: this.originName, open: true });
    },
    async handleFile() {
      if (FORMATS_FOR_PREVIEW.includes(this.ext)) {
        await this.showPreviewImage();
      } else if (this.ext === 'PDF') {
        await this.showPdfFile();
      } else {
        await this.downloadFile();
      }
    },
    hideImagePreview(value) {
      this.showFilePreview = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.file-container {
  background: #066aff18;
  border-radius: 8px;

  &.left {
    background: #e7e9e9;
  }

  :hover {
    cursor: pointer;
  }
}

.file-message {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 8px;
  font-family: Muller, serif;
  margin: 5px 0;
  padding: 10px 25px 15px 10px;

  &-preview-image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 40px;
    width: 80px;
    height: 40px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      object-fit: cover;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 42px);
    height: 100%;

    &__filename {
      font-size: 14px;
      color: #3e3e3e;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-info {
      display: flex;
      flex-direction: column;
      margin-top: 5px;
      font-size: 10px;
      color: #3d3d3d;
    }
  }

  .info {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: 5px;
    font-size: 10px;

    &-time {
      color: #96A9B3;
      padding-right: 5px;
    }

    &-read {
      color: #62bcec;
      font-size: 15px;
    }
  }
}

.preview-enter-active, .preview-leave-active {
  transition: all .7s;
}
.preview-enter, .preview-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
