export default {
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_USER_LOADING(state, payload) {
    state.isUserLoading = payload;
  },
  SET_USER(state, payload) {
    state.user = payload;
  },
  SET_ROLE(state, payload) {
    state.role = payload;
  },
  SET_LOGIN(state, payload) {
    state.login = payload;
  },
};
