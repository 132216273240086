<template>
  <b-modal
    v-model="isOpen"
    centered
    size="xs"
    :title="'Вы действительно хотите восстановить клинику?'"
    hide-footer
    @hidden="onClose"
    @ok="onClose"
  >
    <div class="d-flex justify-content-around">
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isLoading"
        @click="recoveryClinicHandler"
      >
        Восстановить
      </b-button>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        class="px-4"
        @click="onClose"
      >
        Отмена
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { clinicService } from '@/services';
import { showSuccessCustomMessage, showErrorCustomMessage } from '@/helpers/messages';

export default {
  name: 'ClinicRecoveryModal',
  components: {
  },
  props: {
    clinicId: {
      type: [String, Number],
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      isOpen: true,
    };
  },
  methods: {
    async onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async recoveryClinicHandler() {
      this.isLoading = true;
      try {
        await clinicService.remove(this.clinicId);
        this.$store.commit('Clinic/REMOVE_CLINIC_BY_ID', this.clinicId);
        showSuccessCustomMessage('Клиника успешно восстановлена!');
      } catch (err) {
        console.warn(err);
        showErrorCustomMessage('Не удалось восстановить клинику');
        throw new Error(err);
      }
      this.isLoading = false;
      await this.onClose();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
