<template>
  <div>
    <div class="d-flex mb-1">
      <div class="font-weight-bold">
        Адрес
      </div>
      <div class="ml-2 text-break">
        {{ clinic.addressString || '—' }}
      </div>
    </div>
    <div class="d-flex mb-1">
      <div class="font-weight-bold">
        Режим работы
      </div>
      <div
        v-if="clinic.operatingHoursFrom"
        class="ml-2 text-break"
      >
        c {{ clinic.operatingHoursFrom }}
        до {{ clinic.operatingHoursTo || '—' }}
      </div>
      <div
        v-else
        class="ml-2 text-break"
      >
        не указан
      </div>
    </div>
    <div class="d-flex mb-1">
      <div class="font-weight-bold">
        Телефон
      </div>
      <div class="d-flex flex-column">
        <div
          v-for="(phone, i) in clinic.phones"
          :key="i"
          class="ml-2 text-break"
        >
          {{ phone }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClinicsBalloon',
  props: {
    clinic: {
      type: Object,
      required: true,
    },
  },
};
</script>
