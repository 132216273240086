<template>
  <div
    class="clinic-photo-edit-wrapper"
  >
    <div class="clinics-scroll-content">
      <custom-scrollbar>
        <div
          class="clinic-photo-edit"
        >
          <Loading
            v-if="isLoading"
            class="crm-loading-wrapper"
          >
            Загрузка клиники
          </Loading>

          <base-photo-edit
            v-else
            :photo-url-prop="clinicInfo ? clinicInfo.photoUrl : null"
            :image-height="200"
            :image-width="200"
            :save-callback="savePhotoClinicCallback"
            @cancel="onClickClose"
          />
        </div>
      </custom-scrollbar>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */

// import * as types from '@/store/types';

import Loading from '@/components/Loading';

import { clinicService } from '@/services';
import {
  showErrorCustomMessage,
  showSuccessCustomMessage,
} from '@/helpers/messages';

import {
  BasePhotoEdit,
} from '@/components/base';
import 'vue-advanced-cropper/dist/style.css';
import { APP_HOST } from '@/app.config';

export default {
  name: 'ClinicPhotoEdit',
  components: {
    BasePhotoEdit,
    Loading,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      clinicInfo: null,
    };
  },
  computed: {},
  async created() {
    if (this.id) {
      try {
        this.isLoading = true;
        this.clinicInfo = await this.fetchClinicById(this.id);
      } finally {
        this.isLoading = false;
      }
    }
  },
  methods: {
    onClickClose() {
      if (!confirm('Несохраненная информация будет удалена, продолжить?')) {
        return;
      }
      this.$emit('vuedals:close');
    },
    async savePhotoClinicCallback(guid) {
      const preformattedClinicInfo = {
        ...this.clinicInfo,
        photoUrl: `${APP_HOST === '/api/' ? 'https://crm.doctis.ru:6004/api/' : APP_HOST}File/${guid}`,
      };
      try {
        await clinicService.update(preformattedClinicInfo);
        showSuccessCustomMessage('Клиника успешно изменена!');
      } catch (err) {
        console.warn(err);
        showErrorCustomMessage('Не удалось изменить клинику');
        throw new Error(err);
      }
    },
    async fetchClinicById(id) {
      try {
        return clinicService.getOne(id);
      } catch (err) {
        console.warn(err);
        showErrorCustomMessage('Не удалось получить информацию о клинике');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.clinics-scroll-content {
  height: calc(100vh - 310px);
  margin: 0 -10px;
}

.clinic-photo-edit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
