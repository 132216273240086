<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    :title="roleId ? 'Роль' : 'Новая роль'"
    :hide-footer="isLoading"
    @hidden="onClose"
    @ok="onClose"
  >
    <template v-if="isLoading">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      <div class="d-flex justify-content-between">
        <b-form-group
          label="Название для чата"
          class="w-50 mb-1 mr-2"
        >
          <b-form-input
            v-model="role.titleName"
            type="text"
            placeholder="Введите название для чата"
            trim
          />
        </b-form-group>
        <b-form-group
          label="Название роли"
          :invalid-feedback="'Название не может быть пустым'"
          :state="role.name !== null && role.name !== ''"
          class="mb-1 w-50"
        >
          <b-form-input
            v-model="role.name"
            type="text"
            placeholder="Введите название роли"
            trim
            :disabled="!!roleId"
          />
        </b-form-group>
      </div>
      <div class="d-flex justify-content-between mb-2">
        <b-form-checkbox
          v-model="showHelpLine"
          switch
        >
          Показать линию
        </b-form-checkbox>
        <b-form-checkbox
          v-model="role.isAvailableFromOutside"
          switch
        >
          Доступно извне
        </b-form-checkbox>
      </div>
      <b-form-radio-group
        v-if="showHelpLine"
        v-model="role.chatAppealLine"
        :options="lines"
        value-field="id"
        text-field="title"
        class="text-left"
      />
      <b-form-group
        label="Номер"
        class="w-50 mb-1 mr-2"
      >
        <b-form-input
          v-model="role.sipNumber"
          placeholder="Введите номер"
          @keypress="validateSipNumber($event)"
        />
      </b-form-group>
      <b-form-group class="mt-4">
        <template #label>
          <div
            class="d-flex align-items-center"
            style="height: 40px;"
          >
            <span
              class="mr-3"
            >
              Страницы:
            </span>

            <img
              v-if="role.pageIds?.length === pageList?.length "
              src="@/assets/images/gato.gif"
              height="40"
              class="run"
            >
            <span v-else>
              {{ role.pageIds?.length }} из {{ pageList?.length }}
            </span>

            <b-button
              class="ml-auto"
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              style="width: 200px"
              @click="toggleAllPages"
            >
              {{ allPageSelected ? 'Убрать все страницы' : 'Выбрать все страницы' }}
            </b-button>
          </div>
        </template>
        <b-form-checkbox-group
          v-model="role.pageIds"
        >
          <div>
            <RolePageItem
              v-for="page in formatedPageList"
              :key="page.id"
              :page="page"
            />
          </div>
        </b-form-checkbox-group>
      </b-form-group>
    </template>

    <template #modal-footer>
      <b-button
        variant="success"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isLoading || !(role.name !== null && role.name !== '')"
        @click="onSave"
      >
        Сохранить роль
      </b-button>
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        @click="onClose"
      >
        Отменить
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Preloader from '@/components/Preloader';
import RolePageItem from '@/components/Roles/RolePageItem';

export default {
  name: 'RoleEditModal',
  components: {
    Preloader,
    RolePageItem,
  },
  props: {
    roleId: {
      type: [String, Number],
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      showHelpLine: false,
      role: {
        name: null,
        titleName: null,
        sipNumber: null,
        pageIds: [],
        isAvailableFromOutside: true,
        chatAppealLine: 0,
        isRoleForChatAppeal: false,
      },
      pageList: [],
      lines: [
        {
          id: 0,
          title: 'Нет линии',
        },
        {
          id: 1,
          title: 'Первая линия',
        },
        {
          id: 2,
          title: 'Вторая линия',
        },
      ],
    };
  },
  computed: {
    allPageSelected() {
      return this.role.pageIds.length === this.pageList.length;
    },
    formatedPageList() {
      return this.pageList
        .map(this.getChildPages)
        .filter((p) => !p.pageId)
        .sort((a, b) => Number(a.index) - Number(b.index));
    },
  },
  async mounted() {
    try {
      this.isLoading = true;

      this.pageList = (await this.$store.dispatch(this.$types.PAGES_FETCH)).sort((a, b) => a.index - b.index);

      const indexOne = this.pageList.findIndex((i) => i.name.includes('При открытии - тип страхования ДМС'));
      const indexTwo = this.pageList.findIndex((i) => i.name.includes('При открытии - тип страхования заблокирован'));

      if (indexOne !== -1 && indexTwo !== -1) {
        [this.pageList[indexOne], this.pageList[indexTwo]] = [this.pageList[indexTwo], this.pageList[indexOne]];
      }

      if (this.roleId) {
        const role = this.$store.state.Roles.roles.find((r) => r.id === this.roleId);
        this.role = { ...role };
      }
      this.showHelpLine = !!this.role.chatAppealLine;
    } catch (error) {
      console.error(error);
      // this.onClose();
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    validateSipNumber: (e = window.event) => {
      const charCode = (e.which) ? e.which : e.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 43) {
        e.preventDefault();
      } else {
        return true;
      }
    },
    getChildPages(page) {
      return ({
        ...page,
        childPages: this.pageList.filter((p) => p.pageId === page.id).map(this.getChildPages),
      });
    },
    toggleAllPages() {
      this.role.pageIds = this.allPageSelected ? [] : this.pageList.map((p) => p.id);
    },
    onClose() {
      this.isOpen = false;
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onSave() {
      try {
        this.isLoading = true;
        const method = this.roleId ? 'ROLE_UPDATE' : 'ROLE_CREATE';
        await this.$store.dispatch(this.$types[method],
          {
            ...this.role,
            isRoleForChatAppeal: this.role.chatAppealLine !== 0,
          });
        console.log(this.role.pageIds.length);
        this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
        await this.$store.dispatch('Auth/userFetch');
        this.$store.commit(this.$types.ROLES_LOADING_SET, true);
        const roles = await this.$store.dispatch(this.$types.ROLES_FETCH);
        this.$store.commit(this.$types.ROLES_SET, roles);
        this.$store.commit(this.$types.ROLES_LOADING_SET, false);
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.run {
  animation: 5s infinite linear runAnimation;
}

@keyframes runAnimation {
  0% {
    transform: translateX(0) scale(1, 1);
  }

  49% {
    transform: translateX(330px) scale(1, 1);
  }

  51% {
    transform: translateX(330px) scale(-1, 1);
  }

  100% {
    transform: translateX(0) scale(-1, 1);
  }
}
</style>
