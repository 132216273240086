import { render, staticRenderFns } from "./DoctorSpecialityItem.vue?vue&type=template&id=a451fbc0&scoped=true"
import script from "./DoctorSpecialityItem.vue?vue&type=script&lang=js"
export * from "./DoctorSpecialityItem.vue?vue&type=script&lang=js"
import style0 from "./DoctorSpecialityItem.vue?vue&type=style&index=0&id=a451fbc0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a451fbc0",
  null
  
)

export default component.exports