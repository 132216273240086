<template>
  <div>
    <b-container fluid="xl">
      <div
        style="min-height: 270px;"
        class="doctor-list-inner"
      >
        <slot />
        <div class="d-flex justify-content-between mb-2">
          <b-input-group class="bg-white rounded shadow-light">
            <b-form-input
              v-model="query"
              type="search"
              placeholder="Введите фамилию врача"
              class="border-0"
              @keyup.enter="doctorsFetch"
            />
            <b-button
              variant="outline-primary"
              :type="$const.PRIMARY_BUTTON"
              class="rounded-0 border-0"
              @click="doctorsFetch"
            >
              <b-icon
                icon="search"
                @click="doctorsFetch"
              />
            </b-button>
          </b-input-group>
          <slot name="button" />
        </div>

        <div
          class="doctor-search mt-3"
          :style="{ 'grid-template-columns': gridColumns }"
        >
          <div class="doctor-search-select">
            <div>
              <v-select
                v-model="form.doctorSpecializationId"
                :reduce="item => item.id"
                :options="specializations"
                placeholder="Выберите специальность"
                label="name"
                class="crm-select"
              >
                <template #no-options>
                  Ничего не найдено
                </template>
              </v-select>
            </div>
          </div>

          <div
            v-if="allowSearchByPhone"
            class="phone-container"
          >
            <b-input
              :key="uniqId"
              v-model.trim="form.phoneNumber"
              v-mask="phoneMaskString"
              size="sm"
              placeholder="Номер телефона"
              :state="!$v.form.phoneNumber.isValid ? !$v.form.phoneNumber.$error : null"
            />
            <div
              v-if="!$v.form.phoneNumber.isValid && !isPhoneNumberValid"
              class="phone-container-text"
            >
              Номер должен начинаться на +7 или 8
            </div>
          </div>

          <div v-if="allowSearchByID">
            <b-form-input
              v-model.trim="form.id"
              maxlength="7"
              size="sm"
              placeholder="ID врача"
            />
          </div>
          <multiselect
            v-if="allowSearchBySpecialization"
            v-model="form.typeSpecialization"
            class="multiselect-custom"
            :multiple="true"
            :hide-selected="true"
            :close-on-select="true"
            label="name"
            track-by="type"
            select-label="Выбрать"
            selected-label="Выбрано"
            deselect-label="Нажмите, чтобы удалить"
            placeholder="Детский/взрослый"
            :options="selectSpecialization"
          >
            <span slot="noResult">Не удалось найти</span>
          </multiselect>

          <multiselect
            v-model="form.typeConnection"
            class="multiselect-custom"
            :multiple="true"
            :hide-selected="true"
            :close-on-select="true"
            label="name"
            track-by="type"
            select-label="Выбрать"
            selected-label="Выбрано"
            deselect-label="Нажмите, чтобы удалить"
            placeholder="Выберите тип связи"
            :options="selectConnection"
          >
            <span slot="noResult">Не удалось найти</span>
          </multiselect>
        </div>

        <div class="d-flex align-items-center justify-content-end my-3">
          <div class="ml-3">
            <b-form-checkbox
              v-model="form.isActive"
              class="crm-container"
              :unchecked-value="false"
              :disabled="!checkFeature(FEATURES_FOR_DOCTORS.activeDoctors)"
            >
              <span class="crm-form-checkbox-text">
                Активные доктора
              </span>
            </b-form-checkbox>
          </div>
          <b-button
            variant="outline-primary"
            :type="$const.PRIMARY_BUTTON"
            class="ml-auto border-0"
            size="sm"
            @click="clearFilters"
          >
            Очистить
          </b-button>

          <b-button
            :disabled="isLoading"
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            class="ml-2"
            @click="doctorsFetch(true)"
          >
            Поиск с фильтром
          </b-button>
        </div>

        <div
          v-if="nothingFound"
          class="crm-container"
        >
          Ничего не найдено
        </div>

        <slot
          name="doctors"
          :doctors="doctors"
        />
      </div>
    </b-container>
  </div>
</template>

<script>

import debounce from 'lodash/debounce';
import Bus from '@/eventBus';
import { mixinRoles } from '@/mixins';
import { FEATURES_FOR_CHAT, FEATURES_FOR_DOCTORS } from '@/helpers/consts';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { v4 as uuidv4 } from 'uuid';

const LIMIT = 25;
const FETCH_DELAY = 700;

export default {
  name: 'DoctorSearch',
  mixins: [mixinRoles, validationMixin],
  props: {
    modalMode: {
      type: Boolean,
      default: false,
    },
    searchTrigger: {
      type: Boolean,
      default: false,
    },
    onlyActiveDoctors: {
      type: Boolean,
      default: false,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      limit: LIMIT,
      linkGen(pageNum) {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      form: {
        surname: '',
        doctorSpecializationId: '',
        id: '',
        phoneNumber: '',
        isActive: true,
        typeConnection: [],
        typeSpecialization: [],
      },
      selectConnection: [
        { name: 'Аудио', type: 'audio' },
        { name: 'Видео', type: 'video' },
      ],
      selectSpecialization: [
        { name: 'Взр', type: 'forAdults' },
        { name: 'Дет.', type: 'forChildren' },
      ],
      isCheckDeleted: false,
      specializations: [],
      requestMade: false, // становится true после того как произведен запрос и больше не меняется
      FEATURES_FOR_DOCTORS,
      uniqId: null,
    };
  },
  validations() {
    return {
      form: {
        phoneNumber: {
          required: (value) => (value !== '' ? required : true),
          isValid: (value) => {
            if (value === '') {
              return true;
            }
            return /^\+7\(\d{3}\) \d{3}-\d{2}-\d{2}$/i.test(value) || /^\8\(\d{3}\) \d{3}-\d{2}-\d{2}$/i.test(value);
          },

        },
      },
    };
  },
  computed: {
    isPhoneNumberValid() {
      return this.form.phoneNumber.startsWith('+7') || this.form.phoneNumber.startsWith('8');
    },
    phoneMaskString() {
      return this.form.phoneNumber.startsWith('8') ? '#(###) ###-##-##' : '+#(###) ###-##-##';
    },
    doctors() {
      return this.$store.state.Doctor.doctors;
    },
    doctorsDeleted() {
      return this.doctors.filter((item) => item.isDeleted);
    },
    totalCount() {
      return this.doctors.length;
    },
    totalCountDeleted() {
      return this.doctorsDeleted.length;
    },
    query: {
      get() {
        return this.$store.state.Doctor.query;
      },
      set(newVal) {
        this.$store.state.Doctor.query = newVal;
      },
    },
    isDeleted: {
      get() {
        return this.$store.state.Doctor.isDeleted;
      },
      set(newVal) {
        this.$store.state.Doctor.isDeleted = newVal;
      },
    },
    isAllItemsLoaded: {
      get() {
        return this.$store.state.Doctor.isAllItemsLoaded;
      },
      set(newVal) {
        this.$store.state.Doctor.isAllItemsLoaded = newVal;
      },
    },
    isLoading: {
      get() {
        return this.$store.state.Doctor.isLoading;
      },
      set(newVal) {
        this.$store.state.Doctor.isLoading = newVal;
      },
    },
    filtersNotEmpty() {
      const formValues = Object.values(this.form);

      return formValues.some((item) => item || item === 0);
    },
    nothingFound() {
      return this.requestMade
        && !this.totalCount
        && this.isAllItemsLoaded
        && this.filtersNotEmpty;
    },
    allowSearchByID() {
      return this.modalName ? this.checkFeature(FEATURES_FOR_CHAT.searchDoctorByID) : true;
    },
    allowSearchByPhone() {
      return this.checkFeature(FEATURES_FOR_DOCTORS.searchDoctorsByPhone);
    },
    allowSearchBySpecialization() {
      return this.checkFeature(FEATURES_FOR_DOCTORS.typeOfSpecialization);
    },
    gridColumns() {
      if (this.allowSearchBySpecialization) return '1.2fr 0.9fr 0.9fr 1fr 1fr';
      return this.allowSearchByPhone ? 'repeat(4, 1fr)' : 'repeat(3, 1fr)';
    },
  },
  watch: {
    query() {
      this.doctorsFetchDebounced(true);
    },
    async searchTrigger() {
      await this.doctorsFetch(false);
    },
  },
  async created() {
    Bus.$on('doctors:update', this.updateDoctors);
    this.doctorsFetchDebounced = debounce(this.doctorsFetch, FETCH_DELAY);

    this.specializations = await this.$store.dispatch(this.$types.SPECIALIZATIONS_SEARCH, '');
  },
  beforeDestroy() {
    Bus.$off('doctors:update', this.updateDoctors);
    this.$store.commit(this.$types.DOCTORS_SET, []);
  },
  methods: {
    checkFeature(key) {
      return this.checkFeatureAccess({ name: key?.name, url: key?.url });
    },
    comparisonFeature(keyOne, keyTwo) {
      return this.modalName ? this.checkFeatureAccess({ name: keyOne.name, url: keyOne.url })
        : this.checkFeatureAccess({ name: keyTwo.name, url: keyTwo.url });
    },
    async doctorsFetch(clear) {
      if (this.isLoading) return;

      this.$v.$touch();
      if (this.$v.$error) return;

      if (clear) this.isCheckDeleted = false;

      this.requestMade = true;
      this.isAllItemsLoaded = false;
      this.isLoading = true;

      const prevTotal = clear ? 0 : this.totalCount;
      const prevTotalDeleted = clear ? 0 : this.totalCountDeleted;
      const prevQuery = this.query;

      const requestObject = {
        skip: prevTotal,
        take: this.limit,
        query: prevQuery,
        phoneNumber: this.form.phoneNumber,
        clear: !!clear,
        isDeleted: false,
        save: true,
        doctorSpecializationId: this.form.doctorSpecializationId,
        id: this.form.id,
        audio: this.typeConnection('audio'),
        video: this.typeConnection('video'),
        forAdults: this.typeSpecialization('forAdults'),
        forChildren: this.typeSpecialization('forChildren'),
        isActive: this.form.isActive,
      };

      const requestObjectDeleted = {
        ...requestObject,
        skip: prevTotalDeleted,
        isDeleted: true,
        save: true,
        clear: false,
      };

      try {
        if (!this.isCheckDeleted) await this.$store.dispatch(this.$types.DOCTORS_FETCH, requestObject);

        if (prevTotal + this.limit > this.totalCount) {
          this.isCheckDeleted = true;

          if (this.onlyActiveDoctors) {
            this.isAllItemsLoaded = true;
            return;
          }
        }

        if (this.isCheckDeleted) {
          await this.$store.dispatch(this.$types.DOCTORS_FETCH, requestObjectDeleted);

          if (prevTotalDeleted + this.limit > this.totalCountDeleted) {
            this.isAllItemsLoaded = true;
          }
        }
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;

        if (this.query !== prevQuery) this.doctorsFetch(true);
      }
    },
    async clearFilters() {
      const formKeys = Object.keys(this.form);
      formKeys.forEach((key) => { this.form[key] = ''; });
      this.query = '';
      this.form.isActive = true;
      this.updateInput();
      await this.doctorsFetch(true);
    },
    loadMoreItems() {
      this.doctorsFetch(false);
    },
    updateDoctors(id) {
      if (this.doctors.find((doctor) => doctor.id === id)) {
        this.doctorsFetch(true);
      }
    },
    updateInput() {
      this.uniqId = uuidv4();
    },
    typeConnection(value) {
      return this.form.typeConnection ? this.form.typeConnection.some((el) => el.type === value) : false;
    },
    typeSpecialization(value) {
      return this.form.typeSpecialization ? this.form.typeSpecialization.some((el) => el.type === value) : false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.crm-select {
  .vs__dropdown-toggle {
    background: $white;
    height: 31px;
  }
  .vs__dropdown-menu {
    background: $white;
    height: 155px;
  }
  .vs__selected {
    font-size: 12px;
  }

  .vs__search {
    background: $white;
  }
}
.doctor-search{
  display: grid;
  gap: 10px;
}
.doctor-list-inner {
  padding: 30px 0;
}

.shadow-light {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.crm-form-checkbox-text {
  font-size: 16px;
  line-height: 24px;
}
.phone-container{
  position: relative;
    &-text{
      position: absolute;
      top: 100%;
      left: 0;
      margin-top: 3px;
      font-size: 12px;
      color: red;
  }
}

.multiselect-custom {
  & ::v-deep {
    .multiselect__tags {
      height: auto;
      min-height: calc(1.5em + 0.5rem + 2px);
      padding: 2px 40px 0 8px;
      font-size: 0.875rem;
      line-height: 0.5;
      border-radius: 0.2rem;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
    }
    .multiselect__input {
      color: grey;
      line-height: unset;
      padding: unset;
      margin: unset;
      font-size: 14px;
      position: unset;
    }
    .multiselect__select:before {
      top: 85%;
    }
    .multiselect__select {
      width: 25px;
      height: 25px
    }
    .multiselect__placeholder {
      color: grey;
      font-size: 14px;
      margin-top: 10px;
      padding: 0;
    }
    .multiselect__option--highlight,
    .multiselect__option--highlight:after,
    .multiselect__tag {
      background: #007bff;
    }
    .multiselect__tag-icon:hover {
      background: #0063cd;
    }
  }
}
</style>
