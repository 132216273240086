import ScheduleApi from '@/services/schedule/schedule.api';

export default class ScheduleService {
  constructor($axios) {
    this.api = new ScheduleApi($axios);
  }

  getHistoryScheduleTemplate(params) {
    return this.api.getHistoryScheduleTemplate(params);
  }
}
