<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    :title="title"
    no-close-on-backdrop
    hide-footer
    @hidden="onClose"
    @ok="onClose"
  >
    <div class="">
      Вы уверены, что хотите открепить полис по дате?
    </div>

    <div class="mt-4 d-flex justify-content-center">
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        @click="detachPolicy"
      >
        Открепить
        <b-spinner
          v-if="isLoading"
          :disabled="isLoading"
          variant="light"
          small
        />
      </b-button>
      <b-button
        class="ml-2"
        :type="$const.PRIMARY_BUTTON"
        @click="onClose"
      >
        Отмена
      </b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'DetachPolicyModal',

  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Открепление полиса по дате',
    },
    afterFunc: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    isOpen: true,
    isLoading: false,
  }),

  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async detachPolicy() {
      this.isLoading = true;

      try {
        await this.afterFunc();
        this.onClose();
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
