<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    :title="title"
    size="lg"
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <div
      v-if="isLoading"
      class="d-flex justify-content-center w-100"
    >
      <b-spinner variant="success" />
    </div>

    <template v-else>
      <b-row>
        <b-col>
          <b-form-group label="Список партнеров">
            <b-form-radio-group
              v-model="selectLegalType"
              :options="selectLegalPartnerTypes"
              name="Список партнеров"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <multiselect
            v-if="selectLegalType === 1"
            v-model="promocode.availableLegalPartnerIds"
            :multiple="true"
            placeholder="Выберите Юр.лицо"
            :close-on-select="false"
            label="legalName"
            track-by="id"
            :searchable="true"
            :options="legalPartnerList"
            class="w-50 mb-2"
          />
          <b-row
            v-if="$v.promocode.availableLegalPartnerIds.$error"
            class="mb-5"
          >
            <b-col>
              <div
                class="validation-errors"
              >
                <span class="validation-error-text">
                  Данное поле обязательно*
                </span>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-row>
            <b-col>
              <b-form-group label="Название промокода">
                <b-form-input
                  v-model.trim="$v.promocode.code.$model"
                  :state="validateState('code')"
                  type="text"
                  placeholder="Введите название"
                  class="w-50"
                />
                <span
                  v-if="$v.promocode.code.$error"
                  class="text-danger small"
                >
                  {{ errorsValidation.code[0] }}
                </span>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Тип промокода">
                <b-form-radio-group
                  v-model="promocode.type"
                  :options="promocodeTypeOptions[0]"
                  name="Тип промокода"
                />
                <b-form-input
                  v-model.trim="$v.promocode.discount.$model"
                  :state="validateState('discount')"
                  type="number"
                  class="w-50 mt-2"
                  placeholder="Скидка"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Срок действия промокода">
                <b-form-radio-group
                  v-model="timeType"
                  :options="promocodeTypeOptions[1]"
                  name="Срок действия промокода"
                  @input="updatePromoCodeValue('date', timeType, promocodeTypeOptions[2])"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              v-if="timeType === 1"
              class="mb-2"
            >
              <b-row>
                <b-col md="6">
                  <b-form-group label="Дата начала">
                    <base-date-picker
                      v-model="$v.promocode.startDate.$model"
                      :error="$v.promocode.startDate.$error"
                      :errors="errorsValidation.startDate"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Дата окончания">
                    <base-date-picker
                      v-model="$v.promocode.endDate.$model"
                      :error="$v.promocode.endDate.$error"
                      :errors="errorsValidation.endDate"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Общее возможное количество применений промокода">
                <b-form-radio-group
                  v-model="allUseType"
                  :options="promocodeTypeOptions[2]"
                  name="Общее количество применений"
                  @input="updatePromoCodeValue('availableUsesTotalCount', allUseType, promocodeTypeOptions[2])"
                />
              </b-form-group>

              <div
                v-if="allUseType === 1"
              >
                <b-form-group label="Количество применений">
                  <b-form-input
                    v-model.trim="$v.promocode.availableUsesTotalCount.$model"
                    :state="validateState('availableUsesTotalCount')"
                    placeholder="Количество применений"
                    type="number"
                    class="w-50"
                  />
                </b-form-group>
              </div>
              <b-row>
                <b-col>
                  <b-form-group label="Тип промокода по количеству применений на одном профиле">
                    <b-form-radio-group
                      v-model="oneUseType"
                      :options="promocodeTypeOptions[2]"
                      name="Количество применений на одном профиле"
                      @input="updatePromoCodeValue('availableUsePerOneUserProfileCount', oneUseType, promocodeTypeOptions[2])"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div
            v-if="oneUseType === 1"
          >
            <b-form-group label="Лимит применений для одного профиля">
              <b-form-input
                v-model.trim="$v.promocode.availableUsePerOneUserProfileCount.$model"
                :state="validateState('availableUsePerOneUserProfileCount')"
                type="number"
                placeholder="Количество применений"
                class="w-50"
              />
            </b-form-group>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Количество применений для различных профилей одного пользователя">
            <b-form-radio-group
              v-model="countType"
              :options="promocodeTypeOptions[2]"
              name="Количество применений для различных профилей одного пользователя"
              @input="updatePromoCodeValue('availableUsePerDistinctUserProfilesCount', countType, promocodeTypeOptions[2])"
            />
          </b-form-group>

          <div
            v-if="countType === 1"
          >
            <b-form-group label="Лимит применений для различных профилей одного пользователя">
              <b-form-input
                v-model="$v.promocode.availableUsePerDistinctUserProfilesCount.$model"
                :state="validateState('availableUsePerDistinctUserProfilesCount')"
                type="number"
                placeholder="Количество"
                class="w-50"
              />
            </b-form-group>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Услуги, на которые не распространяется скидка">
            <multiselect
              v-model="promocode.unavilableServiceIds"
              :multiple="true"
              placeholder="Выберите услугу"
              :close-on-select="false"
              label="name"
              track-by="id"
              :searchable="true"
              :options="servicesList"
              class="w-50"
              @search-change="fetchServices"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Услуги, на которые распространяется скидка">
            <multiselect
              v-model="promocode.availableServiceIds"
              :multiple="true"
              placeholder="Выберите услугу"
              :close-on-select="false"
              label="name"
              track-by="id"
              :searchable="true"
              :options="servicesList"
              class="w-50"
              @search-change="fetchServices"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Комментарий">
            <b-form-textarea
              id="comments"
              v-model="promocode.comment"
              placeholder="Введите комментарий"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>

    <template #modal-footer>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="onClose"
      >
        Отменить
      </b-button>

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="onClickSave"
      >
        Сохранить
        <b-spinner
          v-if="isSaving"
          variant="light"
          small
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  formatISO,
} from '@evd3v/date-fns';
import { validationMixin } from 'vuelidate';
import { BaseDatePicker } from '@/components/base';
import {
  required, minLength, minValue, maxValue, numeric,
} from 'vuelidate/lib/validators';
import debounce from 'lodash.debounce';
import { api } from '@/helpers/api';

const PROMO_CODES_TYPES = [
  { value: '0', text: 'Фиксированный' },
  { value: '1', text: 'Процентный' },
];

const NUMBER_OF_ACCAUNTS = [
  { value: 0, text: 'Не лимитированное' },
  { value: 1, text: 'Лимитированное' },
];

const PROMO_CODE_VALIDITY_PREIOD = [
  { value: 0, text: 'Бессрочный промокод' },
  { value: 1, text: 'С ограниченным сроком' },
];

const COUNT_TYPES = [
  { value: 0, text: 'Не лимитированное' },
  { value: 1, text: 'Лимитированное' },
];

const SELECT_LEGAL_PARTNER_TYPES = [
  { value: 0, text: 'Для всех' },
  { value: 1, text: 'Выбрать партнера' },
];

const FETCH_DELAY = 700;

export default {
  name: 'ModalNew',
  components: {
    BaseDatePicker,
  },
  mixins: [validationMixin],
  props: {
    legalPartnerList: {
      type: Array,
      required: true,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
    promocodeId: {
      type: [String, Number],
      default: null,
    },
    legalPartnerId: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: 'Добавление промокода',
    },
    limit: {
      type: Number,
      default: null,
    },
    offset: {
      type: Number,
      default: null,
    },
  },

  data: () => ({
    servicesList: [],
    selectLegalType: 0,
    timeType: null,
    oneUseType: null,
    allUseType: null,
    countType: null,
    isOpen: true,
    isLoading: false,
    isSaving: false,
    promocode: {
      code: '',
      discount: 0,
      availableUsesTotalCount: null,
      availableUsePerOneUserProfileCount: null,
      type: 0,
      startDate: null,
      endDate: null,
      comment: '',
      availableUsePerDistinctUserProfilesCount: null,
      availableLegalPartnerIds: [],
      availableServiceIds: [],
      unavilableServiceIds: [],
    },
  }),
  validations() {
    return {
      promocode: {
        availableLegalPartnerIds: {
          required: this.isLegalType ? required : () => true,
          minLength: minLength(1),
        },
        code: {
          required,
          isValid: (code) => code && code.split(' ').length === 1 && !/[`~!@#$%^&*()а-яА-ЯЁё]/.test(code),
        },
        discount: {
          required,
          minValue: minValue(1),
        },
        availableUsesTotalCount: {
          ...(this.isAllUseType && { required }),
          ...(this.isAllUseType && { numeric }),
          ...(this.isAllUseType && { minValue: minValue(0.00000000000001) }),
        },
        availableUsePerOneUserProfileCount: {
          ...(this.isOneUseType && { required }),
          ...(this.isOneUseType && { numeric }),
          ...(this.isOneUseType && { minValue: minValue(0.00000000000001) }),
        },
        availableUsePerDistinctUserProfilesCount: {
          ...(this.isCountType && { required }),
          ...(this.isCountType && { numeric }),
          ...(this.isCountType && { minValue: minValue(0.00000000000001) }),
        },
        startDate: {
          ...(this.isTimeType && { required }),
          ...(this.isTimeType && { maxValue: maxValue(new Date()) }),
        },
        endDate: {
          ...(this.isTimeType && { required }),
          ...(this.isTimeType && { minValue: minValue(new Date()) }),
        },
      },
    };
  },
  computed: {
    promocodeTypeOptions() {
      return [
        PROMO_CODES_TYPES,
        PROMO_CODE_VALIDITY_PREIOD,
        COUNT_TYPES,
        NUMBER_OF_ACCAUNTS,
      ];
    },
    errorsValidation() {
      const errors = {};

      errors.code = [];
      if (!this.$v.promocode.code.required) {
        errors.code.push('Поле не может быть пустым');
      }
      if (!this.$v.promocode.code.isValid) {
        errors.code.push('Некорректное значение поля');
      }

      errors.startDate = [];
      if (!this.$v.promocode.startDate.required) {
        errors.startDate.push('Поле не может быть пустым');
      }
      errors.endDate = [];
      if (!this.$v.promocode.endDate.required) {
        errors.endDate.push('Поле не может быть пустым');
      }
      return errors;
    },
    isLegalType() {
      return this.selectLegalType === 1;
    },
    isOneUseType() {
      return this.oneUseType === 1;
    },
    isAllUseType() {
      return this.allUseType === 1;
    },
    isCountType() {
      return this.countType === 1;
    },
    isTimeType() {
      return this.timeType === 1;
    },
    selectLegalPartnerTypes() {
      return SELECT_LEGAL_PARTNER_TYPES;
    },
  },
  async created() {
    this.isLoading = true;

    await this.fetchServices();

    if (this.promocodeId) {
      await this.fetchPromoCodeInfo();
      this.selectLegalType = this.promocode.availableLegalPartnerIds.length ? 1 : 0;
      this.countType = this.promocode.availableUsePerDistinctUserProfilesCount ? 1 : 0;
      this.allUseType = this.promocode.availableUsesTotalCount ? 1 : 0;
      this.oneUseType = this.promocode.availableUsePerOneUserProfileCount ? 1 : 0;
      this.timeType = this.promocode.startDate ? 1 : 0;
    }

    if (this.promocode.startDate && this.promocode.endDate) {
      this.timeType = 1;
      this.promocode.startDate = new Date(this.promocode.startDate);
      this.promocode.endDate = new Date(this.promocode.endDate);
    }

    this.isLoading = false;
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    fetchServices: debounce(async function fetchServices(query) {
      const params = new URLSearchParams();

      params.append('isActive', true);
      if (query) params.append('Name', query);

      const { data: { data } } = await api.get('v3/laboratory/service/list', {
        params,
      });
      this.servicesList = data;
    }, FETCH_DELAY),
    async fetchPromoCodeInfo() {
      const { data } = await this.$store.dispatch(this.$types.LABORATORY_PROMOCODE_FETCH, this.promocodeId);

      const result = {
        availableLegalPartnerIds: data.availableLegalPartners,
        availableUsePerDistinctUserProfilesCount: data.availableUsePerDistinctUsersCount,
        availableServiceIds: data.availableServices,
        unavilableServiceIds: data.unavailableServices,
        ...data,
      };

      this.promocode = result;
    },
    updatePromoCodeValue(prop, value, type) {
      if (prop === 'date') {
        this.promocode.startDate = type === 1 ? value : null;
        this.promocode.endDate = type === 1 ? value : null;
        return;
      }

      this.promocode[prop] = type === 1 ? value : null;
    },
    onCheckValidation() {
      this.$v.promocode.code.$touch();
      this.$v.promocode.discount.$touch();
      this.$v.promocode.availableLegalPartnerIds.$touch();
      this.$v.promocode.availableUsePerOneUserProfileCount.$touch();
      this.$v.promocode.availableUsesTotalCount.$touch();
      this.$v.promocode.availableUsePerDistinctUserProfilesCount.$touch();
      this.$v.promocode.startDate.$touch();
      this.$v.promocode.endDate.$touch();

      if (
        this.$v.promocode.code.$anyError
        || this.$v.promocode.discount.$anyError
        || this.$v.promocode.availableLegalPartnerIds.$anyError
        || this.$v.promocode.availableUsePerOneUserProfileCount.$anyError
        || this.$v.promocode.availableUsesTotalCount.$anyError
        || this.$v.promocode.availableUsePerDistinctUserProfilesCount.$anyError
        || this.$v.promocode.startDate.$anyError
        || this.$v.promocode.endDate.$anyError
      ) {
        return false;
      }

      return true;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.promocode[name];
      return $dirty ? !$error : null;
    },
    async onClickSave() {
      if (!this.onCheckValidation()) return;

      this.isSaving = true;
      const params = {
        ...this.promocode,
        id: this.promocodeId,
        availableServiceIds: this.promocode.availableServiceIds ? this.promocode.availableServiceIds.map((item) => item.id) : null,
        unavilableServiceIds: this.promocode.unavilableServiceIds ? this.promocode.unavilableServiceIds.map((item) => item.id) : null,
        availableLegalPartnerIds: this.promocode.availableLegalPartnerIds ? this.promocode.availableLegalPartnerIds.map((item) => item.id) : null,
        startDate: this.promocode.startDate ? formatISO(this.promocode.startDate) : null,
        endDate: this.promocode.endDate ? formatISO(this.promocode.endDate) : null,
      };

      try {
        if (this.promocodeId) {
          await this.$store.dispatch(this.$types.LABORATORY_PROMOCODE_UPDATE, params);
        } else {
          await this.$store.dispatch(this.$types.LABORATORY_PROMOCODE_CREATE, params);
        }

        await this.$store.dispatch(this.$types.LABORATORY_PROMOCODES_FETCH, {
          limit: this.limit,
          offset: this.offset,
          legalPartnerId: this.legalPartnerId,
        });
        this.onClose();
      } catch (e) {
        console.warn(e);
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>
