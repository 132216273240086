import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.LEGAL_ENTITIES_SET](state, payload) {
    state.legalEntities = payload;
  },
  [localTypes.LEGAL_ENTITIES_ADD](state, payload) {
    state.legalEntities = [...state.legalEntities, ...payload];
  },
  [localTypes.LEGAL_ENTITIES_REMOVE_BY_ID](state, payload) {
    state.legalEntities = state.legalEntities.filter((entity) => entity.id !== payload);
  },
};
