<template>
  <div>
    <div class="d-flex mb-1">
      <div class="font-weight-bold">
        Адрес
      </div>
      <div class="ml-2 text-break">
        {{ laboratory.address || '—' }}
      </div>
    </div>
    <div class="d-flex mb-1">
      <div class="font-weight-bold">
        Режим работы
      </div>
      <div
        v-if="laboratory.operatingHoursFrom"
        class="ml-2 text-break"
      >
        c {{ laboratory.operatingHoursFrom }}
        до {{ laboratory.operatingHoursTo || '—' }}
      </div>
      <div
        v-else
        class="ml-2 text-break"
      >
        не указан
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LaboratoriesBaloon',
  props: {
    laboratory: {
      type: Object,
      required: true,
    },
  },
};
</script>
