<template>
  <b-modal
    v-model="isOpen"
    centered
    hide-footer
    no-close-on-backdrop
    :title="'Импорт файла'"
    @hidden="onClose"
    @ok="onClose"
  >
    <template v-if="!isImported">
      <div class="px-4">
        <div class="mb-2">
          Будет импортирован файл: "{{ params.fileName }}" <br>
        </div>

        <div>
          Количество строк: {{ params.AllRowsCount }}
        </div>
      </div>

      <div class="crm-container-buttons">
        <b-button
          variant="outline-danger"
          :type="$const.PRIMARY_BUTTON"
          class="crm-button-file"
          @click="onClose"
        >
          <span>Отменить</span>
        </b-button>

        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          class="button-import"
          @click="startImport"
        >
          <span>Импортировать</span>
        </b-button>
      </div>
    </template>

    <template v-else>
      <template v-if="isImportingState">
        <div class="crm-info-text">
          Идет импорт файла. Загрузка может занять до 15 мин. Не прерывайте загрузку
        </div>
        <preloader style="margin-top: 20px" />
      </template>

      <template v-if="isSuccessCompleteState">
        <div class="crm-info-text">
          Импортирование успешно завершено. Загружено: {{ importedData }} из {{ totalData }}
        </div>
        <div class="crm-container-buttons">
          <b-button
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            class="button-import"
            @click="onFinished"
          >
            <span>ОК</span>
          </b-button>
        </div>
      </template>
      <template v-if="isErrorCompleteState">
        <div class="crm-info-text">
          Импорт частично завершен<br>
          Загружено: {{ importedData }} из {{ totalData }}<br>
          <span style="color: #e76666;">Невозможно импортировать: {{ notImportedData }} из {{ totalData }}</span>
        </div>
        <div class="crm-container-buttons">
          <b-button
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            class="button-import"
            @click="downloadTable"
          >
            <span>Скачать таблицу</span>
          </b-button>
          <b-button
            variant="outline-danger"
            :type="$const.PRIMARY_BUTTON"
            class="crm-button-file"
            @click="onClose"
          >
            <span>Отменить</span>
          </b-button>
        </div>
        <div class="additional-text mt-4">
          Таблица содержит неимпортированные данные.
        </div>
      </template>
    </template>
  </b-modal>
</template>

<script>

import Preloader from '@/components/Preloader';
import { api } from '@/helpers/api';
import { saveFile } from '@/helpers/utils';
import { showCustomMessage } from '@/helpers/messages';

export default {
  name: 'ReferralDutyConsultationImportModal',
  components: {
    Preloader,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    params: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isOpen: true,
      isImported: false,
      isImportingState: false,
      isSelectImportTypeState: true,
      isSuccessCompleteState: false,
      isErrorCompleteState: false,

      importedData: 0,
      notImportedData: 0,
      totalData: 0,
      fileGUIDForDownload: '',
    };
  },
  methods: {
    clearState() {
      this.isImportingState = false;
      this.isSelectImportTypeState = false;
      this.isSuccessCompleteState = false;
      this.isErrorCompleteState = false;
    },
    async downloadTable() {
      try {
        const { data: fileName } = await api.get(`File/name/${this.fileGUIDForDownload}`);
        const { data: file } = await api.get(`File/${this.fileGUIDForDownload}`, { responseType: 'blob' });
        saveFile(file, { name: fileName });
      } catch (error) {
        showCustomMessage('warning', 'Ошибка', `Не удалось скачать файл ${error}`);
        console.error(error);
      }
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    onFinished() {
      this.onClose();
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
    async startImport() {
      try {
        this.clearState();
        this.isImported = true;
        this.isImportingState = true;

        const {
          data: {
            allRowsCount, errorsCount, successCount, fileWithErrorsId,
          },
        } = await api.post(`v2/consultations/dutyDoctor/${this.params.queue}/import`, this.params);

        this.notImportedData = errorsCount;
        this.totalData = allRowsCount;
        this.importedData = successCount;

        this.clearState();
        if (errorsCount) {
          this.fileGUIDForDownload = fileWithErrorsId;
          this.isErrorCompleteState = true;
        } else {
          this.isSuccessCompleteState = true;
        }
      } catch (e) {
        this.clearState();
        this.isErrorCompleteState = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .crm-container-buttons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
  }

  .crm-info-text, .additional-text {
    color: #908F8D;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
  }
  .additional-text {
    font-size: 12px;
    line-height: 16px;
  }
</style>
