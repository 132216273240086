/* eslint-disable max-classes-per-file */
// eslint-disable-next-line import/prefer-default-export
export class Document {
  constructor(document) {
    this.id = document.id;
    this.clinicId = document.clinicId;
    this.fileId = document.fileId;
    this.type = document.type;
    this.subtype = document.subtype;
    this.status = document.status;
    this.timeCreated = document.timeCreated;
    this.title = document.title;
  }

  static typeArray = [
    {
      id: 1,
      title: 'Договоры и дополнительные соглашения',
    },
    {
      id: 2,
      title: 'Учредительные документы',
    },
  ];

  static statuses = [];

  get typeTitle() {
    return Document.typeArray.find((type) => type.id === this.type).title;
  }
}

export class Contract extends Document {
  static subtypeArray = [
    {
      id: 1,
      title: 'Агентский договор',
    },
    {
      id: 2,
      title: 'Договор телемедицинских услуг',
    },
    {
      id: 3,
      title: 'Договор медицинских услуг',
    },
    {
      id: 4,
      title: 'Дополнительное соглашение',
    },
  ];

  static statuses = [
    {
      id: 1,
      title: 'Подписан',
    },
    {
      id: 2,
      title: 'На согласовании',
    },
    {
      id: 3,
      title: 'На подписании',
    },
  ]

  get subtypeTitle() {
    return Contract.subtypeArray.find((subtype) => subtype.id === this.subtype).title || null;
  }

  get statusTitle() {
    return Contract.statuses.find((status) => status.id === this.status).title || null;
  }
}

export class Constituent extends Document {
  static subtypeArray = [
    {
      id: 1,
      title: 'Лицензия',
    },
    {
      id: 2,
      title: 'Учётная карточка',
    },
    {
      id: 3,
      title: 'Выписка из ЕГРЮЛ',
    },
    {
      id: 4,
      title: 'Доверенность',
    },
    {
      id: 5,
      title: 'Другое',
    },
  ];

  static statuses = [];

  get subtypeTitle() {
    return Constituent.subtypeArray.find((subtype) => subtype.id === this.subtype).title || null;
  }

  // eslint-disable-next-line class-methods-use-this
  get statusTitle() {
    return null;
  }
}

export function createDocumentInstance(document) {
  switch (document.type) {
    case 1: return new Contract(document);
    case 2: return new Constituent(document);
    default: return null;
  }
}
