<template>
  <b-modal
    id="IndemnityPriceClinicTemplateEditModal"
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    :title="data.id ? 'Редактировать шаблон' : 'Новый шаблон'"
    @hidden="onClose"
    @ok="onClose"
  >
    <div>
      <label for="template-name">Название шаблона:</label>
      <b-input
        id="template-name"
        v-model="data.name"
        placeholder="Введите название шаблона"
        class="mr-2"
      />

      <label class="mt-3">
        Услуги шаблона:
      </label>

      <b-table
        v-if="data.clinicPrices.length"
        :fields="priceSearch.fields"
        :items="data.clinicPrices"
        size="sm"
        class="price-table"
        sticky-header
        bordered
      >
        <template #cell(clinicPrices)="row">
          <div
            v-for="price in row.item.clinicPrices"
            :key="price.code"
            class="mb-1"
          >
            {{ price.code }}, {{ price.name }}, {{ price.cost }} р.
          </div>
        </template>

        <template #cell(actions)="row">
          <div
            class="d-flex justify-content-center align-items-center"
          >
            <b-button
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="mr-1 py-0"
              @click="onPriceDecrease(row.item.id)"
            >
              -
            </b-button>

            <div
              class="text-center"
              style="width: 20px"
            >
              {{ getSelectedPriceById(row.item.id)?.count }}
            </div>

            <b-button
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="ml-1 py-0"
              @click="onPriceIncrease(row.item.id)"
            >
              +
            </b-button>
          </div>
        </template>
      </b-table>

      <div
        v-else
      >
        <small>
          Услуги не выбраны
        </small>
      </div>

      <label class="mt-3">
        Тип прайса:
      </label>

      <div class="d-flex">
        <base-radio-button
          v-for="priceCategory in priceCategories"
          :key="priceCategory.id"
          v-model="priceSearch.priceCategory"
          class="crm-radio-button mr-4 mt-0"
          :name="priceCategory.id"
          @change="onPriceCategoryChange"
        >
          {{ priceCategory.title }}
        </base-radio-button>
      </div>

      <label class="mt-3">
        Поиск услуг:
      </label>

      <div class="d-flex">
        <b-input
          v-model="priceSearch.code"
          placeholder="Код услуги"
          class="mr-2"
          size="sm"
          @keyup.native.enter="onPriceSearch"
        />

        <b-input
          v-model="priceSearch.name"
          placeholder="Название услуги"
          class="mr-2"
          size="sm"
          @keyup.native.enter="onPriceSearch"
        />

        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          size="sm"
          :disabled="priceSearch.isLoading || priceSearch.priceCategory == null"
          @click="onPriceSearch"
        >
          <b-icon icon="search" />
        </b-button>
      </div>

      <b-table
        v-if="priceSearch.clinicPrices.length && !priceSearch.isLoading"
        :fields="priceSearch.fields"
        :items="priceSearch.clinicPrices"
        class="mt-3 price-table"
        size="sm"
        sticky-header
        bordered
      >
        <template #cell(clinicPrices)="row">
          <div
            v-for="price in row.item.clinicPrices"
            :key="price.code"
            class="mb-1"
          >
            {{ price.code }}, {{ price.name }}, {{ price.cost }} р.
          </div>
        </template>

        <template #cell(actions)="row">
          <b-button
            v-if="!getSelectedPriceById(row.item.id)"
            size="sm"
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            class="w-100"
            @click="onPriceAdd(row.item)"
          >
            Добавить
          </b-button>

          <div
            v-else
            class="d-flex justify-content-center align-items-center"
          >
            <b-button
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="mr-1 py-0"
              @click="onPriceDecrease(row.item.id)"
            >
              -
            </b-button>

            <div
              class="text-center"
              style="width: 20px"
            >
              {{ getSelectedPriceById(row.item.id)?.count }}
            </div>

            <b-button
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="ml-1 py-0"
              @click="onPriceIncrease(row.item.id)"
            >
              +
            </b-button>
          </div>
        </template>
      </b-table>

      <div
        v-else-if="!priceSearch.isLoading"
        class="mt-3"
      >
        <small>
          Услуги не найдены
        </small>
      </div>

      <div
        v-else
        class="mt-3 text-center"
      >
        <b-spinner variant="primary" />
      </div>
    </div>

    <template #modal-footer>
      <b-button
        variant="success"
        :type="$const.PRIMARY_BUTTON"
        class="float-right ml-2"
        size="sm"
        :disabled="isLoading || !data.name"
        @click="onSave"
      >
        Сохранить
      </b-button>
      <b-button
        variant="outline-primary"
        :type="$const.PRIMARY_BUTTON"
        class="float-right ml-2"
        size="sm"
        :disabled="isLoading"
        @click="onClose"
      >
        Отмена
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { BaseRadioButton } from '@/components/base';

export default {
  name: 'IndemnityPriceClinicTemplateEditModal',
  components: {
    BaseRadioButton,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    template: {
      type: Object,
      default: null,
    },
    saveTemplateCallback: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    isOpen: true,
    isLoading: false,
    data: {
      id: null,
      name: null,
      clinicPrices: [],
    },
    priceSearch: {
      name: '',
      isLoading: false,
      clinicPrices: [],
      priceCategory: null,
      fields: [
        {
          key: 'code',
          label: 'Код услуги',
        },
        {
          key: 'name',
          label: 'Название услуги',
        },
        {
          key: 'actions',
          label: '',
          tdClass: 'action-td',
          thClass: 'action-td',
        },
      ],
    },
    priceCategories: [
      {
        id: 0,
        title: 'ДМС',
      },
      {
        id: 1,
        title: 'Индивидуальный',
      },
      {
        id: 2,
        title: 'Продукт в коробке',
      },
      {
        id: 3,
        title: 'Телемед',
      },
    ],
  }),

  created() {
    if (this.template) {
      this.priceSearch.priceCategory = this.template.clinicPrices[0]?.type;
      this.data = JSON.parse(JSON.stringify(this.template));
      this.data.clinicPrices = this.data.clinicPrices.reduce((result, item) => {
        const itemInResult = result.find((i) => i.id === item.id);

        if (itemInResult) {
          itemInResult.count += 1;
        } else {
          result.push({ ...item, count: item.count || 1 });
        }

        return result;
      }, []);
    }
  },

  methods: {
    onPriceCategoryChange() {
      this.priceSearch.clinicPrices = [];
      this.data.clinicPrices = [];
    },
    async onPriceSearch() {
      if (this.priceSearch.priceCategory == null) return;
      this.priceSearch.isLoading = true;

      const { paginatedCollection } = await this.$store.dispatch(this.$types.CLINIC_PRICE_LIST_FETCH, {
        code: this.priceSearch.code,
        name: this.priceSearch.name,
        type: this.priceSearch.priceCategory,
        clinicId: this.data?.clinicPrices?.[0]?.clinicId || this.$store.state.Indemnity?.letter?.clinicId,
        take: 10,
      });

      this.priceSearch.clinicPrices = paginatedCollection.filter((el) => el.isActive);
      this.priceSearch.isLoading = false;
    },
    getSelectedPriceById(id) {
      return this.data.clinicPrices.find((p) => p.id === id);
    },
    onPriceAdd(price) {
      this.data.clinicPrices.push({ ...price, count: 1 });
    },
    onPriceRemove(id) {
      this.data.clinicPrices = this.data.clinicPrices.filter((price) => price.id !== id);
    },
    onPriceIncrease(id) {
      const selectedPrice = this.getSelectedPriceById(id);
      if (selectedPrice) {
        selectedPrice.count += 1;
      }
    },
    onPriceDecrease(id) {
      const selectedPrice = this.getSelectedPriceById(id);

      if (selectedPrice) {
        if (selectedPrice.count === 1) {
          this.onPriceRemove(id);
        } else {
          selectedPrice.count -= 1;
        }
      }
    },
    async onSave() {
      this.isLoading = true;

      const method = this.$types[this.data.id ? 'PRICE_CLINIC_TEMPLATE_UPDATE' : 'PRICE_CLINIC_TEMPLATE_CREATE'];
      const clinicPriceIds = [];
      this.data.clinicPrices.forEach((clinicPrice) => {
        for (let i = 0; i < clinicPrice.count; i += 1) {
          clinicPriceIds.push(clinicPrice.id);
        }
      });

      await this.$store.dispatch(method, {
        id: this.data.id,
        name: this.data.name,
        clinicPriceIds,
      });

      this.isLoading = false;

      this.saveTemplateCallback();
      this.onClose();
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep#IndemnityPriceClinicTemplateEditModal___BV_modal_outer_ {
  z-index: 1050!important;
}

::v-deep.price-table {
  max-height: 235px;

  .action-td {
    width: 50px;
  }

  .table th, .table td {
    padding: 0.25rem;
  }
}
</style>
