<template>
  <div
    class="text-center"
  >
    <b-spinner
      variant="primary"
      :style="{ width: `${width}rem`, height: `${height}rem` }"
    />
  </div>
</template>

<script>
export default {
  name: 'Preloader',
  props: {
    width: {
      type: Number,
      default: 2.5,
    },
    height: {
      type: Number,
      default: 2.5,
    },
  },
};
</script>
