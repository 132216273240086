<template>
  <div class="modal-step">
    <div class="crm-scrollbar-wrapper">
      <custom-scrollbar>
        <div class="crm-container indemtity-wrapper">
          <div class="crm-form-fields">
            <div class="crm-form-field crm-form-field_align-center">
              <indemnity-arow-left-button @click="clickPrev" />
              <p class="page-title">
                Информация о подписании
              </p>
            </div>

            <div class="crm-form-field crm-form-field_justify_end">
              <span class="program-name">
                {{ letter.insuranceProgramName }}
              </span>
            </div>
          </div>

          <div class="crm-form-fields">
            <div
              class="crm-form-field d-block"
            >
              <p class="crm-label">
                {{ `Срок действия ${letter.isReferral ? 'направления' : 'гарантийного письма'}` }}
              </p>

              <base-date-picker
                v-if="isNewValidityType"
                v-model="$v.validityDate.$model"
                :clearable="false"
                :error="$v.validityDate.$error"
                :errors="errorsValidation.validityDate"
              />

              <b-form-input
                v-else
                v-model="validityString"
              />
            </div>

            <div class="crm-form-field" />
          </div>

          <div class="crm-form-fields">
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Имя подписанта
                </p>
                <b-form-select
                  v-model="$v.signerName.$model"
                  :options="SIGNERS"
                  disabled
                  value-field="name"
                  text-field="name"
                  placeholder="Имя подписанта"
                />
                <div
                  v-if="$v.signerName.$error"
                  class="validation-errors"
                >
                  <span
                    class="validation-error-text"
                  >
                    {{ errorsValidation.signerName[0] }}
                  </span>
                </div>
              </div>
            </div>

            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Должность подписанта
                </p>
                <b-form-input
                  v-model="$v.signerPosition.$model"
                  disabled
                  label="Должность подписанта"
                />
              </div>
            </div>
          </div>

          <div class="crm-form-fields">
            <div class="crm-form-field">
              <base-input
                v-model="$v.documentNumber.$model"
                :error="$v.documentNumber.$error"
                :errors="errorsValidation.documentNumber"
                label="Номер документа"
                :input-style="{
                  padding: '11px 15px 11px',
                }"
                disabled
                fluid
              />
            </div>

            <div class="crm-form-field">
              <div class="crm-form-fields">
                <div class="crm-form-block">
                  <p class="crm-label">
                    Дата документа
                  </p>

                  <base-date-picker
                    v-model="$v.creationDate.$model"
                    :clearable="false"
                    :error="$v.creationDate.$error"
                    :errors="errorsValidation.creationDate"
                    class="crm-datepicker"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="letter.priceType === 0"
            class="crm-form-fields"
          >
            <div class="crm-form-field" />

            <div
              class="crm-form-field crm-form-field_justify_end crm-form-field_align-center"
              @click="onClickPrint"
            >
              <span class="prewatch-text">
                Предварительный просмотр
              </span>

              <icon-watch class="prewatch-icon" />
            </div>
          </div>

          <div
            v-if="letter.priceType === 2"
            class="mt-3 w-100"
          >
            <div v-if="isLetterDublesLoading">
              Загрузка писем клиник...
            </div>

            <b-table
              v-else
              :fields="letterDublesFields"
              :items="letterDubles"
              class="mt-3 letter-dubles-table"
              size="sm"
              sticky-header
              bordered
            >
              <template #cell(clinic)="row">
                {{ row.item.clinic?.appTitle }}, {{ row.item.clinic?.addressString }}
              </template>
              <template #cell(actions)="row">
                <b-button
                  variant="outline-primary"
                  :type="$const.PRIMARY_BUTTON"
                  class="px-2 py-1 ml-auto"
                  size="sm"
                  @click="() => onPrintLetter(row.item)"
                >
                  <b-icon icon="eye" />
                </b-button>
              </template>
            </b-table>
          </div>

          <div
            v-if="letter.priceType === 3"
            class="crm-form-fields"
          >
            <div class="crm-form-field" />

            <div
              class="crm-form-field crm-form-field_justify_end crm-form-field_align-center"
              @click="() => onPrintLetter(letter)"
            >
              <span class="prewatch-text">
                Предварительный просмотр
              </span>

              <icon-watch class="prewatch-icon" />
            </div>
          </div>
        </div>
      </custom-scrollbar>
    </div>

    <div class="crm-wrapper-buttons">
      <b-button
        variant="outline-primary"
        :type="$const.PRIMARY_BUTTON"
        size="s"
        class="mr-3"
        @click="onClickCancel"
      >
        Отменить
      </b-button>

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        size="s"
        @click="onClickSend"
      >
        Отправить
      </b-button>
    </div>
  </div>
</template>

<script>
import { parseISO, formatISO } from '@evd3v/date-fns';
import Bus from '@/eventBus';

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import { showValidationErrorMessage } from '@/helpers/messages';
import { SIGNERS } from '@/helpers/constsIndemnity';

import { mapGetters } from 'vuex';
import * as types from '@/store/types';

import { BaseInput, BaseDatePicker } from '@/components/base';
import IconWatch from 'assets/images/watch_icon.svg';
import { getBase64Pdf, showPdf } from '@/components/Indemnity/indemnityUtils';
import IndemnityArowLeftButton from './IndemnityArowLeftButton';

export default {
  name: 'IndemnityModalStep4',
  components: {
    IndemnityArowLeftButton,
    BaseInput,
    BaseDatePicker,
    IconWatch,
  },
  mixins: [validationMixin],
  data() {
    return {
      SIGNERS,
      isLetterDublesLoading: false,
      letterDubles: [],
      letterDublesFields: [
        {
          key: 'clinic',
          label: 'Название клиники',
        },
        {
          key: 'actions',
          label: 'Предварительный просмотр',
          tdClass: 'action-td text-right',
          thClass: 'action-td',
        },
      ],
    };
  },
  validations() {
    return {
      validityString: {
        // required,
      },
      validityDate: {
        required: this.isValidityDateValidationRequired ? required : () => true,
        valid: this.isValidityDateValidationRequired
          ? (value) => new Date(this.letter?.policy?.endDate) >= new Date(value)
          : () => true,
      },
      signerName: {
        required,
      },
      signerPosition: {
        required,
      },
      documentNumber: {
        // required,
      },
      creationDate: {
        required,
        valid: (value) => (this.letter?.policyId ? new Date(this.letter?.policy?.endDate) : new Date()) >= new Date(value),
      },
    };
  },
  computed: {
    errorsValidation() {
      const errors = {};

      errors.validityString = [];
      if (!this.$v.validityString.required) {
        errors.validityString.push('Поле не может быть пустым');
      }

      errors.validityDate = [];
      if (!this.$v.validityDate.required) {
        errors.validityDate.push('Поле не может быть пустым');
      }

      if (!this.$v.validityDate.valid) {
        errors.validityDate.push(`Срок гп не должен превышать срок полиса (${new Date(this.letter?.policy?.endDate).toLocaleDateString()})`);
      }

      errors.signerName = [];
      if (!this.$v.signerName.required) {
        errors.signerName.push('Поле не может быть пустым');
      }

      errors.signerPosition = [];
      if (!this.$v.signerPosition.required) {
        errors.signerPosition.push('Поле не может быть пустым');
      }

      errors.documentNumber = [];
      if (!this.$v.documentNumber.required) {
        errors.documentNumber.push('Поле не может быть пустым');
      }

      errors.creationDate = [];
      if (!this.$v.creationDate.required) {
        errors.creationDate.push('Поле не может быть пустым');
      }

      if (!this.$v.creationDate.valid) {
        if (this.letter?.policyId) {
          errors.creationDate.push(`Дата документа не должна превышать срок полиса (${(this.letter?.policyId
            ? new Date(this.letter?.policy?.endDate)
            : new Date())
            .toLocaleDateString()})`);
        } else {
          errors.creationDate.push(`Дата документа не должна превышать ${new Date().toLocaleDateString()}`);
        }
      }

      return errors;
    },
    isLetterSaving() {
      return this.letter.isLetterSaving;
    },
    isLetterSending() {
      return this.letter.isLetterSending;
    },
    letter() {
      return this.$store.state.Indemnity.letter;
    },
    isNewValidityType() {
      const { documentNumber } = this.letter;
      const year = documentNumber.substring(documentNumber.length - 2);

      return +year > 22;
    },
    isValidityDateValidationRequired() {
      return !this.letter.isReferral && this.isNewValidityType && this.letter.policy?.endDate;
    },
    validityString: {
      get() {
        return this.letter.validityString;
      },
      set(newValue) {
        this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, validityString: newValue });
      },
    },
    validityDate: {
      get() {
        return this.letter.validityDate ? parseISO(this.letter.validityDate) : null;
      },
      set(newValue) {
        this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, validityDate: formatISO(newValue) });
      },
    },
    signerName: {
      get() {
        return this.letter.signerName;
      },
      set(newValue) {
        const signer = this.SIGNERS.find((item) => item.name === newValue);

        this.signerPosition = signer?.position || null;
        this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, signerName: signer?.name || null });
      },
    },
    signerPosition: {
      get() {
        return this.letter.signerPosition;
      },
      set(newValue) {
        this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, signerPosition: newValue });
      },
    },
    documentNumber: {
      get() {
        return this.letter.documentNumber;
      },
      set(newValue) {
        this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, documentNumber: newValue });
      },
    },
    creationDate: {
      get() {
        return parseISO(this.letter.creationDate);
      },
      set(newValue) {
        this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, creationDate: formatISO(newValue) });
      },
    },
    ...mapGetters({
      userInfo: types.USER_INFO_GET,
    }),
  },
  async created() {
    this.$store.commit(this.$types.INDEMNITY_LETTER_SET, {
      ...this.letter,
      signerName: this.SIGNERS[0].name,
      signerPosition: this.SIGNERS[0].position,
    });

    if (this.letter.priceType === 2) {
      this.isLetterDublesLoading = true;

      await this.$store.dispatch(this.$types.INDEMNITY_LETTER_SAVE, this.letter);

      const letters = await this.$store.dispatch(this.$types.INDEMNITY_LETTER_CREATE_DUBLES, {
        originalLetterId: this.letter.id,
        clinicWithPrices: this.letter.selectedClinics.map((c) => ({
          clinicId: c.clinic.id,
          priceIds: c.clinicPrices.map((p) => p.id),
        })),
      });

      this.letterDubles = letters;
      this.isLetterDublesLoading = false;
    }
  },
  methods: {
    checkHasValidateErrors() {
      this.$v.$touch();

      if (this.$v.$error) {
        showValidationErrorMessage();
      }

      return this.$v.$error;
    },
    onClickCancel() {
      this.$emit('cancel');
    },
    onSetExecutorInfo() {
      const executor = `${this.userInfo.lastName} ${this.userInfo.firstName[0]}.`;

      this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, executorName: executor || null });
      this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, executorPosition: 'исполнитель' });
    },
    async onPrintLetter(letter) {
      const executorName = `${this.userInfo.lastName} ${this.userInfo.firstName[0]}.`;

      const docdef = await this.$store.dispatch(this.$types.INDEMNITY_LETTER_FORM, {
        letter: {
          ...letter,
          executorName,
          executorPosition: 'исполнитель',
        },
      });

      showPdf(docdef);
    },
    onClickPrint() {
      if (!this.checkHasValidateErrors()) {
        this.onSetExecutorInfo();

        this.$store.dispatch(this.$types.INDEMNITY_LETTER_PRINT, { ...this.letter });
      }
    },
    async onClickSend() {
      if (!this.checkHasValidateErrors()) {
        this.onSetExecutorInfo();

        try {
          if (this.validityDate) this.validityString = null;

          if (this.letter.priceType === 0) {
            await this.$store.dispatch(this.$types.INDEMNITY_LETTER_SEND, { letter: this.letter });
          }

          if (this.letter.priceType === 2) {
            await this.$store.dispatch(this.$types.INDEMNITY_LETTER_SAVE, this.letter);

            const letters = await this.$store.dispatch(this.$types.INDEMNITY_LETTER_CREATE_DUBLES, {
              originalLetterId: this.letter.id,
              clinicWithPrices: this.letter.selectedClinics.map((c) => ({
                clinicId: c.clinic.id,
                priceIds: c.clinicPrices.map((p) => p.id),
              })),
            });

            this.letterDubles = letters;

            const data = await Promise.all(letters.map(async (letter) => {
              const docdef = await this.$store.dispatch(this.$types.INDEMNITY_LETTER_FORM, { letter });
              showPdf(docdef);
              const base64pdf = await getBase64Pdf(docdef);

              return ({
                letter,
                base64pdf: `data:application/pdf;base64,${base64pdf}`,
              });
            }));

            await this.$store.dispatch(this.$types.INDEMNITY_LETTERS_SAVE, data);
          }

          if (this.letter.priceType === 3) {
            // if (true) {
            //   console.log(this.letter.tableRowsPrice);
            //   const tableRowsPrice = this.letter.tableRowsPrice.reduce((result, price) => {
            //     [...Array(price?.count || 1)].forEach(() => result.push(price));
            //     return result;
            //   }, []);

            //   console.log(tableRowsPrice);
            //   return;
            // }

            await this.$store.dispatch(this.$types.INDEMNITY_LETTER_SEND, {
              letter: {
                ...this.letter,
                tableRowsPrice: this.letter.tableRowsPrice.reduce((result, price) => {
                  [...Array(price?.count || 1)].forEach(() => result.push({ ...price, count: 1 }));
                  return result;
                }, []),
              },
            });
          }

          this.$emit('close');

          Bus.$emit('indemnity-history-modal:fetch-letters');
        } catch (error) {
          console.error(error);
        }
      }
    },
    clickPrev() {
      if (!this.checkHasValidateErrors()) {
        this.$emit('change-step', -1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.icon-edit {
  border-radius: 5px;

  &:hover {
    cursor: pointer;
  }

  &.active {
    background-color: $blue;

    path {
      fill: white;
    }
  }
}
.crm-form-block {
  width: 100%;
}
.crm-datepicker {
  width: 100%;
}
.prewatch-text {
  font-size: 16px;
  line-height: 20px;
  color: $blue;
  margin-right: 10px;
  cursor: pointer;
}
::v-deep.prewatch-icon {
  cursor: pointer;

  path {
    fill: $blue;
  }

  rect {
    stroke: $blue;
  }
}

::v-deep.letter-dubles-table {
  .action-td {
    width: 20px;
  }
}
</style>
