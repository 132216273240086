import { APP_HOST } from '@/app.config';

export default class AppealsTemplatesChatApi {
  constructor($axios) {
    this.api = $axios.instance;
  }

  // получение всех шаблонов
  async getAll() {
    const { data: { data } } = await this.api.get(`${APP_HOST}v3/chat/message-template`);
    return data;
  }

  // получение конкретного шаблона
  async getOne(id) {
    const { data: { data } } = await this.api.get(`${APP_HOST}v3/chat/message-template/${id}`);
    return data;
  }

  // поиск конкретного шаблона
  async getWithSearch(text, deleted) {
    const { data: { data } } = await this.api.get(`${APP_HOST}v3/chat/message-template`, { params: { ContainsText: text, IncludeDeleted: deleted } });
    return data;
  }

  // создание
  async create(template) {
    return this.api.post(`${APP_HOST}v3/chat/message-template`, template);
  }

  // редактирование
  async update(id, template) {
    return this.api.put(`${APP_HOST}v3/chat/message-template/${id}`, template);
  }

  // удаление
  async delete(id) {
    return this.api.delete(`${APP_HOST}v3/chat/message-template/${id}`);
  }
}
