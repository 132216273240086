import FileApi from '@/services/file/file.api';

export default class FileService {
  constructor($axios) {
    this.api = new FileApi($axios);
  }

  async send(files) {
    const isArray = Array.isArray(files);

    if (isArray) {
      const filenames = await Promise.all(files.map((file) => {
        const formData = this.prepareFile(file);
        return this.api.saveFile(formData);
      }));
      return filenames;
    }
    const file = files;
    const formData = this.prepareFile(file);
    const filename = await this.api.saveFile(formData);
    return filename;
  }

  // eslint-disable-next-line class-methods-use-this
  prepareFile(file) {
    const formData = new FormData();
    formData.append('file', file);
    return formData;
  }
}
