<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="xs"
    title="Отправить данный текст?"
    :hide-footer="isSaving"
    @hidden="onClose"
    @ok="onClose"
  >
    <template v-if="isSaving">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      {{ text }}
    </template>
    <template #modal-footer>
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        @click="onClickSave"
      >
        Да
      </b-button>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        @click="onClose"
      >
        Нет
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';

import Preloader from '@/components/Preloader';

export default {
  name: 'SmsSendingConfirmation',
  components: {
    Preloader,
  },
  mixins: [validationMixin],
  props: {
    name: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    onSend: {
      type: Function,
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isSaving: false,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onClickSave() {
      this.isSaving = true;

      try {
        await this.onSend();

        this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
      } catch (error) {
        console.error(error);
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
