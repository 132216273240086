<template>
  <div class="consultation-item">
    <div class="crm-row">
      <div class="crm-date ml-auto">
        {{ date }}
      </div>
    </div>
    <div class="specialization">
      Талон
    </div>

    <div class="doctor">
      <span class="queue-title">
        Название очереди:
      </span>

      <div class="queue-name">
        {{ consultation.queueName }}
      </div>
    </div>

    <div
      class="crm-status"
    >
      <span>
        Статус:
      </span>
      <span
        class="status-text"
        :class="statusColor"
      >
        {{ statusText }}
      </span>
    </div>
  </div>
</template>

<script>
import { parseISO, format } from '@evd3v/date-fns';

import { QUEUE_CONSULTATION_STATUSES } from '@/helpers/consts';

export default {
  name: 'PatientDetailQueueConsultationsItem',
  props: {
    consultation: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      consultationStatuses: QUEUE_CONSULTATION_STATUSES,
      statusColors: {
        0: 'green',
        1: 'red',
        2: 'red',
      },
    };
  },
  computed: {
    date() {
      return format(parseISO(this.consultation.createdAt), 'dd.MM.yyyy');
    },
    statusColor() {
      return this.statusColors[this.consultation.status] || '';
    },
    statusText() {
      return this.consultationStatuses[this.consultation.status];
    },
  },
};
</script>

<style lang="scss" scoped>
.consultation-item {
  cursor: pointer;
  padding: 20px 10px;
  margin: 0 -10px;
  width: 100%;
  border-bottom: 1px solid #E0E0E0;

  &.active {
    background: #6E88F3;
    color: #ffffff;

    .specialization {
      color: #ffffff;
    }
    .crm-status {
      color: #ffffff;
    }
    .queue-title {
      color: #ffffff!important;
    }
    .status-text {
      color: #ffffff!important;
    }
  }

  .crm-row {
    display: flex;
    justify-content: space-between;
  }

  .crm-id, .crm-date {
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
  }
  .specialization {
    font-size: 14px;
    line-height: 18px;
    margin-top: 10px;
    color: #4B62C1;
  }
  .doctor {
    margin-top: 5px;

    .queue-title {
      color: #908F8D;
    }
    .queue-name {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }
  }

  .crm-status {
    margin-top: 10px;
    font-size: 14px;
    line-height: 18px;
    color: #908F8D;
  }

  .status-text {
    font-weight: 500;
    &.green {
      color: $green;
    }
    &.yellow {
      color: #F2994A;
    }
    &.red {
      color: $red;
    }
  }
}
</style>
