import InsuranceApi from '@/services/insurance/insurance.api';

export default class InsuranceService {
  constructor($axios) {
    this.api = new InsuranceApi($axios);
  }

  deactivateSubProgram(params) {
    return this.api.deactivateSubProgram(params);
  }
}
