<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    :title="title"
    size="lg"
    hide-footer
    @hidden="onClose"
    @ok="onClose"
  >
    <b-skeleton-table
      v-if="isLoading"
      :rows="5"
      :columns="2"
      :table-props="{ bordered: true, striped: true }"
    />

    <div v-else>
      <div v-if="rows.length > 0">
        <b-table
          :fields="fields"
          :items="rows"
          striped
          bordered
        >
          <template #cell(date)="row">
            {{ formatDate(row.item.date) }}
          </template>
        </b-table>
      </div>

      <div
        v-else
        class="w-100 bg-white shadow-lg rounded p-4 font-weight-bold"
      >
        Нет данных
      </div>
    </div>
  </b-modal>
</template>

<script>
import { scheduleService } from '@/services';
import { format, parseISO, addHours } from '@evd3v/date-fns';

export default {
  name: 'ScheduleHistoryModal',
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'История изменений шаблона расписания',
    },
    templateId: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      rows: [],
      fields: [
        { key: 'date', label: 'Дата и время' },
        { key: 'action', label: 'Действие' },
      ],
    };
  },
  async created() {
    this.isLoading = true;
    try {
      this.rows = await scheduleService.getHistoryScheduleTemplate({ slotTemplateId: this.templateId });
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    formatDate(date) {
      const dateAdded = addHours(parseISO(date), 3);
      return format(dateAdded, 'dd.MM.yyyy HH:mm');
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
  },
};
</script>

<style scoped>

</style>
