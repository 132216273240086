import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.PARTNER_IS_LOADING_GET](state) {
    return state.isLoading;
  },
  [localTypes.PARTNER_SERVICES_LIST_GET](state) {
    return state.partnerServiceList;
  },
  [localTypes.ORDERS_LIST_GET](state) {
    return state.orderList.data;
  },
  [localTypes.POLICY_IS_LOADING_BUTTON_GET](state) {
    return state.isLoadingButton;
  },
  [localTypes.BIND_SERVICES_OFFSET_GET](state) {
    return state.servicesBindOffset;
  },
  [localTypes.LABORATORY_PROMOCODES_GET](state) {
    return state.promocodes;
  },
  [localTypes.LABORATORY_PROMOCODES_COUNT_GET](state) {
    return state.promocodesTotalCount;
  },
  [localTypes.PARTNERS_LABORATORY_SERVICES_TRIGGER](state) {
    return state.partnersServicesTrigger;
  },
  [localTypes.OUR_LABORATORY_SERVICES_TRIGGER](state) {
    return state.ourServicesTrigger;
  },
  [localTypes.LABORATORY_ATTACHED_FILES](state) {
    return state.attachedFiles.reduce((acc, { orderId, files }) => {
      acc[orderId] = files;

      return acc;
    }, {});
  },
};
