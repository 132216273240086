import AppealsApi from '@/services/appeals/appeals.api';

export default class AppealsService {
  constructor($axios) {
    this.api = new AppealsApi($axios);
  }

  // * v3 api
  search(params) {
    return this.api.search(params);
  }

  getById(appealId) {
    return this.api.getById(appealId);
  }

  getTypes(params) {
    return this.api.getTypes(params);
  }

  create(data) {
    return this.api.create(data);
  }

  export(params) {
    return this.api.export(params);
  }

  update(data) {
    return this.api.update(data);
  }

  // ? in future rewrite
  getHistory(appealId) {
    return this.api.getHistory(appealId);
  }

  // ! in future rewrite on v3 api
  // ! it use in component (AppealEditorModal)
  // ! create new appeal in doctors
  createOld(appeal) {
    return this.api.createOld(appeal);
  }

  // ! update new appeal in doctors
  updateOld(appeal) {
    return this.api.updateOld(appeal);
  }
}
