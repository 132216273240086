<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    title="Добавление аватарки врача"
    no-close-on-backdrop
    :hide-footer="true"
    @hidden="onCancel"
    @ok="onCancel"
  >
    <template v-if="isLoading">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      <base-photo-edit
        :photo-url-prop="doctorImageUrl"
        :image-height="180"
        :image-width="180"
        :edit-prop="true"
        :custom-save="true"
        rounded
        @cancel="onCancel"
        @save="onSave"
      />

      <!-- <div
        class="avatar-wrapper"
      >
        <img
          v-if="doctorImageUrl"
          :src="doctorImageUrl"
          class="avatar-img"
        >

        <b-form-file
          v-model="file"
          accept="image/jpeg, image/png, image/gif"
          placeholder="Выберите аватарку врача"
          drop-placeholder="Перетащите изображение сюда..."
          browse-text="Выбрать"
          class="input"
        />
      </div> -->
    </template>

    <!-- <template #modal-footer>
      <b-button
        variant="success"
        class="float-right"
        :disabled="!file"
        @click="onSave"
      >
        Загрузить
      </b-button>
      <b-button
        variant="primary"
        class="float-right"
        @click="onCancel"
      >
        Отменить
      </b-button>
    </template> -->
  </b-modal>
</template>

<script>
import { BasePhotoEdit } from '@/components/base';
import Preloader from '@/components/Preloader';

import { APP_DOCTIS_FILE_SERVER_URL } from '@/app.config';

export default {
  name: 'DoctorAvatarDeleteModal',
  components: {
    Preloader,
    BasePhotoEdit,
  },
  props: {
    doctor: {
      type: Object,
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
    afterSave: {
      type: [Function],
      default: () => {},
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      file: null,
    };
  },
  computed: {
    // imageUrl() {
    //   return URL.createObjectURL(this.file);
    // },
    doctorImageUrl() {
      return this.doctor.avatarCloudKey
        ? `${APP_DOCTIS_FILE_SERVER_URL}/${this.doctor.avatarCloudKey}`
        : '';
    },
  },
  methods: {
    onCancel() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
      setTimeout(() => {
        this.afterSave();
      }, 500);
    },
    // onFileInput(e) {
    //   const file = e.target.files[0];
    //   this.file = file;

    //   if (!file) {
    //     this.imageUrl = null;
    //     return;
    //   }

    //   // const reader = new FileReader();
    //   this.imageUrl = URL.createObjectURL(file);
    // },
    async onSave(file) {
      try {
        this.isLoading = true;
        await this.$store.dispatch(this.$types.DOCTOR_AVATAR_UPLOAD, { doctorId: this.doctor.id, file });
        this.onClose();
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.avatar-img {
  max-width: 300px;
  max-width: 300px;
  margin-bottom: 20px;
}

.input {
  cursor: pointer;
}
</style>
