<template>
  <div class="clinic-price-inner">
    <div class="d-flex">
      <b-form-input
        v-model="data.name"
        placeholder="Наименование"
        class="mr-2"
        @keyup.native.enter="clearAndFetch"
      />

      <b-form-input
        v-model="data.code"
        placeholder="Код"
        style="width: 230px; flex-shrink: 0"
        class="mr-2"
        @keyup.native.enter="clearAndFetch"
      />

      <v-select
        v-model="data.type"
        :reduce="(type) => type.id"
        :options="types"
        placeholder="Тип прайса"
        label="title"
        class="price-select bg-white rounded mr-2"
        style="width: 200px; flex-shrink: 0"
        @input="clearAndFetch"
      />

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        size="sm"
        :disabled="isLoading"
        @click="clearAndFetch"
      >
        <b-icon
          icon="search"
        />
      </b-button>
    </div>

    <transition name="component-fade">
      <div class="mt-4">
        <div
          v-if="isLoading"
          class="text-center"
        >
          <b-spinner
            variant="primary"
            style="width: 3rem; height: 3rem;"
          />
        </div>
        <b-table
          v-else
          :bordered="true"
          :hover="true"
          :fields="fields"
          class="price-table"
          small
          show-empty
          fixed
          :items="rows"
        >
          <template #empty="">
            <div class="d-flex justify-content-center align-items-center">
              <h6 class="p-2">
                Не найдено
                <b-icon
                  icon="exclamation-triangle-fill"
                  variant="danger"
                />
              </h6>
            </div>
          </template>

          <template v-slot:cell(type)="row">
            {{ row.item.type != null ? types[row.item.type].title : '' }}
          </template>

          <template v-slot:cell(fileId)="row">
            <b-button
              v-if="row.item.costHistory"
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              @click="openPriceHistoryModal(row.item.costHistory)"
            >
              <b-icon
                icon="clock-history"
              />
            </b-button>
          </template>
        </b-table>

        <b-pagination
          v-if="rows.length && !isLoading"
          v-model="currentPage"
          :total-rows="originalCount"
          :disabled="isLoading"
          :per-page="limit"
          size="sm"
          class="m-0"
          @input="fetchPricesList"
        />
      </div>

      <!-- <b-form-group
          label="Логи"
          label-class="font-weight-bold pt-0"
        >
          <b-button
            variant="primary"
            @click="openDownloadHistoryModal"
          >
            <b-icon
              icon="clock-history"
              class="mr-1"
            />
            История загрузки
          </b-button>
          <b-button
            class="ml-2"
            variant="info"
            @click="openPricesListModal"
          >
            <b-icon
              icon="view-list"
              class="mr-1"
            />
            История прайсов
          </b-button>
        </b-form-group> -->
    </transition>
  </div>
</template>

<script>
import { saveFile } from '@/helpers/utils';
import { api } from '@/helpers/api';
import { clinicService } from '@/services';
import { showErrorCustomMessage } from '@/helpers/messages';

export default {
  name: 'ClinicPrice',
  components: {},
  props: {},
  data() {
    return {
      isLoading: false,
      data: {
        name: null,
        code: null,
        type: null,
      },
      currentPage: 1,
      limit: 25,
      originalCount: 0,
      rows: [],
      fields: [
        {
          key: 'code',
          label: 'Код',
        },
        {
          key: 'name',
          label: 'Наименование',
        },
        {
          key: 'cost',
          label: 'Цена',
        },
        {
          key: 'cost',
          label: 'Цена',
        },
        {
          key: 'agentsCommission',
          label: 'Агентское вознаграждение',
        },
        {
          key: 'type',
          label: 'Тип',
        },
        {
          key: 'fileId',
          label: '',
          thClass: 'actionsTd',
          tdClass: 'actionsTd',
        },
      ],
      types: [
        {
          id: 0,
          title: 'ДМС',
        },
        {
          id: 1,
          title: 'Индивидуальный',
        },
        {
          id: 2,
          title: 'Продукт в коробке',
        },
        {
          id: 3,
          title: 'Телемед',
        },
      ],
    };
  },
  computed: {
    clinicInfo() {
      return this.$store.state.Clinic.clinic.info;
    },
  },
  async created() {
    await this.fetchPricesList();
  },
  methods: {
    async clearAndFetch() {
      if (this.isLoading) return;
      this.currentPage = 1;

      await this.fetchPricesList();
    },
    async fetchPricesList() {
      if (this.isLoading) return;
      this.isLoading = true;

      try {
        const { paginatedCollection, originalCount } = await clinicService.getPricesListForOne({
          clinicId: this.clinicInfo.id,
          ...this.data,
          skip: (this.currentPage - 1) * this.limit,
          take: this.limit ? this.limit : 10,
        });

        if (paginatedCollection.length) {
          this.rows = paginatedCollection.filter((el) => el.isActive);
        } else {
          this.rows = paginatedCollection;
        }

        this.originalCount = originalCount;
        this.rows.reverse();
      } catch (err) {
        showErrorCustomMessage('Не удалось загрузить список прайсов.');
        console.warn(err);
        throw err;
      } finally {
        this.isLoading = false;
      }
    },
    openPriceHistoryModal(data) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'PriceHistoryModal',
        props: {
          costHistory: data,
        },
      });
    },
    openPricesListModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'PricesListViewModal',
        props: {
          clinicId: this.clinicInfo.id,
        },
      });
    },
    async downloadTable(id) {
      const { data: fileName } = await api.get(`File/name/${id}`);
      const { data: file } = await api.get(`File/${id}`, { responseType: 'blob' });
      saveFile(file, { name: fileName });
    },
  },
};
</script>

<style lang="scss" scoped>
.clinic-price-inner {
  padding: 0 10px;
}

::v-deep.price-select {
  .vs__dropdown-toggle {
    height: 100%;
  }

  .vs__dropdown-menu {
    max-height: 350px;
  }

  .vs__dropdown-option {
    word-break: break-all;
    text-overflow: initial;
    overflow: initial;
    white-space: initial;
  }
}

::v-deep.price-table {
  td {
    width: 100%;
  }
  .actionsTd {
    width: 45px;
  }
}
</style>
