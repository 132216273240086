import { APP_HOST } from '@/app.config';

export default class ConsultationApi {
  constructor($axios) {
    this.api = $axios.instance;
  }

  async getProtocol(id) {
    const { data } = await this.api.get(`${APP_HOST}v2/consultations/protocol/get-file`, { params: { ConsultationId: id }, responseType: 'blob' });
    return data;
  }

  async searchProtocols(params) {
    const { data } = await this.api.get(`${APP_HOST}v2/protocols`, { params });
    return data;
  }
}
