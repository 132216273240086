<template>
  <indemnity-arrow-icon
    class="crm-button"
    @click="$emit('click')"
  />
</template>

<script>
import IndemnityArrowIcon from 'assets/images/indemnity-arrow-icon.svg';

export default {
  name: 'IndemnityArowLeftButton',
  components: {
    IndemnityArrowIcon,
  },
};
</script>

<style lang="scss" scoped>
.crm-button {
  width: 30px;
  height: 30px;
  margin-right: 15px;

  &:hover {
    cursor: pointer;
  }
}
</style>
