<template>
  <div
    :class="{ ['crm-fluid']: fluid }"
    class="crm-wrap-block"
  >
    <span
      v-if="label"
      :class="{ required }"
      class="crm-label"
    >
      {{ label }}
    </span>

    <v-select
      :value="value"
      :reduce="reduce"
      :options="paginated"
      :filterable="false"
      :class="{ error }"
      :disabled="disabled"
      :label="optionTitle"
      :clearable="clearable"
      :placeholder="placeholder"
      :calculate-position="placement"
      @close="onClose"
      @search="onSearchDebounced"
      @open="onOpen"
      @input="$emit('change', $event)"
    >
      <!-- :loading="!isSearch && isLoading" -->
      <template
        v-if="isSearch"
        #open-indicator
      >
        <icon-search />
      </template>

      <template
        #list-footer
      >
        <div
          v-show="hasNextPage"
          ref="load"
          class="crm-loader"
        >
          {{ loadingTitle }}
        </div>
      </template>

      <template
        #selected-option="{ ...item }"
      >
        <slot
          name="selected-option"
          :item="{ ...item }"
        />
      </template>

      <template
        #option="{ ...item }"
      >
        <slot
          name="option"
          :item="{ ...item }"
        />
      </template>

      <template #no-options>
        <div class="select-no-options">
          {{ isLoading ? '' : noOptionsTitle }}
        </div>
      </template>
    </v-select>

    <template v-if="error && errors.length">
      <div
        class="validation-errors"
      >
        <span
          class="validation-error-text"
        >
          {{ errors[0] }}
        </span>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import debounce from 'lodash/debounce';
import IconSearch from 'assets/images/search.svg';

// const LIMIT = 10;
const FETCH_DELAY = 700;

export default {
  name: 'BaseAsyncSelect',
  components: {
    IconSearch,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [Object, Number, String],
      default: () => ({}),
    },
    reduce: {
      type: Function,
      default: ((value) => value),
    },
    error: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    fetchFunction: {
      type: Function,
      default: () => ({}),
    },
    noOptionsTitle: {
      type: String,
      default: 'Ничего не найдено',
    },
    loadingTitle: {
      type: String,
      default: 'Загрузка',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    placement: {
      type: Function,
      default: undefined,
    },
    required: {
      type: Boolean,
      default: false,
    },
    serverPaginated: {
      type: Boolean,
      default: false,
    },
    serverPaginatedStartValue: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fluid: {
      type: Boolean,
      default: false,
    },
    optionTitle: {
      type: String,
      default: 'name',
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      observer: null,
      query: '',
      options: [],
      isAllItemsLoaded: false,
      isLoading: false,
    };
  },
  computed: {
    paginated() {
      if (this.serverPaginated) {
        return this.options;
      }
      return this.options.slice(0, this.limit);
    },
    hasNextPage() {
      if (this.serverPaginated) {
        return !this.isAllItemsLoaded;
      }
      return this.paginated.length < this.options.length;
    },
    totalCountShowingOptions() {
      return this.paginated.length;
    },
  },
  created() {
    this.onSearchDebounced = debounce(this.onSearch, FETCH_DELAY);

    if (this.serverPaginated && this.serverPaginatedStartValue) {
      this.options.push(this.serverPaginatedStartValue);
    }
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll);
  },
  methods: {
    async onSearch(query, loading) {
      if (query === '' && !this.serverPaginated) return;
      loading(true);
      this.isLoading = true;

      try {
        if (this.serverPaginated) {
          await this.serverPaginatedFetch({ query, clear: true });
        } else {
          this.limit = 10;
          this.options = await this.fetchFunction(query);
        }

        if (this.hasNextPage) {
          Vue.nextTick(async () => {
            await this.$nextTick();
            if (this.$refs.load) {
              this.observer.observe(this.$refs.load);
            }
          });
        }
      } catch (e) {
        this.options = [];
        console.warn(e);
      } finally {
        loading(false);
        this.isLoading = false;
      }
    },
    async onOpen() {
      await this.$nextTick();
      this.observer.observe(this.$refs.load);
    },
    onClose() {
      this.observer.disconnect();
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const { scrollTop } = target.offsetParent;

        if (this.serverPaginated) {
          try {
            await this.serverPaginatedLoadMore();
          } catch (e) {
            console.warn(e);
          }
        } else {
          this.limit += 10;
          await this.$nextTick();
        }

        if (this.hasNextPage) {
          Vue.nextTick(async () => {
            await this.$nextTick();
            if (this.$refs.load) {
              this.observer.observe(this.$refs.load);
            }
          });
        }

        ul.scrollTop = scrollTop;
      }
    },

    forceServerPaginatedFetch() {
      this.serverPaginatedFetch({ query: this.query, clear: true });
    },

    async serverPaginatedFetch({ query, clear }) {
      this.query = query;
      this.isAllItemsLoaded = false;
      this.isLoading = true;

      const prevTotal = clear ? 0 : this.totalCountShowingOptions;

      if (clear) {
        this.options = [];
      }

      try {
        const data = await this.fetchFunction({
          query,
          skip: prevTotal,
          take: this.limit,
        });
        this.options = [...this.options, ...data];

        if (prevTotal + this.limit > this.totalCountShowingOptions) {
          this.isAllItemsLoaded = true;
        }
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;
      }
    },
    serverPaginatedLoadMore() {
      if (!this.isLoading) {
        return this.serverPaginatedFetch({ query: this.query, clear: false });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-wrap-block {
  display: flex;
  flex-direction: column;
  position: relative;

  &.crm-fluid {
    width: 100%;
  }
}
.crm-loader {
  text-align: center;
}
</style>
