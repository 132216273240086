import modals from './modals';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    modals,
    openedModals: [],
  },
  mutations,
};
