<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="xl"
    :title="`${id ? 'Редактирование' : 'Добавление'} клиники`"
    hide-footer
    no-close-on-backdrop
    @hidden="onClose"
  >
    <base-tabs
      v-model="activeTab"
      :items="filteredTabs"
      :disabled="tabsDisableCond"
      class="crm-tabs"
    />
    <!-- <div class="clinics-tab-content"> -->
    <!-- <custom-scrollbar ref="scrollbar"> -->
    <component
      :is="activeTab"
      :id="id"
      @create-clinic="onCreateClinic"
    />
    <!-- </custom-scrollbar> -->
    <!-- </div> -->
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { mixinRoles } from '@/mixins';

/* eslint-disable vue/no-unused-components */
import { BaseTabs } from '@/components/base';
import ClinicEdit from './ClinicEdit';
import ClinicDoctorsEdit from './ClinicDoctorsEdit';
import ClinicPhotoEdit from './ClinicPhotoEdit';

export default {
  name: 'ClinicEditModal',
  components: {
    BaseTabs,
    ClinicEdit,
    ClinicDoctorsEdit,
    ClinicPhotoEdit,
  },
  mixins: [mixinRoles],
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    clinicId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      id: this.clinicId,
      // id: 'fsdfsdfsdf43543',
      tabs: [
        {
          title: 'Данные клиники',
          value: 'ClinicEdit',
        },
        {
          title: 'Врачи клиники',
          value: 'ClinicDoctorsEdit',
        },
        {
          title: 'Фото клиники',
          value: 'ClinicPhotoEdit',
        },
      ],
      activeTab: 'ClinicEdit',
      // activeTab: 'ClinicDoctorsEdit',
    };
  },
  computed: {
    ...mapGetters({
      isChangedDoctorsInfo: 'Clinic/GET_IS_CHANGED_DOCTORS_INFO',
      isChangedInfo: 'Clinic/GET_IS_CHANGED_INFO',
    }),
    tabsDisableCond() {
      if (this.activeTab === 'ClinicEdit' && !this.id) return true;
      return false;
    },
    filteredTabs() {
      return this.tabs;
    },
  },
  watch: {
    activeTab() {
      this.scrollTo({ y: 0 }, 300);
    },
  },
  methods: {
    onCreateClinic(id) {
      this.id = id;
      this.activeTab = 'ClinicDoctorsEdit';
    },
    scrollTo(position, duration = 0) {
      const { scrollbar } = this.$refs;

      if (scrollbar) scrollbar.scrollTo(position, duration);
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.crm-tabs {
  @extend .clinics-tabs;
}
:deep(.modal-dialog) {
      max-width: 1240px;
    }
</style>
