<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    :title="title"
    size="lg"
    @hidden="onClose"
    @ok="onClose"
  >
    <div
      style="min-height: 300px;"
      class="pb-5"
    >
      <div class="mb-2">
        <div class="small font-weight-bold mb-1">
          Инициатор
        </div>
        <b-select
          v-model="$v.selectedInitiators.$model"
          value-field="id"
          text-field="name"
          :options="initiators"
        />
        <div
          v-if="$v.selectedInitiators.$error"
          class="mt-2 text-danger small"
        >
          {{ errorsValidation.selectedInitiators[0] }}
        </div>
      </div>
      <div class="mb-2">
        <div class="small font-weight-bold mb-1">
          Причина отмены консультации
        </div>
        <v-select
          v-model="$v.selectedTransferReason.$model"
          label="reason"
          :options="reasons"
          :clearable="true"
          class="crm-select"
          placeholder="Выберите причину"
          :disabled="selectedInitiators===null"
          loading-title="Загрузка"
        />
        <div
          v-if="$v.selectedTransferReason.$error"
          class="mt-2 text-danger small"
        >
          {{ errorsValidation.selectedTransferReason[0] }}
        </div>
      </div>
    </div>
    <template #modal-footer>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isCanceling"
        @click="onClose"
      >
        Отменить
      </b-button>
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isCanceling"
        class="ml-2"
        @click="onClickCancel"
      >
        Удалить
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import Bus from '@/eventBus';
import { showValidationErrorMessage } from '@/helpers/messages';

export default {
  name: 'ConsultationDeletingModal',
  mixins: [validationMixin],
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Вы уверены, что хотите удалить консультацию?',
    },
    consultation: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isOpen: true,
      isCanceling: false,
      selectedTransferReason: null,
      selectedInitiators: null,
      reasons: [],
      initiators: [
        {
          id: 0,
          name: 'Врач',
        },
        {
          id: 1,
          name: 'Пациент',
        },
        {
          id: 2,
          name: 'Техническая ошибка',
        },
      ],
    };
  },
  validations() {
    return {
      selectedInitiators: {
        required,
      },
      selectedTransferReason: {
        required,
      },
    };
  },
  computed: {
    errorsValidation() {
      const errors = {};

      errors.selectedTransferReason = [];
      if (!this.$v.selectedTransferReason.required) {
        errors.selectedTransferReason.push('Поле не может быть пустым');
      }

      errors.selectedInitiators = [];
      if (!this.$v.selectedInitiators.required) {
        errors.selectedInitiators.push('Поле не может быть пустым');
      }
      return errors;
    },
  },
  watch: {
    async selectedInitiators() {
      await this.fetchTransferReasons();
      this.selectedTransferReason = '';
    },
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async fetchTransferReasons() {
      this.reasons = await this.$store.dispatch(this.$types.TRANSFER_REASONS_FETCH, this.selectedInitiators);
    },
    async onClickCancel() {
      this.$v.$touch();

      if (this.$v.$error) {
        showValidationErrorMessage();
        return;
      }

      try {
        this.isCanceling = true;
        await this.$store.dispatch(this.$types.CONSULTATIONS_CANCEL, {
          consultationId: this.consultation.id,
          cancelReason: this.selectedTransferReason.id,
        });

        Bus.$emit('consultations:update', this.consultation.id);
        this.$store.commit(this.$types.PATIENT_DETAIL_CONSULATIONS_RESET);
        this.onClose();
      } finally {
        this.isCanceling = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
