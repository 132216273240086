<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    :title="title"
    size="lg"
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <template #modal-header>
      <div class="d-flex justify-content-between align-items-start w-100">
        <div class="h5 font-weight-bold">
          {{ title }}
          <span class="small h6m ml-2">
            {{ formattedDate }}
          </span>
        </div>

        <b-button
          size="sm"
          title="Закрыть"
          variant="outline-danger"
          :type="$const.PRIMARY_BUTTON"
          class="p-0"
          @click="onClose"
        >
          <b-icon icon="x" />
        </b-button>
      </div>
    </template>

    <div>
      <div class="mb-3">
        <div class="font-weight-bold mb-2">
          Учетная запись
        </div>

        <b-form-radio-group
          v-model="accountType"
          :options="filteredAccountTypes"
          value-field="id"
          text-field="title"
          class="mb-2"
        />
        <template v-if="accountType === 0">
          <div
            v-if="!patientIdFromChat"
            class="d-flex align-items-center"
          >
            <b-form-input
              v-if="search.field === 'phone'"
              v-model="search.filter.PhoneNumber"
              type="search"
              size="sm"
              :disabled="search.isLoading"
              placeholder="Введите номер телефона"
              class="border"
              @keyup.native.enter="onClickSearch"
            />
            <b-form-input
              v-if="search.field === 'fio'"
              v-model="search.filter.SearchFIO"
              type="search"
              size="sm"
              :disabled="search.isLoading"
              placeholder="Введите ФИО"
              class="border"
              @keyup.native.enter="onClickSearch"
            />
            <b-form-input
              v-if="search.field === 'policy'"
              v-model="search.filter.PolicyNumber"
              type="search"
              size="sm"
              :disabled="search.isLoading"
              placeholder="Введите номер полиса"
              class="border"
              @keyup.native.enter="onClickSearch"
            />
            <b-button
              id="popover-filter"
              v-b-tooltip.hover
              variant="light"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              :disabled="search.isLoading"
              class="border border-primary p-1 ml-2"
              title="Дополнительные фильтры"
            >
              <b-icon icon="filter" />
            </b-button>
            <b-button
              v-b-tooltip.hover
              variant="light"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="border border-success p-1 ml-2"
              title="Поиск"
              :disabled="search.isLoading"
              @click="onClickSearch"
            >
              <b-spinner
                v-if="search.isLoading"
                variant="primary"
                small
              />
              <b-icon
                v-else
                icon="search"
              />
            </b-button>
          </div>

          <div
            v-if="search.error"
            class="text-danger mb-2 font-weight-bold small"
          >
            {{ search.error }}
          </div>

          <div
            v-if="$v.checkup.patientId.$error"
            class="text-danger mt-2 small"
          >
            {{ errorsValidation.patientId[0] }}
          </div>

          <div
            v-if="search.patients && search.patients.length"
            class="mt-3"
          >
            <div class="patients d-flex">
              <PatientInfo
                v-for="patient in search.patients"
                :key="patient.id"
                :patient="patient"
                :active="patient.id === checkup.patientId"
                class="mr-2"
                @click.native="setPatient(patient)"
              />
            </div>
          </div>
        </template>

        <div
          v-if="accountType === 1"
          class="border p-2 my-2"
        >
          <div class="mb-3 d-flex">
            <div class="w-50">
              <div class="font-weight-bold mb-2">
                Фамилия
              </div>

              <b-form-input
                v-model="$v.checkup.lastName.$model"
                :state="checkValidations ? !$v.checkup.lastName.$error : null"
                size="sm"
              />

              <div
                v-if="$v.checkup.lastName.$error"
                class="text-danger mt-2 small"
              >
                {{ errorsValidation.lastName[0] }}
              </div>
            </div>
            <div class="w-50 ml-2">
              <div class="font-weight-bold mb-2">
                Имя
              </div>

              <b-form-input
                v-model="$v.checkup.firstName.$model"
                :state="checkValidations ? !$v.checkup.firstName.$error : null"
                size="sm"
              />

              <div
                v-if="$v.checkup.firstName.$error"
                class="text-danger mt-2 small"
              >
                {{ errorsValidation.firstName[0] }}
              </div>
            </div>
          </div>
          <div class="mb-3">
            <div class="font-weight-bold mb-2">
              Отчество
            </div>

            <b-form-input
              v-model="checkup.middleName"
              size="sm"
            />
          </div>
          <div class="mb-3 d-flex justify-content-between">
            <div>
              <div class="font-weight-bold mb-2">
                Дата рождения
              </div>

              <base-date-picker
                v-model="$v.checkup.birthDate.$model"
                :error="$v.checkup.birthDate.$error"
                :clearable="false"
              />

              <div
                v-if="$v.checkup.birthDate.$error"
                class="text-danger mt-2 small"
              >
                {{ errorsValidation.birthDate[0] }}
              </div>
            </div>
            <div>
              <div class="font-weight-bold mb-2">
                Пол
              </div>

              <b-form-radio-group
                v-model="$v.checkup.sex.$model"
                :options="GENDERS"
                :state="checkValidations ? !$v.checkup.sex.$error : null"
                value-field="value"
                text-field="title"
                class="mb-2"
              />

              <div
                v-if="$v.checkup.sex.$error"
                class="text-danger mt-2 small"
              >
                {{ errorsValidation.sex[0] }}
              </div>
            </div>
          </div>
          <div class="mb-3">
            <div class="font-weight-bold mb-2">
              Телефон
            </div>
            <b-form-input
              v-model="$v.checkup.phoneNumber.$model"
              v-mask="'+7 (###) ### ##-##'"
              :state="checkValidations ? !$v.checkup.phoneNumber.$error : null"
              size="sm"
            />

            <div
              v-if="$v.checkup.phoneNumber.$error"
              class="text-danger mt-2 small"
            >
              {{ errorsValidation.phoneNumber[0] }}
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="checkFeature(
          FEATURES_FOR_DOCTORS.btnProtocolInScheduleForConsultation,
          FEATURES_FOR_DOCTORS.btnProtocolInScheduleForConsultation
        )"
        class="mb-3"
      >
        <div v-if="protocolItems.length">
          <transition name="slide-fade">
            <!-- open -->
            <div
              v-if="!isVisibleProtocols && personId"
              style="cursor: pointer; height: 35px"
              class="bg-primary rounded-bottom d-flex justify-content-center p-1"
              @click="onClickOpenProtocol"
            >
              <span class="text-white">Протоколы</span>
            </div>
          </transition>

          <div
            v-if="isVisibleProtocols"
            class="bg-white w-100"
          >
            <transition-group
              name="list"
              tag="p"
            >
              <div
                v-for="item in protocolFiles"
                :key="item.consultationId"
                class="d-flex align-items-center"
              >
                <div class="my-2">
                  {{ formatDate(item.closeDate) }} / {{ item.doctor.fullName }} / {{ item.specialization }}
                </div>

                <b-button
                  v-b-tooltip.hover.right
                  variant="outline-success"
                  :type="$const.PRIMARY_BUTTON"
                  size="sm"
                  class="ml-3"
                  title="Протокол"
                  @click="getProtocol(item.consultationId)"
                >
                  <b-icon
                    icon="file-earmark-pdf"
                  />
                </b-button>

                <b-button
                  size="sm"
                  variant="outline-danger"
                  :type="$const.PRIMARY_BUTTON"
                  class="ml-3"
                  @click="deleteProtocol(item)"
                >
                  <b-icon icon="trash" />
                </b-button>
              </div>
            </transition-group>

            <transition
              name="fade"
              mode="out-in"
            >
              <div
                v-if="rowsInProtocolItems"
                :key="currentPage"
              >
                <b-table
                  key="table"
                  class="my-3"
                  bordered
                  size="sm"
                  :fields="fields"
                  :items="protocolItems"
                  :per-page="perPage"
                  :current-page="currentPage"
                >
                  <template #cell(nameDoctor)="row">
                    {{ row.item.doctor.fullName }}
                  </template>

                  <template #cell(specialtyDoctor)="row">
                    {{ row.item.specialization }}
                  </template>

                  <template #cell(dateClosedConsultation)="row">
                    {{ formatDate(row.item.closeDate) }}
                  </template>

                  <template #cell(addProtocolBtn)="row">
                    <b-button
                      variant="primary"
                      :type="$const.PRIMARY_BUTTON"
                      size="sm"
                      @click="setProtocol(row.item)"
                    >
                      Добавить
                    </b-button>
                  </template>
                </b-table>
              </div>
            </transition>

            <b-pagination
              v-if="rowsInProtocolItems"
              v-model="currentPage"
              class="mt-4 mb-5"
              :total-rows="rowsInProtocolItems"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </div>

          <transition name="slide-fade">
            <!-- closed -->
            <div
              v-if="isVisibleProtocols"
              style="cursor: pointer; height: 35px"
              class="bg-primary rounded-bottom d-flex justify-content-center p-1"
              @click="() => isVisibleProtocols = !isVisibleProtocols"
            >
              <span class="text-white">Закрыть</span>
            </div>
          </transition>
        </div>
        <div
          v-else-if="isVisibleProtocols && !protocolItems.length"
          class="my-4"
        >
          <span><strong>Протоколы не найдены</strong></span>
        </div>
      </div>

      <div class="mb-3">
        <div class="font-weight-bold mb-2">
          Способ связи
        </div>

        <b-form-radio-group
          v-model="$v.checkup.communicationMethod.$model"
          :state="checkValidations ? !$v.checkup.communicationMethod.$error : null"
          :options="COMMUNICATION_METHODS"
          value-field="id"
          text-field="title"
        />
      </div>

      <div class="mb-3">
        <div class="font-weight-bold mb-2">
          Тип
        </div>

        <b-form-radio-group
          v-model="$v.checkup.availabilityOfPayment.$model"
          :state="checkValidations ? !$v.checkup.availabilityOfPayment.$error : null"
          :options="PAYMENT_VARIANTS"
          value-field="value"
          text-field="title"
        />
      </div>

      <div class="mb-3">
        <div class="font-weight-bold mb-2">
          Файлы
        </div>

        <div class="mb-2">
          <div
            v-for="document in documents"
            :key="document.guid"
            class="d-flex mb-1"
          >
            <div class="overflow-hidden">
              {{ document.title }}
            </div>
            <b-button
              variant="light"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="border ml-2 p-1"
              @click="onClickDeleteFile(document)"
            >
              <b-icon icon="trash" />
            </b-button>
          </div>
        </div>

        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          size="sm"
          @click="onClickOpenFileModal"
        >
          Добавить файлы
        </b-button>
      </div>

      <div class="mb-3">
        <div class="font-weight-bold mb-2">
          Текст обращения
          <div class="small">
            (опционально)
          </div>
        </div>

        <b-form-textarea
          v-model="checkup.body"
          rows="3"
          max-rows="6"
        />
      </div>
    </div>

    <b-popover
      :show.sync="isPopoverOpen"
      target="popover-filter"
      triggers="click"
    >
      <div class="d-flex justify-content-end mb-2">
        <b-button
          variant="light"
          :type="$const.PRIMARY_BUTTON"
          size="sm"
          class="border border-danger p-0 ml-2"
          @click="isPopoverOpen = false"
        >
          <b-icon icon="x" />
        </b-button>
      </div>
      <div class="popover-inner p-2">
        <div>
          <div class="mb-3">
            <div class="mb-2 font-weight-bold">
              Поиск по
            </div>

            <b-form-radio-group
              v-model="search.field"
              :options="SEARCH_FIELDS"
              value-field="alias"
              text-field="title"
              class="mb-2"
              @change="changeSearchField"
            />
          </div>
          <div class="mb-3">
            <div class="mb-2 font-weight-bold">
              Тип полиса
            </div>

            <b-form-radio-group
              v-model="search.filter.PatientType"
              :options="POLICY_TYPES"
              value-field="id"
              text-field="title"
              class="mb-2"
              @change="changeSearchField"
            />
          </div>
        </div>
      </div>
    </b-popover>

    <template #modal-footer>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="onClose"
      >
        Отменить
      </b-button>

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="onClickSave"
      >
        Сохранить
        <b-spinner
          v-if="isSaving"
          variant="light"
          small
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { format, formatISO } from '@evd3v/date-fns';
import { mixinRoles } from '@/mixins';

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { phoneMixins } from '@/mixins/phoneMixins';

import {
  saveFile,
  dateWithoutTime,
  utc0ToDateWithUserTimezone,
} from '@/helpers/utils';

import {
  showErrorCustomMessage,
  showValidationErrorMessage,
} from '@/helpers/messages';

import {
  GENDERS,
  POLICY_TYPES,
  SEARCH_FIELDS,
  ACCOUNT_TYPES,
  PAYMENT_VARIANTS,
  FEATURES_FOR_CHAT,
  FEATURES_FOR_DOCTORS,
  FEATURES_FOR_SCHEDULE,
  COMMUNICATION_METHODS,
} from '@/helpers/consts';

import { BaseDatePicker } from '@/components/base';

import PatientInfo from '@/components/Schedule/Modals/ScheduleMakeAnAppointmentModal/PatientInfo';
import { personService, consultationService } from '@/services';

import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

export default {
  name: 'ScheduleMakeAnAppointmentModal',
  components: {
    PatientInfo,
    BaseDatePicker,
  },
  mixins: [validationMixin, phoneMixins, mixinRoles],

  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Записать на прием',
    },
    time: {
      type: Number,
      default: 0,
    },
    doctorId: {
      type: Number,
      default: 0,
    },
    parentModalName: {
      type: [String, Number],
      default: null,
    },
    patientIdFromChat: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isPopoverOpen: false,
      checkValidations: false,
      ACCOUNT_TYPES,
      SEARCH_FIELDS,
      POLICY_TYPES,
      COMMUNICATION_METHODS,
      PAYMENT_VARIANTS,
      GENDERS,
      isLoading: false,
      birthDateType: 'date',
      phoneMask: ['+', /\d/, ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, '-', /\d/, /\d/],
      maxDate: new Date().toISOString().split('T')[0],
      isSaving: false,
      accountType: 0,
      documents: [],
      checkup: {
        middleName: '',
        firstName: '',
        lastName: '',
        birthDate: null,
        sex: null,
        phoneNumber: '+7 (',
        body: '',
        patientId: null,
        parentId: null,
        communicationMethod: null,
        availabilityOfPayment: null,
      },
      search: {
        isLoading: false,
        isFilterOpen: false,
        filter: {
          PatientId: null,
          PhoneNumber: null,
          SearchFIO: null,
          PolicyNumber: null,
          PatientType: 2,
        },
        field: null,
        patients: [],
        error: null,
      },
      FEATURES_FOR_SCHEDULE,
      FEATURES_FOR_DOCTORS,
      isVisibleProtocols: false,
      fields: [
        {
          key: 'nameDoctor',
          label: 'ФИО врача',
          thClass: ['text-center', 'align-middle'],
          tdClass: ['text-center', 'align-middle'],
        },
        {
          key: 'specialtyDoctor',
          label: 'Специальность врача',
          class: 'text-center',
          thClass: ['text-center', 'align-middle'],
          tdClass: ['text-center', 'align-middle'],
        },
        {
          key: 'dateClosedConsultation',
          label: 'Дата закрытия консультации',
          class: 'text-center',
          tdClass: ['text-center', 'align-middle'],
        },
        {
          key: 'addProtocolBtn',
          label: 'Файл',
          thClass: ['text-center', 'align-middle'],
        },
      ],
      personId: null,
      protocolItems: [],
      perPage: 5,
      currentPage: 1,
      protocolConsultationInfo: null,
      protocolFiles: [],
    };
  },
  validations() {
    return this.accountType === 0
      ? {
        checkup: {
          patientId: {
            required,
          },
          communicationMethod: {
            required,
          },
          availabilityOfPayment: {
            required,
          },
        },
      } : {
        checkup: {
          lastName: {
            required,
          },
          firstName: {
            required,
          },
          birthDate: {
            required,
          },
          sex: {
            required,
          },
          phoneNumber: {
            required,
            isValid: (value) => /^\+\d \(\d{3}\) \d{3} \d{2}-\d{2}$/i.test(value),
          },
          communicationMethod: {
            required,
          },
          availabilityOfPayment: {
            required,
          },

        },
      };
  },
  computed: {
    errorsValidation() {
      const errors = {};

      errors.communicationMethod = [];
      if (this.accountType === 0) {
        errors.patientId = [];

        if (!this.$v.checkup.patientId.required) {
          errors.patientId.push('Выберите существующего пользователя или создайте нового');
        }

        if (!this.$v.checkup.communicationMethod.required) {
          errors.communicationMethod.push('Способ связи обязателен к заполнению');
        }

        return errors;
      }

      if (!this.$v.checkup.communicationMethod.required) {
        errors.communicationMethod.push('Способ связи обязателен к заполнению');
      }

      errors.lastName = [];
      if (!this.$v.checkup.lastName.required) {
        errors.lastName.push('Поле не может быть пустым');
      }

      errors.firstName = [];
      if (!this.$v.checkup.firstName.required) {
        errors.firstName.push('Поле не может быть пустым');
      }

      errors.birthDate = [];
      if (!this.$v.checkup.birthDate.required) {
        errors.birthDate.push('Поле не может быть пустым');
      }

      errors.sex = [];
      if (!this.$v.checkup.sex.required) {
        errors.sex.push('Поле не может быть пустым');
      }

      errors.phoneNumber = [];
      if (!this.$v.checkup.phoneNumber.required) {
        errors.phoneNumber.push('Поле не может быть пустым');
      }
      if (!this.$v.checkup.phoneNumber.isValid) {
        errors.phoneNumber.push('Некорректное значение поля');
      }

      return errors;
    },
    formattedDate() {
      return format(new Date(this.time * 1000), 'dd.MM.yyyy HH:mm');
    },
    filteredAccountTypes() {
      return this.checkFeature(FEATURES_FOR_CHAT.scheduleAppointment, FEATURES_FOR_SCHEDULE.scheduleAppointment)
        ? ACCOUNT_TYPES.filter((item) => item.id === 0) : ACCOUNT_TYPES;
    },
    rowsInProtocolItems() {
      return this.protocolItems.length;
    },
  },
  created() {
    this.search.field = this.patientIdFromChat ? 'fio' : 'phone';
  },
  async mounted() {
    this.$v.$reset();
    if (this.patientIdFromChat) {
      await this.onClickSearch();

      const checkedPatient = this.search.patients.find((p) => p.id === this.patientIdFromChat);
      this.setPatient(checkedPatient);
    }
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    checkFeature(keyOne, keyTwo) {
      return this.parentModalName ? this.checkFeatureAccess({ name: keyOne.name, url: keyOne.url })
        : this.checkFeatureAccess({ name: keyTwo.name, url: keyTwo.url });
    },
    async onClickSave() {
      this.$v.$touch();
      this.checkValidations = true;

      if (this.$v.$error) {
        showValidationErrorMessage();
        return;
      }

      try {
        this.isSaving = true;

        const checkupObj = this.accountType === 0
          ? {
            patientId: this.checkup.parentId ? this.checkup.parentId : this.checkup.patientId,
          } : {
            patient: {
              phoneNumber: this.checkup.phoneNumber,
              lastName: this.checkup.lastName,
              firstName: this.checkup.firstName,
              middleName: this.checkup.middleName,
              birthDate: dateWithoutTime(formatISO(this.checkup.birthDate)),
              sex: this.checkup.sex,
            },
          };

        const params = {
          consultation: {
            doctorId: this.doctorId,
            unixTime: this.time,
            body: this.checkup.body,
            communicationMethod: this.checkup.communicationMethod,
            availabilityOfPayment: this.checkup.availabilityOfPayment,
            fileNames: this.documents.map((doc) => doc.guid),
            protocolIds: this.protocolFiles.map((item) => item.protocolId),
          },
          ...checkupObj,
        };

        if (this.checkup.parentId) params.consultation.targetPatientId = this.checkup.patientId;

        await this.$store.dispatch(this.$types.SCHEDULE_CREATE_CHECKUP, params);

        // const consultationId = await this.$store.dispatch(this.$types.SCHEDULE_CREATE_CHECKUP, params);

        // const fileToken = await this.$store.dispatch(this.$types.SCHEDULE_FILETOKEN_FETCH, consultationId);

        // const documentsId = this.documents.map((doc) => doc.guid);

        // await this.$store.dispatch(this.$types.SCHEDULE_CHECKUP_ADD_DOCUMENTS, {
        //   consultationId,
        //   documentsId,
        //   fileToken,
        // });
        this.$store.commit(this.$types.TOGGLE_SCHEDULE_TRIGGER); // сделает тогл триггера и обновит расписание
        this.onClose();
      } finally {
        this.isSaving = false;
      }
    },
    changeSearchField() {
      this.search.filter.PhoneNumber = null;
      this.search.filter.SearchFIO = null;
      this.search.filter.PolicyNumber = null;
    },
    async onClickSearch() {
      this.checkup.patientId = null;
      this.search.isLoading = true;

      try {
        this.search.error = null;
        this.search.patients = [];
        if (this.patientIdFromChat) {
          this.search.filter.PatientId = this.patientIdFromChat;
        }
        const patients = await personService.getPatients(this.search.filter);
        this.search.patients = patients;
      } catch (error) {
        console.error(error);
      } finally {
        this.search.isLoading = false;

        if (this.search.patients.length === 0) {
          if (this.search.field === 'phone') {
            this.search.error = 'Пользователь с таким номером телефона не найден.';
          }

          if (this.search.field === 'fio') {
            this.search.error = 'Пользователь с таким ФИО не найден.';
          }

          if (this.search.field === 'policy') {
            this.search.error = 'Пользователь с таким номером полиса не найден.';
          }
        }
      }
    },
    setPatient(patient) {
      // console.log(patient);
      this.personId = patient.id;
      this.getProtocolsForPatient();
      if (this.checkup.patientId === patient.id) {
        this.checkup.patientId = null;
        this.checkup.parentId = null;
        return;
      }
      this.checkup.parentId = patient.parentId;
      this.checkup.patientId = patient.id;
    },
    setProtocol(protocol) {
      this.protocolFiles.push(protocol);
    },
    async getProtocol(id) {
      const protocol = await consultationService.getProtocol(id);
      const blob = new Blob([protocol], { type: 'application/pdf' });
      await saveFile(blob, { name: 'this.originName', open: true });
    },
    deleteProtocol(protocol) {
      this.protocolFiles = this.protocolFiles.filter((item) => item.protocolId !== protocol.protocolId);
    },
    onClickDeleteFile(file) {
      this.documents = this.documents.filter((doc) => doc.guid !== file.guid);
    },
    saveFileCallback(newFilesArray) {
      newFilesArray.map((fileObject) => {
        console.log(fileObject);
        this.documents.push({
          title: fileObject.name,
          guid: fileObject.responseData,
        });
        return null;
      });
    },
    onClickOpenFileModal() {
      uiService.showModal(MODALS.FILES_UPLOAD_MODAL, {
        name: 'FilesUploadModal',
        props: {
          multiple: true,
          title: 'Загрузка документов',
          saveFileCallback: this.saveFileCallback,
          fileApi: 'File/fileServer',
        },
      });
    },
    onClickOpenProtocol() {
      this.isVisibleProtocols = !this.isVisibleProtocols;
    },
    async getProtocolsForPatient() {
      try {
        const result = await consultationService.searchProtocols({ patientId: this.personId });
        this.protocolItems = result;
      } catch (err) {
        showErrorCustomMessage('Не удалось получить список протоколов');
        console.log(err);
      }
    },
    formatDate(date) {
      const newDate = utc0ToDateWithUserTimezone(date);
      return format(new Date(newDate), 'dd.MM.yyyy');
    },
  },
};
</script>

<style lang="scss" scoped>
.patients {
  overflow: auto;
  max-width: 100%;
  padding-bottom: 20px;
}

.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateZ(5px);
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .3s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.list-enter-active, .list-leave-active {
  transition: all .7s;
}
.list-enter, .list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
