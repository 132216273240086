var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-photo-edit"},[(!_vm.isEdit)?[(_vm.showPhotoEditTitle)?_c('div',{staticClass:"crm-title"},[_vm._v(" Редактирование фотографии ")]):_vm._e(),_vm._m(0),_c('div',{staticClass:"photo"},[_c('img',{class:{
          'source-photo': true,
          'source-photo-rounded': _vm.rounded,
        },attrs:{"src":_vm.photoUrl,"width":_vm.imageWidth,"height":_vm.imageHeight}})]),_c('b-button',{attrs:{"variant":"primary","type":_vm.$const.PRIMARY_BUTTON,"size":"sm"},on:{"click":_vm.toNewPhoto}},[_vm._v(" Изменить ")])]:[(!_vm.newPhoto)?[(_vm.showPhotoEditTitle)?_c('div',{staticClass:"crm-title"},[_vm._v(" Загрузка фотографии ")]):_vm._e(),_vm._m(1),_c('div',{staticClass:"photo"},[(_vm.photoUrl)?_c('img',{class:{
            'source-photo': true,
            'source-photo-rounded': _vm.rounded,
          },attrs:{"src":_vm.photoUrlProp,"width":_vm.imageWidth,"height":_vm.imageHeight},on:{"click":_vm.onPhotoClickEvent}}):_c('PhotoAvatar',{staticClass:"photo-avatar",on:{"click":_vm.onPhotoClickEvent}})],1),_c('input',{ref:"inputFile",staticClass:"photo-input",attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.onChoosePhoto}}),_c('div',{staticClass:"d-flex"},[_c('b-button',{attrs:{"variant":"primary","type":_vm.$const.PRIMARY_BUTTON,"size":"sm"},on:{"click":_vm.onPhotoClickEvent}},[_vm._v(" Загрузить файл ")]),(_vm.showDeleteButton && _vm.photoUrl)?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"danger","type":_vm.$const.PRIMARY_BUTTON,"size":"sm"},on:{"click":_vm.deletePhoto}},[_vm._v(" Удалить ")]):_vm._e()],1)]:[(_vm.showPhotoEditTitle)?_c('div',{staticClass:"crm-title"},[_vm._v(" Редактирование фотографии ")]):_vm._e(),_c('div',{staticClass:"description"},[_vm._v(" Выбранная область будет показываться на Вашей странице. ")]),_c('div',{staticClass:"photo"},[_c('cropper',{staticClass:"cropper",attrs:{"default-size":_vm.defaultSize,"src":_vm.newPhoto,"stencil-props":{
            aspectRatio: 1/1
          }},on:{"change":_vm.setCropState}}),_c('canvas',{attrs:{"id":"photo-canvas","width":_vm.imageWidth,"height":_vm.imageHeight}})],1)]],_c('div',{staticClass:"crm-wrapper-buttons crm-wrapper-buttons_clinic-photo-edit"},[_c('b-button',{attrs:{"variant":"secondary","type":_vm.$const.PRIMARY_BUTTON,"disabled":_vm.isSaving},on:{"click":_vm.onClickCancel}},[_vm._v(" Отменить ")]),_c('b-button',{staticClass:"ml-4",attrs:{"variant":"primary","type":_vm.$const.PRIMARY_BUTTON,"disabled":_vm.isSaving || !_vm.isCanSave},on:{"click":_vm.onClickSave}},[_vm._v(" Сохранить ")])],1)],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"description"},[_vm._v(" Вы можете загрузить изображение в формате JPG или PNG. "),_c('br'),_vm._v(" Если у Вас возникают проблемы с загрузкой, попробуйте выбрать фотографию меньшего размера. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"description"},[_vm._v(" Вы можете загрузить изображение в формате JPG или PNG. "),_c('br'),_vm._v(" Если у Вас возникают проблемы с загрузкой, попробуйте выбрать фотографию меньшего размера. ")])
}]

export { render, staticRenderFns }