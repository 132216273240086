<template>
  <!-- @click="openWatchModal" -->
  <div class="d-flex justify-content-between p-3 border rounded bg-light">
    <div class="crm-info-wrapper">
      <div class="font-weight-bold h6">
        {{ fullname }}
      </div>
      <div class="text-primary mt-2">
        {{ specializations }}
      </div>
      <!-- null тоже не подходит, поэтому - строгое равенство -->
      <div
        v-if="doctor.settings?.isActive === false"
        class="text-danger mt-2"
      >
        Деактивирован
      </div>
    </div>

    <div class="d-flex flex-column">
      <b-button
        v-b-tooltip.hover
        variant="light"
        :type="$const.PRIMARY_BUTTON"
        size="sm"
        class="border border-primary p-1"
        title="Информация о враче"
        @click="openWatchModal"
      >
        <b-icon icon="eye" />
      </b-button>
      <b-button
        v-b-tooltip.hover
        variant="light"
        :type="$const.PRIMARY_BUTTON"
        size="sm"
        class="border border-primary mt-1 p-1"
        title="Расписание"
        @click="openScheduleModal"
      >
        <b-icon icon="calendar2-date" />
      </b-button>
      <b-button
        v-if="edit"
        v-b-tooltip.hover
        variant="light"
        :type="$const.PRIMARY_BUTTON"
        size="sm"
        class="border border-danger mt-1 p-1"
        title="Удалить"
        @click="onClickUnpin"
      >
        <b-icon icon="trash" />
      </b-button>
    </div>
  </div>
</template>

<script>
// import Bus from '@/eventBus';
import { parseISO, format } from '@evd3v/date-fns';

export default {
  name: 'DoctorInfoItem',
  props: {
    doctor: {
      type: Object,
      default: () => ({}),
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fullname() {
      const { firstName, lastName, middleName } = this.doctor;
      return `${lastName}\n${firstName}\n${middleName}`;
    },
    specializations() {
      return this.doctor.doctorSpecializations
        ?.map((doctorSpecialization) => doctorSpecialization?.title || doctorSpecialization?.specialization?.title)
        ?.join(', ').toLowerCase();
    },
  },
  methods: {
    parseData(ISODate) {
      return format(parseISO(ISODate), 'dd.MM.yyyy');
    },
    onClickUnpin() {
      this.$emit('toggle-pin');
    },
    openWatchModal() {
      // const isClear = e.targeasst.clList.contains('icon-clear');
      // if (isClear) return; не понял зачем это

      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'DoctorInfoModal',
        props: {
          doctorId: this.doctor.id,
          title: this.doctor.fullName,
        },
      });
    },
    openScheduleModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ScheduleModal',
        props: {
          doctorId: this.doctor.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
