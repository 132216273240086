<template>
  <div class="clinic-info">
    <Loading
      v-if="isLoading"
      class="crm-loading-wrapper"
    >
      Загрузка клиники
    </Loading>

    <template v-else>
      <div class="crm-title-row">
        <div class="clinic-title">
          {{ clinicInfo.appTitle || clinicInfo.title || '—' }}
        </div>
        <template v-if="checkFeatureAccess({ name: 'Редактирование информации клиники', url: '/clinics/:clinicId' })">
          <icon-edit
            v-if="isEdit"
            class="crm-icon edit-btn active"
            @click="onClickCancel"
          />
          <icon-edit
            v-else
            class="crm-icon edit-btn"
            @click="editClinic"
          />
        </template>
      </div>

      <div class="line-title">
        <div class="line-title-inner">
          Информация о юридическом лице
        </div>
      </div>

      <template v-if="isEdit">
        <div class="clinics-form-fields">
          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-info-title">
                Сеть клиник
              </p>
              <span class="crm-info-text">
                {{ clinicInfo?.clinicNetworkTitle || '—' }}
              </span>
            </div>
          </div>

          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-info-title">
                Юр. лицо
              </p>
              <span class="crm-info-text">
                {{ clinicInfo.legalPerson.name || '—' }}
              </span>
            </div>
          </div>

          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-info-title flex">
                ФИО руководителя
              </p>
              <span class="crm-info-text">
                {{ clinicInfo.legalPerson.leaderFio || '—' }}
              </span>
            </div>
          </div>

          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-info-title flex">
                Должность руководителя
              </p>
              <span class="crm-info-text">
                {{ clinicInfo.legalPerson.leaderPosition || '—' }}
              </span>
            </div>
          </div>
        </div>

        <div class="clinics-form-fields">
          <div class="crm-form-field">
            <div class="crm-form-block site-info">
              <p class="crm-info-title">
                Веб-сайт
              </p>
              <span class="crm-info-text">
                <a
                  v-if="clinicInfo.legalPerson.site"
                  :href="clinicInfo.legalPerson.site"
                  target="_blank"
                >
                  {{ clinicInfo.legalPerson.site }}
                </a>
                <template v-else>
                  —
                </template>
              </span>
            </div>
          </div>

          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-info-title">
                ОГРН
              </p>
              <span class="crm-info-text">
                {{ clinicInfo.legalPerson.ogrn || '—' }}
              </span>
            </div>
          </div>

          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-info-title">
                ИНН
              </p>
              <span class="crm-info-text">
                {{ clinicInfo.legalPerson.inn || '—' }}
              </span>
            </div>
          </div>
          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-info-title">
                КПП
              </p>
              <span class="crm-info-text">
                {{ clinicInfo.legalPerson.kpp || '—' }}
              </span>
            </div>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="clinics-form-fields">
          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-info-title">
                Сеть клиник
              </p>
              <span class="crm-info-text">
                {{ clinicInfo?.clinicNetworkTitle || '—' }}
              </span>
            </div>
          </div>

          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-info-title">
                Юр. лицо
              </p>
              <span class="crm-info-text">
                {{ clinicInfo.legalPerson.name || '—' }}
              </span>
            </div>
          </div>

          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-info-title flex">
                ФИО руководителя
              </p>
              <span class="crm-info-text">
                {{ clinicInfo.legalPerson.leaderFio || '—' }}
              </span>
            </div>
          </div>

          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-info-title flex">
                Должность руководителя
              </p>
              <span class="crm-info-text">
                {{ clinicInfo.legalPerson.leaderPosition || '—' }}
              </span>
            </div>
          </div>
        </div>

        <div class="clinics-form-fields">
          <div class="crm-form-field">
            <div class="crm-form-block site-info">
              <p class="crm-info-title">
                Веб-сайт
              </p>
              <span class="crm-info-text">
                <a
                  v-if="clinicInfo.legalPerson.site"
                  :href="clinicInfo.legalPerson.site"
                  target="_blank"
                >
                  {{ clinicInfo.legalPerson.site }}
                </a>
                <template v-else>
                  —
                </template>
              </span>
            </div>
          </div>

          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-info-title">
                ОГРН
              </p>
              <span class="crm-info-text">
                {{ clinicInfo.legalPerson.ogrn || '—' }}
              </span>
            </div>
          </div>

          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-info-title">
                ИНН
              </p>
              <span class="crm-info-text">
                {{ clinicInfo.legalPerson.inn || '—' }}
              </span>
            </div>
          </div>
          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-info-title">
                КПП
              </p>
              <span class="crm-info-text">
                {{ clinicInfo.legalPerson.kpp || '—' }}
              </span>
            </div>
          </div>
        </div>

        <template v-for="contact in clinicInfo.legalPerson.contacts">
          <div
            v-if="!contact.isDeleted"
            :key="contact.id"
            class="clinics-form-fields"
          >
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-info-title">
                  Должность
                </p>
                <span class="crm-info-text">
                  {{ contact.position|| '—' }}
                </span>
              </div>
            </div>

            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-info-title">
                  ФИО
                </p>
                <span class="crm-info-text">
                  {{ contact.fio || '—' }}
                </span>
              </div>
            </div>

            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-info-title">
                  E-mail
                </p>
                <span class="crm-info-text">
                  {{ contact.email || '—' }}
                </span>
              </div>
            </div>

            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-info-title">
                  Телефон
                </p>
                <span class="crm-info-text">
                  {{ contact.phoneNumber || '—' }}
                </span>
              </div>
            </div>
          </div>
        </template>
      </template>

      <div class="line-title">
        <div class="line-title-inner">
          Информация о клинике
        </div>
      </div>

      <template v-if="isEdit">
        <div class="d-flex mb-3">
          <div class="w-50">
            <p class="crm-info-title">
              Название клиники для приложения <span class="crm-error-text">*</span>
            </p>
            <base-input
              v-model="$v.clinicInfoEditData.title.$model"
              :error="$v.clinicInfoEditData.title.$error"
              :errors="errorsValidation.title"
              :input-style="inputStyleProps"
              class="w-100"
              placeholder="Введите название"
              required
            />
          </div>
          <div class="w-50 ml-3">
            <p class="crm-label invisible">
              _
            </p>
            <b-form-checkbox
              v-model="automaticGeolocationSetting"
              switch
              size="sm"
              style="margin-top: 19px;"
              :disabled="editGeolocationDisabled"
              @change="onChangeGeolocationSetting"
            >
              Выставить автоматически географические координаты
              <span
                v-b-tooltip.hover
                :title="AUTOMATIC_GEOLOCATION_TITLE"
                class="ml-2"
              >
                <b-icon icon="question-circle" />
              </span>
            </b-form-checkbox>
          </div>
        </div>

        <div class="clinics-form-fields">
          <div class="crm-form-field">
            <div class="crm-form-block">
              <div class="crm-label">
                Город <span class="crm-error-text ml-1">*</span>
              </div>
              <v-select
                v-model="$v.clinicInfoEditData.city.$model"
                :options="cities"
                placeholder="Выберите город"
                label="name"
                :clearable="true"
                class="crm-select person-select"
              />
              <div
                v-if="$v.clinicInfoEditData.city.$error"
                class="validation-errors"
              >
                <span
                  class="validation-error-text"
                >
                  {{ errorsValidation.city[0] }}
                </span>
              </div>
            </div>
          </div>

          <div
            style="width: 520px"
            class="mr-0"
          >
            <div
              class="crm-info-title"
            >
              Адрес <span style="color: red">*</span>
            </div>
            <b-form-group>
              <b-form-input
                id="city"
                v-model="$v.clinicInfoEditData.address.$model"
                :state="!$v.clinicInfoEditData.address.$error"
                :errors="errorsValidation.address"
                :input-style="inputStyleProps"
                required
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >
                Данное поле обязательно *
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="crm-form-field">
            <div class="d-flex flex-column ml-3">
              <p class="crm-label">
                Режим работы
              </p>
              <div class="d-flex">
                <div class="crm-form-input-prefix crm-bold">
                  с
                </div>
                <base-input
                  v-model="clinicInfoEditData.operatingHoursFrom"
                  class="crm-form-input"
                  type="text"
                  placeholder="8:00"
                  :input-style="{ ...inputStyleProps, width: '70px'}"
                  :disable-arrows="true"
                />

                <div class="divide-line" />

                <div class="crm-form-input-prefix crm-bold">
                  до
                </div>
                <base-input
                  v-model="clinicInfoEditData.operatingHoursTo"
                  class="crm-form-input"
                  type="text"
                  placeholder="20:00"
                  :input-style="{ ...inputStyleProps, width: '70px'}"
                  :disable-arrows="true"
                />
              </div>
            </div>
          </div>

          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-info-title">
                Географические координаты
              </p>

              <div class="crm-form-fields">
                <base-input
                  v-model="$v.clinicInfoEditData.latitude.$model"
                  :error="$v.clinicInfoEditData.latitude.$error"
                  :errors="errorsValidation.latitude"
                  :input-style="inputStyleProps"
                  :mask="maskLatitude"
                  :disabled="geolocationInputDisabled"
                  class="crm-form-field coord-input"
                >
                  <template v-slot:prefix>
                    <span class="coord-prefix">Ш.</span>
                  </template>
                </base-input>

                <base-input
                  v-model="$v.clinicInfoEditData.longitude.$model"
                  :error="$v.clinicInfoEditData.longitude.$error"
                  :errors="errorsValidation.longitude"
                  :input-style="inputStyleProps"
                  :mask="maskLongitude"
                  :disabled="geolocationInputDisabled"
                  class="crm-form-field coord-input"
                >
                  <template v-slot:prefix>
                    <span class="coord-prefix">Д.</span>
                  </template>
                </base-input>
              </div>
            </div>
          </div>
        </div>

        <div class="clinics-form-fields">
          <div class="crm-form-field">
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  E-mail для гарантийных писем
                </p>

                <div
                  v-for="(contact, index) in $v.clinicInfoEditData.contactsForIL.$each.$iter"
                  :key="index + 's'"
                  class="crm-form-field-email"
                >
                  <base-input
                    v-model="contact.email.$model"
                    :input-style="inputStyleProps"
                    :style="{
                      flex: '1',
                      marginBottom: contact.email.$invalid ? '20px' : '0',
                    }"
                    placeholder="example@example.com"
                    :error="contact.email.$invalid"
                    :errors="['Некорректная почта']"
                  />

                  <icon-delete
                    class="crm-icon-delete crm-form-email-delete"
                    @click="deleteEmail(index)"
                  />
                </div>

                <b-button
                  :type="$const.PRIMARY_BUTTON"
                  variant="link"
                  class="add-email button-link"
                  @click="addEmail"
                >
                  <span>Добавить e-mail</span>
                </b-button>
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Приоритет клиники <span class="crm-error-text ml-1">*</span>
                </p>
                <v-select
                  v-model="$v.clinicInfoEditData.priority.$model"
                  :reduce="priority => priority.index"
                  :options="clinicPriorities"
                  :clearable="false"
                  class="crm-form-field person-select"
                  placeholder="Выберите приоритет"
                  label="title"
                />
                <div
                  v-if="$v.clinicInfoEditData.priority.$error"
                  class="validation-errors"
                >
                  <span
                    class="validation-error-text"
                  >
                    {{ errorsValidation.priority[0] }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-info-title">
                Комментарий к режиму работы:
              </p>
              <base-input
                v-model="clinicInfoEditData.operatingHoursComment"
                :input-style="inputStyleProps"
                class="crm-form-field comment"
                placeholder="Введите текст"
              />
            </div>
          </div>
        </div>

        <div class="clinics-form-fields-grid">
          <div class="crm-form-field-left">
            <div class="crm-form-block">
              <div class="crm-label">
                Услуги
              </div>
              <div class="crm-form-checkbox-list mt-2">
                <base-checkbox
                  v-for="(service, index) in clinicServices"
                  :key="service"
                  v-model="clinicInfoEditData.services"
                  :label="index"
                  class="crm-form-checkbox"
                >
                  <span class="crm-form-checkbox-text">
                    {{ service }}
                  </span>
                </base-checkbox>
              </div>
            </div>
          </div>
          <div class="crm-form-field-right">
            <div class="crm-form-block">
              <p class="crm-info-title">
                Особенности клиники
              </p>
              <div>
                <ClassicEditor
                  v-model="clinicInfoEditData.specialComment"
                  class="custom-ck-class"
                  :disabled="false"
                />
              </div>
              <br>
              <base-checkbox
                v-model="clinicInfoEditData.viewInDoctis"
                class="crm-form-checkbox"
              >
                <span class="crm-form-checkbox-text">
                  Выводить для doctis.ru
                </span>
              </base-checkbox>
            </div>
          </div>
        </div>
        <div class="crm-form-grid">
          <div class="crm-form-field">
            <div class="crm-form-block">
              <div class="crm-label">
                Виды услуг <span class="crm-error-text ml-1">*</span>
              </div>
              <div class="d-flex flex-column">
                <div class="d-flex align-items-center">
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="clinicInfoEditData.isOms"
                    :disabled="!checkFeatureAccess({ name: 'Редактирование. Виды услуг. Можно выбрать ОМС', url: '/clinics/:clinicId' })"
                    name="checkbox-1"
                    :value="true"
                    :unchecked-value="false"
                  >
                    <span class="crm-form-checkbox-text">
                      ОМС
                    </span>
                  </b-form-checkbox>
                  <b-form-checkbox
                    id="checkbox-2"
                    v-model="clinicInfoEditData.isDms"
                    :disabled="!checkFeatureAccess({ name: 'Редактирование. Виды услуг. Можно выбрать ДМС', url: '/clinics/:clinicId' })"
                    name="checkbox-2"
                    :value="true"
                    class="mr-3 ml-3"
                    :unchecked-value="false"
                  >
                    <span class="crm-form-checkbox-text">
                      ДМС
                    </span>
                  </b-form-checkbox>
                  <b-form-checkbox
                    id="checkbox-3"
                    v-model="clinicInfoEditData.isTelemed"
                    :disabled="!checkFeatureAccess({ name: 'Редактирование. Виды услуг. Можно выбрать Телемед', url: '/clinics/:clinicId' })"
                    name="checkbox-3"
                    :value="true"
                    :unchecked-value="false"
                  >
                    <span class="crm-form-checkbox-text">
                      Телемед
                    </span>
                  </b-form-checkbox>
                </div>

                <div
                  v-if="typesOfServicesErrors && checkErrors"
                >
                  <span
                    class="validation-error-text"
                  >
                    {{ typesOfServicesErrors[0] }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="crm-form-field ml-3">
            <div class="crm-form-block">
              <div class="crm-label">
                Привязка клиники <span class="crm-error-text ml-1">*</span>
              </div>
              <div class="d-flex flex-column">
                <div class="d-flex align-items-center">
                  <b-form-checkbox
                    id="checkbox-22"
                    v-model="clinicInfoEditData.isDmsPay"
                    name="checkbox-22"
                    disabled
                    :value="true"
                    class="mr-4"
                    :unchecked-value="false"
                  >
                    <span class="crm-form-checkbox-text">
                      ДМС
                    </span>
                  </b-form-checkbox>
                </div>
                <div
                  v-if="clinicLinkingErrors && checkErrors"
                >
                  <span
                    class="validation-error-text"
                  >
                    {{ clinicLinkingErrors[0] }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="crm-form-field ml-2">
            <div class="crm-form-block">
              <div class="crm-label">
                Признак договора <span class="crm-error-text ml-1">*</span>
              </div>

              <b-form-group
                v-slot="{ ariaDescribedby }"
                class="mb-0"
              >
                <b-form-checkbox-group
                  id="checkbox-group-clinicAttributes-edit"
                  v-model="clinicInfoEditData.clinicAttributes"
                  :options="SIGNS_AGENCY_AGREEMENT"
                  :aria-describedby="ariaDescribedby"
                  name="clinicAttributes-edit"
                />
              </b-form-group>
              <div
                v-if="signContractErrors && checkErrors"
              >
                <span
                  class="validation-error-text"
                >
                  {{ signContractErrors[0] }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="clinics-form-fields mt-2 ml-2">
          <div
            v-if="selectedAgencyAgreement"
            class="crm-form-field"
          >
            <div class="crm-form-block" />
            <div class="crm-form-block">
              <div
                class="crm-label"
              >
                Номер агентского договора *
              </div>

              <b-form-group class="mr-2">
                <b-form-input
                  v-model="$v.clinicInfoEditData.contractNumber.$model"
                  :state="!$v.clinicInfoEditData.contractNumber.$error"
                  :errors="errorsValidation.contractNumber"
                  :input-style="inputStyleProps"
                  :required="selectedAgencyAgreement"
                />
                <b-form-invalid-feedback>
                  Данное поле обязательно*
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
          </div>
        </div>
        <div class="clinics-form-fields">
          <div class="crm-form-field">
            <div class="crm-form-block">
              <div class="crm-label font-weight-bold">
                Дистанционный мониторинг
              </div>
              <div
                title="Недоступно при редактировании"
                class="d-flex align-items-center"
              >
                <b-form-checkbox
                  v-model="clinicInfoEditData.isAddedToDismonitoringAdmin"
                  :value="true"
                  :unchecked-value="false"
                  disabled
                >
                  <span class="crm-form-checkbox-text font-weight-bold">
                    Клиника привязана
                  </span>
                </b-form-checkbox>
              </div>
            </div>
          </div>
          <div class="crm-form-field">
            <div class="crm-form-block">
              <div
                class="crm-label"
              >
                ID клиники МиД
              </div>

              <b-form-group>
                <b-form-input
                  v-model="clinicMidId"
                  class="w-25"
                  :disabled="!isMidClinic"
                />
              </b-form-group>
            </div>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="clinics-form-fields">
          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-info-title">
                Название клиники для приложения
              </p>
              <span class="crm-info-text">
                {{ clinicInfo.title || '—' }}
              </span>
            </div>
          </div>
        </div>

        <div class="clinics-form-fields">
          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-info-title">
                Адрес
              </p>
              <span class="crm-info-text">
                {{ clinicInfo.address || '—' }}
              </span>
            </div>
          </div>

          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-info-title">
                Город
              </p>
              <span class="crm-info-text">
                {{ clinicInfo.city?.title || '—' }}
              </span>
            </div>
          </div>

          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-info-title">
                Режим работы:
              </p>
              <div class="crm-info-text">
                с {{ clinicInfo.operatingHoursFrom || '—' }}
                до {{ clinicInfo.operatingHoursTo || '—' }}
              </div>
              <div class="crm-info-text">
                {{ clinicInfo.operatingHoursComment || '—' }}
              </div>
            </div>
          </div>

          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-info-title">
                E-mail для гарантийных писем
              </p>
              <template v-if="clinicInfo.contactsForIL?.length">
                <div
                  v-for="(email, index) in clinicInfo.contactsForIL"
                  :key="index"
                  class="crm-info-text"
                >
                  {{ email }}
                </div>
              </template>
              <span
                v-else
                class="crm-info-text"
              >
                {{ '—' }}
              </span>
            </div>
          </div>
        </div>

        <div class="clinics-form-fields">
          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-info-title">
                Приоритет клиники
              </p>
              <span class="crm-info-text">
                {{ clinicPriority || '—' }}
              </span>
            </div>
          </div>

          <div class="crm-form-field" />

          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-info-title">
                Широта
              </p>
              <span class="crm-info-text">
                {{ clinicInfo.latitude || '—' }}
              </span>
            </div>
          </div>

          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-info-title">
                Долгота
              </p>
              <span class="crm-info-text">
                {{ clinicInfo.longitude || '—' }}
              </span>
            </div>
          </div>
        </div>
        <div class="clinics-form-fields">
          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-info-title">
                Услуги
              </p>
              <div
                v-if="clinicInfo.services?.length"
                class="service-list"
              >
                <div
                  v-for="service in clinicInfo.services"
                  :key="service"
                  class="service-item"
                >
                  {{ clinicServices[service] }}
                </div>
              </div>
              <span
                v-else
                class="crm-info-text"
              >
                {{ '—' }}
              </span>
            </div>
          </div>

          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-info-title">
                Особенности клиники
              </p>
              <ClassicEditor
                v-model="clinicInfo.specialComment"
                class="custom-ck-class"
                :disabled="true"
              />
            </div>
          </div>
        </div>
        <div class="clinics-form-fields">
          <div class="crm-form-field">
            <div class="crm-form-block">
              <div class="crm-label">
                Виды услуг
              </div>
              <div class="d-flex align-items-center">
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="clinicInfo.isOms"
                  name="checkbox-1"
                  :value="true"
                  :unchecked-value="false"
                  disabled
                >
                  <span class="crm-form-checkbox-text">
                    ОМС
                  </span>
                </b-form-checkbox>
                <b-form-checkbox
                  id="checkbox-2"
                  v-model="clinicInfo.isDms"
                  name="checkbox-2"
                  :value="true"
                  class="mr-3 ml-3"
                  disabled
                  :unchecked-value="false"
                >
                  <span class="crm-form-checkbox-text">
                    ДМС
                  </span>
                </b-form-checkbox>
                <b-form-checkbox
                  id="checkbox-3"
                  v-model="clinicInfo.isTelemed"
                  name="checkbox-3"
                  :value="true"
                  disabled
                  :unchecked-value="false"
                >
                  <span class="crm-form-checkbox-text">
                    Телемед
                  </span>
                </b-form-checkbox>
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <div class="crm-label">
                  Привязка клиники
                </div>
                <b-form-checkbox
                  id="checkbox-22"
                  v-model="clinicInfo.isDmsPay"
                  name="checkbox-22"
                  :value="true"
                  class="mr-4"
                  :unchecked-value="false"
                  disabled
                >
                  <span class="crm-form-checkbox-text">
                    ДМС
                  </span>
                </b-form-checkbox>
              </div>
            </div>
          </div>
          <div class="crm-form-field">
            <div class="crm-form-block">
              <div class="crm-label">
                Признак договора
              </div>

              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  id="checkbox-group-clinicAttributes-preview"
                  v-model="clinicInfo.clinicAttributes"
                  :options="SIGNS_AGENCY_AGREEMENT"
                  :aria-describedby="ariaDescribedby"
                  disabled
                  name="clinicAttributes-preview"
                />
              </b-form-group>
            </div>
          </div>
        </div>
        <div class="clinics-form-fields">
          <div
            v-if="previewSelectedAgencyAgreement"
            class="crm-form-field"
          >
            <div class="crm-form-block">
              <div class="crm-label">
                Номер агентского договора
              </div>

              <span class="crm-info-text">
                {{ clinicInfo.contractNumber || '—' }}
              </span>
            </div>
          </div>
        </div>
        <div class="clinics-form-fields">
          <div class="crm-form-field">
            <div class="crm-form-block">
              <div class="crm-label font-weight-bold">
                Дистанционный мониторинг
              </div>
              <div class="d-flex align-items-center">
                <b-form-checkbox
                  v-model="clinicInfo.isAddedToDismonitoringAdmin"
                  :unchecked-value="false"
                  disabled
                >
                  <span class="crm-form-checkbox-text">
                    Клиника привязана
                  </span>
                </b-form-checkbox>
              </div>
            </div>
          </div>
          <div
            v-if="clinicMidId"
            class="crm-form-field ml-3"
          >
            <div class="crm-form-block">
              <div
                class="crm-label"
              >
                ID клиники МиД
              </div>

              <b-form-group>
                <b-form-input
                  v-model="clinicMidId"
                  class="w-25"
                  :disabled="isMidClinic"
                />
              </b-form-group>
            </div>
          </div>
        </div>
      </template>

      <template v-if="isEdit">
        <div
          v-if="clinicInfoEditData.clinicAttributes.includes(1)"
          class="pb-3"
        >
          <div class="line-title">
            <div class="line-title-inner">
              Оферта
            </div>
          </div>

          <div class="border rounded">
            <div
              v-if="offersShow"
              class="p-3 mb-2"
            >
              <div
                v-for="(offer, index) in clinicInfoEditData.clinicOffers"
                :key="`${offer.title + index}-edit`"
                class="editor-item"
              >
                <div class="mb-2 font-weight-bold">
                  {{ offerTitle(offer.type) }}
                </div>

                <div class="editor-container">
                  <div class="editor-hashtags d-flex">
                    <div
                      v-for="hashtag in HASHTAGS"
                      :key="hashtag.key"
                      class="editor-hashtags__item"
                      @click="copyHashtag(hashtag)"
                    >
                      {{ hashtag.title }}
                    </div>
                  </div>
                  <TextEditor
                    v-model="offer.text"
                    :custom-toolbar="CLINIC_EDITOR_TOOLBAR"
                    :disabled="!isEdit"
                  />

                  <p
                    v-if="checkErrors && !offer.text"
                    class="mt-1 text-danger"
                  >
                    Поле обязательно к заполнению
                  </p>
                </div>
              </div>
            </div>

            <div
              style="cursor: pointer;"
              class="bg-primary rounded-bottom d-flex justify-content-center p-2 "
              @click="offersShow = !offersShow"
            >
              <b-icon
                icon="caret-down-fill"
                variant="light"
                :rotate="offersShow ? 180 : 0"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div
          v-if="clinicInfo.clinicAttributes.includes(1)"
          class="pb-3"
        >
          <div class="line-title">
            <div class="line-title-inner">
              Оферта
            </div>
          </div>

          <div class="border rounded">
            <div
              v-if="offersShow"
              class="p-3 mb-2"
            >
              <div
                v-for="offer in clinicInfo.clinicOffers"
                :key="`${offer.id}-default`"
                class="editor-item"
              >
                <div class="mb-2 font-weight-bold">
                  {{ offerTitle(offer.type) }}
                </div>

                <div class="editor-container">
                  <div class="editor-hashtags d-flex">
                    <div
                      v-for="hashtag in HASHTAGS"
                      :key="hashtag.key"
                      class="editor-hashtags__item"
                      @click="copyHashtag(hashtag)"
                    >
                      {{ hashtag.title }}
                    </div>
                  </div>
                  <TextEditor
                    v-model="offer.text"
                    :custom-toolbar="CLINIC_EDITOR_TOOLBAR"
                    :disabled="!isEdit"
                  />
                </div>
              </div>
            </div>

            <div
              style="cursor: pointer;"
              class="bg-primary rounded-bottom d-flex justify-content-center p-2 "
              @click="offersShow = !offersShow"
            >
              <b-icon
                icon="caret-down-fill"
                variant="light"
                :rotate="offersShow ? 180 : 0"
              />
            </div>
          </div>
        </div>
      </template>

      <div class="line-title">
        <div class="line-title-inner">
          Контакты клиники
        </div>
      </div>

      <template v-if="isEdit">
        <div
          v-for="(contact, index) in $v.clinicInfoEditData.contacts.$each.$iter"
          :key="index"
          class="clinics-form-fields"
        >
          <base-input
            v-model="contact.position.$model"
            :input-style="inputStyleProps"
            class="crm-form-field"
            label="Должность"
          />

          <base-input
            v-model="contact.fio.$model"
            :input-style="inputStyleProps"
            class="crm-form-field"
            label="ФИО"
          />

          <base-input
            v-model="contact.email.$model"
            :input-style="inputStyleProps"
            placeholder="example@example.com"
            :error="contact.email.$invalid"
            :errors="['Некорректная почта']"
            class="crm-form-field"
            label="E-mail *"
          />

          <div class="crm-form-field">
            <base-input
              v-model="contact.phoneNumber.$model"
              :error="contact.phoneNumber.$error"
              :errors="['Поле не может быть пустым']"
              :input-style="{ ...inputStyleProps, width: '150px'}"
              :guide="false"
              class="crm-form-field"
              label="Телефон *"
            />

            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-info-title">
                  Осн. контакт
                </p>
                <span class="crm-info-text contact">
                  <base-checkbox
                    v-model="contact.isMain.$model"
                    class="checkbox"
                  />
                  <icon-delete
                    class="crm-icon-delete contact-delete"
                    @click="deleteContact(index)"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="clinics-form-fields">
          <b-button
            :type="$const.PRIMARY_BUTTON"
            variant="link"
            class="add-contact button-link"
            @click="addContact"
          >
            Добавить контакт
          </b-button>
        </div>

        <template v-if="clinicInfoEditData.isAddedToDismonitoringAdmin || clinicInfoEditData.isTelemed">
          <div class="line-title">
            <div class="line-title-inner">
              {{ clinicInfoEditData.isAddedToDismonitoringAdmin ? 'Профиль администратора дис.мониторинга' : 'Профиль администратора' }}
            </div>
          </div>

          <div class="clinics-form-fields">
            <div class="crm-form-field">
              <base-input
                v-model="$v.clinicInfoEditData.authorization.login.$model"
                :input-style="inputStyleProps"
                :disabled="hadAuthorization"
                :error="$v.clinicInfoEditData.authorization.login.$error"
                :errors="errorsValidation.authorization.login"
                class="crm-form-field"
                label="Логин администратора"
              />

              <base-input
                ref="password"
                v-model="$v.clinicInfoEditData.authorization.password.$model"
                :type="showPassword ? 'text' : 'password'"
                :input-style="inputStyleProps"
                class="crm-form-field"
                label="Пароль администратора"
                autocomplete="new-password"
                :error="$v.clinicInfoEditData.authorization.password.$error"
                :errors="errorsValidation.authorization.password"
              >
                <template
                  v-slot:suffix
                >
                  <icon-eye
                    :class="[ 'crm-icon-eye', { active: showPassword }]"
                    @click="toggleShowPassword"
                  />
                </template>
              </base-input>
            </div>
            <div class="crm-form-field" />
          </div>
        </template>

        <div class="line-title">
          <div class="line-title-inner">
            Документы клиники
          </div>
        </div>

        <b-row v-if="clinicInfoEditData.documents.length">
          <b-col>
            <b-form-group>
              <b-table
                :items="clinicInfoEditData.documents"
                class="bg-white border-secondary w-50"
                bordered
                hover
                :small="true"
                :fields="fields"
              >
                <template #cell(id)="row">
                  <b-button
                    v-b-tooltip.hover
                    size="sm"
                    :title="row.item.title"
                    variant="primary"
                    :type="$const.PRIMARY_BUTTON"
                    class="document-btn ml-2"
                    @click="saveFile(row)"
                  >
                    <b-icon icon="file-earmark-fill" />
                    <span class="ml-2">{{ documentType(row) }}</span>
                  </b-button>
                </template>
                <template #cell(actions)="row">
                  <b-button
                    v-b-tooltip.hover
                    size="sm"
                    title="Редактировать"
                    variant="primary"
                    :type="$const.PRIMARY_BUTTON"
                    class="ml-2"
                    @click="openEditDocumentModal(row)"
                  >
                    <b-icon icon="pencil-fill" />
                  </b-button>
                  <b-button
                    v-b-tooltip.hover
                    size="sm"
                    title="Удалить"
                    variant="danger"
                    :type="$const.PRIMARY_BUTTON"
                    class="ml-2"
                    @click="deleteDocument(row)"
                  >
                    <b-icon icon="x" />
                  </b-button>
                </template>
              </b-table>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button
              size="sm"
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              @click="openAddDocumentModal"
            >
              Добавить файл
            </b-button>
          </b-col>
        </b-row>
        <template>
          <div class="line-title mt-4">
            <div class="line-title-inner">
              Описание клиники
            </div>
          </div>
          <div class="crm-form-field">
            <div class="crm-form-block">
              <ClassicEditor
                v-model="clinicInfoEditData.appDescription"
                class="custom-ck-class"
                :disabled="false"
              />
              <br>
            </div>
          </div>
        </template>
      </template>

      <template v-else>
        <div
          v-for="(contact, i) in clinicInfo.contacts"
          :key="i"
          class="clinics-form-fields"
        >
          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-info-title">
                Должность
              </p>
              <span class="crm-info-text">
                {{ contact.position || '—' }}
              </span>
            </div>
          </div>

          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-info-title">
                ФИО
              </p>
              <span class="crm-info-text">
                {{ contact.fio || '—' }}
              </span>
            </div>
          </div>

          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-info-title">
                E-mail
              </p>
              <span class="crm-info-text">
                {{ contact.email || '—' }}
              </span>
            </div>
          </div>

          <div class="crm-form-field">
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-info-title">
                  Телефон
                </p>
                <span class="crm-info-text">
                  {{ contact.phoneNumber || '—' }}
                </span>
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-info-title">
                  Осн. контакт
                </p>
                <span class="crm-info-text">
                  <base-checkbox
                    :value="contact.isMain"
                    :disabled="true"
                    class="checkbox"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="clinics-form-fields">
          <div class="crm-form-field">
            <div class="crm-form-block">
              <div class="line-title">
                <div class="line-title-inner">
                  Описание клиники
                </div>
              </div>
              <ClassicEditor
                v-model="clinicInfo.appDescription"
                class="custom-ck-class"
                :disabled="true"
              />
            </div>
          </div>
        </div>

        <div class="line-title">
          <div class="line-title-inner">
            Профиль администратора
          </div>
        </div>

        <div class="clinics-form-fields">
          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-info-title">
                Логин администратора
              </p>
              <span class="crm-info-text">
                {{ clinicInfo.authorization && clinicInfo.authorization.login ? clinicInfo.authorization.login : '—' }}
              </span>
            </div>
          </div>
        </div>
      </template>

      <div
        v-if="isEdit"
        class="crm-wrapper-buttons crm-wrapper-buttons_clinic-edit"
      >
        <b-button
          variant="outline-primary"
          class="crm-button"
          :type="$const.PRIMARY_BUTTON"
          @click="onClickCancel"
        >
          Отменить
        </b-button>

        <b-button
          v-if="checkFeatureAccess({ name: 'Редактирование. Кнопка Сохранить', url: '/clinics/:clinicId' })"
          variant="primary"
          :loading="isSaving"
          class="crm-button"
          :type="$const.PRIMARY_BUTTON"
          @click="onClickSave"
        >
          Сохранить
        </b-button>
      </div>
    </template>
  </div>
</template>

<script>
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */

import { api } from '@/helpers/api';
import { mapGetters } from 'vuex';
import {
  CLINIC_PRIORITIES,
  CLINIC_SERVICES,
  SIGNS_AGENCY_AGREEMENT,
  OFFERS,
  HASHTAGS,
  CLINIC_EDITOR_TOOLBAR,
} from '@/helpers/consts';
import { AUTOMATIC_GEOLOCATION_TITLE } from '@/services/clinic/clinic.const';
import { FEATURES } from '@/helpers/features';
import { validationMixin } from 'vuelidate';
import { mixinRoles } from '@/mixins';
import { required } from 'vuelidate/lib/validators';
import {
  createCoordMask,
  saveFile,
} from '@/helpers/utils';
import {
  showValidationErrorMessage,
  showCustomMessage,
  showSuccessCustomMessage,
  showErrorCustomMessage,
} from '@/helpers/messages';

import { clinicService, locationService } from '@/services';

import emailMask from 'text-mask-addons/dist/emailMask';

import {
  BaseInput,
  // BaseRadioButton,
  BaseCheckbox,
  // BaseAsyncSelect,
} from '@/components/base';
import TextEditor from '@/components/common/TextEditor';

import Loading from '@/components/Loading';
import IconEdit from 'assets/images/edit_icon.svg';
import IconEye from 'assets/images/eye_1.svg';
import IconDelete from 'assets/images/delete_icon.svg';

export default {
  name: 'ClinicInfo',
  components: {
    // BaseRadioButton,
    Loading,
    IconEdit,
    IconEye,
    IconDelete,
    BaseInput,
    BaseCheckbox,
    TextEditor,
    ClassicEditor: () => import('@/components/common/CKEditor/ClassicEditorComponent.vue'),
    // BaseAsyncSelect,
  },
  mixins: [validationMixin, mixinRoles],
  props: {
    clinicInfoObj: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isLoading: false,
      checkErrors: false,
      legalPersons: [],
      offersShow: false,
      AUTOMATIC_GEOLOCATION_TITLE,
      HASHTAGS,
      CLINIC_EDITOR_TOOLBAR,
      fields: [
        {
          key: 'id',
          label: 'Документ',
          thClass: 'text-center',
        },
        {
          key: 'actions',
          label: 'Изменения',
          thClass: 'text-center',
          tdClass: 'align-middle',
          class: 'text-center',
        },
      ],
      cities: [],
      networks: [],
      newNetwork: false,
      newNetworkName: null,
      newLegal: false,
      hadAuthorization: false,
      emailMask,
      mask: ['+', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
      isSaving: false,
      inputStyleProps: {
        padding: '11px 15px 12px',
        resize: 'none',
      },
      isOms: false,
      isDms: false,
      isOmsPay: false,
      isDmsPay: false,
      isTelemed: false,
      clinicPriorities: CLINIC_PRIORITIES,
      clinicServices: CLINIC_SERVICES,
      maskLatitude: createCoordMask('latitude'),
      maskLongitude: createCoordMask('longitude'),
      unwatch: null,
      showPassword: false,
      isAdminEdit: false,
      SIGNS_AGENCY_AGREEMENT,
      clinicInfoEditData: {
        id: null,
        title: '',
        appTitle: '',
        appDescription: null,
        clinicNetwork: null,
        city: null,
        latitude: null,
        longitude: null,
        isOms: false,
        isDms: false,
        isOmsPay: false,
        isDmsPay: false,
        isTelemed: false,
        contractNumber: null,
        isAddedToDismonitoringAdmin: false,
        clinicOffers: [],
        contactsForIL: [
          {
            email: '',
          },
        ],
        operatingHoursFrom: null,
        viewInDoctis: false,
        operatingHoursTo: null,
        priority: 0,
        operatingHoursComment: null,
        leader: {
          fio: null,
          position: null,
        },
        legalPerson: {
          contacts: [
            {
              email: null,
              fio: null,
              id: 0,
              legalPersonId: 0,
              phoneNumber: null,
              position: null,
            },
          ],
          id: 0,
          inn: null,
          kpp: null,
          leaderFio: null,
          leaderPosition: null,
          name: null,
          ogrn: null,
          site: null,
        },
        address: null,
        webSite: null,
        // legalInformation: {
        //   inn: null,
        //   kpp: null,
        //   ogrn: null,
        // },
        authorization: {
          login: null,
          password: null,
        },
        description: null,
        specialComment: null,
        // isTelemed: false,
        specializations: [],
        doctors: [],
        photoFile: [],
        agreementFiles: {},
        contacts: [],
      },
      automaticGeolocationSetting: true,
      intermediateGeolocations: null,
      clinicMidId: null,
    };
  },
  validations() {
    return {
      clinicInfoEditData: {
        title: { required },
        appTitle: {
          // required,
        },
        clinicNetwork: {
          // required
        },
        priority: {
          required,
        },
        city: { required },
        longitude: {
          required: this.automaticGeolocationSetting ? () => true : required,
          // isValid: this.clinicInfo.isTelemed ? (val) => /^-?\d{2,3}.\d+$/.test(val) : () => true,
        },
        contractNumber: {
          required: this.selectedAgencyAgreement ? required : () => true,
        },
        latitude: {
          required: this.automaticGeolocationSetting ? () => true : required,
          // isValid: this.clinicInfo.isTelemed ? (val) => /^-?\d{2,3}.\d+$/.test(val) : () => true,
        },
        address: { required },
        // legalInformation: {
        //   inn: { required },
        //   kpp: { required },
        //   ogrn: { required },
        // },
        legalPerson: {
          required,
          contacts: {
            $each: {
              position: {
                // required,
              },
              phoneNumber: {
                // required,
              },
              fio: {
                // required,
              },
              email: {
                // required,
                // isValid: (val) => /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(val),
              },
            },
          },
          inn: {
            // required,
          },
          kpp: {
            // required,
          },
          ogrn: {
            // required,
          },
          name: {
            // required,
          },
          leaderFio: {
            // required,
          },
          leaderPosition: {
            // required,
          },
          site: {
            // required
          },
        },
        authorization: {
          login: {
            required: this.clinicInfoEditData.isAddedToDismonitoringAdmin || this.clinicInfoEditData.isTelemed ? required : () => true,
          },
          password: {
            required: (value) => {
              if (this.hadAuthorization) {
                return !value || !!(value && value.length >= 6);
              }

              if (!this.clinicInfoEditData.authorization.login) return true;

              return !!(value && value.length >= 6);
            },
          },
        },
        contacts: {
          $each: {
            phoneNumber: {
              required,
            },
            email: {
              required,
              isValid: (val) => /^\S*$/.test(val),
            },
            position: {
              // required,
            },
            fio: {
              // required,
            },
            comment: {
              // required,
            },
            isMain: {
              // required,
            },
          },
        },
        contactsForIL: {
          $each: {
            email: {
              required,
              isValid: (val) => /^\S*$/.test(val),
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      isChangedInfo: 'Clinic/GET_IS_CHANGED_INFO',
    }),
    editGeolocationDisabled() {
      return !this.checkFeatureAccess(FEATURES.ClinicsEditingGeolocation);
    },
    geolocationInputDisabled() {
      return this.editGeolocationDisabled || this.automaticGeolocationSetting;
    },
    inputGeolocationTitle() {
      return this.automaticGeolocationSetting ? AUTOMATIC_GEOLOCATION_TITLE : '';
    },
    responsibles() {
      if (this.clinicInfo.responsibles.length === 0) return '—';

      return this.clinicInfo.responsibles.join(', ');
    },
    isCanNetworkChange() {
      return this.newLegal || (!this.newLegal && this.clinicInfoEditData.legalPerson && this.clinicInfoEditData.legalPerson.network === null);
    },
    getActualNetworkForLegalPerson() {
      if (this.newNetwork) return this.newNetworkName ? { id: 0, title: this.newNetworkName } : null;
      return this.clinicInfoEditData.clinicNetwork ? { id: this.clinicInfoEditData.clinicNetwork.id } : null;
    },
    offersError() {
      return this.clinicInfoEditData.clinicOffers.some((item) => !item.text);
    },
    isMidClinic() {
      return this.clinicInfo.legalPerson.network?.id === 1;
    },
    isNeedUpdateLegarPerson() {
      return true;
    },
    errorsValidation() {
      const errors = {};

      errors.legalPerson = [];
      if (!this.$v.clinicInfoEditData.legalPerson.required) {
        errors.legalPerson.push('Поле не может быть пустым');
      }

      errors.legalPersonName = [];
      if (!this.$v.clinicInfoEditData.legalPerson.name.required) {
        errors.legalPersonName.push('Поле не может быть пустым');
      }

      errors.inn = [];
      if (!this.$v.clinicInfoEditData.legalPerson.inn.required) {
        errors.inn.push('Поле не может быть пустым');
      }

      errors.ogrn = [];
      if (!this.$v.clinicInfoEditData.legalPerson.ogrn.required) {
        errors.ogrn.push('Поле не может быть пустым');
      }

      errors.kpp = [];
      if (!this.$v.clinicInfoEditData.legalPerson.kpp.required) {
        errors.kpp.push('Поле не может быть пустым');
      }

      errors.leaderPosition = [];
      if (!this.$v.clinicInfoEditData.legalPerson.leaderPosition.required) {
        errors.leaderPosition.push('Поле не может быть пустым');
      }

      errors.leaderFio = [];
      if (!this.$v.clinicInfoEditData.legalPerson.leaderFio.required) {
        errors.leaderFio.push('Поле не может быть пустым');
      }

      errors.title = [];
      if (!this.$v.clinicInfoEditData.title.required) {
        errors.title.push('Поле не может быть пустым');
      }

      errors.appTitle = [];
      if (!this.$v.clinicInfoEditData.appTitle.required) {
        errors.appTitle.push('Поле не может быть пустым');
      }

      errors.network = [];
      if (!this.$v.clinicInfoEditData.clinicNetwork.required) {
        errors.network.push('Поле не может быть пустым');
      }

      errors.city = [];
      if (!this.$v.clinicInfoEditData.city.required) {
        errors.city.push('Поле не может быть пустым');
      }

      errors.priority = [];
      if (!this.$v.clinicInfoEditData.priority.required) {
        errors.priority.push('Поле не может быть пустым');
      }

      errors.contractNumber = [];
      if (!this.$v.clinicInfoEditData.contractNumber.required) {
        errors.contractNumber.push('Поле не может быть пустым');
      }

      errors.address = [];
      if (!this.$v.clinicInfoEditData.address.required) {
        errors.address.push('Поле не может быть пустым');
      }

      errors.authorization = { login: [], password: [] };

      if (!this.$v.clinicInfoEditData.authorization.login.required) {
        errors.authorization.login.push('Поле не может быть пустым');
      }

      if (!this.$v.clinicInfoEditData.authorization.password.required) {
        errors.authorization.password.push('Длина не менее 6 знаков');
      }

      return errors;
    },
    clinicLinkingErrors() {
      const errors = [];
      if (
        !this.clinicInfoEditData.isOmsPay
        && !this.clinicInfoEditData.isDmsPay
      ) {
        errors.push('Выберите хотя-бы одну');
      }
      if (errors.length) return errors;
      return false;
    },
    typesOfServicesErrors() {
      const errors = [];
      if (
        !this.clinicInfoEditData.isOms
        && !this.clinicInfoEditData.isDms
        && !this.clinicInfoEditData.isTelemed
      ) {
        errors.push('Выберите хотя-бы одну');
      }

      if (errors.length) return errors;
      return false;
    },
    signContractErrors() {
      const errors = [];
      if (
        !this.clinicInfoEditData.clinicAttributes
        || !this.clinicInfoEditData.clinicAttributes.length
      ) {
        errors.push('Выберите хотя-бы одну');
      }

      if (errors.length) return errors;
      return false;
    },
    isEdit: {
      get() {
        return this.$store.state.Clinic.isEditClinicInfo;
      },
      set(newVal) {
        this.$store.commit('Clinic/SET_CLINIC_INFO_IS_EDIT', newVal);
      },
    },
    clinicInfo() {
      const { info } = this.$store.state.Clinic.clinic;
      let clinicOffers = JSON.parse(JSON.stringify(info.clinicOffers));

      if (clinicOffers && clinicOffers.length) {
        clinicOffers = clinicOffers.sort((a, b) => a.type - b.type);
      }

      return { ...info, clinicOffers };
    },
    clinicPriority() {
      const index = this.clinicInfo.priority;
      const priority = this.clinicPriorities.find((p) => p.index === index);
      return priority ? priority.title : null;
    },
    previewSelectedAgencyAgreement() {
      if (!this.clinicInfo.clinicAttributes) return;

      return this.clinicInfo.clinicAttributes.some((item) => item === 0);
    },
    selectedAgencyAgreement() {
      if (!this.clinicInfoEditData.clinicAttributes) return;

      return this.clinicInfoEditData.clinicAttributes.some((item) => item === 0);
    },
  },
  watch: {
    isLoading() {
      if (!this.isLoading && this.isEdit) {
        this.editClinic();
      }
    },
    clinicInfo() {
      if (this.clinicInfo.legalPerson.id) this.newLegal = false;
      this.setEditClinic();
    },
    isEdit(e) {
      if (e) {
        this.clinicInfo.isOmsPay = false;
        this.clinicInfo.isDmsPay = true;
        this.clinicInfoEditData.isOmsPay = false;
        this.clinicInfoEditData.isDmsPay = true;
      }
    },
  },
  async created() {
    // !!! нужно ли это в фичи?
    if (this.checkRoleAccess(['ClinicAdministrator'])) this.isEdit = false;
    this.isLoading = true;
    try {
      this.networks = await this.getList();
      if (this.clinicInfo?.legalPerson) await this.fetchLegalPersons({ skip: 0, query: this.clinicInfo.legalPerson.name });
      this.cities = (await locationService.getCities({ query: '' })).sort((a, b) => a.name.localeCompare(b.name));
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }

    // подтягиваем midId из пропсов clinicInfoObj
    if (this.clinicInfoObj) {
      this.clinicMidId = this.clinicInfoObj.midId;
    }
  },
  beforeDestroy() {
    this.isEdit = false;
  },
  methods: {
    async getList() {
      try {
        const data = await clinicService.getNetworkList();
        return Array.isArray(data) ? data : [];
      } catch (err) {
        console.log(err);
        this.networks = [];
      }
    },
    openDownloadFileModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'AddClinicDownloadFileModal',
        props: {
          saveFileCallback: this.saveFileCallback,
          multiple: false,
          fileApi: 'File/clinicdocument/upload',
        },
      });
    },
    setNewLegalPerson() {
      this.clinicInfoEditData.legalPerson = {
        contacts: [
          {
            email: null,
            fio: null,
            id: 0,
            legalPersonId: 0,
            phoneNumber: null,
            position: null,
          },
        ],
        id: 0,
        inn: null,
        kpp: null,
        leaderFio: null,
        leaderPosition: null,
        name: null,
        ogrn: null,
        site: null,
        network: null,
      };
    },
    offerTitle(type) {
      return OFFERS.find((item) => (item.id === type))?.title;
    },
    async copyHashtag(hashtag) {
      try {
        await navigator.clipboard.writeText(hashtag.key);
        showCustomMessage('success', 'Хештег скопирован в буфер обмена', 'Вставьте хештег, куда вам удобно (ctrl+v)');
      } catch (error) {
        console.error(error);
        showCustomMessage('danger', 'Что-то пошло не так', error);
      }
    },
    documentType(row) {
      if (row.item.type === 1) {
        return 'Договор';
      }
      if (row.item.type === 3) {
        return 'Лицензия';
      }
      if (row.item.type === 4) {
        return 'Прайс';
      }
      if (row.item.type === 5) {
        return 'Скан';
      }
      return 'Учредительные документ';
    },
    async saveFile(row) {
      const { data: file } = await api.get(`File/clinicdocument/${row.item.fileId}`, { responseType: 'blob' });

      saveFile(file, { name: row.item.title });
    },
    openEditDocumentModal(row) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'EditClinicDocument',
        props: {
          currentDocumentInfo: row.item,
        },
      });
    },
    async deleteDocument(row) {
      const document = row.item;
      if (document.id) {
        await this.$store.dispatch(this.$types.CLINIC_DOCUMENTS_DELETE, {
          document,
          isFetch: false,
        });
        const clinicData = await this.getInfoOneClinic(this.clinicInfoEditData.id);
        this.$store.commit('Clinic/SET_ONE_CLINIC', {
          ...clinicData,
          info: clinicData,
        });
      }
    },
    async getInfoOneClinic(id) {
      try {
        return clinicService.getOne(id);
      } catch (err) {
        console.warn(err);
        showErrorCustomMessage('Не удалось получить информацию о клинике');
      }
    },
    openAddDocumentModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'AddClinicDocument',
        props: {
          clinicId: this.clinicInfoEditData.id,
        },
      });
    },
    addContact() {
      const newContact = {
        email: null,
        fio: null,
        comment: null,
        isMain: false,
        phoneNumber: null,
        position: null,
      };

      this.clinicInfoEditData.contacts = [...this.clinicInfoEditData.contacts, newContact];
    },
    addLegalPersonContact() {
      const newContact = {
        id: 0,
        legalPersonId: this.clinicInfoEditData.legalPerson.id,
        email: null,
        fio: null,
        phoneNumber: null,
        position: null,
      };

      this.clinicInfoEditData.legalPerson.contacts = [...this.clinicInfoEditData.legalPerson.contacts, newContact];
    },
    setWatcher() {
      this.$store.commit('Clinic/SET_IS_CHANGED_CLINIC_INFO', false);
      this.unwatch = this.$watch('clinicInfoEditData',
        () => {
          if (this.unwatch) {
            this.unwatch();
          }
          this.$store.commit('Clinic/SET_IS_CHANGED_CLINIC_INFO', true);
        },
        {
          deep: true,
        });
    },
    async fetchLegalPersons({ skip, take, query }) {
      const clinics = await clinicService.getLegalPersons({
        name: query,
        skip,
        take,
      });

      return clinics.filter((el) => el.isDeleted !== true);
    },
    setEditClinic() {
      const authorization = this.clinicInfo.authorization ? { ...this.clinicInfo.authorization } : { login: null, password: null };

      if (authorization.login) {
        this.hadAuthorization = true;
      }

      const newClinicInfoObject = JSON.parse(JSON.stringify(this.clinicInfo));

      if (!newClinicInfoObject.clinicOffers || !newClinicInfoObject.clinicOffers.length) {
        newClinicInfoObject.clinicOffers = OFFERS.map((item) => ({ type: item.id, title: item.title, text: '' }));
      }

      this.clinicInfoEditData = {
        ...newClinicInfoObject,
        agreementFiles: { ...newClinicInfoObject.agreementFiles },
        authorization,
        latitude: newClinicInfoObject.latitude ? newClinicInfoObject.latitude.toString() : null,
        longitude: newClinicInfoObject.longitude ? newClinicInfoObject.longitude.toString() : null,
        city: newClinicInfoObject.city
          ? { id: newClinicInfoObject.city.id, name: newClinicInfoObject.city.title } : null,
        // clinicNetwork: newClinicInfoObject.clinicNetwork
        //   ? { id: newClinicInfoObject.clinicNetwork.id, title: newClinicInfoObject.clinicNetwork.title } : null,
        contactsForIL: newClinicInfoObject.contactsForIL.map((e) => ({ email: e })),
        clinicAttributes: newClinicInfoObject.clinicAttributes ? newClinicInfoObject.clinicAttributes : [],
      };

      const { latitude, longitude } = this.clinicInfoEditData;
      this.automaticGeolocationSetting = !(latitude || longitude);
      this.clinicMidId = this.clinicInfoEditData.midId;

      // if (this.clinicInfoEditData.contacts.length === 0) this.addContact();
      // if (this.clinicInfoEditData.legalPerson.contacts.length === 0) this.addLegalPersonContact();
    },
    onChangeGeolocationSetting(value) {
      if (value) {
        const { latitude, longitude } = this.clinicInfoEditData;
        this.intermediateGeolocations = { latitude, longitude };
        this.$v.clinicInfoEditData.latitude.$model = null;
        this.$v.clinicInfoEditData.longitude.$model = null;
        return;
      }

      if (this.intermediateGeolocations) {
        const { latitude, longitude } = this.intermediateGeolocations;

        this.$v.clinicInfoEditData.latitude.$model = latitude;
        this.$v.clinicInfoEditData.longitude.$model = longitude;

        this.intermediateGeolocations = null;
      }
    },
    editClinic() {
      this.isEdit = true;

      this.setEditClinic();
      this.setWatcher();

      this.$v.$touch();
    },
    onClickCancel() {
      if (this.isChangedInfo) {
        if (!confirm('Несохраненная информация будет удалена, продолжить?')) {
          return;
        }
      }

      this.$store.commit('Clinic/SET_IS_CHANGED_CLINIC_INFO', false);
      this.isEdit = false;
      this.setEditClinic();
    },
    async toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    async onClickSave() {
      this.setWatcher();
      this.checkErrors = true;
      this.$v.$touch();

      if (
        this.$v.$error
        || this.clinicLinkingErrors
        || this.typesOfServicesErrors
        || this.signContractErrors
      ) {
        showValidationErrorMessage();
        return;
      }
      if (
        this.clinicInfoEditData.clinicAttributes.includes(1)
        && this.offersError
      ) {
        showValidationErrorMessage('"Оферта"');
        return;
      }

      // const clinicNetwork = this.newNetwork ? { id: 0, title: this.newNetworkName } : this.clinicInfoEditData.clinicNetwork;
      const contacts = this.clinicInfoEditData.contacts.map((c) => ({ ...c, email: c.email || null }));
      const contactsForIL = this.clinicInfoEditData.contactsForIL.map((c) => c.email);

      const preformattedClinicInfo = {
        ...this.clinicInfoEditData,
        appTitle: this.clinicInfoEditData.title,
        documents: this.clinicInfoEditData.documents.map((doc) => ({
          ...doc,
          status: doc.status?.value,
          subtype: doc.subtype?.value,
        })),
        // clinicNetwork,
        contacts,
        contactsForIL,
        midId: this.clinicMidId !== null ? Number(this.clinicMidId) : null,
      };

      delete preformattedClinicInfo.clinicNetwork;
      // delete preformattedClinicInfo.legalPerson;

      try {
        this.isSaving = true;

        let legalPerson = null;

        if (this.newLegal) {
          legalPerson = await this.createOrUpdateLegalPerson();
        } else {
          legalPerson = this.clinicInfoEditData.legalPerson;
          // if (this.isNeedUpdateLegarPerson) await this.createOrUpdateLegalPerson();
        }
        preformattedClinicInfo.legalPerson = legalPerson;

        // if (this.clinicInfo?.isAddedToDismonitoringOperator !== this.clinicInfoEditData.isAddedToDismonitoringOperator) {
        //   await this.clinicService.dismonitoringBindToggle({
        //     clinicId: this.clinicInfo.id,
        //     isBind: this.clinicInfoEditData.isAddedToDismonitoringOperator,
        //   });
        // }

        if (this.$store.state.Clinic.clinic.id) {
          const clinicInfo = await this.clinicHandler(preformattedClinicInfo, 'update');
          this.$store.commit('Clinic/SET_ONE_CLINIC', { ...clinicInfo, info: clinicInfo });
          this.isEdit = false;
          this.setEditClinic();
        } else {
          const clinicInfo = await this.clinicHandler(preformattedClinicInfo, 'create');
          this.$store.commit('Clinic/SET_ONE_CLINIC', { ...this.$store.state.Clinic.clinic, info: clinicInfo });
          this.isEdit = false;
          this.setEditClinic();
        }
      } catch (error) {
        const message = error.response.data;
        showErrorCustomMessage(message);
        console.error(error);
      } finally {
        this.isSaving = false;
      }
    },
    async clinicHandler(obj, value) {
      try {
        if (value === 'create') {
          const data = await clinicService.create(obj);
          showSuccessCustomMessage('Клиника успешно добавлена!');
          return data;
        }
        if (value === 'update') {
          const data = await clinicService.update(obj);
          showSuccessCustomMessage('Клиника успешно изменена!');
          return data;
        }
      } catch (err) {
        if (value === 'create') {
          console.warn(err);
          showErrorCustomMessage(err.response?.data);
          throw new Error(err);
        }
        console.warn(err);
        showErrorCustomMessage(err.response?.data);
        throw new Error(err);
      }
    },
    async createOrUpdateLegalPerson() {
      const legalPersonParam = {
        ...this.clinicInfoEditData.legalPerson,
        network: this.getActualNetworkForLegalPerson,
      };
      const action = this.newLegal ? this.$types.LEGAL_ENTITIES_CREATE : this.$types.LEGAL_ENTITIES_UPDATE;
      try {
        const { data } = await this.$store.dispatch(action, legalPersonParam);
        return data;
      } catch {
        return null;
      }
    },
    onClickOpenFileModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'FileModal',
        props: {
          saveFileCallback: this.saveFileCallback,
          multiple: true,
        },
      });
    },
    addEmail() {
      this.clinicInfoEditData.contactsForIL.push({ email: '' });
    },
    deleteEmail(index) {
      this.clinicInfoEditData.contactsForIL.splice(index, 1);
    },
    deleteContact(index) {
      this.clinicInfoEditData.contacts.splice(index, 1);
    },
    deleteLegalPersonContact(index) {
      this.clinicInfoEditData.legalPerson.contacts.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.base-input{
  display: flex;
  flex-direction: column;
}
.crm-title-row {
  display: flex;
  justify-content: space-between;
}

.clinic-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-right: 30px;
  margin-bottom: 30px;
}

::v-deep.edit-btn {
  border-radius: 5px;
  cursor: pointer;

  &.active {
    background-color: $blue;

    path {
      fill: white;
    }
  }
}

.clinic-info {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  margin-bottom: 1px;
}

.crm-form-block {
  width: 100%;
}

// eslint-disable-next-line vue-scoped-css/no-unused-selector
.crm-info {
  &-title {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 10px 0;
    color: #707070;

    &.flex {
      display: flex;
    }
  }

  &-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    & + & {
      margin-top: 10px;
    }

    &.contact {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }

    a {
      color: $blue;
      text-decoration: none;
    }
  }
}

.crm-form-grid{
  display: grid;
  grid-template-columns: 25% 25% 25% 1fr;
}

.crm-form-field {
  &-left {
    grid-area: 1/1/1/1
  }
  &-right {
    grid-area: 1/2/1/2
  }
  .divide-line {
    align-self: center;
    height: 1px;
    width: 20px;
    background-color: #323232;
    flex-shrink: 0;
    margin: 0 20px;
  }

  .crm-label {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 10px 0;
    color: #707070;
  }
}
:deep(.comment .crm-label) {
  margin-bottom: 8px;
}
.label-address {
  margin-bottom: 11px;
}
::v-deep.coord-input {
  .crm-wrap-input.with-prefix {
    .crm-base-input {
      padding-left: 40px !important;
    }
  }
}

::v-deep.crm-icon-cross {
  margin-right: 15px;
  path {
    fill: $blue;
  }
}

::v-deep.crm-icon-eye {
  cursor: pointer;

  &:hover,
  &.active {
    path {
      fill: $blue;
    }
  }
}

.line-title {
  position: relative;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #979797;
  margin-bottom: 30px;

  &::before {
    position: absolute;
    content: '';
    display: block;
    height: 1px;
    background: #E0E0E0;
    top: calc(50% + 1px);
    left: 0;
    right: 0;
    z-index: 0;
  }

  &-inner {
    position: relative;
    z-index: 1;
    background-color: #fff;
    padding-right: 20px;
    display: inline-block;
  }
}

.clinics-form-fields-grid {
  display: grid;
  grid-template-columns: 28% 72%;
}

.clinics-form-fields + .line-title {
  margin-top: 50px;
}
.crm-error-text {
  color: $red;
  font-size: 12px;
  font-weight: 500;
  margin-top: 0px;
}

::v-deep.crm-radio-input:checked + .crm-radio-text {
  color: $blue;
}

.crm-radio-buttons_vertical {
  flex-direction: column;
  margin-bottom: 15px;

  .crm-radio-button {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

::v-deep.person-select {
  .vs__dropdown-toggle {
    width: 100%;
  }
  .vs__selected {
    padding-left: 8px;
  }
}

::v-deep.crm-form-input {
  &-prefix {
    align-self: center;
    margin-right: 10px;

    &.crm-bold {
      font-weight: 500;
    }
  }

  &-postfix {
    align-self: center;
    margin-left: 10px;

    &.crm-bold {
      font-weight: 500;
    }
  }

  & + .crm-form-input {
    margin-left: 20px;
  }
}

.button-link {
    text-decoration: none;
    margin-left: -10px;
    &.btn:focus, .btn.focus {
    box-shadow: none;
  }
  }

.add-contact {
    margin-top: -20px;
  }

.crm-form-field-email {
  display: flex;

  .crm-form-email-delete {
    margin-left: 10px;
    margin-top: 5px;
  }
}

::v-deep.crm-form-field-email + .crm-form-field-email {
  margin-top: 10px;
}

.crm-form-checkbox-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: -10px -15px 0;
  column-gap: 16px;
  .crm-form-checkbox {
    margin: 5px 10px;
  }
}

.crm-form-checkbox-text {
  font-size: 14px;
  line-height: 18px;
}

.clinics-form-fields .crm-form-field:not(:last-child) {
  margin-right: 20px;
}

.crm-icon-delete {
  cursor: pointer;
}

.contact-delete {
  margin-left: 10px;
}

.contact-legal-delete {
  align-self: flex-end;
  margin-left: -10px;
  margin-bottom: 5px;
}

.service-list {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;

  .service-item {
    margin: 5px;
    padding: 10px 15px;
    font-size: 16px;
    line-height: 20px;
    background: #F3F3F3;
    border-radius: 20px;
  }
}

.crm-info-text--special {
  white-space: pre-line;
}

.editor-item {
  margin-bottom: 50px;
}

.editor-container {
  position: relative;
  transform: translate(0, 39px);
}

.editor-hashtags {
  position: absolute;
  width: 100%;
  padding: 6px;
  height: 40px;
  top: 8px;
  left: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid #ccc;

  &__item {
    padding: 3px 6px;
    font-size: 13px;
    border: 1px solid #ccc;
    cursor: pointer;
    transition: .15s;

    &:hover {
      border: 1px solid #133bd3;
    }

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}
.validation-error-text{
  font-size: 12px;
  color: red;
}
::v-deep .ql-toolbar {
  transform: translate(0, -39px);
}
.document-btn{
  width: 120px;
}
.site-info{
  word-break: break-word;
}
</style>
