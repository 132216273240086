<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    title="Переоткрытие консультации"
    size="md"
    header-class="p-2"
    footer-class="p-2"
    @hidden="onClose"
    @ok="onClose"
  >
    <div class="py-3">
      Вы действительно хотите переоткрыть консультацию?
    </div>
    <template #modal-footer>
      <b-button
        variant="outline-danger"
        :type="$const.PRIMARY_BUTTON"
        @click="onClose"
      >
        Нет
      </b-button>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        class="ml-2"
        @click="consultationReopen"
      >
        Да
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Bus from '@/eventBus';

export default {
  name: 'ConsultationReopeningModal',
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    consultationId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      isOpen: true,
      isReopening: false,
    };
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async consultationReopen() {
      try {
        this.isReopening = true;
        await this.$store.dispatch(this.$types.CONSULATION_REOPEN, this.consultationId);
        Bus.$emit('consultations:update', this.consultationId);
        this.onClose();
      } finally {
        this.isReopening = false;
      }
    },
  },
};
</script>
