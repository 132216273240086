<template>
  <b-modal
    v-model="isOpen"
    centered
    hide-footer
    no-close-on-backdrop
    title="Восстановление пациента"
    @hidden="onClose"
  >
    <div
      class="crm-container patient-container"
    >
      <div class="description">
        Вы уверены, что хотите восстановить пациента?
      </div>
      <div class="crm-wrapper-buttons">
        <b-button
          variant="outline-primary"
          :type="$const.PRIMARY_BUTTON"
          class="crm-button"
          @click="onClose"
        >
          Отмена
        </b-button>

        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          :loading="isLoading"
          class="crm-button"
          @click="onClickReestablish"
        >
          Восстановить
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mixinRoles } from '@/mixins';
import Bus from '@/eventBus';

export default {
  name: 'PatientReestablishModal',
  components: {
  },
  mixins: [mixinRoles],
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
    };
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onClickReestablish() {
      this.isLoading = true;

      try {
        await this.$store.dispatch(this.$types.PATIENT_REESTABLISH, this.id);

        Bus.$emit('patients:update');
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
        this.onClose();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.patient-container {
  flex-direction: column;
  align-items: center;
}
.crm-wrapper-buttons {
  margin-top: 35px;
}
.description {
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
}
</style>
