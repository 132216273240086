<template>
  <b-modal
    v-model="isOpen"
    centered
    hide-footer
    no-close-on-backdrop
    title="Активация полиса"
    @hidden="onClose"
  >
    <div class="policy-activate">
      <custom-scrollbar>
        <div
          class="crm-container policy-container"
        >
          <div class="description">
            Вы уверены, что хотите активировать полис?
          </div>
          <div class="crm-wrapper-buttons">
            <b-button
              variant="outline-primary"
              :type="$const.PRIMARY_BUTTON"
              class="crm-button"
              @click="onClose"
            >
              Отменить
            </b-button>
            <b-button
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              :loading="isActivating"
              class="crm-button"
              @click="onClickActivate"
            >
              Активировать
            </b-button>
          </div>
        </div>
      </custom-scrollbar>
    </div>
  </b-modal>
</template>

<script>
import { mixinRoles } from '@/mixins';
import Bus from '@/eventBus';

export default {
  name: 'PolicyActivateModal',
  components: {
  },
  mixins: [mixinRoles],
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    policy: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isActivating: false,
    };
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onClickActivate() {
      this.isActivating = true;

      try {
        await this.$store.dispatch(this.$types.PATIENT_POLICY_ACTIVATE, this.policy);

        Bus.$emit('patient:update');
        Bus.$emit('patients:update');
      } catch (e) {
        console.log(e);
      } finally {
        this.isActivating = false;
        this.onClose();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.policy-container {
  flex-direction: column;
  align-items: center;
}
.crm-wrapper-buttons {
  margin-top: 35px;
}
.description {
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
}
</style>
