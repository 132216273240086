import { APP_HOST } from '@/app.config';

export default class ChatApi {
  constructor($axios) {
    this.api = $axios.instance;
  }

  async saveFile(formData) {
    const data = await this.api.post(`${APP_HOST}File/fileServer`, formData);
    return data;
  }
}
