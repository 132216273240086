import mutations from './mutations';
import getters from './getters';
import actions from './actions';

export default {
  namespaced: true,
  state: {
    users: null,
    isLoading: false,
    fetchUsersTrigger: false,
  },
  actions,
  getters,
  mutations,
};
