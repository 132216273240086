<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    :title="cityId ? 'Редактирование города' : 'Создание нового города'"
    :hide-footer="isLoading"
    @hidden="onClose"
    @ok="onClose"
  >
    <b-overlay
      v-if="isLoading"
      :show="isLoading"
      variant="transparent"
      rounded="lg"
      style="height: 10rem"
      opacity="0.6"
    />

    <template v-else>
      <b-row>
        <b-col>
          <b-form-group
            label="Полное название города"
          >
            <b-form-input
              v-model="cityData.fullName"
              type="text"
              :placeholder="'Введите полное название города'"
              trim
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Название города"
            label-class="required"
          >
            <b-form-input
              v-model="cityData.name"
              type="text"
              :placeholder="'Введите название города'"
              trim
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Долгота"
          >
            <b-form-input
              v-model="cityData.longitude"
              type="text"
              :placeholder="'Введите долготу'"
              trim
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Широта"
          >
            <b-form-input
              v-model="cityData.latitude"
              type="text"
              :placeholder="'Введите широту'"
              trim
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>

    <template #modal-footer>
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        :disabled="isLoading"
        @click="onEditCity"
      >
        Изменить город
      </b-button>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        class="float-right ml-2"
        :disabled="isLoading"
        @click="onClose"
      >
        Отмена
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { showCustomMessage } from '@/helpers/messages';

export default {
  name: 'CityEditModal',
  components: {},
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    cityId: {
      type: [String, Number],
      default: null,
    },
    afterSave: {
      type: [Function],
      default: () => {},
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      cityData: {
        name: '',
        fullName: '',
        longitude: null,
        latitude: null,
      },
    };
  },
  async created() {
    await this.fetchCityInfo();
  },
  methods: {
    async onEditCity() {
      if (!this.cityData.name) {
        showCustomMessage('warning', 'Ошибка валидации', 'Заполните обязательные поля');
        return;
      }
      await this.$store.dispatch(this.$types.CITY_UPDATE, this.cityData);
      await this.afterSave();
      this.onClose();
    },
    async fetchCityInfo() {
      this.isLoading = true;
      const data = await this.$store.dispatch(this.$types.GET_LABORATORY_CITY_FOR_ID, this.cityId);
      this.cityData = data;
      this.isLoading = false;
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
  },
};
</script>

<style lang="scss" scoped>
.passwordBtn:focus {
  box-shadow: none;
}
</style>
