// eslint-disable-next-line
export const MODALS = {
  PATIENT_CREATE_SELECT_MODE: () => import('@/components/domains/patient/PatientCreateSelectModeModal.vue'),
  PATIENT_CREATE: () => import('@/components/domains/patient/PatientCreateModal.vue'),
  STORY_COVER_ADD_MODAL: () => import('@/components/domains/stories/StoryCoverAddModal.vue'),
  STORY_DELETE_MODAL: () => import('@/components/domains/stories/StoryDeleteModal.vue'),
  DOCTOR_EDIT_MODAL: () => import('@/components/domains/doctors/DoctorEditModal.vue'),
  DOCTOR_SCHEDULE_INFO_MODAL: () => import('@/components/domains/doctors/DoctorScheduleInfoModal.vue'),
  DOCTOR_HISTORY_OF_CHANGES_MODAL: () => import('@/components/domains/doctors/DoctorHistoryOfChangesModal.vue'),
  INSURANCE_SUB_PROGRAM_DEACTIVATE_MODAL: () => import('@/components/domains/insurance/InsuranceSubProgramDeactivateModal.vue'),
  PATIENT_TRANSFER_MODAL: () => import('@/components/domains/patient/PatientTransferModal.vue'),
  PATIENT_SERVICES_MODAL: () => import('@/components/domains/patient/PatientServicesModal.vue'),
  // Policies
  COMBINE_POLICIES_MODAL: () => import('@/components/domains/policies/CombinePoliciesModal.vue'),
  POLICY_DETACH_MODAL: () => import('@/components/domains/policies/PolicyDetachModal.vue'),
  POLICY_DETACH_DOP_MODAL: () => import('@/components/domains/policies/PolicyDetachDopModal.vue'),
  POLICY_CREATE_MODAL: () => import('@/components/domains/policies/PolicyCreateModal.vue'),
  // Appeals
  APPEAL_TEMPLATE_MODAL: () => import('@/components/domains/appeals/AppealTemplatesModal/index.vue'),
  APPEAL_TEMPLATE_CHAT_MODAL: () => import('@/components/domains/appeals/AppealTemplatesChatModal/index.vue'),
  APPEAL_TEMPLATE_EDIT_CHAT_MODAL: () => import('@/components/domains/appeals/AppealTemplatesEditChatModal/index.vue'),
  APPEAL_TEMPLATE_EDIT_MODAL: () => import('@/components/domains/appeals/AppealTemplateEditModal/index.vue'),
  APPEAL_BEFORE_CLOSING_MODAL: () => import('@/components/domains/appeals/CreatingAppealBeforeClosingModal.vue'),
  APPEAL_PATIENT_CHAT_MODAL: () => import('@/components/domains/appeals/PatientAppealChatModal.vue'),
  APPEAL_HISTORY_MODAL: () => import('@/components/domains/appeals/AppealHistoryModal.vue'),
  APPEAL_EXPORT_MODAL: () => import('@/components/domains/appeals/AppealsExportModal.vue'),
  APPEAL_EDITOR_MODAL: () => import('@/components/domains/appeals/AppealEditorModal.vue'),
  APPEAL_EDITOR_NEW_MODAL: () => import('@/components/domains/appeals/AppealEditorModalNew/index.vue'),
  PATIENT_APPEALS_MODAL: () => import('@/components/domains/appeals/PatientAppealsModal.vue'),
  // Accept
  AGREE_MODAL: () => import('@/components/domains/AgreeModal.vue'),
  // CHAT
  CHAT_MODAL: () => import('@/components/domains/chat/ChatModal.vue'),
  CHAT_APPEAL_DELETE_MODAL: () => import('@/components/domains/chat/ChatAppealDeleteModal.vue'),
  CHAT_CONSULTATION_MODAL: () => import('@/components/domains/chat/ConsultationChatModal.vue'),
  MESSAGE_EDIT_MODAL: () => import('@/components/Chat/Modals/MessageEditModal'),

  // PHONE
  CHECK_PHONE_NUMBER_MODAL: () => import('@/components/domains/phone/CheckPhoneNumberModal.vue'),
  // Files upload
  FILES_UPLOAD_MODAL: () => import('@/components/domains/FilesUploadModal.vue'),
  // Appointement
  PATIENT_APPOINTMENT_MODAL: () => import('@/components/domains/patient/PatientAppointmentModal.vue'),
  // Clinics
  CLINIC_HISTORY_MODAL: () => import('@/components/domains/clinics/ClinicHistoryModal.vue'),
  // Regions
  REGION_EDIT_MODAL: () => import('@/components/domains/regions/RegionEditModal.vue'),
  // Consultations
  CONSULTAION_TRANSFER_MODAL: () => import('@/components/domains/consultations/ConsultationTransferModal.vue'),
  // Protocol
  PROTOCOLS_MODAL: () => import('@/components/domains/consultations/ProtocolsModal.vue'),
};
