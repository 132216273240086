<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    title="История переоткрытий консультации для изменения протокола"
    size="xl"
    hide-footer
    @hidden="onClose"
    @ok="onClose"
  >
    <b-overlay
      :show="isLoading"
      no-wrap
      spinner-variant="primary"
      opacity="0.6"
    />

    <div
      v-if="!isLoading"
      class="consultation-wrapper"
    >
      <div
        v-if="historyConsultations.length > 0"
      >
        <ConsultationReopenInfo
          v-for="(consultation, index) in historyConsultations"
          :key="index"
          :consultation="consultation"
        />
      </div>

      <div
        v-else
        class="w-100 bg-white shadow-sm rounded p-4 font-weight-bold"
      >
        Нет данных
      </div>
    </div>
  </b-modal>
</template>

<script>
import ConsultationReopenInfo from '@/components/Consultations/ConsultationReopenInfo';

export default {
  name: 'HistoryConsultationReopeningModal',
  components: {
    ConsultationReopenInfo,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    consultationId: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      historyConsultations: [],
    };
  },
  async created() {
    this.isLoading = true;
    try {
      const data = await this.$store.dispatch(this.$types.CONSULTATION_REOPEN_HISTORY_FETCH, this.consultationId);
      this.historyConsultations = data.map((item) => ({
        ...item,
        protocolVersion: JSON.parse(item.protocolVersion),
      }));
      console.log(this.historyConsultations);
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
  },
};
</script>

<style scoped>
.consultation-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - 500px);
  max-height: calc(100vh - 160px);
  padding: 0 15px;
}
</style>
