import NProgress from 'nprogress/nprogress';

import * as types from '@/store/types';
import { removeNamespaces, showMessage } from '@/helpers/utils';
import { api } from '@/helpers/api';

const localTypes = removeNamespaces(types);

export default {
  async [localTypes.ROLES_FETCH]() {
    NProgress.start();

    try {
      const { data } = await api.get('v2/account/roles');

      return data;
    } catch (e) {
      if (e.response.status !== 401) {
        showMessage({
          type: 'error',
          title: 'Ошибка',
          message: 'Не удалось получить роли',
        });
      }

      console.warn(e);

      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.ROLE_CREATE](_, role) {
    NProgress.start();

    try {
      await api.post(`v2/account/role?name=${role.name}`, role.pageIds);
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось создать роль',
      });

      throw (e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.ROLE_UPDATE](_, role) {
    NProgress.start();

    try {
      await api.put('v2/account/role', role);
    } catch (e) {
      console.warn(e);
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось обновить роль',
      });

      throw (e);
    } finally {
      NProgress.done();
    }
  },
};
