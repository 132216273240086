import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.USERS_SET](state, payload) {
    state.users = payload;
  },
  [localTypes.IS_LOADING_FALSE](state) {
    state.isLoading = false;
  },
  [localTypes.IS_LOADING_TRUE](state) {
    state.isLoading = true;
  },
  [localTypes.TOGGLE_FETCH_USERS_TRIGGER](state) {
    state.fetchUsersTrigger = !state.fetchUsersTrigger;
  },
};
