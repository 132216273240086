import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.POLICY_SET](state, payload) {
    state.policyList = payload;
  },
  [localTypes.POLICY_IS_LOADING](state, payload) {
    state.isLoading = payload.isLoading ?? false;
    state.isLoadingButton = payload.isLoadingButton ?? false;
  },
  [localTypes.TOGGLE_POLICY_SEARCH_TRIGGER](state) {
    state.policySearchTrigger = !state.policySearchTrigger;
  },
};
