<template>
  <b-modal
    v-model="isOpen"
    centered
    size="s"
    hide-footer
    title="Деактивация программы"
    @hidden="onClose"
  >
    <template v-if="isDeactivationState">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-if="isSelectDeactivationReasonState">
      <span class="d-block mb-2"><b>Причина деактивации</b></span>
      <div class="crm-wrapper-radio">
        <base-radio-button
          v-model="deactivationReason"
          :name="0"
        >
          Программа не продается<br>
          (обследование продолжается)
        </base-radio-button>

        <base-radio-button
          v-model="deactivationReason"
          :name="1"
        >
          Программа не активна<br>
          (все полисы деактивированы)
        </base-radio-button>
      </div>
      <div class="crm-container-buttons">
        <b-button
          variant="outline-danger"
          :type="$const.PRIMARY_BUTTON"
          class="button-import"
          @click="startDeactivation"
        >
          <span>Деактивировать</span>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BaseRadioButton,
} from '@/components/base';
import Preloader from '@/components/Preloader';
import { api } from '@/helpers/api';

export default {
  name: 'InsuranceProgramDeactivateModal',
  components: {
    BaseRadioButton,
    Preloader,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    program: {
      type: Object,
      default: () => {},
    },
    deactivationCallback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isOpen: true,
      isDeactivationState: false,
      isSelectDeactivationReasonState: true,
      deactivationReason: 0,
    };
  },
  methods: {
    clearState() {
      this.isDeactivationState = false;
      this.isSelectDeactivationReasonState = false;
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async startDeactivation() {
      try {
        this.clearState();
        this.isDeactivationState = true;
        await api.put(`v2/insuranceCompanies/deactivationProgram?programId=${this.program.id}&reasonDeactivation=${this.deactivationReason}`);
        this.deactivationCallback();
        this.onClose();
      } catch (e) {
        this.clearState();
        this.isSelectDeactivationReasonState = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .crm-container-buttons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
  }

  .crm-wrapper-radio {
    & label {
      margin: 16px 0px;
    }
  }

  .info-text, .additional-text {
    color: #908F8D;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
  }
  .additional-text {
    font-size: 12px;
    line-height: 16px;
  }
</style>
