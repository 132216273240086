import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.POLICY_IS_LOADING_GET](state) {
    return state.isLoading;
  },
  [localTypes.POLICY_IS_LOADING_BUTTON_GET](state) {
    return state.isLoadingButton;
  },
  [localTypes.POLICY_SEARCH_TRIGGER](state) {
    return state.policySearchTrigger;
  },
};
