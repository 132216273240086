<template>
  <b-modal
    v-model="isOpen"
    centered
    :title="title"
    @hidden="onClose"
    @ok="onClose"
  >
    <p>
      {{ text }}
    </p>

    <template #modal-footer>
      <b-button
        class="float-right"
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isLoading || isSaving"
        @click="onClose"
      >
        Нет
      </b-button>

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        :disabled="isSaving"
        @click="onSave"
      >
        Да
        <b-spinner
          v-if="isSaving"
          variant="light"
          small
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'AgreeModal',

  props: {
    text: {
      type: String,
      default: 'Вы уверены?',
    },
    title: {
      type: String,
      default: '',
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
    cb: {
      type: Function,
      default: () => {},
    },
    cbArgs: {
      type: Array,
      default: () => ([]),
    },
    closeAnotherModalName: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    isOpen: true,
    isLoading: false,
    isSaving: false,
  }),

  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onSave() {
      this.isSaving = true;

      try {
        await this.cb(...this.cbArgs);

        if (this.closeAnotherModalName) {
          this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.closeAnotherModalName });
        }
      } finally {
        this.onClose();
        this.isSaving = false;
      }
    },
  },
};
</script>

<style scoped>

</style>
