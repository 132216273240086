import axios from 'axios';
// eslint-disable-next-line import/no-extraneous-dependencies
import qs from 'qs';
import store from '@/store';
import NProgress from 'nprogress/nprogress';
import { INTERNAL_ONLY_ERROR } from '@/services/auth/auth.const';
// eslint-disable-next-line
import { APP_HOST, setInternalHost, setInternalSocket } from '@/app.config';
import { LOGOUT } from '@/store/types';
import { setInternalHostOld } from '@/helpers/api';

class AxiosClient {
  constructor($axios) {
    this.instance = $axios;

    this.instance.interceptors.request.use((config) => {
      NProgress.start();
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${store.state.Auth.token}`,
        },
      };
    });

    this.instance.interceptors.response.use((response) => {
      NProgress.done();
      return response;
    }, async (error) => {
      if (error.response.status === 401) {
        await store.dispatch(LOGOUT);
      }

      if (error.response?.data?.code === INTERNAL_ONLY_ERROR) {
        setInternalHost();
        setInternalSocket();
        setInternalHostOld();

        localStorage.setItem('internal', 'true');
        // eslint-disable-next-line no-use-before-define
        return axiosClient.instance.post(`${APP_HOST}account/login/`, JSON.parse(error.config.data));

        // eslint-disable-next-line no-use-before-define
        // await axiosClient.instance({ ...originalRequest, url: originalRequest.url.replace(APP_HOST, APP_HOST_INTERNAL) });
      }

      NProgress.done();
      throw error;
    });
  }
}

const axiosClient = new AxiosClient(axios.create({
  paramsSerializer: (params) => qs.stringify(params, { skipNulls: true, skipEmptyString: true, allowEmptyArrays: false }),
}));
export default axiosClient;
