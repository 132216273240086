/* eslint-disable no-restricted-syntax */
import NProgress from 'nprogress/nprogress';

import * as types from '@/store/types';
import { removeNamespaces, showMessage /* , appendFormData */ } from '@/helpers/utils';
import { api } from '@/helpers/api';

import {
  createDocumentInstance,
} from '@/components/Clinics/clinicUtils';

const localTypes = removeNamespaces(types);

export default {
  async [localTypes.CLINIC_DOCUMENTS_FETCH]({ commit, state: { clinicId } }) {
    NProgress.start();

    commit(localTypes.CLINIC_DOCUMENTS_LOADING_SET, true);
    try {
      const params = new URLSearchParams();

      params.append('clinicId', clinicId);

      const { data: documents } = await api.get('v2/clinic/documents', {
        params,
      });
      const formattedDocuments = documents.map((doc) => createDocumentInstance({
        ...doc,
        subtype: doc.subtype.value,
        status: doc.status.value,
      }));

      commit(localTypes.CLINIC_DOCUMENTS_SET, formattedDocuments);

      NProgress.done();
      return documents;
    } catch (e) {
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить список документов',
      });
    } finally {
      commit(localTypes.CLINIC_DOCUMENTS_LOADING_SET, false);
      NProgress.done();
    }
  },
  async [localTypes.CLINIC_DOCUMENTS_CREATE](_, document) {
    NProgress.start();

    try {
      await api.post('v2/clinic/document', document);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Документ успешно добавлен!',
      });

      // dispatch(localTypes.CLINIC_DOCUMENTS_FETCH);
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.CLINIC_DOCUMENTS_UPDATE](_, document) {
    NProgress.start();

    try {
      await api.put('v2/clinic/document', document);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Документ успешно изменен!',
      });

      // dispatch(localTypes.CLINIC_DOCUMENTS_FETCH);
    } catch (e) {
      console.warn(e);
      showMessage({
        type: 'error',
        title: 'error',
        message: `${e}`,
      });
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.CLINIC_DOCUMENTS_DELETE]({ dispatch }, {
    document,
    isFetch,
  }) {
    NProgress.start();

    try {
      await api.delete('v2/clinic/document', { params: { documentId: document.id || document.fileId } });

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Документ успешно удален!',
      });

      if (isFetch) {
        dispatch(localTypes.CLINIC_DOCUMENTS_FETCH);
      }
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
};
