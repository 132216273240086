// import Bus from '@/eventBus';

// import { BaseModalHeader } from '@/components/base';

// import ClinicDocumentEdit from '@/components/Clinics/Modals/ClinicDocumentEdit';

// const CLOSE_ON_BACKDROP = false;
// const SHOW_CLOSE_BUTTON = true;
// eslint-disable-next-line import/prefer-default-export
export const mixinModals = {
  created() {
    // Bus.$on('open-modal:clinic-document-edit', this.openClinicDocumentEdit);
  },
  beforeDestroy() {
    // Bus.$off('open-modal:clinic-document-edit', this.openClinicDocumentEdit);
  },
  methods: {

    // openClinicDocumentEdit({ document }) {
    //   this.$vuedals.open({
    //     header: {
    //       component: BaseModalHeader,
    //       props: {
    //         title: 'Редактирование документа',
    //         closeButton: SHOW_CLOSE_BUTTON,
    //       },
    //     },
    //     props: {
    //       documentProp: document,
    //     },
    //     component: ClinicDocumentEdit,
    //     closeOnBackdrop: CLOSE_ON_BACKDROP,
    //     size: 'xs',
    //   });
    // },

  },
};
