import { render, staticRenderFns } from "./ClinicPhotoEdit.vue?vue&type=template&id=7fefa57c&scoped=true"
import script from "./ClinicPhotoEdit.vue?vue&type=script&lang=js"
export * from "./ClinicPhotoEdit.vue?vue&type=script&lang=js"
import style0 from "./ClinicPhotoEdit.vue?vue&type=style&index=0&id=7fefa57c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fefa57c",
  null
  
)

export default component.exports