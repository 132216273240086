<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="s"
    :title="'Изменение партнера'"
    @hidden="onClose"
    @ok="onClose"
  >
    <b-overlay
      v-if="isLoading"
      :show="isLoading"
      variant="transparent"
      rounded="lg"
      style="height: 10rem"
      opacity="0.6"
    />
    <div v-else>
      <b-row>
        <b-col>
          <b-form-group
            label="Имя"
          >
            <b-form-input
              v-model="partnerData.name"
              type="text"
              :placeholder="'Введите имя'"
              trim
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Номер телефона"
          >
            <b-form-input
              v-model="partnerData.contactPhoneNumber"
              type="text"
              :placeholder="'Введите номер телефона'"
              trim
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Активирован">
            <b-form-select
              v-model="partnerData.isActive"
              :options="optionsActivate"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="d-flex">
          <b-form-file
            v-model="partnerLogo"
            placeholder="Выберите файл"
            @input="updateLogo"
          />
          <b-button
            variant="danger"
            :type="$const.PRIMARY_BUTTON"
            class="w-75 ml-2"
            :disabled="!partnerLogo"
            @click="onDeleteLogo"
          >
            Удалить логотип
          </b-button>
        </b-col>
      </b-row>
      <b-row
        v-if="partnerLogo"
        class="mt-3"
      >
        <b-col>
          <b-img
            thumbnail
            fluid
            :src="partnerLogoSrc"
            alt="Не удалось загрузить файл"
          />
        </b-col>
      </b-row>
    </div>
    <template #modal-footer>
      <div>
        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          @click="onClickUpdatePartner"
        >
          Изменить
        </b-button>
        <b-button
          variant="danger"
          :type="$const.PRIMARY_BUTTON"
          class="ml-2"
          @click="onClose"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { addHours, format, parseISO } from '@evd3v/date-fns';

export default {
  name: 'EditPartnerModal',
  components: {},
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    partnerId: {
      type: [String, Number],
      default: null,
    },
    params: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      isLogoRemoved: false,
      isLogoUpdated: false,
      userData: {},
      rows: [],
      name: '',
      isActive: null,
      partnerData: {
        name: null, isActive: null, logo: null, contactPhoneNumber: null,
      },
      partnerLogo: null,
      partnerLogoSrc: null,
      optionsActivate: [
        { value: null, text: 'Выберите состояние', disabled: true },
        { value: true, text: 'Активирован' },
        { value: false, text: 'Деактивирован' },
      ],
      // modalName: 'PricesImportInClinic',
    };
  },
  async created() {
    await this.fetchPartnerInfo();
  },
  methods: {
    async onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async fetchPartnerInfo() {
      this.isLoading = true;
      const { data } = await this.$store.dispatch(this.$types.GET_LABORATORY_PARTNER_FOR_ID, this.partnerId);
      this.partnerData = data;
      this.partnerLogo = data.logo;
      this.partnerLogoSrc = data.logo;
      this.isLoading = false;
    },
    async onClickUpdatePartner() {
      const params = {
        id: this.partnerData.id,
        name: this.partnerData.name,
        contactPhoneNumber: this.partnerData.contactPhoneNumber,
        isActive: this.partnerData.isActive,
      };

      if (this.isLogoRemoved) {
        await this.$store.dispatch(this.$types.LABORATORY_PARTNER_DELETE_LOGO, {
          id: this.partnerId,
        });
      }

      if (this.isLogoUpdated) {
        await this.$store.dispatch(this.$types.LABORATORY_PARTNER_UPLOAD_LOGO, {
          id: this.partnerId,
          logo: this.partnerLogo,
        });
      }

      await this.$store.dispatch(this.$types.LABORATORY_PARTNER_UPDATE, params);
      await this.$store.dispatch(this.$types.GET_LABORATORY_PARTNERS, this.params);
      this.onClose();
    },
    formatDate(date) {
      const dateAdded = addHours(parseISO(date), 3);
      return format(dateAdded, 'dd.MM.yyyy HH:mm');
    },
    getUploadedImage() {
      const file = this.partnerLogo;

      if (file) {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.partnerLogoSrc = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        this.partnerLogoSrc = null;
      }
    },
    async updateLogo() {
      this.isLogoRemoved = false;
      this.isLogoUpdated = true;
      this.getUploadedImage();
    },
    async onDeleteLogo() {
      this.isLogoRemoved = true;
      this.isLogoUpdated = false;
      this.partnerLogo = null;
      this.partnerData.logo = null;
      this.partnerLogoSrc = null;
    },
  },
};
</script>

<style lang="scss" scoped>

.crm-table {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  &-row {
    display: flex;

    &--header {
      background: #F3F3F3;
      color: #6E88F3;
    }

    & + & {
      border-top: 1px solid #E0E0E0;
    }
  }

  &-cell {
    padding: 10px;
    width: 100%;

    &--date {
      flex-shrink: 0;
      width: 150px;
    }

    & + & {
      border-left: 1px solid #E0E0E0;
    }
  }
}

</style>
