import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.USER_INFO_GET](state) {
    return state.user;
  },
  // pagesString(state) {
  //   let b = [];
  //   state.user.roles[0].pages.forEach((item) => {
  //     let a = [];
  //     if (item.pages?.length) {
  //       a = item.pages.map((z) => z.name);
  //     }
  //     b = [...b, ...a, item.name];
  //   });
  //   return b;
  // },
};
