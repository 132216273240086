<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="xl"
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <template #modal-header>
      <p class="h5">
        {{ title }}
      </p>

      <b-button
        size="sm"
        title="Закрыть"
        variant="outline-danger"
        :type="$const.PRIMARY_BUTTON"
        class="p-0"
        @click="onClose"
      >
        <b-icon icon="x" />
      </b-button>
    </template>

    <div
      v-if="isLoading"
      class="d-flex justify-content-center w-100"
    >
      <b-spinner variant="primary" />
    </div>

    <div v-else>
      <div class="mb-3">
        <div class="font-weight-bold h5 mb-2">
          Услуга
        </div>
        <div class="pl-2">
          <div>
            <span class="font-weight-bold">Код услуги:</span> {{ service.code }}
          </div>
          <div>
            <span class="font-weight-bold">Название:</span> {{ service.name }}
          </div>
          <div>
            <span class="font-weight-bold">Комментарий:</span> {{ service.description }}
          </div>
          <div>
            <span class="font-weight-bold">Цена:</span> {{ service.cost }}
          </div>
          <div>
            <span class="font-weight-bold">Активен:</span>
            <span :style="{color: service.isActive ? 'green' : 'red'}">
              {{ service.isActive ? 'Да' : 'Нет' }}
            </span>
          </div>
        </div>
      </div>
      <div>
        <div class="font-weight-bold h5 mb-2">
          Склеить с
        </div>

        <div
          v-if="selectedServices.length"
          class="pl-2"
        >
          <div
            v-for="({service: relatedService, ...selectedService}, index) in selectedServices"
            :key="selectedService.id"
            class="mb-3"
          >
            <div>
              <span class="font-weight-bold">({{ index + 1 }}) Название:</span> {{ selectedService.name }}
            </div>
            <div>
              <span class="font-weight-bold">Название партнера:</span> {{ selectedService.partner?.name }}
            </div>
            <div>
              <span class="font-weight-bold">Код:</span> {{ selectedService.code }}
            </div>
            <div>
              <span class="font-weight-bold">Связана с:</span>

              <div
                v-if="relatedService"
                class="pl-3"
              >
                <div>
                  <span class="font-weight-bold">Название:</span> {{ relatedService.name }}
                </div>
                <div>
                  <span class="font-weight-bold">Код:</span> {{ relatedService.code }}
                </div>
              </div>
              <div v-else>
                -
              </div>
            </div>
          </div>

          <b-button
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            class="mt-2"
            @click="abilityAddMore = true"
          >
            Добавить еще
          </b-button>
        </div>
        <template v-if="abilityAddMore">
          <div class="mt-2 mb-4 px-3 pt-3 border">
            <div class="d-flex mb-2">
              <b-input-group>
                <b-form-input
                  v-model="searchData.name"
                  size="sm"
                  placeholder="По названию  услуги"
                  @keyup.native.enter="clearFetchPartnerServices"
                />
                <b-form-input
                  v-model="searchData.code"
                  size="sm"
                  placeholder="По коду услуги"
                  @keyup.native.enter="clearFetchPartnerServices"
                />
              </b-input-group>

              <b-button
                variant="primary"
                :type="$const.PRIMARY_BUTTON"
                size="sm"
                class="ml-2"
                @click="clearFetchPartnerServices"
              >
                <b-icon icon="search" />
              </b-button>
            </div>

            <div class="d-flex">
              <b-form-group label="Только с возможностью связать">
                <b-form-checkbox
                  v-model="searchData.isMappable"
                  name="check-button"
                  switch
                  @input="clearFetchPartnerServices"
                />
              </b-form-group>

              <b-form-group
                label="Только связанные"
                class="ml-3"
              >
                <b-form-checkbox
                  v-model="searchData.isMerged"
                  name="check-button"
                  switch
                  @input="clearFetchPartnerServices"
                />
              </b-form-group>

              <b-form-group
                label="Только активные"
                class="ml-3"
              >
                <b-form-checkbox
                  v-model="searchData.isActive"
                  name="check-button"
                  switch
                  @input="clearFetchPartnerServices"
                />
              </b-form-group>

              <b-form-group
                label="Юр. лицо"
                class="ml-3"
              >
                <v-select
                  v-model="searchData.legalPartnerId"
                  :reduce="legal => legal.id"
                  style="width: 220px;"
                  :options="legalCompanies"
                  placeholder="Выберите Юр.Лицо"
                  label="legalName"
                  class="bg-white"
                  @input="clearFetchPartnerServices"
                />
              </b-form-group>
            </div>
          </div>

          <div v-if="partnersServicesSearched && !partnersServicesLoading">
            <template v-if="partnersServices.length">
              <b-table
                :fields="partnersServicesFields"
                :items="partnersServices"
                bordered
                fixed
                hover
                responsive
                :small="true"
                class="bg-white crm-table w-100"
              >
                <template v-slot:cell(name)="row">
                  <div class="break-word">
                    {{ row.item.name }} <span class="font-italic">({{ row.item.partner?.name }})</span>
                  </div>
                </template>
                <template v-slot:cell(legalPartnerName)="row">
                  <div class="break-word">
                    {{ row.item.legalPartner?.legalName }}
                  </div>
                </template>
                <template v-slot:cell(isActive)="row">
                  <b-badge
                    v-if="row.item.isActive"
                    class="p-2"
                    pill
                    variant="success"
                  >
                    Активирована
                  </b-badge>
                  <b-badge
                    v-else
                    class="p-2"
                    pill
                    variant="danger"
                  >
                    Не активирована
                  </b-badge>
                </template>
                <template v-slot:cell(actions)="row">
                  <b-button
                    :disabled="!row.item.isMapable"
                    variant="primary"
                    :type="$const.PRIMARY_BUTTON"
                    size="sm"
                    @click="selectService(row.item)"
                  >
                    Выбрать
                  </b-button>
                </template>
              </b-table>

              <b-pagination
                v-model="partnerServicesCurrentPage"
                pills
                :total-rows="+partnersServicesTotalCount"
                :per-page="LIMIT"
                style="padding-top: 16px"
                @input="fetchPartnerServices"
              />
            </template>

            <div
              v-else
              class="font-weight-bold"
            >
              Ничего не найдено
            </div>
          </div>

          <b-skeleton-table
            v-if="partnersServicesLoading"
            :rows="3"
            :columns="partnersServicesFields.length"
            :table-props="{ bordered: true, striped: true }"
          />
        </template>
      </div>
    </div>

    <template #modal-footer>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="onClose"
      >
        Отменить
      </b-button>

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isLoading || isSaving || !selectedServices.length"
        class="float-right"
        @click="onClickSave"
      >
        Склеить
        <b-spinner
          v-if="isSaving"
          variant="light"
          small
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { showCustomMessage } from '@/helpers/messages';

const LIMIT = 6;

export default {
  name: 'GlueOurServiceModal',

  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Склеить услугу',
    },
    ourServiceId: {
      type: [Number, String],
      default: null,
    },
  },
  data: () => ({
    isOpen: true,
    isLoading: false,
    isSaving: false,
    service: null,
    partnersServices: [],
    partnersServicesTotalCount: null,
    partnersServicesSearched: false,
    partnersServicesLoading: false,
    partnerServicesCurrentPage: 1,
    abilityAddMore: true,
    legalCompanies: null,
    partnersServicesFields: [
      {
        key: 'code',
        label: 'Код',
        sortable: true,
        tdClass: 'align-middle',
        class: 'text-center',
      },
      {
        key: 'name',
        label: 'Название',
        sortable: true,
        tdClass: 'align-middle',
        class: 'text-center',
      },
      {
        key: 'legalPartnerName',
        label: 'Юр. лицо',
        sortable: true,
        tdClass: 'align-middle',
        class: 'text-center',
      },
      {
        key: 'isActive',
        label: 'Статус услуги',
        tdClass: ['align-middle'],
        class: 'text-center',
      },
      {
        key: 'actions',
        label: 'Действия',
        tdClass: ['align-middle'],
        class: 'text-center',
      },
    ],
    searchData: {
      name: null,
      code: null,
      isActive: true,
      isMerged: false,
      isMappable: true,
      legalPartnerId: null,
    },
    selectedServices: [],
    LIMIT,
  }),

  async mounted() {
    this.isLoading = true;

    try {
      await this.fetchOurService();
      await this.fetchLegalPartners();
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async fetchLegalPartners() {
      const { data: { data } } = await this.$store.dispatch(this.$types.GET_LABORATORY_LEGAL_PARTNERS, {
        limit: 500,
      });
      this.legalCompanies = data;
    },
    async glueService(services) {
      try {
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const service of services) {
          const params = {
            partnerServiceGuid: service.id,
            serviceGuid: this.ourServiceId,
          };
          // eslint-disable-next-line no-await-in-loop
          await this.$store.dispatch(this.$types.LABORATORY_PARTNER_SERVICE_MAP, params);
        }

        this.$store.commit(this.$types.TOGGLE_OUR_LABORATORY_SERVICES_TRIGGER);
        this.onClose();
      } catch (e) {
        console.error(e);
        showCustomMessage('warning', 'Ошибка', 'Что-то пошло не так');
      }
    },
    onClickSave() {
      this.$store.commit(
        this.$types.OPEN_MODAL,
        {
          name: 'AgreeModal',
          props: {
            title: 'Вы уверены?',
            cb: this.glueService,
            cbArgs: [this.selectedServices],
          },
        },
      );
    },
    async fetchOurService() {
      const { data } = await this.$store.dispatch(this.$types.GET_LABORATORY_SERVICE_FOR_ID, this.ourServiceId);
      this.service = data;
    },
    clearFetchPartnerServices() {
      this.partnerServicesCurrentPage = 1;
      this.fetchPartnerServices();
    },
    async fetchPartnerServices() {
      this.partnersServicesLoading = true;

      try {
        const params = {
          limit: LIMIT,
          offSet: (this.partnerServicesCurrentPage - 1) * LIMIT,
        };

        const {
          code,
          name,
          isActive,
          isMerged,
          isMappable,
          legalPartnerId,
        } = this.searchData;

        if (code) params.Code = code;
        if (name) params.Name = name;
        if (isActive) params.isActive = isActive;
        if (isMerged) params.isMerged = isMerged;
        if (isMappable) params.isMappable = isMappable;
        if (legalPartnerId) params.LegalPartnerId = legalPartnerId;

        const { data: { data }, xTotalCount } = await this.$store.dispatch(this.$types.GET_LABORATORY_PARTNER_SERVICES, params);

        this.partnersServices = data;
        this.partnersServicesTotalCount = xTotalCount;
      } catch (e) {
        console.error(e);
      } finally {
        this.partnersServicesSearched = true;
        this.partnersServicesLoading = false;
      }
    },
    selectService(item) {
      this.selectedServices.push(item);
      this.abilityAddMore = false;
    },
  },
};
</script>

<style lang="scss">
</style>
