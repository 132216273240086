<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    title="Удаление аватарки врача"
    :hide-footer="isLoading"
    @hidden="onCancel"
    @ok="onCancel"
  >
    <template v-if="isLoading">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      Вы уверены?
    </template>

    <template #modal-footer>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        @click="onDelete"
      >
        Удалить
      </b-button>
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        @click="onCancel"
      >
        Отменить
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Preloader from '@/components/Preloader';

export default {
  name: 'DoctorAvatarDeleteModal',
  components: {
    Preloader,
  },
  props: {
    doctorId: {
      type: [String, Number],
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
    afterDelete: {
      type: [Function],
      default: () => {},
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
    };
  },
  methods: {
    onCancel() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
      setTimeout(() => {
        this.afterDelete();
      }, 1000);
    },
    async onDelete() {
      try {
        this.isLoading = true;
        await this.$store.dispatch(this.$types.DOCTOR_AVATAR_DELETE, { doctorId: this.doctorId });
        this.onClose();
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
