import NProgress from 'nprogress/nprogress';

import * as types from '@/store/types';
import { removeNamespaces, showMessage } from '@/helpers/utils';
import { api } from '@/helpers/api';

const localTypes = removeNamespaces(types);

export default {
  async [localTypes.PAGES_FETCH]() {
    NProgress.start();

    try {
      const { data } = await api.get('v2/crmPages/list');
      return data;
    } catch (e) {
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить страницы',
      });

      console.warn(e);

      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PAGE_CREATE](_, page) {
    NProgress.start();

    try {
      await api.post('v2/crmPages', page);
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось создать страницу',
      });

      throw (e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PAGE_UPDATE](_, page) {
    NProgress.start();

    try {
      await api.put('v2/crmPages', page);
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось обновить страницу',
      });

      throw (e);
    } finally {
      NProgress.done();
    }
  },
};
