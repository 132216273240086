<template>
  <div
    class="patient-info"
    :class="cardClasses"
  >
    <div class="d-flex flex-column">
      <div
        v-if="patient"
        class="p-1"
      >
        <img
          :src="PROJECT_TYPES[patient.projectType]?.logo"
          alt="#"
          class="project-type-img rounded"
        >
        <div class="is-main">
          {{ isMain }}
        </div>
      </div>
      <div
        v-if="patient.isVip || patient.isTop || patient.isGosuslugi || patient.isMango || patient.isTest"
        class="patient-statuses"
      >
        <icon-vip v-if="patient.isVip" />

        <icon-top v-if="patient.isTop" />

        <icon-gosuslugi v-if="patient.isGosuslugi" />

        <icon-mango v-if="patient.isMango" />

        <span v-if="patient.isTest">
          TEST
        </span>
      </div>
    </div>
    <div class="ml-3">
      <div class="font-weight-bold">
        {{ patient.lastName }}
        {{ patient.firstName }}
        {{ patient.middleName }}
      </div>
      <div class="small">
        <div>
          {{ patient.phoneNumber || '-' }}
        </div>
        <div>
          {{ patient.isDMS ? 'ДМС' : '' }}
        </div>
        <div>
          {{ patient.isDMS && patient.isOMS ? ', ' : '' }}
        </div>
        <div>
          {{ patient.isOMS ? 'ОМС' : '' }}
        </div>
        <div>
          {{ parseDate(patient.birthDate) || '-' }}
        </div>
      </div>
    </div>
    <div
      v-if="patient.policyInfo"
      class="detailed-info-btn"
    >
      <b-button
        v-b-tooltip.hover.left.v-light
        variant="light"
        :type="$const.PRIMARY_BUTTON"
        size="sm"
        class="mt-2 border p-0"
        title="Информация о полисе"
        @click="openPolicyDetail(patient.policyInfo)"
      >
        <icon-info />
      </b-button>
    </div>
  </div>
</template>

<script>
import { format, parseISO } from '@evd3v/date-fns';
import IconMango from 'assets/images/icon_mango.svg';
import IconGosuslugi from 'assets/images/icon_gosuslugi.svg';
import IconVip from 'assets/images/icon_vip.svg';
import IconTop from 'assets/images/icon_top.svg';
import IconInfo from 'assets/images/info.svg';
import { PROJECT_TYPES } from '@/helpers/consts';

export default {
  name: 'PatientInfo',
  components: {
    IconMango,
    IconGosuslugi,
    IconVip,
    IconTop,
    IconInfo,
  },

  props: {
    patient: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      PROJECT_TYPES,
    };
  },
  computed: {
    isMain() {
      const { parentId, isMain } = this.patient;

      if (parentId) {
        return 'доп';
      }

      if (isMain) {
        return 'осн';
      }

      return 'доп';
    },
    cardClasses() {
      return {
        'marked-card': this.active && !this.patient.isChild,
        'child-card': this.patient.isChild && !this.active,
        'marked-child-card': this.patient.isChild && this.active,
      };
    },
  },
  methods: {
    parseDate(ISODate) {
      return format(parseISO(ISODate), 'dd.MM.yyyy');
    },
    openPolicyDetail(policies) {
      this.$store.commit(this.$types.PATIENT_ACTIVE_POLICY_ID_SET, policies[0].policyId);
      this.openPatientDetail();
    },
    openPatientDetail() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'PatientDetailModal',
        props: {
          patientId: this.patient.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.patient-info {
  position: relative;
  display: flex;
  min-width: 250px;
  border: 1px solid rgb(205, 207, 205);
  border-radius: 5px;
  cursor: pointer;
  padding: 6px;
  margin: 2px;
}
.project-type-img {
  width: 20px;
}
.is-main {
  font-size: 12px;
  line-height: 16px;
  color: #8a8383;
}
.marked-card {
  border: 1px solid rgb(6, 29, 240);
  box-shadow: rgba(6, 29, 240, 0.19) 0px 5px 20px, rgba(6, 29, 240, 0.23) 0px 5px 5px;
}
.child-card {
  border: 1px solid rgb(6, 240, 45);
}
.marked-child-card {
  outline: 2px solid rgb(6, 240, 45);
  box-shadow: rgba(6, 240, 6, 0.19) 0px 5px 20px, rgba(6, 240, 6, 0.23) 0px 5px 5px;
}
.detailed-info-btn {
  position: absolute;
  right: 3px;
  bottom: 3px;
}
</style>
