<template>
  <b-modal
    id="specialization-doctors-modal"
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    class="z-index"
    :title="'Список врачей по специализации'"
    hide-footer
    @hidden="onClose"
    @ok="onClose"
  >
    <loading v-if="isLoading">
      Загрузка данных
    </loading>
    <div v-else>
      <custom-scrollbar>
        <div
          v-if="doctors.length > 0"
          class="crm-table"
        >
          <div class="crm-table-row crm-table-row--header">
            <div class="crm-table-cell crm-table-cell--fullname">
              ФИО
            </div>
            <div class="crm-table-cell">
              Специализация
            </div>
          </div>
          <div
            v-for="doctor in formatedDoctorsListFetch"
            :key="doctor.id"
            class="crm-table-row"
          >
            <div class="crm-table-cell crm-table-cell--fullname">
              {{ doctor.fullName }}
            </div>
            <div class="d-flex crm-table-cell">
              <span class="align-self-center">
                {{ doctor.specialization }}
              </span>
              <b-button
                v-if="checkFeatureAccess({ name: 'Кнопка детальной информации о враче (глазик)', url: '/doctors' })"
                v-b-tooltip.hover
                variant="light"
                :type="$const.PRIMARY_BUTTON"
                size="sm"
                class="btn-info"
                title="Информация о враче"
                @click="openWatchModal(doctor)"
              >
                <b-icon icon="eye" />
              </b-button>
            </div>
          </div>
        </div>

        <div v-else>
          Нет данных
        </div>
      </custom-scrollbar>
    </div>
  </b-modal>
</template>

<script>
import Loading from '@/components/Loading';
import { mixinRoles } from '@/mixins';

export default {
  name: 'SpecializationDoctorsModal',
  components: {
    Loading,
  },
  mixins: [mixinRoles],
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    specializationId: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      userData: {},
      doctors: [],
    };
  },
  computed: {
    formatedDoctorsListFetch() {
      return this.doctors.map((doctor) => ({
        ...doctor,
        specialization: doctor.doctorSpecializations[0].specializationTitle
        ,
      }));
    },
  },
  async created() {
    this.isLoading = true;
    await this.doctorsListFetch();
    this.isLoading = false;
  },
  methods: {
    async doctorsListFetch() {
      this.doctors = await this.$store.dispatch(this.$types.DOCTORS_LIST_BY_SPECIALIZATION, this.specializationId);
    },
    openWatchModal(doctor) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'DoctorInfoModal',
        props: {
          doctorId: doctor.id,
          title: doctor.fullName,
        },
      });
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep#specialization-doctors-modal___BV_modal_outer_ {
  z-index: 1000!important;
}

.crm-table {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  &-row {
    display: flex;

    &--header {
      background: #F3F3F3;
      color: #6E88F3;
    }

    & + & {
      border-top: 1px solid #E0E0E0;
    }
  }

  &-cell {
    padding: 10px;
    width: 100%;

    &--fullname {
      flex-shrink: 0;
      width: 35%;
    }

    & + & {
      border-left: 1px solid #E0E0E0;
    }
  }
}
.btn-info{
  align-self: center;
  height: 35px;
  margin-left: auto;
}

</style>
