<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    :title="page ? 'Страница' : 'Новая страница'"
    :hide-footer="isLoading"
    @hidden="onClose"
    @ok="onClose"
  >
    <template v-if="isLoading">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      <b-form-group
        label="Название страницы"
        :invalid-feedback="'Название не может быть пустым'"
        :state="page.name !== null && page.name !== ''"
      >
        <b-form-input
          v-model="page.name"
          type="text"
          placeholder="Введите название"
          :disabled="false"
          trim
        />
      </b-form-group>

      <b-form-group
        label="Адрес страницы"
        description="Для того, чтобы создать некликабельный элемент меню, вставьте # в данное поле"
        :invalid-feedback="'Адрес не может быть пустым'"
        :state="page.url !== null && page.url !== ''"
      >
        <b-form-input
          v-model="page.url"
          type="text"
          placeholder="Введите адрес"
          trim
          :disabled="false"
        />
      </b-form-group>

      <b-form-group
        label="Индекс страницы (порядковый номер)"
      >
        <b-form-input
          v-model="page.index"
          type="text"
          placeholder="Введите индекс"
          trim
          :disabled="false"
        />
      </b-form-group>

      <b-form-group
        label="Родительская страница"
      >
        <b-form-select
          v-model="page.pageId"
          :options="pages"
          value-field="id"
          text-field="name"
          :disabled="false"
        >
          <b-form-select-option :value="null">
            Выберите страницу
          </b-form-select-option>
        </b-form-select>
      </b-form-group>

      <b-form-checkbox
        v-model="page.isMenuItem"
      >
        Отображать в меню
      </b-form-checkbox>
    </template>

    <template #modal-footer>
      <b-button
        variant="success"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isLoading || !(page.name !== null && page.name !== '') || !(page.url !== null && page.url !== '')"
        @click="onSave"
      >
        Сохранить страницу
      </b-button>

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        @click="onClose"
      >
        Отменить
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Preloader from '@/components/Preloader';

export default {
  name: 'PageEditModal',
  components: {
    Preloader,
  },
  props: {
    pageId: {
      type: [String, Number],
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      page: {
        name: null,
        url: null,
        isMenuItem: false,
        pageId: null,
        index: null,
      },
    };
  },
  computed: {
    pages() {
      let allPages = this.$store.state.Pages.pages;
      allPages = allPages.map((item) => {
        const newItem = {
          ...item,
          name: `${item.name} (${item.index})`,
        };
        return newItem;
      });

      return allPages.filter((p) => p.id !== this.pageId);
    },
  },
  async mounted() {
    try {
      this.isLoading = true;

      if (this.pageId) {
        const page = this.$store.state.Pages.pages.find((p) => p.id === this.pageId);
        this.page = { ...page };
      }
    } catch (error) {
      console.error(error);
      // this.onClose();
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    onClose() {
      this.isOpen = false;
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onSave() {
      try {
        this.isLoading = true;
        const method = this.pageId ? 'PAGE_UPDATE' : 'PAGE_CREATE';
        await this.$store.dispatch(this.$types[method], this.page);
        this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });

        await this.$store.dispatch('Auth/userFetch');
        this.$store.commit(this.$types.PAGES_LOADING_SET, true);
        const pages = await this.$store.dispatch(this.$types.PAGES_FETCH);
        this.$store.commit(this.$types.PAGES_SET, pages);
        this.$store.commit(this.$types.PAGES_LOADING_SET, false);
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
