<template>
  <div class="pt-4 w-100 w-100 containerLabsPage">
    <custom-scrollbar>
      <b-container
        fluid
      >
        <div class="h3 mb-4">
          Отделения
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <b-form-checkbox
            v-model="isActive"
            :disabled="disabledActive"
            size="sm"
            @input="fetchBranches()"
          >
            <span class="text-secondary">
              Активирован
            </span>
          </b-form-checkbox>
          <div class="d-flex justify-content-end align-items-center">
            <b-button
              v-if="checkFeatureForModal(FEATURES_FOR_CHAT.addDepartment, true)"
              :disabled="disabledAddBranch"
              class="mr-2"
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              @click="openAddBranchModal"
            >
              Добавить отделение
            </b-button>
            <b-button
              v-if="checkFeatureForModal(FEATURES_FOR_CHAT.importDepartments, true)"
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              @click="$router.push('/laboratory-import-branches')"
            >
              <b-icon icon="file-earmark-excel" />
              Импорт отделений
            </b-button>
          </div>
        </div>
        <div class="mt-4 mb-4 d-flex align-items-center">
          <v-select
            v-model="selectedLegalCompanyId"
            class="legalPartnerSelect w-100"
            :reduce="legal => legal.id"
            :options="legalCompanyOptions.data?.data"
            placeholder="Выберите Юр.Лицо"
            label="legalName"
            @input="fetchBranches"
          />

          <b-button
            variant="primary"
            class="ml-2"
            :type="$const.PRIMARY_BUTTON"
            @click="fetchBranches"
          >
            <b-icon icon="search" />
          </b-button>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <v-select
            v-model="branchCityId"
            :reduce="city => city.id"
            :options="cities"
            placeholder="Выберите город"
            label="name"
            :clearable="true"
            class="legalPartnerSelect w-50 mr-2"
            @input="fetchBranches"
          />
          <b-input-group>
            <b-form-input
              v-model="branchAddress"
              placeholder="По адресу"
              @keyup.native.enter="fetchBranches"
            />
            <b-form-input
              v-model="branchLongitude"
              placeholder="Долгота"
              @keyup.native.enter="fetchBranches"
            />
            <b-form-input
              v-model="branchLatitude"
              placeholder="Широта"
              @keyup.native.enter="fetchBranches"
            />
          </b-input-group>
        </div>
        <b-overlay
          v-if="!branchListStore || Object.keys(branchListStore).length === 0"
          :show="isLoading"
          no-wrap
        />
        <div v-if="branchListStore">
          <div v-if="Object.keys(branchListStore).length !== 0">
            <b-table
              v-if="branchListStore"
              :fields="fields"
              :items="branchListStore.data"
              :busy="isLoading"
              :per-page="0"
              :current-page="currentPage"
              :filter="filter"
              class="bg-white crm-table mt-4"
              bordered
              hover
              show-empty
              :small="true"
            >
              <template #empty="">
                <div class="d-flex justify-content-center align-items-center">
                  <h4 class="p-2">
                    Не найдено <b-icon
                      icon="exclamation-triangle-fill"
                      variant="danger"
                    />
                  </h4>
                </div>
              </template>
              <template v-slot:cell(legalPartnerId)="row">
                {{ legalPartnerName(row) }}
              </template>
              <template v-slot:cell(cityId)="row">
                {{ cityName(row) }}
              </template>
              <template v-slot:cell(isActive)="row">
                <b-badge
                  v-if="row.item.isActive"
                  class="p-2"
                  pill
                  variant="success"
                >
                  Активировано
                </b-badge>
                <b-badge
                  v-else
                  class="p-2"
                  pill
                  variant="danger"
                >
                  Не активировано
                </b-badge>
              </template>
              <template #cell(actions)="row">
                <div class="d-flex">
                  <b-button
                    v-if="checkFeatureForModal(FEATURES_FOR_CHAT.edit, true)"
                    v-b-tooltip.hover
                    class="contact-legal-delete mr-2"
                    variant="primary"
                    :type="$const.PRIMARY_BUTTON"
                    size="sm"
                    title="Редактировать"
                    @click="openEditBranchModal(row)"
                  >
                    <b-icon
                      icon="pencil-fill"
                    />
                  </b-button>
                  <div v-if="checkFeatureForModal(FEATURES_FOR_CHAT.deactivate, true)">
                    <b-button
                      v-if="row.item.isActive"
                      v-b-tooltip.hover
                      :disabled="disabledDeactivateBranch"
                      class="contact-legal-delete mr-2"
                      variant="danger"
                      size="sm"
                      title="Деактивировать"
                      :type="$const.PRIMARY_BUTTON"
                      @click="onClickDeActivateBranch(row)"
                    >
                      <b-icon icon="person-x-fill" />
                    </b-button>

                    <b-button
                      v-else
                      v-b-tooltip.hover
                      class="contact-legal-delete mr-2"
                      variant="success"
                      size="sm"
                      title="Активировать"
                      :type="$const.PRIMARY_BUTTON"
                      @click="onClickActivateBranch(row)"
                    >
                      <b-icon icon="person-check-fill" />
                    </b-button>
                  </div>
                </div>
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-2" />
                  <strong>Загрузка...</strong>
                </div>
              </template>
            </b-table>
            <div
              class="mt-3"
            >
              <div
                v-show="branchListStore"
                class="crm-pagination"
              >
                <b-dropdown
                  id="dropdown-1"
                  :text="`${perPage}`"
                  split
                  class="m-md-2"
                  size="sm"
                  split-variant="outline-primary"
                  variant="primary"
                  :type="$const.PRIMARY_BUTTON"
                >
                  <b-dropdown-item @click="tableSizeHandler(10)">
                    10
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item @click="tableSizeHandler(25)">
                    25
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item @click="tableSizeHandler(50)">
                    50
                  </b-dropdown-item>
                </b-dropdown>
                <b-pagination
                  v-model="currentPage"
                  pills
                  :total-rows="+allCount"
                  :per-page="perPage"
                  style="padding-top: 16px"
                  @input="fetchBranches"
                />
              </div>
            </div>
          </div>
          <span v-else>Ничего не найдено</span>
        </div>
      </b-container>
    </custom-scrollbar>
  </div>
</template>

<script>
import { mixinRoles } from '@/mixins';
import { FEATURES_FOR_LABORATORY, FEATURES_FOR_CHAT } from '@/helpers/consts';

import { locationService } from '@/services';

export default {
  name: 'LaboratoryBranches',
  page: {
    title: 'CRM Doctis - Отделения',
  },
  components: {},
  mixins: [mixinRoles],
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'legalPartnerId',
          label: 'Название юр. лица',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'cityId',
          label: 'Город',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'address',
          label: 'Адрес',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'isActive',
          label: 'Статус отделения',
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'actions',
          label: '',
          tdClass: 'align-middle',
          class: ['actionsClass', 'text-center'],
        },
      ],
      userData: null,
      searchCityName: null,
      currentPage: 1,
      perPage: 10,
      filter: null,
      rows: 1,
      partnerList: [],
      isActive: true,
      allCount: null,
      selectedLegalCompanyId: null,
      legalCompanyOptions: [],
      branchName: null,
      branchAddress: null,
      branchLongitude: null,
      branchCityId: null,
      cities: [],
      branchLatitude: null,
      legalPartnerInn: null,
      limit: 10,
      isLoading: false,
      FEATURES_FOR_LABORATORY,
      FEATURES_FOR_CHAT,
    };
  },
  computed: {
    branchListStore() {
      return this.$store.state.Laboratories.branchesList;
    },
    paginationParams() {
      return {
        Name: this.branchName,
        Address: this.branchAddress,
        LegalPartnerId: this.selectedLegalCompanyId,
        Longitude: this.branchLongitude,
        Latitude: this.branchLatitude,
        CityId: this.branchCityId,
        limit: this.limit,
        offSet: this.offSet,
        isActive: this.isActive,
        CityName: this.searchCityName,
      };
    },
    offSet() {
      return (this.currentPage - 1) * this.limit;
    },
    disabledActive() {
      return this.checkFeatureForModal(this.FEATURES_FOR_CHAT.activatedOnly, false);
    },
    disabledAddBranch() {
      return this.modalName ? false
        : !this.checkFeature(this.FEATURES_FOR_LABORATORY.addBranch);
    },
    disabledDeactivateBranch() {
      return this.modalName ? false
        : !this.checkFeature(this.FEATURES_FOR_LABORATORY.deactivateBranch);
    },
  },
  async created() {
    await this.fetchLegalPartners();
    this.cities = (await locationService.getCities({ query: '' })).sort((a, b) => a.name.localeCompare(b.name));
    await this.fetchBranches();
  },
  beforeDestroy() {
    this.$store.commit(this.$types.LEGAL_PARTNERS_SET, null);
  },
  methods: {
    checkFeatureForModal(key, boolean) {
      return this.modalName ? this.checkFeatureAccess({ name: key.name, url: key.url }) : boolean;
    },
    checkFeature(key) {
      return this.checkFeatureAccess({ name: key.name, url: key.url });
    },
    async tableSizeHandler(mount) {
      this.perPage = mount;
      this.limit = this.perPage;
      await this.fetchBranches();
    },
    async fetchBranches() {
      this.isLoading = true;
      const data = await this.$store.dispatch(this.$types.GET_LABORATORY_BRANCHES, this.paginationParams);
      this.allCount = data.xTotalCount;
      this.isLoading = false;
    },
    async fetchLegalPartners() {
      this.legalCompanyOptions = await this.$store.dispatch(this.$types.GET_LABORATORY_LEGAL_PARTNERS, {});
    },
    async onClickActivateBranch(row) {
      await this.$store.dispatch(this.$types.LABORATORY_BRANCH_ACTIVATE, row.item.id);
      await this.fetchBranches();
    },
    async onClickDeActivateBranch(row) {
      await this.$store.dispatch(this.$types.LABORATORY_BRANCH_DEACTIVATE, row.item.id);
      await this.fetchBranches();
    },
    openAddBranchModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'AddBranchModal',
        props: {
          params: this.paginationParams,
          cities: this.cities,
          noCloseOnBackdrop: true,
        },
      });
    },
    openEditBranchModal(row) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'EditBranchModal',
        props: {
          branchId: row.item.id,
          cities: this.cities,
          params: this.paginationParams,
          noCloseOnBackdrop: true,
        },
      });
    },
    cityName(row) {
      return this.cities.find((item) => item.id === row.item.cityId)?.name;
    },
    legalPartnerName(row) {
      const legalPartner = this.$store.state.Laboratories.legalPartnerList.data?.find((item) => item.id === row.item.legalPartnerId);
      return legalPartner.legalName;
    },
  },
};
</script>

<style lang="scss" scoped>
.policy-list-content {
  height: calc(100vh - #{$header-height});
  transition: height 0.3s linear;
}

::v-deep.crm-table {
  overflow: hidden;

  .actionsClass{
    min-width: 6.1rem !important;
  }
}

.crm-pagination {
  display: flex;
  align-items: center;
}

.crm-icon-delete {
  cursor: pointer;
  transition: .3s all;

  &:hover {
    rect {
      stroke: $blue;
      opacity: 0.5;
    }
  }
}

.crm-icon-edit {
  cursor: pointer;
  transition: .3s all;

  path {
    fill: $blue;
  }

  &:hover {
    rect {
      stroke: $blue;
      opacity: 0.5;
    }
  }
}
.legalPartnerSelect{
  background-color: white;
  height: 100%;
}
</style>
