<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    :title="'Изменение отделения'"
    :no-close-on-backdrop="noCloseOnBackdrop"
    @hidden="onClose"
    @ok="onClose"
  >
    <b-overlay
      v-if="isLoading"
      :show="isLoading"
      variant="transparent"
      rounded="lg"
      style="height: 10rem"
      opacity="0.6"
    />
    <template v-else>
      <b-row>
        <b-col>
          <b-form-group
            label="Код отделения"
          >
            <b-form-input
              v-model="branchData.code"
              class="w-25"
              type="text"
              :state="validateState('code')"
              :placeholder="'Введите код'"
              autocomplete="new-branch"
              trim
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >
              Данное поле обязательно к заполнению
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            class="mb-0"
            label="Режим работы"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mb-3">
          <b-form-input
            v-model="branchData.operatingHoursFrom"
            v-maska="'##:##'"
            :disabled="!isChange"
            type="text"
            :placeholder="'Начало работы'"
            autocomplete="new-branch"
            trim
          />
        </b-col>
        <b-col>
          <b-form-input
            v-model="branchData.operatingHoursTo"
            v-maska="'##:##'"
            :disabled="!isChange"
            type="text"
            :placeholder="'Окончание работы'"
            autocomplete="new-branch"
            trim
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Город">
            <v-select
              v-model="$v.branchData.cityId.$model"
              :reduce="city => city.id"
              :disabled="!isChange"
              :options="cities"
              placeholder="Выберите город"
              label="name"
              autocomplete="new-cities"
              :clearable="true"
              class="legalPartnerSelect  mr-2"
              @input="fetchMetroData(true)"
            />
            <span
              v-if="$v.branchData.cityId.$error"
              id="input-1-live-feedback"
              class="error-text"
            >
              Данное поле обязательно*
            </span>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Адрес"
          >
            <b-form-input
              v-model="$v.branchData.address.$model"
              :disabled="!isChange"
              type="text"
              :state="validateState('address')"
              :placeholder="'Введите адрес'"
              trim
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >
              Данное поле обязательно и должно быть не менее 5 символов*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-row>
            <b-col>
              <b-form-group
                class="mb-0"
                label="Широта"
              />
            </b-col>
          </b-row>
          <b-form-input
            v-model="branchData.latitude"
            :disabled="!isChange"
            placeholder="Широта"
          />
        </b-col>
        <b-col class="mb-3">
          <b-row>
            <b-col>
              <b-form-group
                class="mb-0"
                label="Долгота"
              />
            </b-col>
          </b-row>
          <b-form-input
            v-model="branchData.longitude"
            :disabled="!isChange"
            placeholder="Долгота"
          />
        </b-col>
      </b-row>
      <b-row v-if="isMetro">
        <b-col>
          <b-form-group
            label="Метро"
          >
            <multiselect
              v-model="branchData.subwayStations"
              :multiple="true"
              placeholder="Выберите метро"
              :close-on-select="false"
              :disabled="!isChange"
              label="name"
              track-by="id"
              :options="mainMetroData"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Отделение на карте">
            <yandex-map
              :coords="coords"
              :zoom="15"
              :scroll-zoom="false"
              @click="onClick"
            >
              <ymap-marker
                :coords="coords"
                marker-id="123"
                :hint-content="`Отделение`"
              />
            </yandex-map>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Название юр. лица">
            <b-form-select
              v-model="branchData.legalPartnerId"
              value-field="id"
              text-field="legalName"
              label="legalName"
              :disabled="!isChange"
              trim
              :options="optionsLegalPartner"
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Активирован">
            <b-form-select
              v-model="branchData.isActive"
              :disabled="!isChange"
              :options="optionsActivate"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>
    <template #modal-footer>
      <div>
        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          :disabled="!isChange"
          @click="onClickEditBranch"
        >
          Изменить
        </b-button>
        <b-button
          variant="danger"
          :type="$const.PRIMARY_BUTTON"
          class="ml-2"
          :disabled="!isChange"
          @click="onClose"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { minLength, required } from 'vuelidate/lib/validators';
import { yandexMap, ymapMarker } from 'vue-yandex-maps';
import { mixinRoles } from '@/mixins';

export default {
  name: 'EditBranchModal',
  components: {
    ymapMarker,
    yandexMap,
  },
  mixins: [validationMixin, mixinRoles],
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    branchId: {
      type: [String, Number],
      default: null,
    },
    params: {
      type: Object,
      default: null,
    },
    cities: {
      type: Array,
      default: null,
    },
    noCloseOnBackdrop: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      rows: [],
      mainMetroData: [],
      hhCityId: null,
      metroCities: [
        { name: 'Москва', id: 1 },
        { name: 'Санкт-Петербург', id: 2 },
        { name: 'Екатеринбург', id: 3 },
        { name: 'Новосибирск', id: 4 },
        { name: 'Cамара', id: 78 },
        { name: 'Казань', id: 88 },
        { name: 'Нижний Новгород', id: 66 },
      ],
      branchData: {
        code: '',
        name: '',
        cityId: null,
        longitude: null,
        latitude: null,
        address: '',
        isActive: null,
        legalPartnerId: '',
        subwayStations: [],
        subwayColor: '',
      },
      metroData: [],
      optionsLegalPartner: [],
      optionsActivate: [
        { value: null, text: 'Выберите состояние' },
        { value: true, text: 'Активирован' },
        { value: false, text: 'Деактивирован' },
      ],
      coords: [],
      isMetro: false,
    };
  },
  validations: {
    branchData: {
      code: {
        required,
      },
      cityId: { required },
      address: {
        required,
        minLength: minLength(5),
      },
    },
  },
  computed: {
    legalPartnerListStore() {
      return this.$store.state.Laboratories.legalPartnerList;
    },
    isChange() {
      return this.checkFeatureAccess({ name: 'Изменение отделения', url: '/laboratory-branches' });
    },
  },
  watch: {
    coords() {
      [this.branchData.latitude, this.branchData.longitude] = this.coords;
    },
  },
  async created() {
    await this.fetchBranchInfo();

    this.coords[0] = this.branchData.latitude || 55.7558;
    this.coords[1] = this.branchData.longitude || 37.6173;

    await this.$store.dispatch(this.$types.GET_LABORATORY_LEGAL_PARTNERS, {});
    this.optionsLegalPartner = this.legalPartnerListStore.data;

    await this.fetchMetroData();
  },
  methods: {
    async onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    setMapPoint() {
      const currentCity = this.cities.filter((item) => item.id === this.branchData.cityId)[0];

      this.coords = [
        currentCity.latitude,
        currentCity.longitude,
      ];
    },
    async fetchMetroData(isSetMap) {
      if (isSetMap) this.setMapPoint();

      await this.checkMetroFromCity();

      if (this.isMetro) {
        this.metroData = await this.$store.dispatch(this.$types.GET_METRO_FOR_CITY, this.hhCityId);
        this.mainMetroData = [];

        for (let i = 0; i < this.metroData.data.lines.length; i += 1) {
          for (let y = 0; y < this.metroData.data.lines[i].stations.length; y += 1) {
            this.metroData.data.lines[i].stations[y].hexColor = this.metroData.data.lines[i].hex_color;
            this.mainMetroData.push(this.metroData.data.lines[i].stations[y]);
          }
        }
      }
    },
    onClick(e) {
      this.coords = e.get('coords');
    },
    checkMetroFromCity() {
      const currCity = this.cities.filter((item) => item.id === this.branchData.cityId)[0];

      for (let i = 0; i < this.metroCities.length; i += 1) {
        if (currCity.name === this.metroCities[i].name) {
          this.hhCityId = this.metroCities[i].id;
          this.isMetro = true;

          break;
        }

        if (currCity.name !== this.metroCities[i].name) {
          this.hhCityId = null;

          this.isMetro = false;
        }
      }

      return currCity;
    },
    async fetchBranchInfo() {
      this.isLoading = true;
      const { data } = await this.$store.dispatch(this.$types.GET_LABORATORY_BRANCH_FOR_ID, this.branchId);
      this.branchData = data;
      console.log('this.branchData: ', this.branchData);
      if (this.branchData.subwayStations) {
        this.branchData.subwayStations = this.branchData.subwayStations.map((str) => ({ name: str }));
      }
      this.isLoading = false;
    },
    onHidden() {
      // Return focus to the button once hidden
      this.$refs.button.focus();
    },
    async onClickEditBranch() {
      if (!this.onCheckValidation()) return;

      const currMetro = this.mainMetroData.filter((item) => this.branchData.subwayStations === item.name);
      if (currMetro[0]) this.branchData.subwayColor = currMetro[0].hexColor;
      if (!this.isMetro) {
        this.branchData.subwayStations = [];
        this.branchData.subwayColor = '';
      }
      console.log('this.branchData: ', this.branchData);
      await this.$store.dispatch(this.$types.LABORATORY_BRANCH_UPDATE, this.branchData);
      await this.$store.dispatch(this.$types.GET_LABORATORY_BRANCHES, this.params);
      this.onClose();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.branchData[name];
      return $dirty ? !$error : null;
    },
    onCheckValidation() {
      this.$v.branchData.cityId.$touch();
      this.$v.branchData.address.$touch();
      this.$v.branchData.code.$touch();
      if (this.$v.branchData.cityId.$anyError
        || this.$v.branchData.address.$anyError
        || this.$v.branchData.code.$anyError
      ) {
        return false;
      }
      return true;
    },
    formatInn(e) {
      return String(e).substring(0, 12);
    },
    formatKpp(e) {
      return String(e).substring(0, 9);
    },
  },
};
</script>

<style lang="scss" scoped>

.crm-table {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  &-row {
    display: flex;

    &--header {
      background: #F3F3F3;
      color: #6E88F3;
    }

    & + & {
      border-top: 1px solid #E0E0E0;
    }
  }

  &-cell {
    padding: 10px;
    width: 100%;

    &--date {
      flex-shrink: 0;
      width: 150px;
    }

    & + & {
      border-left: 1px solid #E0E0E0;
    }
  }
}
.error-text{
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
.ymap-container{
  height: 350px;
}
.crm-base-input {
  height: 43px;
}
</style>
