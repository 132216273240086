import AccountApi from '@/services/account/account.api';

export default class AccountService {
  constructor($axios) {
    this.api = new AccountApi($axios);
  }

  getUsers(params) {
    return this.api.getUsersAccount(params);
  }
}
