var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'banner-item',
    { 'banner-item_small': _vm.bannerSmall },
    { 'banner-item_no-colored': !_vm.bannerColored },
  ],style:({
    'background': _vm.bannerBackground,
  })},[(_vm.$slots.actions)?_c('div',{staticClass:"banner-item__actions d-flex bg-white p-1 rounded"},[_vm._t("actions")],2):_vm._e(),_c('div',{staticClass:"banner-item__info"},[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"banner-item__title"},[_vm._v(" "+_vm._s(_vm.banner.title)+" ")]),_c('div',{staticClass:"banner-item__text mt-2"},[_vm._v(" "+_vm._s(_vm.banner.text)+" ")])]),(_vm.banner.buttonText)?_c('button',{staticClass:"banner-item__btn border-0",style:({
        'background': _vm.buttonBgColor,
        'color': _vm.buttonTextColor,
      }),on:{"click":_vm.openBunnerLink}},[_c('span',{staticClass:"font-medium"},[_vm._v(" "+_vm._s(_vm.banner.buttonText))])]):_vm._e()]),_c('div',{staticClass:"banner-item__img-block",class:{ 'lg:w-[144px]': !_vm.banner.small }},[_c('img',{staticClass:"banner-item__img",attrs:{"alt":_vm.banner.fileCloudKey || 'banner-img',"loading":"lazy","src":_vm.propImageUrl || _vm.imageUrl,"width":"144","height":"200"}}),(!_vm.bannerColored)?_c('svg',{staticClass:"banner-item__circle",style:({
        'fill': _vm.circleBackground,
      }),attrs:{"width":"86","height":"100","viewBox":"0 0 86 100","version":"1.1","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"54.5","cy":"54.5","r":"54.5"}})]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }