<template>
  <div
    v-if="show"
    class="wrapper"
    @click="hidePreview"
  >
    <div
      class="buttons"
    >
      <button
        class="zoom-btn"
        @click="zoomIn"
      >
        +
      </button>
      <button
        class="zoom-btn"
        @click="zoomOut"
      >
        -
      </button>
    </div>
    <div
      class="preview"
      :style="{ transform: `scale(${scale})`, transition: 'all 0.5s' }"
    >
      <span
        class="close-button"
        @click="hidePreview"
      >
        ×
      </span>
      <div
        class="file-container"
      >
        <img
          v-if="extention !== 'MP4'"
          :src="actualImagePreview"
          class="open-file"
        >

        <video
          v-else
          ref="videoSource"
          :key="actualImagePreview"
          :src="actualImagePreview"
          preload="auto"
          controls
          class="video-preview"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChatPreviewFile',
  props: {
    actualImagePreview: {
      type: String,
      required: true,
    },
    extention: {
      type: String,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      scale: 1,
      maxScale: 1.5,
      minScale: 1,
    };
  },
  watch: {
    active() {
      if (this.active) {
        this.playVideo();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.playVideo();
    });
  },
  methods: {
    hidePreview(event) {
      if (event.target.classList.contains('close-button')) {
        this.$emit('hide-preview', false);
      }
    },
    playVideo() {
      if (this.active && this.extention === 'MP4') {
        const video = this.$refs.videoSource;

        if (video) {
          video.pause();
          video.currentTime = 0;
          video.play();
        }
      }
    },
    zoomIn() {
      if (this.scale < this.maxScale) {
        this.scale += 0.1;
      }
    },

    zoomOut() {
      if (this.scale > this.minScale) {
        this.scale -= 0.1;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  z-index: 1000;
}
.preview {
  margin: auto;
  position: relative;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.close-button {
  position: absolute;
  top: -35px;
  right: -30px;
  font-size: 40px;
  color: #fff;
  cursor: pointer;
  z-index: 10;
}
.buttons {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-around;
  width: 10%;
  gap: 20px;
  z-index: 1001;
  .zoom-btn{
    color: #fff;
    background: rgb(145, 143, 143);
    border-radius: 5px;
    width: 50%;
    &:hover {
      background: darken(rgb(145, 143, 143), 10%); // Затемнение цвета при наведении
    }

    &:active {
      background: lighten(rgb(145, 143, 143), 10%); // Осветление цвета при клике
    }
  }

}
.file-container {
  max-height: 57vh;
  max-width: 500px;
  overflow: auto;
}
.video-preview {
  max-height: 57vh;
  max-width: 500px;
}
</style>
