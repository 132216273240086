<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="s"
    :title="'Загрузка прайса'"
    @hidden="onClose"
    @ok="onClose"
  >
    <loading v-if="isLoading">
      Загрузка данных
    </loading>
    <div v-else>
      <b-row>
        <b-col>
          <h4>{{ showTextImportPrice }}</h4>
        </b-col>
      </b-row>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          v-if="data.fileKey"
          class="d-flex float-left"
          variant="outline-primary"
          :type="$const.PRIMARY_BUTTON"
          @click="onClickSave"
        >
          <b-icon
            icon="file-earmark-fill"
          />Скачать
        </b-button>
        <div class="float-right">
          <b-button
            variant="danger"
            :type="$const.PRIMARY_BUTTON"
            @click="onClose"
          >
            Закрыть
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Loading from '@/components/Loading';

export default {
  name: 'AddPartnerPrices',
  components: {
    Loading,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    fileName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
    };
  },
  computed: {
    showTextImportPrice() {
      return this.data.hasErrors ? 'Прайс загружен с ошибками' : 'Прайс загружен без ошибок';
    },
  },
  methods: {
    async onClose() {
      this.isOpen = false;
    },
    async onClickSave() {
      await this.$store.dispatch(this.$types.GET_LABORATORY_PARTNER_PRICES,
        { fileKey: this.data.fileKey, fileName: this.fileName });
      this.onClose();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
