import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.PROMOCODES_GET](state) {
    return state.promocodes;
  },
  [localTypes.PROMOCODES_COUNT_GET](state) {
    return state.promocodes.length;
  },
  [localTypes.PROMOCODES_FILTER_GET](state) {
    return state.filter;
  },
  [localTypes.PROMOCODES_IS_LOADING_GET](state) {
    return state.isLoading;
  },
  [localTypes.PROMOCODES_IS_ALL_ITEMS_LOADED_GET](state) {
    return state.isAllItemsLoaded;
  },
};
