import LaboratoryApi from '@/services/laboratory/laboratory.api';

export default class LaboratoryService {
  constructor($axios) {
    this.api = new LaboratoryApi($axios);
  }

  /** Получить список отделений */
  async getLaboratoryBranchList(params) {
    const { data } = await this.api.getLaboratoryBranchList(params);

    return data.map((i) => ({
      ...i,
      price: 0,
    }));
  }

  /** Получить цену услуги по отделению */
  async getLaboratoryServicePrice(params) {
    const { data } = await this.api.getLaboratoryServicePrice(params);

    return data.clientCost;
  }

  getHistoryOrder(id) {
    return this.api.getHistoryOrder(id);
  }

  getPreparationList(payload) {
    return this.api.getPreparationList(payload);
  }

  getPreparation(payload) {
    return this.api.getPreparation(payload);
  }

  createPreparation(payload) {
    return this.api.createPreparation(payload);
  }

  deletePreparation(payload) {
    return this.api.deletePreparation(payload);
  }

  updatePreparation(id, payload) {
    return this.api.updatePreparation(id, payload);
  }
}
