import { showMessage } from '@/helpers/utils';

export function showDefaultErrorMessage(title) {
  showMessage({
    type: 'error',
    title: `При ${title} что-то пошло не так`,
  });
}

export function showErrorMessageGetUsers() {
  showMessage({
    type: 'error',
    title: 'Ошибка',
    message: 'Не удалось получить список ответственных лиц',
  });
}

export function showSuccessMessage(info, text) {
  showMessage({
    type: 'success',
    title: `Обращение ${info} успешно ${text}`,
  });
}

export function showValidationErrorMessage(textTitle = '') {
  showMessage({
    type: 'warning',
    title: `Ошибка валидации ${textTitle}`,
    message: 'Проверьте правильность заполнения полей',
  });
}

export function showValidationErrorCustomMessage(textMessage = '') {
  showMessage({
    type: 'warning',
    title: 'Ошибка',
    message: `${textMessage}`,
  });
}

export function showErrorCustomMessage(textMessage = '') {
  showMessage({
    type: 'error',
    title: 'Ошибка',
    message: `${textMessage}`,
  });
}

export function showSuccessCustomMessage(textMessage = '') {
  showMessage({
    type: 'success',
    title: 'Успешно',
    message: `${textMessage}`,
  });
}

export function showCustomMessage(type = '', title = '', message = '') {
  showMessage({
    type: `${type}`,
    title: `${title}`,
    message: `${message}`,
  });
}
