import { APP_HOST } from '@/app.config';

export default class LocationApi {
  constructor($axios) {
    this.api = $axios.instance;
  }

  async getCities(params) {
    const { data } = await this.api.get(`${APP_HOST}Cities/list`, { params });
    return data;
  }

  async getRegions(params) {
    const { data } = await this.api.get(`${APP_HOST}v2/regions/list`, { params });
    return data;
  }

  async getAllCities(query) {
    const { data } = await this.api.get(`${APP_HOST}Cities/list`, { name: query });
    return data;
  }

  async createRegion(params) {
    return this.api.post(`${APP_HOST}v2/regions`, params);
  }

  async updateRegion(params) {
    return this.api.put(`${APP_HOST}v2/regions`, params);
  }

  async deleteRegion(id) {
    return this.api.delete(`${APP_HOST}v2/regions`, { params: { id } });
  }
}
