import cloneDeep from 'lodash/cloneDeep';

import mutations, { defaultState } from './mutations';
import getters from './getters';
import actions from './actions';

export default {
  namespaced: true,
  state: {
    ...cloneDeep(defaultState),
  },
  actions,
  getters,
  mutations,
};
