import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.PATIENTS_SET](state, payload) {
    state.patients = payload;
  },
  [localTypes.PATIENTS_DELETED_SET](state, payload) {
    state.patientsDeleted = payload;
  },
  [localTypes.PATIENTS_ADD](state, payload) {
    state.patients = [...state.patients, ...payload];
  },
  [localTypes.PATIENTS_DELETED_ADD](state, payload) {
    state.patientsDeleted = [...state.patientsDeleted, ...payload];
  },
  [localTypes.PATIENT_CONSULTATIONS_SET](state, payload) {
    state.detailConsultations.consultations = payload;
  },
  [localTypes.PATIENT_CONSULTATIONS_ADD](state, payload) {
    state.detailConsultations.consultations = [...state.detailConsultations.consultations, ...payload];
  },
  [localTypes.PATIENT_DETAIL_CONSULATIONS_IS_LOADING_SET](state, payload) {
    state.detailConsultations.isLoading = payload;
  },
  [localTypes.PATIENT_DETAIL_CONSULATIONS_RESET](state) {
    state.detailConsultations.isAllItemsLoaded = false;
    state.detailConsultations.activeConsultation = false;
    state.detailConsultations.consultations = [];
  },
  [localTypes.PATIENT_DETAIL_ACTIVE_CONSULATION_SET](state, payload) {
    state.detailConsultations.activeConsultation = payload;
  },
  [localTypes.PATIENT_DETAIL_CONSULATIONS_IS_ALL_ITEMS_LOADED_SET](state, payload) {
    state.detailConsultations.isAllItemsLoaded = payload;
  },
  [localTypes.PATIENT_APPEALS_SET](state, payload) {
    state.detailAppeals.appeals = payload;
  },
  [localTypes.PATIENT_APPEALS_ADD](state, payload) {
    state.detailAppeals.appeals = [...state.detailAppeals.appeals, ...payload];
  },
  [localTypes.PATIENT_DETAIL_APPEALS_IS_LOADING_SET](state, payload) {
    state.detailAppeals.isLoading = payload;
  },
  [localTypes.PATIENT_APPEALS_RESPONSIBLES_SET](state, payload) {
    state.detailAppeals.responsibles = payload;
  },
  [localTypes.PATIENT_DETAIL_APPEALS_RESET](state) {
    state.detailAppeals.isAllItemsLoaded = false;
    state.detailAppeals.activeAppeal = false;
    state.detailAppeals.appeals = [];
  },
  [localTypes.PATIENT_DETAIL_ACTIVE_APPEAL_SET](state, payload) {
    state.detailAppeals.activeAppeal = payload;
  },
  [localTypes.PATIENT_DETAIL_APPEALS_IS_ALL_ITEMS_LOADED_SET](state, payload) {
    state.detailAppeals.isAllItemsLoaded = payload;
  },
  [localTypes.PATIENT_POLICY_SET](state, payload) {
    state.policy = payload;
  },
  [localTypes.PATIENT_ACTIVE_POLICY_ID_SET](state, payload) {
    state.activePolicyId = payload;
  },
  [localTypes.INSURANCE_SUBPROGRAMS_SET](state, payload) {
    state.insuranceSubprograms = payload;
  },
  [localTypes.INSURANCE_COMPANIES_SET](state, payload) {
    state.insuranceCompanies = payload;
  },
  [localTypes.PATIENT_SERVICES_SET](state, payload) {
    state.patientServices = payload;
  },
  [localTypes.PATIENT_POLICY_ID_SET](state, payload) {
    state.patientPolicyId = payload;
  },
  [localTypes.PATIENT_SERVICES_DEL](state) {
    state.patientServices = [];
  },
};
