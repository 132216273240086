<template>
  <div
    :class="{ disabled }"
    class="crm-tabs"
  >
    <div
      v-for="tab in items"
      :key="tab.value"
      :class="['crm-tab-wrapper', { 'crm-tab-wrapper_disabled': tab.disabled }]"
    >
      <span
        v-if="tab.description"
        class="crm-tab-title"
      >
        {{ tab.description }}
      </span>

      <div
        :id="`popover-${tab.value}`"
        :class="{ active: tab.value === value, highlight: tab.highlight }"
        class="crm-tab"
        @click="onClickTab(tab.value)"
      >
        {{ tab.title }}
      </div>

      <b-popover
        v-if="tab.isLocked"
        custom-class="tab-popover"
        :target="`popover-${tab.value}`"
        placement="bottom"
        triggers="hover focus"
        :content="`popover-${tab.value}`"
      >
        <div class="d-flex">
          <span class="pr-1">
            Статус:
          </span>
          <span>
            Новый
          </span>
        </div>
        <span>
          {{ tab?.request?.userFIO }}
        </span>
        <div class="popover-inner">
          ID: {{ tab?.request?.consultationId }}
        </div>
      </b-popover>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTabs',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClickTab(newValue) {
      if (this.disabled) return;

      this.$emit('change', newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-tabs {
  width: 100%;
  display: flex;
}
.crm-tab-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  &_disabled {
    opacity: 0.4 !important;
  }
}
.crm-tab-title {
  color: $gray-dark;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.crm-tab {
  display: flex;
  padding: 15px 0;
  border-bottom: 5px solid $blue-light;
  color: $gray-dark;
  font-size: 18px;
  line-height: 22px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;

  &.active {
    border-bottom: 5px solid $blue;
    color: $blue;
  }
}
.tab-popover{
  background-color: rgb(242, 247, 250);
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.5);
}
</style>
