import { APP_HOST } from '@/app.config';

export default class PersonApi {
  constructor($axios) {
    this.api = $axios.instance;
  }

  async getHistoryOfActions(params) {
    const { data, headers } = await this.api.get(`${APP_HOST}v2/person/change-history`, { params });
    const xTotalCount = headers['x-total-count'] || headers.get('x-total-count');

    return { data, xTotalCount };
  }

  async getPatients(params) {
    const { data: patients } = await this.api.get(`${APP_HOST}v2/person/searchPatient`, { params });
    return patients;
  }
}
