<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    :title="'История загрузок'"
    hide-footer
    @hidden="onClose"
    @ok="onClose"
  >
    <b-overlay
      v-if="isLoading"
      :show="isLoading"
      variant="transparent"
      rounded="lg"
      style="height: 10rem"
      opacity="0.6"
    />
    <div v-else>
      <custom-scrollbar>
        <b-table
          :bordered="true"
          :hover="true"
          :fields="fields"
          class="text-center"
          small
          show-empty
          :items="rows"
        >
          <template #empty="">
            <div class="d-flex justify-content-center align-items-center">
              <h4 class="p-2">
                Не найдено <b-icon
                  icon="exclamation-triangle-fill"
                  variant="danger"
                />
              </h4>
            </div>
          </template>
          <template v-slot:cell(createDate)="row">
            <span>{{ parseData(row.item.createDate) }}</span>
          </template>
          <template v-slot:cell(fileId)="row">
            <b-icon
              icon="file-earmark-excel"
              font-scale="2"
              class="fileIcon"
              @click="downloadTable(row.item.fileId)"
            />
          </template>
        </b-table>
      </custom-scrollbar>
    </div>
  </b-modal>
</template>

<script>
import {
  format,
  parseISO,
  addHours,
} from '@evd3v/date-fns';
import { api } from '@/helpers/api';
import { saveFile } from '@/helpers/utils';
import { clinicService } from '@/services';

export default {
  name: 'PricesHistoryModal',
  components: {},
  props: {
    clinicId: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      userData: {},
      rows: [],
      fields: [
        {
          key: 'createDate',
          label: 'Дата загрузки',
          tdClass: 'align-middle',
          sortable: true,
        },
        {
          key: 'authorName',
          label: 'Изменил',
          tdClass: 'align-middle',
          sortable: true,
        },
        {
          key: 'fileId',
          label: 'Файл загрузки',
          sortable: true,
        },
      ],
    };
  },
  async created() {
    this.isLoading = true;
    this.rows = await clinicService.getPricesHistory(this.clinicId);
    this.isLoading = false;
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    parseData(ISODate) {
      return format(parseISO(ISODate), 'dd.MM.yyyy');
    },
    formatDate(date) {
      const dateAdded = addHours(parseISO(date), 3);
      return format(dateAdded, 'dd.MM.yyyy HH:mm');
    },
    async downloadTable(id) {
      const { data: fileName } = await api.get(`File/name/${id}`);
      const { data: file } = await api.get(`File/${id}`, { responseType: 'blob' });
      saveFile(file, { name: fileName });
    },
  },
};
</script>

<style lang="scss" scoped>

.crm-table {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  &-row {
    display: flex;

    &--header {
      background: #F3F3F3;
      color: #6E88F3;
    }

    & + & {
      border-top: 1px solid #E0E0E0;
    }
  }

  &-cell {
    padding: 10px;
    width: 100%;

    &--date {
      flex-shrink: 0;
      width: 150px;
    }

    & + & {
      border-left: 1px solid #E0E0E0;
    }
  }
}
.fileIcon{
  cursor: pointer;
}
//.table th, .table td {
//  vertical-align: middle !important;
//}
</style>
