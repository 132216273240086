<template>
  <label class="custom-checkbox">
    <input
      v-model="model"
      type="checkbox"
      :value="label"
      :disabled="disabled"
    >
    <span
      :style="{ alignItems : alignTop ? 'flex-start' : 'center' }"
    >
      <slot />
    </span>
  </label>
</template>

<script>
export default {
  name: 'BaseCheckbox',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    label: {
      type: null,
      default: '',
    },
    value: {
      type: [Boolean, Array],
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    alignTop: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('change', val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-checkbox {
  position: relative;
  cursor: pointer;
  font-size: 12px;
  line-height: 15px;

  /* для элемента input c type="checkbox" */
  &>input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    /* стили при наведении курсора на checkbox */
    &:not(:disabled):not(:checked)+span:hover::before {
      border-color: $blue;
    }

    /* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
    &:focus:not(:checked)+span::before {
      border-color: $blue;
    }

    /* стили для чекбокса, находящегося в состоянии disabled */
    &:disabled+span::before {
      background-color: #e9ecef;
    }

    /* стили для чекбокса, находящегося в состоянии checked */
    &:checked+span::before {
      border-color: $blue;
      background-image: url("~assets/images/check_mark.svg?inline");
    }
  }

  /* для элемента label, связанного с .custom-checkbox */
  &>span {
    display: inline-flex;
    user-select: none;

    /* создание в label псевдоэлемента before со следующими стилями */
    &::before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1px solid $gray;
      border-radius: 4px;
      margin-right: 10px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
      background-color: #fff;
    }
  }
}
</style>
