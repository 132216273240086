v<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="s"
    :title="'Создание нового партнера'"
    @hidden="onClose"
    @ok="onClose"
  >
    <loading v-if="isLoading">
      Загрузка данных
    </loading>
    <div>
      <b-row>
        <b-col>
          <b-form-group
            label="Имя"
          >
            <b-form-input
              v-model="name"
              type="text"
              :placeholder="'Введите имя'"
              trim
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Номер телефона"
          >
            <b-form-input
              v-model="contactPhoneNumber"
              type="text"
              :placeholder="'Введите номер телефона'"
              trim
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Активирован">
            <b-form-select
              v-model="isActive"
              :options="optionsActivate"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="d-flex">
          <b-form-file
            v-model="partnerLogo"
            placeholder="Выберите файл"
            @input="onFileChange"
          />
          <b-button
            variant="danger"
            :type="$const.PRIMARY_BUTTON"
            class="w-75 ml-2"
            :disabled="!partnerLogo"
            @click="onDeleteLogo"
          >
            Удалить логотип
          </b-button>
        </b-col>
      </b-row>
      <b-row
        v-if="partnerLogo"
        class="mt-3"
      >
        <b-col>
          <b-img
            thumbnail
            fluid
            :src="logoPreview"
            alt="Не удалось загрузить файл"
          />
        </b-col>
      </b-row>
    </div>
    <template #modal-footer>
      <div>
        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          @click="onClickCreatePartner"
        >
          Создать
        </b-button>
        <b-button
          variant="danger"
          :type="$const.PRIMARY_BUTTON"
          class="ml-2"
          @click="onClose"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { addHours, format, parseISO } from '@evd3v/date-fns';
import Loading from '@/components/Loading';
import { api } from '@/helpers/api';
import { showCustomMessage } from '@/helpers/messages';
// import { clinicService } from '@/services';

export default {
  name: 'AddPartnerModal',
  components: {
    Loading,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    params: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      userData: {},
      rows: [],
      name: '',
      contactPhoneNumber: '',
      isActive: null,
      partnerLogo: null,
      logoPreview: null,
      optionsActivate: [
        { value: null, text: 'Выберите состояние', disabled: true },
        { value: true, text: 'Активирован' },
        { value: false, text: 'Деактивирован' },
      ],
      // modalName: 'PricesImportInClinic',
    };
  },
  async created() {
    // this.isLoading = true;
    // this.rows = await clinicService.getHistory(this.clinicId);
    // this.isLoading = false;
  },
  methods: {
    async onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onClinicReestrImport() {
      const params = {
        ...this.params,
      };
      await api.post('v2/clinic/services/import', params);
      this.onClose();
    },
    onDeleteLogo() {
      this.partnerLogo = null;
    },
    onFileChange() {
      if (!this.partnerLogo) return;

      const file = this.partnerLogo;
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        this.logoPreview = reader.result;
      };
    },
    async onClickCreatePartner() {
      const fd = new FormData();
      fd.set('name', this.name);
      fd.set('contactPhoneNumber', this.contactPhoneNumber);
      fd.set('isActive', this.isActive);
      fd.set('logo', this.partnerLogo);

      if (this.isActive && this.name) {
        await this.$store.dispatch(this.$types.LABORATORY_PARTNER_CREATE, fd);
        await this.$store.dispatch(this.$types.GET_LABORATORY_PARTNERS, this.params);
        this.onClose();
      } else {
        showCustomMessage('error', 'Ошибка', 'Проверьте правильность заполнения полей');
      }
    },
    formatDate(date) {
      const dateAdded = addHours(parseISO(date), 3);
      return format(dateAdded, 'dd.MM.yyyy HH:mm');
    },
  },
};
</script>

<style lang="scss" scoped>

.crm-table {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  &-row {
    display: flex;

    &--header {
      background: #F3F3F3;
      color: #6E88F3;
    }

    & + & {
      border-top: 1px solid #E0E0E0;
    }
  }

  &-cell {
    padding: 10px;
    width: 100%;

    &--date {
      flex-shrink: 0;
      width: 150px;
    }

    & + & {
      border-left: 1px solid #E0E0E0;
    }
  }
}

</style>
