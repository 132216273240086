<template>
  <b-nav-item
    v-if="childrenLinks.length === 0"
    :to="link.url"
    :disabled="link.url === '#'"
    exact
    exact-active-class="text-primary"
    class="text-secondary"
  >
    {{ link.name }}
  </b-nav-item>

  <div v-else>
    <div
      class="d-flex align-items-center justify-content-between"
      @click="() => isDropdownOpen = !isDropdownOpen"
    >
      <b-nav-item
        :to="link.url"
        :disabled="link.url === '#'"
        exact
        exact-active-class="text-primary"
        class="text-secondary"
      >
        {{ link.name }}
      </b-nav-item>

      <b-icon
        :icon="isDropdownOpen ? 'folder2-open' :'folder'"
        class="text-secondary mr-2"
        style="font-size: 1.2rem;"
      />
    </div>

    <transition
      name="fade"
    >
      <div
        v-if="isDropdownOpen"
        class="pl-3"
      >
        <navbar-item
          v-for="childrenLink in childrenLinks"
          :key="childrenLink.id"
          :link="childrenLink"
        />
      </div>
    </transition>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-self-import
import NavbarItem from '@/components/NavbarItem';

export default {
  name: 'NavbarItem',
  components: {
    NavbarItem,
  },
  props: {
    link: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isDropdownOpen: false,
    };
  },
  computed: {
    childrenLinks() {
      return this.link.childPages?.filter((p) => p.isMenuItem) || [];
    },
    hasActivePage() {
      if (this.childrenLinks.some((child) => child.url === this.$route.path)) {
        return true;
      }
      return this.childrenLinks.some((child) => {
        if (child.childPages && child.childPages.length > 0) {
          return this.checkChildPages(child.childPages);
        }
        return false;
      });
    },
  },
  created() {
    this.isDropdownOpen = this.hasActivePage;
  },
  methods: {
    checkChildPages(childPages) {
      if (childPages.some((page) => page.url === this.$route.path)) {
        return true;
      }
      return childPages.some((page) => {
        if (page.childPages && page.childPages.length > 0) {
          return this.checkChildPages(page.childPages);
        }
        return false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
