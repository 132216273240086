<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="md"
    :title="'Удалить пользователя?'"
    hide-footer
    @hidden="onClose"
    @ok="onClose"
  >
    <div
      v-if="currentUserData"
      class="d-flex justify-content-center"
    >
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        :disabled="isLoading"
        @click="onSave"
      >
        Да
      </b-button>

      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        class="float-right ml-2"
        :disabled="isLoading"
        @click="onClose"
      >
        Нет
      </b-button>
    </div>
  </b-modal>
</template>

<script>
// import {
//   // formatISO,
//   format,
// } from '@evd3v/date-fns';
import { showErrorCustomMessage } from '@/helpers/messages';

export default {
  name: 'UserViewModal',
  components: {
  },
  props: {
    userId: {
      type: [String, Number],
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
    currentUserData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      passwordShow: true,
      isLoading: false,
      passwordInputType: 'password',
      userData: {},
    };
  },
  methods: {
    passwordDisplay() {
      this.passwordInputType = this.passwordInputType === 'password' ? 'text' : 'password';
      this.passwordShow = !this.passwordShow;
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onSave() {
      try {
        await this.$store.dispatch(this.$types.USER_DELETE, this.currentUserData.item.id);
        this.$store.commit(this.$types.TOGGLE_FETCH_USERS_TRIGGER);
      } catch (e) {
        console.error(e);
        showErrorCustomMessage('Что-то пошло не так');
      } finally {
        this.onClose();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
