<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    title="Загрузка документов"
    hide-footer
    @hidden="onClose"
    @ok="onClose"
  >
    <b-container
      fluid="sm"
      class="mt-4"
    >
      <b-row>
        <b-col>
          <b-form-file
            v-model="contractFile"
            placeholder="Добавить договор"
            @input="sendFile(contractFile, 1)"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-file
            v-model="pricesFile"
            placeholder="Добавить прайс"
            @input="sendFile(pricesFile, 4)"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-file
            v-model="licenseFile"
            placeholder="Добавить лицензию"
            @input="sendFile(licenseFile, 3)"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-file
            v-model="scanFile"
            placeholder="Добавить скан"
            @input="sendFile(scanFile, 5)"
          />
        </b-col>
      </b-row>
      <div class="d-flex justify-content-end mt-4">
        <transition name="slide-fade">
          <b-button
            v-if="isFileLoaded"
            class="mr-2"
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            @click="onClickAdd"
          >
            Ок
          </b-button>
        </transition>
        <b-button
          variant="danger"
          :type="$const.PRIMARY_BUTTON"
          @click="onClickClose"
        >
          Отменить
        </b-button>
      </div>
    </b-container>
  </b-modal>
</template>

<script>
import { api } from '@/helpers/api';
import { getFileExtension } from '@/helpers/utils';
import { formatISO } from '@evd3v/date-fns';

export default {
  name: 'AddClinicDownloadFileModal',
  components: {},
  props: {
    saveFileCallback: {
      type: Function,
      default: () => {},
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    fileApi: {
      type: String,
      default: '',
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      files: [],
      contractFile: null,
      licenseFile: null,
      pricesFile: null,
      scanFile: null,
      isSaving: false,
      test: '232',
    };
  },
  computed: {
    filesPending() {
      return this.files.filter((file) => file.status === 'pending');
    },
    filesSuccess() {
      return this.files.filter((file) => file.status === 'success');
    },
    filesError() {
      return this.files.filter((file) => file.status === 'error');
    },
    computedFilesForInput() {
      return this.files.map((file) => file.file);
    },
    isDisabledContinueButton() {
      return !!this.filesPending.length || !this.files.length;
    },
    isFileLoaded() {
      return this.contractFile || this.licenseFile || this.pricesFile || this.scanFile;
    },
    clinicDocuments() {
      return this.$store.state.ClinicDocuments.documents;
    },
  },
  methods: {
    onAddFiles(event) {
      this.files = [...this.files, ...event.map((file) => ({ name: file.name, status: 'pending', file }))];

      this.filesPending.forEach((fileObject) => this.sendFile(fileObject));
    },
    async sendFile(fileObject, typeDoc) {
      const checkSimilarTypes = this.files.findIndex((file) => file.type === typeDoc);
      if (checkSimilarTypes !== -1) {
        this.$store.dispatch(this.$types.CLINIC_DOCUMENTS_DELETE, {
          document: this.files[checkSimilarTypes],
          isFetch: false,
        });
        this.files[checkSimilarTypes] = [...this.files, { name: fileObject.name, status: 'pending', fileObject }];
      } else {
        this.files = [...this.files, { name: fileObject.name, status: 'pending', fileObject }];
      }

      const extension = getFileExtension(fileObject.name);

      try {
        const fd = new FormData();

        if (this.fileApi) {
          fd.append('file', fileObject);
        }

        const { data: { guid } } = await api.post(this.fileApi || `File/${extension}`, this.fileApi ? fd : fileObject, {
          headers: {
            'Content-Type': fileObject.type,
          },
        });

        const existingFileIndex = this.files.indexOf(fileObject);

        this.files.splice(existingFileIndex, 1, {
          fileId: guid,
          type: typeDoc,
          title: fileObject.name,
          timeCreated: formatISO(new Date()),
        });
      } catch (e) {
        const existingFileIndex = this.files.indexOf(fileObject);

        this.files.splice(existingFileIndex, 1, { ...fileObject, status: 'error' });
      }
    },
    onClickAdd() {
      if (this.clinicDocuments.length) {
        this.clinicDocuments.forEach((doc) => this.files.push(doc));
      }
      this.$store.commit(this.$types.CLINIC_DOCUMENTS_SET, this.files);
      this.onClose();
    },
    onClickClose() {
      if (this.files.length) {
        this.files.forEach((item) => this.deleteDocument(item.fileId));
      }
      this.onClose();
    },
    deleteDocument(id) {
      this.$store.dispatch(this.$types.CLINIC_DOCUMENTS_DELETE, {
        document: id,
        isFetch: false,
      });
    },
    onClickContinue() {
      this.saveFileCallback(this.filesSuccess);
      this.onClose();
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
  },
};
</script>

<style lang="scss" scoped>
.files-title {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 20px 0;

  &_pending {
    color: $orange;
  }

  &_success {
    color: $green;
  }

  &_error {
    color: $red;
  }
}
label{
  display: block;
}

.textDoc{
  font-size: 16px;
}
.row{
  margin-bottom: 0.5rem;
}
</style>
