import mutations from './mutations';
import getters from './getters';
import actions from './actions';

export default {
  namespaced: true,
  state: {
    clinics: [],
    clinicListNames: [],
    pinnedDoctors: [],
    pricesList: [],
    pricesListPaginationCount: 0,
    pricesListLoading: false,
    isClinicLoading: false,
    isChangedClinicInfo: false,
    isChangedClinicDoctorsInfo: false,
    clinic: {
      info: null,
      specializations: null,
      doctors: null,
      documents: null,
    },
    isEditClinicInfo: false,
    isEditClinicDoctors: false,
  },
  actions,
  getters,
  mutations,
};
