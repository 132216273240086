<template>
  <div class="modal-step">
    <div class="crm-scrollbar-wrapper">
      <custom-scrollbar>
        <div class="crm-container indemtity-wrapper pr-3">
          <div class="crm-form-fields">
            <div class="crm-form-field crm-form-field_align-center">
              <indemnity-arow-left-button @click="clickPrev" />
              <p class="page-title">
                Перечень оказанных услуг
              </p>
            </div>

            <div class="crm-form-field crm-form-field_justify_end">
              <span class="program-name">
                {{ letter.insuranceProgramName }}
              </span>
            </div>
          </div>

          <div class="guarantee-letter-wrapper">
            <div class="crm-flex-row crm-justify-between crm-align-center">
              <b-button
                v-if="letter.priceType === 0"
                variant="primary"
                :type="$const.PRIMARY_BUTTON"
                size="sm"
                @click="toTemplates"
              >
                Добавить шаблон
              </b-button>

              <b-button
                v-if="letter.priceType === 1"
                variant="primary"
                :type="$const.PRIMARY_BUTTON"
                size="sm"
                @click="toNosologicTemplates"
              >
                Добавить сервис
              </b-button>

              <b-button
                v-if="letter.priceType === 2"
                variant="primary"
                :type="$const.PRIMARY_BUTTON"
                size="sm"
                @click="toLinkedTemplates"
              >
                Добавить шаблон
              </b-button>

              <b-button
                v-if="letter.priceType === 3"
                variant="primary"
                :type="$const.PRIMARY_BUTTON"
                size="sm"
                @click="toPriceTemplates"
              >
                Добавить шаблон
              </b-button>

              <b-button
                :variant="isEdit ? 'primary' : 'outline-primary'"
                size="sm"
                class="ml-auto"
                :type="$const.PRIMARY_BUTTON"
                @click="isEditToggle"
              >
                <b-icon icon="pencil" />
              </b-button>
            </div>

            <div class="d-flex mt-3">
              <div class="w-50">
                <div class="crm-label mb-3 mt-0">
                  Тип обращения
                </div>
                <div class="crm-radio-buttons d-flex">
                  <base-radio-button
                    v-for="checkupType in checkupTypes"
                    :key="checkupType"
                    v-model="$v.checkup.$model"
                    class="crm-radio-button mr-4 mt-0"
                    :name="checkupType"
                    :disabled="!isEdit || letter.isEdited"
                  >
                    {{ checkupType }}
                  </base-radio-button>
                </div>
                <div
                  v-if="$v.checkup.$error"
                  class="validation-errors position-static margin-left"
                >
                  <span
                    class="validation-error-text"
                  >
                    {{ `${errorsValidation.checkup}` }}
                  </span>
                </div>
              </div>

              <div
                v-if="[2, 3].includes(letter.priceType)"
                class="ml-3 w-50"
              >
                <div
                  class="crm-label mb-3 mt-0"
                  :class="{ error: priceCategory == null }"
                >
                  Тип прайса
                </div>
                <div class="crm-radio-buttons d-flex">
                  <base-radio-button
                    v-for="category in priceCategories"
                    :key="category.id"
                    v-model="priceCategory"
                    class="crm-radio-button mr-4 mt-0"
                    :name="category.id"
                    :disabled="!isEdit || letter.isEdited"
                  >
                    {{ category.title }}
                  </base-radio-button>
                </div>

                <div
                  v-if="$v.priceCategory.$error"
                  class="validation-errors position-static margin-left"
                >
                  <span
                    class="validation-error-text"
                  >
                    {{ `${errorsValidation.priceCategory}` }}
                  </span>
                </div>

                <div class="d-flex">
                  <b-form-checkbox
                    v-model="letter.isWithoutPrice"
                    class="mr-4"
                    size="sm"
                    :disabled="!isEdit"
                  >
                    Без цены
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="withoutLimit"
                    size="sm"
                    :disabled="!isEdit"
                  >
                    Без лимита
                  </b-form-checkbox>
                </div>
              </div>
            </div>

            <template v-if="letter.priceType === 0">
              <template v-if="isEdit">
                <div class="guarantee-letter-edit-header crm-flex-row crm-justify-between">
                  <div class="crm-column">
                    <div class="crm-title">
                      Добавить столбцы
                    </div>
                    <div class="checkbox-block">
                      <!-- <base-checkbox
                        v-model="letter.tableColumns['№']"
                        class="crm-checkbox"
                        @change="toggleShowServiceNumber"
                      >
                        Номер услуги
                      </base-checkbox> -->
                    </div>
                    <div class="crm-checkbox-block">
                      <base-checkbox
                        v-model="letter.tableColumns['Код услуги']"
                        class="crm-checkbox"
                        @change="toggleShowServiceCode"
                      >
                        Код услуги
                      </base-checkbox>
                    </div>
                    <div class="crm-checkbox-block">
                      <base-checkbox
                        v-model="letter.tableColumns['Количество услуг']"
                        class="crm-checkbox"
                        @change="toggleShowServiceCount"
                      >
                        К-во услуг
                      </base-checkbox>
                    </div>
                    <div class="crm-checkbox-block">
                      <base-checkbox
                        v-model="letter.tableColumns['Стоимость за единицу']"
                        class="crm-checkbox"
                        @change="toggleShowServicePrice"
                      >
                        Стоимость за единицу
                      </base-checkbox>
                    </div>
                    <div class="crm-checkbox-block">
                      <base-checkbox
                        v-model="letter.tableColumns['Итоговая стоимость']"
                        class="crm-checkbox"
                        @change="toggleShowServiceSum"
                      >
                        Итоговая стоимость
                      </base-checkbox>
                    </div>
                  </div>

                  <div class="crm-column">
                    <div class="crm-title">
                      Добавить строки
                    </div>

                    <div
                      class="crm-checkbox-block"
                    >
                      <div class="row-interface">
                        <div
                          class="row-interface-btn"
                          @click="removeTableRow(letter.tableRows.length - 1)"
                        >
                          -
                        </div>
                        <div class="row-interface-count">
                          {{ letter.tableRows.length }}
                        </div>
                        <div
                          class="row-interface-btn"
                          @click="addTableRow(letter.tableRows.length)"
                        >
                          +
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="crm-column">
                    <base-button
                      v-if="letter.finalRow"
                      :type="$const.SECONDARY_BUTTON"
                      class="crm-button button_transparent without-border width-unset w300 gray"
                      @click="toggleFinalRow"
                    >
                      Скрыть итоговую строку
                    </base-button>
                    <base-button
                      v-else
                      :type="$const.SECONDARY_BUTTON"
                      class="crm-button button_transparent without-border width-unset w300"
                      @click="toggleFinalRow"
                    >
                      <icon-cross class="crm-icon-cross" />
                      Добавить итоговую строку
                    </base-button>
                    <base-button
                      :type="$const.SECONDARY_BUTTON"
                      class="crm-button button_transparent without-border width-unset w300"
                      @click="clearAllRows"
                    >
                      Удалить все услуги
                    </base-button>
                  </div>
                </div>
              </template>

              <indemnity-template-table
                class="mt-3"
              />
            </template>

            <template v-if="letter.priceType === 1">
              <div
                v-if="isEdit"
                class="mt-3"
              >
                <div class="crm-label">
                  Поиск нозологических сервисов
                </div>

                <div class="d-flex">
                  <b-input
                    v-model="nosologicalSearch.code"
                    placeholder="Код сервиса"
                    class="mr-2"
                    size="sm"
                    @keyup.native.enter="onNosologicalSearch"
                  />

                  <b-input
                    v-model="nosologicalSearch.name"
                    placeholder="Название сервиса"
                    class="mr-2"
                    size="sm"
                    @keyup.native.enter="onNosologicalSearch"
                  />

                  <b-button
                    variant="primary"
                    size="sm"
                    :type="$const.PRIMARY_BUTTON"
                    :disabled="nosologicalSearch.isLoading"
                    @click="onNosologicalSearch"
                  >
                    <b-icon icon="search" />
                  </b-button>
                </div>

                <b-table
                  v-if="nosologicalSearch.data.length && !nosologicalSearch.isLoading"
                  :fields="nosologicalSearch.fields"
                  :items="nosologicalSearch.data"
                  class="mt-3 nosological-service-table"
                  bordered
                  size="sm"
                >
                  <template #cell(prices)="row">
                    <div
                      v-for="price in row.item.prices"
                      :key="price.code"
                      class="mb-1"
                    >
                      {{ price.code }}, {{ price.name }}, {{ price.cost }} р.
                    </div>
                  </template>

                  <template #cell(actions)="row">
                    <b-button
                      size="sm"
                      variant="primary"
                      :type="$const.PRIMARY_BUTTON"
                      @click="onNosologicalPricesAdd(row.item.prices)"
                    >
                      Добавить
                    </b-button>
                  </template>
                </b-table>

                <div
                  v-else-if="!nosologicalSearch.isLoading"
                  class="mt-3"
                >
                  Нозологические сервисы не найдены
                </div>

                <div
                  v-else
                  class="mt-3 text-center"
                >
                  <b-spinner variant="primary" />
                </div>
              </div>

              <div class="crm-label mt-3">
                Нозологические услуги
              </div>

              <b-table
                v-if="letter.nosologicalServices?.length"
                :fields="nosologicalServiceFields"
                :items="letter.nosologicalServices"
                class="mt-3 nosological-service-table-2"
                bordered
                size="sm"
              >
                <template #cell(cost)="row">
                  {{ row.item.cost }} р.
                </template>

                <template #cell(actions)="row">
                  <b-button
                    size="sm"
                    variant="danger"
                    :type="$const.PRIMARY_BUTTON"
                    @click="onNosologicalPriceRemove(row.item.id)"
                  >
                    <b-icon icon="trash" />
                  </b-button>
                </template>
              </b-table>

              <div
                v-else
                class="mt-3"
              >
                Нозологические услуги не найдены
              </div>
            </template>

            <template v-if="letter.priceType === 2">
              <div
                v-if="isEdit"
                class="mt-2"
              >
                <div class="crm-label">
                  Поиск услуг
                </div>

                <div class="d-flex">
                  <b-input
                    v-model="priceSearch.code"
                    placeholder="Код услуги"
                    class="mr-2"
                    size="sm"
                    @keyup.native.enter="onPriceSearch"
                  />

                  <b-input
                    v-model="priceSearch.name"
                    placeholder="Название услуги"
                    class="mr-2"
                    size="sm"
                    @keyup.native.enter="onPriceSearch"
                  />

                  <b-button
                    variant="primary"
                    :type="$const.PRIMARY_BUTTON"
                    size="sm"
                    :disabled="priceSearch.isLoading || letter.priceCategory == null"
                    @click="onPriceSearch"
                  >
                    <b-icon icon="search" />
                  </b-button>
                </div>

                <b-table
                  v-if="priceSearch.prices.length && !priceSearch.isLoading"
                  :fields="priceSearch.fields"
                  :items="priceSearch.prices"
                  class="mt-3 price-table"
                  size="sm"
                  sticky-header
                  bordered
                >
                  <template #cell(prices)="row">
                    <div
                      v-for="price in row.item.prices"
                      :key="price.code"
                      class="mb-1"
                    >
                      {{ price.code }}, {{ price.name }}, {{ price.cost }} р.
                    </div>
                  </template>

                  <template #cell(actions)="row">
                    <b-button
                      v-if="!getSelectedPriceById(row.item.id)"
                      size="sm"
                      variant="primary"
                      :type="$const.PRIMARY_BUTTON"
                      class="w-100"
                      @click="onPriceAdd(row.item)"
                    >
                      Добавить
                    </b-button>

                    <div
                      v-else
                      class="d-flex justify-content-center align-items-center"
                    >
                      <b-button
                        variant="primary"
                        :type="$const.PRIMARY_BUTTON"
                        size="sm"
                        class="mr-1 py-0"
                        @click="onPriceDecrease(row.item.id)"
                      >
                        -
                      </b-button>

                      <div
                        class="text-center"
                        style="width: 20px"
                      >
                        {{ getSelectedPriceById(row.item.id)?.count }}
                      </div>

                      <b-button
                        variant="primary"
                        :type="$const.PRIMARY_BUTTON"
                        size="sm"
                        class="ml-1 py-0"
                        @click="onPriceIncrease(row.item.id)"
                      >
                        +
                      </b-button>
                    </div>
                  </template>
                </b-table>

                <div
                  v-else-if="!priceSearch.isLoading"
                  class="mt-3"
                >
                  Услуги не найдены
                </div>

                <div
                  v-else
                  class="mt-3 text-center"
                >
                  <b-spinner variant="primary" />
                </div>
              </div>

              <hr class="my-4 font-weight-bold">

              <div class="d-flex  align-items-center mt-2">
                <div class="crm-label">
                  Выбранные услуги
                </div>

                <b-button
                  size="sm"
                  variant="primary"
                  :type="$const.PRIMARY_BUTTON"
                  class="ml-auto"
                  @click="priceSearch.isSelectedPricesOpen = !priceSearch.isSelectedPricesOpen"
                >
                  {{ priceSearch.isSelectedPricesOpen ? 'Свернуть' : 'Развернуть' }}
                </b-button>
              </div>

              <template v-if="priceSearch.isSelectedPricesOpen">
                <b-table
                  v-if="priceSearch.selectedPrices?.length"
                  :fields="priceSearch.fields"
                  :items="priceSearch.selectedPrices"
                  class="mt-3 price-table"
                  size="sm"
                  sticky-header
                  bordered
                >
                  <template #cell(prices)="row">
                    <div
                      v-for="price in row.item.prices"
                      :key="price.code"
                      class="mb-1"
                    >
                      {{ price.code }}, {{ price.name }}, {{ price.cost }} р.
                    </div>
                  </template>

                  <template #cell(actions)="row">
                    <div
                      class="d-flex justify-content-center align-items-center"
                    >
                      <b-button
                        variant="primary"
                        :type="$const.PRIMARY_BUTTON"
                        size="sm"
                        class="mr-1 py-0"
                        @click="onPriceDecrease(row.item.id)"
                      >
                        -
                      </b-button>

                      <div
                        class="text-center"
                        style="width: 20px"
                      >
                        {{ getSelectedPriceById(row.item.id)?.count }}
                      </div>

                      <b-button
                        variant="primary"
                        :type="$const.PRIMARY_BUTTON"
                        size="sm"
                        class="ml-1 py-0"
                        @click="onPriceIncrease(row.item.id)"
                      >
                        +
                      </b-button>
                    </div>
                  </template>
                </b-table>

                <div
                  v-else
                  class="mt-3"
                >
                  Услуги не выбраны
                </div>
              </template>

              <div class="crm-label align-items-center mt-2">
                Клиники с выбранными услугами
              </div>

              <div
                v-if="priceSearch.isClinicsLoading"
                class="mt-3 text-center"
              >
                <b-spinner variant="primary" />
              </div>

              <b-table
                v-else-if="priceSearch.clinics?.length || priceSearch.selectedClinics?.length"
                :fields="priceSearch.clinicFields"
                :items="priceSearch.clinics.length ? priceSearch.clinics : priceSearch.selectedClinics"
                class="mt-3 price-table selected-clinics-table"
                sticky-header
                bordered
                size="sm"
              >
                <template #cell(clinic)="row">
                  {{ row.item.clinic.title }}, {{ row.item.clinic.addressString }}
                </template>
                <template #cell(clinicPrices)="row">
                  <div
                    v-for="(price, i) in row.item.clinicPrices"
                    :key="price.code + i"
                    class="mb-1"
                  >
                    {{ price.code }}, {{ price.name }}, {{ price.cost }} р.
                  </div>
                </template>

                <template #cell(sum)="row">
                  {{ row.item.sum }} р.
                </template>

                <template #cell(actions)="row">
                  <b-form-checkbox
                    v-model="priceSearch.selectedClinics"
                    :value="row.item"
                  />
                </template>
              </b-table>

              <div
                v-else
                class="mt-3 mb-2"
              >
                Клиники не найдены
              </div>

              <div
                v-if="$v.priceSearch.selectedClinics.$error"
                class="validation-errors position-static margin-left"
              >
                <span
                  class="validation-error-text"
                >
                  {{ errorsValidation.selectedClinics[0] }}
                </span>
              </div>

              <div class="mt-3">
                <div class="crm-label align-items-center mb-3">
                  Прочие услуги
                </div>
                <base-input
                  v-model="serviceString"
                  class="guarantee-letter-field mt-0"
                  type="textarea"
                  :disabled="!isEdit"
                  :textarea-props="{
                    rows: 3,
                  }"
                />
              </div>
            </template>

            <template v-if="letter.priceType === 3">
              <div
                v-if="isEdit"
                class="mt-3"
              >
                <div class="crm-label">
                  Поиск услуг
                </div>

                <div class="d-flex">
                  <b-input
                    v-model="clinicPriceSearch.code"
                    placeholder="Код услуги"
                    class="mr-2"
                    size="sm"
                    @keyup.native.enter="onClinicPriceSearch"
                  />

                  <b-input
                    v-model="clinicPriceSearch.name"
                    placeholder="Название услуги"
                    class="mr-2"
                    size="sm"
                    @keyup.native.enter="onClinicPriceSearch"
                  />

                  <b-button
                    variant="primary"
                    :type="$const.PRIMARY_BUTTON"
                    size="sm"
                    :disabled="clinicPriceSearch.isLoading || priceCategory == null"
                    @click="onClinicPriceSearch"
                  >
                    <b-icon icon="search" />
                  </b-button>
                </div>

                <b-table
                  v-if="clinicPriceSearch.prices.length && !clinicPriceSearch.isLoading"
                  :fields="clinicPriceSearch.fields"
                  :items="clinicPriceSearch.prices"
                  class="mt-3 price-table"
                  size="sm"
                  sticky-header
                  bordered
                >
                  <template #cell(cost)="row">
                    {{ row.item.cost }} р.
                  </template>

                  <template #cell(actions)="row">
                    <b-button
                      v-if="!getSelectedClinicPriceById(row.item.id)"
                      size="sm"
                      variant="primary"
                      :type="$const.PRIMARY_BUTTON"
                      class="w-100"
                      @click="onClinicPriceAdd(row.item)"
                    >
                      Добавить
                    </b-button>

                    <div
                      v-else
                      class="d-flex justify-content-center align-items-center"
                    >
                      <b-button
                        variant="primary"
                        :type="$const.PRIMARY_BUTTON"
                        size="sm"
                        class="mr-1 py-0"
                        @click="onClinicPriceDecrease(row.item.id)"
                      >
                        -
                      </b-button>

                      <b-form-input
                        class="pl-3"
                        type="number"
                        :style="{ width: inputWidth(getSelectedClinicPriceById(row.item.id)?.count) + 'px' }"
                        :value="getSelectedClinicPriceById(row.item.id)?.count || 1"
                        @input="handleInput(row.item.id, $event)"
                        @keypress="filterNonNumericInput($event)"
                      />

                      <b-button
                        variant="primary"
                        :type="$const.PRIMARY_BUTTON"
                        size="sm"
                        class="ml-1 py-0"
                        @click="onClinicPriceIncrease(row.item.id)"
                      >
                        +
                      </b-button>
                    </div>
                  </template>
                </b-table>

                <div
                  v-else-if="!clinicPriceSearch.isLoading"
                  class="mt-3"
                >
                  Услуги не найдены
                </div>

                <div
                  v-else
                  class="mt-3 text-center"
                >
                  <b-spinner variant="primary" />
                </div>
              </div>

              <hr class="my-4 font-weight-bold">

              <div class="d-flex  align-items-center mt-2">
                <div class="crm-label">
                  Выбранные услуги
                </div>

                <b-button
                  size="sm"
                  variant="primary"
                  :type="$const.PRIMARY_BUTTON"
                  class="ml-auto"
                  @click="clinicPriceSearch.isSelectedPricesOpen = !clinicPriceSearch.isSelectedPricesOpen"
                >
                  {{ clinicPriceSearch.isSelectedPricesOpen ? 'Свернуть' : 'Развернуть' }}
                </b-button>
              </div>

              <template v-if="clinicPriceSearch.isSelectedPricesOpen">
                <b-table
                  v-if="clinicPriceSearch.selectedPrices?.length"
                  :fields="clinicPriceSearch.fields"
                  :items="clinicPriceSearch.selectedPrices"
                  class="mt-3 price-table"
                  :tbody-tr-class="trClass"
                  size="sm"
                  sticky-header
                  bordered
                >
                  <template #cell(cost)="row">
                    {{ row.item.cost }} р.
                  </template>

                  <template #cell(actions)="row">
                    <div
                      class="d-flex justify-content-center align-items-center"
                    >
                      <b-button
                        variant="primary"
                        :type="$const.PRIMARY_BUTTON"
                        size="sm"
                        class="mr-1 py-0"
                        @click="onClinicPriceDecrease(row.item.id)"
                      >
                        -
                      </b-button>

                      <b-form-input
                        class="pl-3"
                        type="number"
                        :style="{ width: inputWidth(getSelectedClinicPriceById(row.item.id)?.count) + 'px' }"
                        :value="getSelectedClinicPriceById(row.item.id)?.count || 1"
                        @input="handleInput(row.item.id, $event)"
                        @keypress="filterNonNumericInput($event)"
                      />

                      <b-button
                        variant="primary"
                        :type="$const.PRIMARY_BUTTON"
                        size="sm"
                        class="ml-1 py-0"
                        @click="onClinicPriceIncrease(row.item.id)"
                      >
                        +
                      </b-button>
                    </div>
                  </template>
                </b-table>

                <div
                  v-else
                  class="mt-3"
                >
                  Услуги не выбраны
                </div>
              </template>

              <div
                v-if="$v.clinicPriceSearch.selectedPrices.$error"
                class="validation-errors position-static margin-left"
              >
                <span
                  class="validation-error-text"
                >
                  {{ errorsValidation.selectedClinicPrices[0] }}
                </span>
              </div>

              <div class="mt-2">
                <div class="crm-label align-items-center mb-3">
                  Прочие услуги
                </div>
                <base-input
                  v-model="serviceString"
                  class="guarantee-letter-field mt-0"
                  type="textarea"
                  :disabled="!isEdit"
                  :textarea-props="{
                    rows: 3,
                  }"
                />
              </div>
            </template>

            <div
              class="guarantee-letter-fields mt-3"
            >
              <base-input
                v-model="sumString"
                class="guarantee-letter-field"
                label="Итоговая сумма прописью:"
                type="textarea"
                :disabled="!isEdit"
                :textarea-props="{
                  rows: 1,
                }"
              />

              <base-input
                :value="limitsData?.balance"
                class="guarantee-letter-field"
                label="Баланс страхового покрытия:"
                disabled
              />

              <base-input
                v-model="paymentConditionString"
                class="guarantee-letter-field"
                label="Условия об оплате услуг"
                type="textarea"
                :disabled="!isEdit"
                :textarea-props="{
                  rows: 1,
                }"
              />

              <base-input
                v-model="additionalConditionString"
                class="guarantee-letter-field"
                label="Дополнительные условия"
                type="textarea"
                :disabled="!isEdit"
                :textarea-props="{
                  rows: 3,
                }"
              />
            </div>
          </div>
        </div>
      </custom-scrollbar>
    </div>

    <div class="crm-wrapper-buttons">
      <b-button
        variant="outline-primary"
        :type="$const.PRIMARY_BUTTON"
        class="mr-2"
        :disabled="isLetterSaving"
        @click="onClickCancel"
      >
        Отменить
      </b-button>
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class=""
        :disabled="isLetterSaving"
        @click="onClickNext"
      >
        Продолжить
      </b-button>
    </div>
  </div>
</template>

<script>

import { validationMixin } from 'vuelidate';
import {
  showCustomMessage,
  showErrorCustomMessage,
  showValidationErrorMessage,
} from '@/helpers/messages';
import IconCross from 'assets/images/cross.svg';
// import IconDelete from 'assets/images/delete_icon.svg';
// import PlusRowIcon from 'assets/images/plus-row-icon.svg';

import {
  BaseInput,
  BaseButton,
  BaseCheckbox,
  BaseRadioButton,
} from '@/components/base';

import { required } from 'vuelidate/lib/validators';
import IndemnityArowLeftButton from './IndemnityArowLeftButton';
import IndemnityTemplateTable from '../IndemnityTemplateTable';

export default {
  name: 'IndemnityModalStep3',
  components: {
    IndemnityTemplateTable,
    BaseButton,
    BaseRadioButton,
    IndemnityArowLeftButton,
    IconCross,
    BaseInput,
    BaseCheckbox,
    // PlusRowIcon,
    // IconDelete,
  },
  mixins: [validationMixin],
  props: {
    saveToTabTrigger: {
      type: Boolean,
      default: false,
    },
    tabSelectedPrices: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      showServiceCode: false,
      showServicePrice: false,
      withoutLimit: false,
      limitsData: null,
      checkupTypes: [
        'Полный чекап',
        'Неполный чекап',
        'Доп. услуги',
      ],
      nosologicalSearch: {
        code: '',
        name: '',
        isLoading: false,
        fields: [
          {
            key: 'code',
            label: 'Код сервиса',
          },
          {
            key: 'name',
            label: 'Название сервиса',
          },
          {
            key: 'prices',
            label: 'Услуги',
          },
          {
            key: 'actions',
            label: '',
            tdClass: 'action-td',
            thClass: 'action-td',
          },
        ],
        data: [],
      },
      nosologicalServiceFields: [
        {
          key: 'code',
          label: 'Код услуги',
        },
        {
          key: 'name',
          label: 'Название услуги',
        },
        {
          key: 'cost',
          label: 'Цена',
        },
        {
          key: 'actions',
          label: '',
          tdClass: 'action-td',
          thClass: 'action-td',
        },
      ],
      priceSearch: {
        code: '',
        name: '',
        isLoading: false,
        isSelectedPricesOpen: false,
        isClinicsLoading: false,
        fields: [
          {
            key: 'code',
            label: 'Код услуги',
          },
          {
            key: 'name',
            label: 'Название услуги',
          },
          {
            key: 'actions',
            label: '',
            tdClass: 'action-td',
            thClass: 'action-td',
          },
        ],
        prices: [],
        selectedPrices: [],
        clinics: [],
        selectedClinics: [],
        clinicFields: [
          {
            key: 'actions',
            label: '',
            tdClass: 'action-td',
            thClass: 'action-td',
          },
          {
            key: 'clinic',
            label: 'Название клиники',
          },
          {
            key: 'clinicPrices',
            label: 'Услуги клиники',
          },
          {
            key: 'sum',
            label: 'Сумма',
          },
        ],
      },
      clinicPriceSearch: {
        code: '',
        name: '',
        isLoading: false,
        isSelectedPricesOpen: false,
        fields: [
          {
            key: 'code',
            label: 'Код',
          },
          {
            key: 'name',
            label: 'Название',
          },
          {
            key: 'cost',
            label: 'Стоимость',
          },
          {
            key: 'actions',
            label: '',
            tdClass: 'action-td',
            thClass: 'action-td',
          },
        ],
        prices: [],
        selectedPrices: [],
      },
      priceCategories: [
        {
          id: 0,
          title: 'ДМС',
        },
        {
          id: 1,
          title: 'Индивидуальный',
        },
        {
          id: 2,
          title: 'Продукт в коробке',
        },
        {
          id: 3,
          title: 'Телемед',
        },
      ],
    };
  },
  validations() {
    return {
      checkup: { required },
      priceCategory: [2, 3].includes(this.letter.priceType) ? { required } : () => true,
      priceSearch: {
        selectedClinics: {
          required: this.letter.priceType === 2 ? required : () => true,
        },
      },
      clinicPriceSearch: {
        selectedPrices: {
          required: this.letter.priceType === 3 ? required : () => true,
        },
      },
    };
  },
  computed: {
    letter() {
      return this.$store.state.Indemnity.letter;
    },
    letterIndemnityCity() {
      return this.$store.state.Indemnity.letterIndemnityCity;
    },
    isEdit() {
      return this.$store.state.Indemnity.isEditIndemnity;
    },
    isLetterSaving() {
      return this.$store.state.Indemnity.isLetterSaving;
    },
    errorsValidation() {
      const errors = {};

      errors.checkup = [];
      if (!this.$v.checkup.required) {
        errors.checkup.push('Выберите один из вариантов');
      }

      errors.priceCategory = [];
      if (!this.$v.priceCategory.required) {
        errors.priceCategory.push('Выберите один из вариантов');
      }

      errors.selectedClinics = [];
      if (!this.$v.priceSearch.selectedClinics.required) {
        errors.selectedClinics.push('Необходимо выбрать хотя бы одну клинику');
      }

      errors.selectedClinicPrices = [];
      if (!this.$v.clinicPriceSearch.selectedPrices.required) {
        errors.selectedClinicPrices.push('Необходимо выбрать хотя бы одну услугу');
      }

      return errors;
    },
    additionalConditionString: {
      get() {
        return this.letter.additionalConditionString;
      },
      set(newValue) {
        this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, additionalConditionString: newValue });
      },
    },
    paymentConditionString: {
      get() {
        return this.letter.paymentConditionString;
      },
      set(newValue) {
        this.$store.commit(this.$types.INDEMNITY_LETTER_SET, {
          ...this.letter,
          paymentConditionString: newValue,
          validityString: newValue,
        });
      },
    },
    sumString: {
      get() {
        return this.letter.sumString;
      },
      set(newValue) {
        this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, sumString: newValue });
      },
    },
    checkup: {
      get() {
        return this.letter.checkup;
      },
      set(newValue) {
        this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, checkup: newValue });
      },
    },
    priceCategory: {
      get() {
        return this.letter.priceCategory;
      },
      set(newValue) {
        this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, priceCategory: newValue });
      },
    },
    serviceString: {
      get() {
        return this.letter.serviceString;
      },
      set(newValue) {
        this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, serviceString: newValue });
      },
    },
    totalServiceSum() {
      const array = [...this.letter.tableRows.filter((r) => (typeof r) !== 'string')];
      const value = array.reduce((sum, row) => +sum + +row.sum.value, 0);
      return value;
    },
  },
  watch: {
    'priceSearch.selectedPrices': {
      handler() {
        this.onPriceClinicsSearch();
      },
      deep: true,
    },
    'priceSearch.selectedClinics': {
      handler() {
        this.sumString = this.priceSearch.selectedClinics.reduce((s, item) => s + item.sum, 0);
      },
    },
    priceCategory: {
      handler() {
        this.priceSearch.selectedPrices = [];
        this.priceSearch.prices = [];

        this.clinicPriceSearch.selectedPrices = [];
        this.clinicPriceSearch.prices = [];
      },
    },
    'clinicPriceSearch.selectedPrices': {
      handler() {
        this.sumString = this.clinicPriceSearch.selectedPrices.reduce((s, item) => s + item.cost * item.count, 0);
      },
      deep: true,
    },
    saveToTabTrigger() {
      this.letter.tableRowsPrice = this.clinicPriceSearch.selectedPrices;
      // this.$emit('updateSelectedPrices', this.clinicPriceSearch.selectedPrices);
    },
  },
  async created() {
    if (this.letter.priceType === 2 && this.letter.selectedClinics?.length) { // если тип склеенные услуги
      this.priceSearch.selectedClinics = this.letter.selectedClinics;
    }

    if (this.letter.priceType === 3 && this.letter.tableRowsPrice?.length) { // если тип прайсы клиник
      const { sumString, letter: { tableRowsPrice } } = this;
      this.priceCategory = this.letter.tableRowsPrice[0].type;

      await this.$nextTick();
      this.clinicPriceSearch.selectedPrices = tableRowsPrice.reduce((result, item) => {
        const itemInResult = result.find((i) => i.id === item.id);

        if (itemInResult) {
          itemInResult.count += 1;
        } else {
          result.push({ ...item, count: item.count || 1 });
        }

        return result;
      }, []);
      this.clinicPriceSearch.isSelectedPricesOpen = true;
      this.letter.tableRowsPrice = tableRowsPrice;

      await this.$nextTick();
      this.sumString = sumString;

      // console.log(tableRowsPrice);
    }

    if (this.letter?.policyId) {
      this.limitsData = await this.$store.dispatch(this.$types.PATIENT_LIMITS_BY_PERSON_ID_FETCH, {
        personId: this.letter.personId || this.letter.patient?.id,
        policyId: this.letter.policyId,
      });
    }
  },
  methods: {
    checkHasValidateErrors() {
      // if (this.letter.tableColumns['№']) {
      //   const errorNumbers = this.letter.tableRows.filter((r) => (typeof r) !== 'string').some((r) => !r.number.value);

      //   if (errorNumbers) {
      //     showMessage({
      //       type: 'warning',
      //       title: 'Ошибка валидации',
      //       message: 'Укажите номер для каждой услуги',
      //     });

      //     return true;
      //   }
      // }
      if (this.letter.tableRows) {
        const error = this.letter.tableRows.filter((r) => (typeof r) !== 'string').some((r) => !r.name.value);

        if (error) {
          showCustomMessage('warning', 'Ошибка валидации', 'Укажите название для каждой услуги');
          return true;
        }
      }

      this.$v.$touch();

      if (this.$v.$error) {
        showValidationErrorMessage();
      }

      return this.$v.$error;
    },
    async onNosologicalSearch() {
      this.nosologicalSearch.isLoading = true;

      const data = await this.$store.dispatch(this.$types.NOSOLOGICAL_SERVICE_FETCH, {
        code: this.nosologicalSearch.code,
        name: this.nosologicalSearch.name,
        take: 100,
      });

      this.nosologicalSearch.data = data;

      this.nosologicalSearch.isLoading = false;
    },
    onNosologicalPricesAdd(prices) {
      prices.forEach((price) => {
        const isPriceNotAdded = !this.letter.nosologicalServices?.find((p) => p.id === price.id);
        if (isPriceNotAdded) {
          if (this.letter.nosologicalServices) {
            this.letter.nosologicalServices.push(price);
          } else {
            this.letter.nosologicalServices = [price];
          }
        }
      });
    },
    onNosologicalPriceRemove(id) {
      this.letter.nosologicalServices = this.letter.nosologicalServices.filter((price) => price.id !== id);
    },
    async onPriceSearch() {
      if (this.priceCategory == null) return;

      this.priceSearch.isLoading = true;

      const { paginatedCollection } = await this.$store.dispatch(this.$types.PRICE_LIST_FETCH, {
        code: this.priceSearch.code,
        name: this.priceSearch.name,
        type: this.priceCategory,
        isActive: true,
        isBind: true,
        take: 100,
      });

      this.priceSearch.prices = paginatedCollection;
      this.priceSearch.isLoading = false;
    },
    onPriceAdd(price) {
      this.priceSearch.selectedPrices.push({ ...price, count: 1 });
    },
    onPriceRemove(id) {
      this.priceSearch.selectedPrices = this.priceSearch.selectedPrices.filter((price) => price.id !== id);
    },
    onPriceIncrease(id) {
      const selectedPrice = this.getSelectedPriceById(id);
      if (selectedPrice) {
        selectedPrice.count += 1;
      }
    },
    onPriceDecrease(id) {
      const selectedPrice = this.getSelectedPriceById(id);

      if (selectedPrice) {
        if (selectedPrice.count === 1) {
          this.onPriceRemove(id);
        } else {
          selectedPrice.count -= 1;
        }
      }
    },
    async onPriceClinicsSearch() {
      this.priceSearch.isClinicsLoading = true;

      this.priceSearch.clinics = [];
      this.priceSearch.selectedClinics = [];

      if (this.priceSearch.selectedPrices.length) {
        const priceIds = [];
        this.priceSearch.selectedPrices.forEach((p) => {
          for (let i = 0; i < p.count; i += 1) {
            priceIds.push(p.id);
          }
        });

        this.priceSearch.clinics = await this.$store.dispatch(this.$types.PRICE_CLINICS_FETCH, {
          cityId: this.letterIndemnityCity?.id,
          priceIds,
          take: 100,
        });
      }

      this.priceSearch.isClinicsLoading = false;
    },
    trClass: (rowData) => !rowData.isActive && 'table-danger',
    onClickCancel() {
      this.$emit('cancel');
    },
    onClickNext() {
      let sumStringValue = '';

      if (this.letter.priceType === 0) { // если тип шаблон
        this.$store.commit(this.$types.INDEMNITY_CHECKVALIDATION);
        sumStringValue = this.letter.tableRows.reduce((sum, row) => (typeof row === 'string' ? +sum : +sum + +row.sum.value), 0);
      }

      if (this.letter.priceType === 2) { // если тип склеенные услуги
        sumStringValue = this.sumString;
        this.letter.selectedClinics = this.priceSearch.selectedClinics;
      }

      if (this.letter.priceType === 3) { // если тип прайсы клиник
        sumStringValue = this.sumString;
        this.letter.tableRowsPrice = this.clinicPriceSearch.selectedPrices;
      }

      if (!this.letter.isEdited && sumStringValue && this.limitsData?.balance != null
      && (sumStringValue > this.limitsData.balance && !this.withoutLimit)) {
        showErrorCustomMessage('Итоговая сумма превышает лимит страхового покрытия');

        return;
      }

      if (this.clinicPriceSearch.selectedPrices.some((el) => !el.isActive)) {
        showErrorCustomMessage('В списке есть удаленная услуга');

        return;
      }

      if (!this.checkHasValidateErrors()) {
        this.$emit('change-step', 1);
      }
    },
    toTemplates() {
      this.$emit('change-step', 28);
    },
    toNosologicTemplates() {
      this.$emit('change-step', 30);
    },
    clickPrev() {
      this.$emit('change-step', -1);
    },
    async isEditToggle() {
      await this.$store.commit(this.$types.INDEMNITY_IS_EDIT_TEMPLATE_TOGLE);
    },
    toggleFinalRow() {
      const newValue = !this.letter.finalRow;
      this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, finalRow: newValue });
    },
    clearAllRows() {
      const array = [];
      const sumStringValue = array.reduce((sum, row) => (typeof row === 'string' ? +sum : +sum + +row.sum.value), 0);

      this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, tableRows: array, sumString: sumStringValue });
      this.$store.dispatch(this.$types.INDEMNITY_LETTER_NUMBERS_UPDATE);

      this.letterRowDataInputHandler();
    },
    toggleShowServiceCode(value) {
      const array = this.letter.tableRows.map((row) => {
        if ((typeof row) !== 'string') {
          const newRow = { ...row };
          newRow.code.isVisible = value;
          return newRow;
        }
        return row;
      });
      this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, tableRows: array });
    },
    toggleShowServiceCount(value) {
      const array = this.letter.tableRows.map((row) => {
        if ((typeof row) !== 'string') {
          const newRow = { ...row };
          newRow.count.isVisible = value;
          return newRow;
        }
        return row;
      });
      this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, tableRows: array });
    },
    toggleShowServicePrice(value) {
      const array = this.letter.tableRows.map((row) => {
        if ((typeof row) !== 'string') {
          const newRow = { ...row };
          newRow.price.isVisible = value;
          return newRow;
        }
        return row;
      });

      this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, tableRows: array });
    },
    toggleShowServiceSum(value) {
      const array = this.letter.tableRows.map((row) => {
        if ((typeof row) !== 'string') {
          const newRow = { ...row };
          newRow.sum.isVisible = value;
          return newRow;
        }
        return row;
      });

      this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, tableRows: array });
    },
    toggleShowServiceNumber(value) {
      const array = this.letter.tableRows.map((row) => {
        if ((typeof row) !== 'string') {
          const newRow = { ...row };
          newRow.number.isVisible = value;
          return newRow;
        }
        return row;
      });

      this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, tableRows: array });
    },
    addTableRow(index) {
      const row = {
        number: {
          // isVisible: this.letter.tableColumns['№'],
          isVisible: false,

          value: index,
        },
        code: {
          isVisible: this.letter.tableColumns['Код услуги'],
          value: '',
        },
        name: {
          isVisible: true,
          value: '',
        },
        count: {
          isVisible: this.letter.tableColumns['Количество услуг'],
          value: 1,
        },
        price: {
          isVisible: this.letter.tableColumns['Стоимость за единицу'],
          value: 0,
        },
        sum: {
          isVisible: this.letter.tableColumns['Итоговая стоимость'],
          value: 0,
        },
      };

      const array = [...this.letter.tableRows.slice(0, index), row, ...this.letter.tableRows.slice(index, this.letter.tableRows.length)];
      this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, tableRows: array });
      this.$store.dispatch(this.$types.INDEMNITY_LETTER_NUMBERS_UPDATE);
    },
    removeTableRow(index) {
      const array = [...this.letter.tableRows.slice(0, index), ...this.letter.tableRows.slice(index + 1, this.letter.tableRows.length)];
      const sumStringValue = array.reduce((sum, row) => (typeof row === 'string' ? +sum : +sum + +row.sum.value), 0);

      this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, tableRows: array, sumString: sumStringValue });
      this.$store.dispatch(this.$types.INDEMNITY_LETTER_NUMBERS_UPDATE);

      this.letterRowDataInputHandler();
    },
    letterRowDataInputHandler() {
      const array = this.letter.tableRows.map((row) => {
        const newRow = row;
        if (typeof row === 'string') return newRow;

        newRow.sum.value = +newRow.price.value * +newRow.count.value;
        return newRow;
      });
      const sumStringValue = array.reduce((sum, row) => (typeof row === 'string' ? +sum : +sum + +row.sum.value), 0);

      this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, tableRows: array, sumString: sumStringValue });
    },
    letterRowStringInputHandler(value, index) {
      const array = this.letter.tableRows.map((row, i) => (index === i ? value : row));

      this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, tableRows: array });
    },
    getSelectedPriceById(id) {
      return this.priceSearch.selectedPrices.find((p) => p.id === id);
    },
    async saveTemplateCallback(prices) {
      if (prices.length) {
        this.priceCategory = prices[0].type;
        await this.$nextTick();

        this.priceSearch.selectedPrices = prices
          .filter((price) => this.priceCategory == null || price.type === this.priceCategory);
        // .map((price) => this.onPriceAdd(price));
      }
    },
    async savePriceTemplateCallback(prices) {
      if (prices.length) {
        this.priceCategory = prices[0].type;
        await this.$nextTick();

        this.clinicPriceSearch.selectedPrices = prices
          .filter((price) => this.priceCategory == null || price.type === this.priceCategory);
        // .map((price) => this.onClinicPriceAdd(price));

        this.clinicPriceSearch.isSelectedPricesOpen = true;
      }
    },
    toLinkedTemplates() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'IndemnityPriceTemplateAddModal',
        props: {
          saveTemplateCallback: this.saveTemplateCallback,
        },
      });
    },
    toPriceTemplates() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'IndemnityPriceClinicTemplateAddModal',
        props: {
          saveTemplateCallback: this.savePriceTemplateCallback,
        },
      });
    },
    async onClinicPriceSearch() {
      if (this.priceCategory == null) return;
      this.clinicPriceSearch.isLoading = true;

      const { paginatedCollection } = await this.$store.dispatch(this.$types.CLINIC_PRICE_LIST_FETCH, {
        code: this.clinicPriceSearch.code,
        name: this.clinicPriceSearch.name,
        clinicId: this.letter.clinicId,
        type: this.priceCategory,
        take: 100,
      });

      this.clinicPriceSearch.prices = paginatedCollection.filter((el) => el.isActive);
      this.clinicPriceSearch.isLoading = false;
    },
    getSelectedClinicPriceById(id) {
      return this.clinicPriceSearch.selectedPrices.find((p) => p.id === id);
    },
    handleInput(id, value) {
      const updateValue = value.replace(/\D/g, '');
      this.updateClinicPriceCount(id, updateValue);
    },
    filterNonNumericInput(event) {
      const char = String.fromCharCode(event.keyCode);
      if (!/[\d]/.test(char)) {
        event.preventDefault();
      }
    },
    updateClinicPriceCount(id, value) {
      const item = this.getSelectedClinicPriceById(id);
      if (item) {
        item.count = parseInt(value, 10);
      }
    },
    inputWidth(count) {
      const digits = count ? count.toString().length : 1;
      return 60 + digits * 8;
    },
    onClinicPriceAdd(price) {
      this.clinicPriceSearch.selectedPrices.push({ ...price, count: 1 });
    },
    onClinicPriceRemove(id) {
      this.clinicPriceSearch.selectedPrices = this.clinicPriceSearch.selectedPrices.filter((price) => price.id !== id);
    },
    onClinicPriceIncrease(id) {
      const selectedPrice = this.getSelectedClinicPriceById(id);
      if (selectedPrice) {
        this.updateClinicPriceCount(id, selectedPrice.count);
        selectedPrice.count += 1;
      }
    },
    onClinicPriceDecrease(id) {
      const selectedPrice = this.getSelectedClinicPriceById(id);
      if (selectedPrice) {
        this.updateClinicPriceCount(id, selectedPrice.count);
        if (selectedPrice.count === 1) {
          this.onClinicPriceRemove(id);
        } else {
          selectedPrice.count -= 1;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.guarantee-letter-wrapper {
  width: 100%;
}

.crm-label {
  border-bottom: 1px solid #007bff;
  width: fit-content;
  padding-bottom: 0.5rem;

  &.error {
    border-bottom: 1px solid $red;
  }
}

.crm-flex-row {
  display: flex;
  &.crm-justify-between {
    justify-content: space-between;
  }
  &.crm-align-center {
    align-items: center;
  }
}

::v-deep.crm-icon-edit {
  cursor: pointer;

  &.active {
    border-radius: 4px;
    background: $blue;

    rect {
      stroke: $blue;
    }

    path {
      fill: #fff;
    }
  }
}

::v-deep.crm-button {
  font-weight: 400;

  &.width-unset {
    width: unset;
    padding-left: 20px;
    padding-right: 20px;
  }

  .crm-icon-cross {
    margin-right: 15px;
  }

  &.button_transparent {
    background-color: transparent;
    color: $blue;
    font-weight: 600;

    .crm-icon-cross {
      path {
        fill: $blue;
      }
    }
  }
  &.without-border {
    border: none;
  }
  &.w300 {
    width: 300px;
  }
  &.gray {
    color: $gray-border;
  }
}

.guarantee-letter-edit-header {
  margin-top: 30px;
  margin-bottom: 30px;

  .crm-column + .crm-column {
    margin-left: 15px;
  }

  .crm-title {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;

    &:not(:first-child) {
      margin-top: 25px;
    }
  }
  .crm-checkbox-block {
    margin-top: 10px;

    .crm-checkbox {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.sheet-row {
  overflow: visible;
  position: relative;

  .plus-icon {
    display: none;
    position: absolute;
    left: 5px;
    bottom: 0;
    transform: translateY(50%);
    z-index: 1;
    cursor: pointer;
    border-radius: 50%;
  }

  &:hover {
    .plus-icon {
      display: block;
    }
  }
}

.crm-icon-delete {
  cursor: pointer;
}

.row-interface {
  display: flex;

  &-count {
    width: 70px;
    text-align: center;
  }
  &-btn {
    // background: #6E88F3;
    background: $blue;
    color: #fff;
    width: 18px;
    height: 18px;
    font-size: 20px;
    font-weight: 400;
    line-height: 18px;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
    user-select: none;
  }
}

.guarantee-letter-sheet {
  overflow: visible !important;
  margin-top: 30px;
  margin-bottom: 10px;
}

::v-deep.sheet {
  width: 100%;
  border: 1px solid $gray;
  border-radius: 4px;
  overflow: hidden;
  font-size: 16px;
  line-height: 20px;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  &-row {
    display: flex;
    background-color: #fff;
    & + & {
      border-top: 1px solid $gray-shadow;
    }

    &--header {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      background-color: #f3f3f3;

      .sheet-cell {
        padding: 15px 10px;

        &--service-count {
          width: 90px;
          text-align: center;
          padding-left: 0;
          padding-right: 0;
        }

        &:last-child {
          border: none;
        }
      }
    }

    &--summary-line {
      font-weight: bold;
      color: $blue-dark;
      .sheet-cell  + .sheet-cell{
        border: none;
      }

      .sheet-cell--name {
        text-align: right;
        justify-content: flex-end;
        text-transform: uppercase;
      }
    }
  }

  &-cell {
    padding: 10px 0;
    align-items: center;
    display: flex;
    justify-content: center;
    & + & {
      border-left: 1px solid $gray-shadow;
    }

    &--number {
      width: 40px;
      text-align: center;
    }

    &--code {
      width: 200px;
      text-align: center;
      padding: 10px;
    }

    &--name {
      flex: 1;
      padding: 10px;
      word-break: break-word;
    }

    &--service-count {
      width: 90px;
      text-align: center;
    }

    &--price {
      width: 100px;
      text-align: center;
    }

    &--sum {
      width: 100px;
      text-align: center;
    }

    &--del {
      width: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
    }

    &.guarantee-letter-cell-field {
      padding: 0;

      .crm-base-input {
        box-sizing: border-box;
        padding: 10px 5px;
        border: none;
        text-align: center;
      }

      &.sheet-cell--name,
      &.sheet-cell--text {
        .crm-wrap-input {
          width: 100%;
        }
        .crm-base-input {
          padding: 10px;
        }
      }

      &.sheet-cell--name {
        .crm-base-input {
          text-align: left;
        }
      }

      &.sheet-cell--text {
        width: 100%;

        .crm-base-input {
          text-align: center;
        }
      }

    }
  }
}

::v-deep.guarantee-letter-field {
  margin-top: 30px;

  .crm-label {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 18px;
  }

  .crm-base-input {
    padding: 10px 10px 10px 20px;
    font-size: 16px;
    line-height: 20px;
    resize: none;
  }
}

::v-deep.crm-radio-buttons.checkup {
  position: relative;

  .crm-radio-button {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  .crm-radio-button + .crm-radio-button {
    margin-top: 10px;
  }
}

::v-deep.nosological-service-table {
  .action-td {
    width: 100px;
  }

  .table th, .table td {
    padding: 0.25rem;
  }
}

::v-deep.price-table {
  max-height: 250px;

  .action-td {
    width: 30px;
  }

  .table th, .table td {
    padding: 0.25rem;
  }
}

.selected-clinics-table {
  max-height: 500px;
}

</style>
