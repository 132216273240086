<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    :title="title"
    size="md"
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <div style="min-height: 350px">
      <div class="mb-3">
        <div class="mb-2">
          Инициатор
        </div>
        <v-select
          v-model="$v.selectedInitiators.$model"
          :options="initiators"
          :error="$v.selectedInitiators.$error"
          :errors="errorsValidation.selectedInitiators"
          placeholder="Выберите инициатора"
          fluid
          label="name"
        />
        <div
          v-if="$v.selectedInitiators.$error"
          class="text-danger mb-2 small"
        >
          {{ errorsValidation.selectedInitiators[0] }}
        </div>
      </div>

      <div>
        <div class="mb-2">
          Причина отмены консультации
        </div>
        <v-select
          v-model="$v.selectedTransferReason.$model"
          label="reason"
          :options="reasons"
          placeholder="Выберите причину"
          loading-title="Загрузка"
          :disabled="selectedInitiators===null"
          fluid
        />
        <div
          v-if="$v.selectedTransferReason.$error"
          class="text-danger mb-2 small"
        >
          {{ errorsValidation.selectedTransferReason[0] }}
        </div>
      </div>
    </div>
    <template #modal-footer>
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isCanceling"
        class="float-right"
        @click="cancelConsultation"
      >
        Отмена консультации
        <b-spinner
          v-if="isCanceling"
          variant="light"
          small
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import Bus from '@/eventBus';
import { showValidationErrorMessage } from '@/helpers/messages';

export default {
  name: 'ConsultationCancelModal',
  mixins: [validationMixin],
  props: {
    consultation: {
      type: Object,
      default: () => ({}),
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Отмена консультации',
    },
    cb: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isOpen: true,
      isCanceling: false,
      selectedTransferReason: null,
      selectedInitiators: null,
      reasons: [],
      initiators: [
        {
          id: 0,
          name: 'Врач',
        },
        {
          id: 1,
          name: 'Пациент',
        },
        {
          id: 2,
          name: 'Техническая ошибка',
        },
      ],
    };
  },
  validations() {
    return {
      selectedInitiators: {
        required,
      },
      selectedTransferReason: {
        required,
      },
    };
  },
  computed: {
    errorsValidation() {
      const errors = {};

      errors.selectedTransferReason = [];
      if (!this.$v.selectedTransferReason.required) {
        errors.selectedTransferReason.push('Поле не может быть пустым');
      }

      errors.selectedInitiators = [];
      if (!this.$v.selectedInitiators.required) {
        errors.selectedInitiators.push('Поле не может быть пустым');
      }
      return errors;
    },
  },
  watch: {
    async selectedInitiators() {
      await this.fetchTransferReasons();
      this.selectedTransferReason = null;
    },
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async fetchTransferReasons() {
      const type = this.selectedInitiators.id;
      this.reasons = await this.$store.dispatch(this.$types.TRANSFER_REASONS_FETCH, type);
    },
    async cancelConsultation() {
      this.$v.$touch();

      if (this.$v.$error) {
        showValidationErrorMessage();
        return;
      }

      try {
        this.isCanceling = true;
        await this.$store.dispatch(this.$types.CONSULTATIONS_CANCEL, {
          consultationId: this.consultation.id,
          cancelReason: this.selectedTransferReason.id,
        });

        Bus.$emit('consultations:update', this.consultation.id);
        this.$store.commit(this.$types.PATIENT_DETAIL_CONSULATIONS_RESET);
        this.$store.commit(this.$types.TOGGLE_SCHEDULE_TRIGGER); // сделает тогл триггера и обновит расписание
        this.onClose();
      } finally {
        this.isCanceling = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
