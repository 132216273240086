import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.CLINIC_DOCUMENTS_SET](state, payload) {
    state.documents = payload;
  },
  [localTypes.CLINIC_DOCUMENTS_CLINIC_ID_SET](state, payload) {
    state.clinicId = payload;
  },
  [localTypes.CLINIC_DOCUMENTS_LOADING_SET](state, payload) {
    state.isLoading = payload;
  },
  [localTypes.CLINIC_DOCUMENTS_QUERY_SET](state, payload) {
    state.query = payload;
  },
};
