import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.PROMOCODES_QUERY_SET](state, payload) {
    state.query = payload;
  },
  [localTypes.PROMOCODES_FILTER_SET](state, payload) {
    state.filter = { ...payload };
  },
  [localTypes.PROMOCODES_SET](state, payload) {
    state.promocodes = payload;
  },
  [localTypes.PROMOCODES_ADD](state, payload) {
    state.promocodes = [...state.promocodes, ...payload];
  },
  [localTypes.PROMOCODE_REMOVE_BY_ID](state, payload) {
    state.promocodes = state.promocodes.filter((promocode) => promocode.id !== payload);
  },
  [localTypes.PROMOCODES_IS_LOADING_SET](state, payload) {
    state.isLoading = payload;
  },
  [localTypes.PROMOCODES_IS_ALL_ITEMS_LOADED_SET](state, payload) {
    state.isAllItemsLoaded = payload;
  },
};
