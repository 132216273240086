import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.STATISTICS_CONSULTATIONS_SET](state, payload) {
    state.statisticsConsultations = payload;
  },
  [localTypes.STATISTICS_SERVICE_SET](state, payload) {
    state.statisticsService = payload;
  },
  [localTypes.STATISTICS_CONSULTATIONS_COMMON_SET](state, payload) {
    state.statisticsConsultationsCommon = payload;
  },
  [localTypes.STATISTICS_COMMON_SERVICES_SET](state, payload) {
    state.statisticsServiceCommon = payload;
  },
  [localTypes.STATISTICS_CLEAR](state) {
    state.statisticsConsultations = [];
    state.statisticsConsultationsCommon = {};
    state.statisticsService = [];
    state.statisticsServiceCommon = {};
  },
};
