<template>
  <div class="base-photo-edit">
    <template v-if="!isEdit">
      <div
        v-if="showPhotoEditTitle"
        class="crm-title"
      >
        Редактирование фотографии
      </div>
      <div class="description">
        Вы можете загрузить изображение в формате JPG или PNG. <br>
        Если у Вас возникают проблемы с загрузкой, попробуйте выбрать фотографию меньшего размера.
      </div>
      <div class="photo">
        <img
          :src="photoUrl"
          :width="imageWidth"
          :height="imageHeight"
          :class="{
            'source-photo': true,
            'source-photo-rounded': rounded,
          }"
        >
      </div>

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        size="sm"
        @click="toNewPhoto"
      >
        Изменить
      </b-button>
    </template>

    <template v-else>
      <template v-if="!newPhoto">
        <div
          v-if="showPhotoEditTitle"
          class="crm-title"
        >
          Загрузка фотографии
        </div>
        <div class="description">
          Вы можете загрузить изображение в формате JPG или PNG. <br>
          Если у Вас возникают проблемы с загрузкой, попробуйте выбрать фотографию меньшего размера.
        </div>
        <div class="photo">
          <img
            v-if="photoUrl"
            :src="photoUrlProp"
            :width="imageWidth"
            :height="imageHeight"
            :class="{
              'source-photo': true,
              'source-photo-rounded': rounded,
            }"
            @click="onPhotoClickEvent"
          >
          <PhotoAvatar
            v-else
            class="photo-avatar"
            @click="onPhotoClickEvent"
          />
        </div>
        <input
          ref="inputFile"
          type="file"
          class="photo-input"
          accept="image/*"
          @change="onChoosePhoto"
        >

        <div class="d-flex">
          <b-button
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            @click="onPhotoClickEvent"
          >
            Загрузить файл
          </b-button>

          <b-button
            v-if="showDeleteButton && photoUrl"
            variant="danger"
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            class="ml-2"
            @click="deletePhoto"
          >
            Удалить
          </b-button>
        </div>
      </template>

      <template v-else>
        <div
          v-if="showPhotoEditTitle"
          class="crm-title"
        >
          Редактирование фотографии
        </div>
        <div class="description">
          Выбранная область будет показываться на Вашей странице.
        </div>
        <div class="photo">
          <cropper
            class="cropper"
            :default-size="defaultSize"
            :src="newPhoto"
            :stencil-props="{
              aspectRatio: 1/1
            }"
            @change="setCropState"
          />
          <canvas
            id="photo-canvas"
            :width="imageWidth"
            :height="imageHeight"
          />
        </div>
      </template>
    </template>

    <div class="crm-wrapper-buttons crm-wrapper-buttons_clinic-photo-edit">
      <b-button
        variant="secondary"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isSaving"
        @click="onClickCancel"
      >
        Отменить
      </b-button>

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="ml-4"
        :disabled="isSaving || !isCanSave"
        @click="onClickSave"
      >
        Сохранить
      </b-button>
    </div>
  </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper';
import { api } from '@/helpers/api';
import { getFileExtension } from '@/helpers/utils';
import { APP_HOST } from '@/app.config';

import PhotoAvatar from '@/assets/images/photo-avatar.svg';
import 'vue-advanced-cropper/dist/style.css';

export default {
  name: 'BasePhotoEdit',
  components: {
    PhotoAvatar,
    Cropper,
  },
  props: {
    editProp: {
      type: Boolean,
      default: false,
    },
    photoUrlProp: {
      type: String,
      default: '',
    },
    imageHeight: {
      type: Number,
      default: 200,
    },
    imageWidth: {
      type: Number,
      default: 200,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    customSave: {
      type: Boolean,
      default: false,
    },
    showDeleteButton: {
      type: Boolean,
      default: false,
    },
    showPhotoEditTitle: {
      type: Boolean,
      default: true,
    },
    saveCallback: {
      type: Function,
      default: async () => {},
    },
  },
  data() {
    return {
      photoUrl: this.photoUrlProp,
      isEdit: this.editProp || !this.photoUrlProp,
      isDelete: false,
      isSaving: false,
      isCanSave: false,
      newPhoto: null,
      newPhotoName: null,
      cropState: null,
    };
  },
  methods: {
    defaultSize({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height,
      };
    },
    onClickCancel() {
      this.$emit('cancel');
    },
    async onClickSave() {
      try {
        this.isSaving = true;

        let fileObject = null;
        if (this.newPhoto) {
          fileObject = await this.getImageFileFromCanvas(this.cropState.canvas, this.newPhotoName, 'image/png');
        }

        if (this.customSave) {
          this.isSaving = false;
          this.isCanSave = false;
          this.$emit('save', fileObject);
          return;
        }

        if (fileObject) {
          const extension = getFileExtension(fileObject.name);
          const { data: { guid } } = await api.post(`File/${extension}`, fileObject, {
            headers: {
              'Content-Type': 'image/png',
            },
          });

          const url = `${APP_HOST === '/api/' ? 'https://crm.doctis.ru:6004/api/' : APP_HOST}File/${guid}`;
          await this.saveCallback(guid);
          this.photoUrl = url;
        } else {
          await this.saveCallback(null);
        }

        this.newPhoto = null;
        this.newPhotoName = null;
        this.cropState = null;

        this.$emit('save');
      } finally {
        this.isSaving = false;
        this.isCanSave = false;
      }
    },
    onPhotoClickEvent() {
      this.$refs.inputFile.click();
    },
    onChoosePhoto(event) {
      const file = event.target.files[0];
      const url = URL.createObjectURL(file);
      this.isCanSave = true;
      this.newPhoto = url;
      this.newPhotoName = file.name;
    },
    setCropState(data) {
      this.cropState = data;
    },
    toNewPhoto() {
      this.isEdit = true;
    },
    deletePhoto() {
      this.photoUrl = null;
      this.isCanSave = true;
    },
    getImageFileFromCanvas(sourceCanvas, name, type) {
      const { imageHeight, imageWidth } = this;
      const canvas = document.getElementById('photo-canvas');
      const ctx = canvas.getContext('2d');
      ctx.drawImage(sourceCanvas, 0, 0, imageWidth, imageHeight);

      const promise = new Promise((resolve) => {
        canvas.toBlob((blob) => {
          const file = new File([blob], name, { type });
          resolve(file);
        }, type);
      }).catch((err) => { throw err; });
      return promise;
    },
  },
};
</script>

<style lang="scss" scoped>
.base-photo-edit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.crm-wrapper-buttons_clinic-photo-edit {
  margin-top: 50px;
}
// eslint-disable-next-line vue-scoped-css/no-unused-selector
.crm-button.disabled {
  pointer-events: none;
  color: #e0e0e0;
  border: 1px solid transparent;
  background-color: transparent;
}
.crm-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #3A3A3A;
}

.description {
  margin: 30px auto;
  font-size: 16px;
  line-height: 20px;
  color: #505050;
  max-width: 550px;
  text-align: center;
}

.photo {
  margin-bottom: 20px;
  max-width: 500px;
}

.source-photo {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}

.source-photo-rounded {
  border-radius: 50%;
}

.photo-avatar {
  cursor: pointer;
}

.photo-input {
  height: 0;
  width: 0;
  overflow: hidden;
}
#photo-canvas {
  display: none;
}

.cropper {
  height: 300px;
}
</style>
