import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.TOGGLE_BANNERS_TRIGGER](state) {
    state.bannersTrigger = !state.bannersTrigger;
  },
};
