<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    :title="title"
    size="lg"
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <div class="schedule-edit-inner">
      <loading
        v-if="isLoading"
        class="schedule-templates"
      >
        Загрузка шаблонов
      </loading>

      <template v-else>
        <div class="mb-3">
          <div class="mb-2 font-weight-bold">
            Название шаблона
          </div>
          <b-input
            v-model="$v.template.name.$model"
            size="sm"
            :state="checkValidation ? !$v.template.name.$error : null"
          />
          <div
            v-if="$v.template.name.$error"
            class="mt-2 text-danger small"
          >
            {{ errorsValidation.name[0] }}
          </div>
        </div>

        <div
          v-if="false"
          class="s"
        >
          <div>
            <base-checkbox
              v-model="template.isLiveShedule"
              style="font-size: 14px"
            >
              Дежурный врач (живая очередь)
            </base-checkbox>
          </div>
        </div>

        <!-- <div class="s">
          <div>
            <div class="crm-form-block">
              <p class="crm-label">
                Специальность
              </p>

              <v-select
                v-model="$v.template.specializationId.$model"
                :reduce="spec => spec.id"
                :options="doctorSpecializations"
                :class="{ error: $v.template.specializationId.$error }"
                class="select-specialization"
                label="title"
              />
            </div>

            <div
              v-if="$v.template.specializationId.$error"
              class="validation-errors"
            >
              <span
                class="validation-error-text"
              >
                {{ errorsValidation.specializationId[0] }}
              </span>
            </div>
          </div>
        </div> -->

        <div class="mb-3 d-flex justify-content-between">
          <div class="w-50">
            <div class="mb-2 font-weight-bold">
              Дата начала
            </div>
            <base-date-picker
              v-model="$v.template.startDate.$model"
              :error="$v.template.startDate.$error"
              :disabled="!isEndTimeEnabled"
            />
          </div>
          <div class="w-50 ml-3">
            <div class="mb-2 font-weight-bold">
              Дата окончания
            </div>
            <base-date-picker
              v-model="$v.template.endDate.$model"
              :error="$v.template.endDate.$error"
              :disabled="!isEndTimeEnabled"
            />
          </div>
        </div>

        <div class="d-flex mb-3">
          <div class="mb-2 font-weight-bold">
            Включить дату окончания
          </div>
          <base-switcher
            v-model="isEndTimeEnabled"
            class="ml-3"
          />
        </div>

        <div class="mb-4 d-flex">
          <div class="w-50">
            <div class="mb-2 font-weight-bold">
              Время первого приема
            </div>
            <vue-timepicker
              :value="startTime"
              :minute-interval="timePickerInterval"
              placeholder="Укажите время"
              hour-label="чч"
              minute-label="мм"
              hide-clear-button
              @change="onInputStartTime"
            />
          </div>

          <div class="w-50 ml-3">
            <div class="mb-2 font-weight-bold">
              Время последнего приема
            </div>
            <vue-timepicker
              :value="endTime"
              :minute-interval="timePickerInterval"
              placeholder="Укажите время"
              hour-label="чч"
              minute-label="мм"
              hide-clear-button
              @change="onInputEndTime"
            />
          </div>
        </div>

        <div
          v-if="!template.isLiveShedule && showingSlots.length"
          class="mb-3 d-flex flex-wrap justify-content-between"
        >
          <div class="d-flex flex-wrap">
            <div
              v-for="slot in showingSlots"
              :key="slot.title"
              :class="{ selected: slot.isSelected }"
              :title="slot.isSelected ? 'Активен' : 'Не активен'"
              class="slot mr-2 mb-2"
              @click="slot.isSelected = !slot.isSelected"
            >
              {{ slot.title }}
            </div>
          </div>
        </div>

        <div class="mb-3">
          <label
            v-for="day of showedDaysOfWeek"
            :key="day.value"
            class="date-label mr-2"
          >
            <input
              v-model="template.daysOfWeek"
              :value="day.value"
              style="display: none;"
              type="checkbox"
            >

            <div class="crm-date-checkbox">
              {{ day.title }}
            </div>
          </label>
        </div>
        <div class="mb-3">
          <div>
            <div class="crm-form-block">
              <p class="crm-label required">
                Часовой пояс
              </p>
              <b-form-select
                v-model="$v.template.timeZone.$model"
                :state="checkValidation ? !$v.template.timeZone.$error : null"
                text-field="title"
                value-field="id"
                size="sm"
                :options="UTCChoices"
                class="select-specialization"
                placeholder="Часовой пояс"
              />

              <div
                v-if="$v.template.timeZone.$error"
                class="small text-danger mt-2"
              >
                {{ errorsValidation.timeZone[0] }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <template #modal-footer>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        @click="onClose"
      >
        Закрыть
      </b-button>

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        :disabled="isSaving"
        @click="saveTemplate"
      >
        Сохранить
      </b-button>
    </template>
  </b-modal>
</template>

<script>
/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
import VueTimepicker from 'vue2-timepicker';
import { validationMixin } from 'vuelidate';
import {
  formatTimeZone, getClientTimezoneOffset, dateWithoutTime,
} from '@/helpers/utils';
import { showCustomMessage, showValidationErrorMessage } from '@/helpers/messages';
import { required } from 'vuelidate/lib/validators';
import {
  set, addMinutes, sub, format, differenceInDays, addDays, formatISO,
} from '@evd3v/date-fns';
import {
  BaseSwitcher, BaseCheckbox, BaseDatePicker,
} from '@/components/base';
import { uniq } from 'lodash';

export default {
  name: 'ScheduleTemplateEditModal',
  components: {
    BaseSwitcher,
    VueTimepicker,
    BaseCheckbox,
    BaseDatePicker,
  },
  mixins: [validationMixin],
  props: {
    templateProp: {
      type: Object,
      default: () => ({}),
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Редактирование шаблона расписания',
    },
    minuteInterval: {
      type: Number,
      default: 15,
    },
  },
  data() {
    return {
      isOpen: true,
      checkValidation: false,
      isSaving: false,
      daysOfWeek: [
        { title: 'Пн', value: 1 },
        { title: 'Вт', value: 2 },
        { title: 'Ср', value: 3 },
        { title: 'Чт', value: 4 },
        { title: 'Пт', value: 5 },
        { title: 'Сб', value: 6 },
        { title: 'Вс', value: 0 },
      ],
      daysOfWeekValues: {
        Sunday: 0,
        Monday: 1,
        Tuesday: 2,
        Wednesday: 3,
        Thursday: 4,
        Friday: 5,
        Saturday: 6,
      },

      startTime: '',
      endTime: '',
      isEndTimeEnabled: true,
      isLoading: false,

      slots: [],
      timePickerInterval: 5,

      template: {
        isLiveShedule: false,
        id: null,
        available: true,
        startDate: null,
        endDate: null,
        daysOfWeek: [],
        slots: {
          morning: 0,
          afternoon: 0,
          evening: 0,
        },
        slotTemplateTimes: [],
        staffId: null,
        specializationId: null,
        name: '',
        isExternalSchedule: false,
        timeZone: null,
      },
    };
  },
  validations() {
    return {
      template: {
        name: { required },
        startDate: {
          required,
        },
        endDate: {
          required: this.isEndTimeEnabled ? required : () => true,
        },
        daysOfWeek: {
          required: (value) => value.length > 0,
        },
        timeZone: {
          required,
        },
        // specializationId: {
        //   required,
        // },
      },
    };
  },
  computed: {
    showedDaysOfWeek() {
      if (!this.isEndTimeEnabled && this.template.startDate) {
        return [...this.daysOfWeek];
      }

      if (!this.template.startDate || !this.template.endDate) return [];

      const days = differenceInDays(new Date(this.template.endDate), new Date(this.template.startDate));
      if (days < 0) return [];
      const dates = [...Array(days + 1).keys()].map((i) => addDays(new Date(this.template.startDate), i));
      const daysOfWeek = dates.map((item) => new Date(item).getDay());

      const uniqDaysOfWeek = uniq(daysOfWeek);

      return this.daysOfWeek.filter((item) => uniqDaysOfWeek.includes(item.value));
    },
    errorsValidation() {
      const errors = {};

      errors.name = [];
      if (!this.$v.template.name.required) {
        errors.name.push('Поле не может быть пустым');
      }

      errors.startDate = [];
      if (!this.$v.template.startDate.required) {
        errors.startDate.push('Поле не может быть пустым');
      }

      errors.timeZone = [];
      if (!this.$v.template.timeZone.required) {
        errors.timeZone.push('Поле не может быть пустым');
      }

      // errors.specializationId = [];
      // if (!this.$v.template.specializationId.required) {
      //   errors.specializationId.push('Поле не может быть пустым');
      // }
      return errors;
    },
    doctorSpecializations() {
      return this.doctor.specializations;
    },
    indexStartTime() {
      return this.findIndexSlot(this.startTime, 'next');
    },
    indexEndTime() {
      return this.findIndexSlot(this.endTime, 'prev');
    },
    isValidStartTime() {
      return this.isValidTimeRange(this.startTime);
    },
    isValidEndTime() {
      return this.isValidTimeRange(this.endTime);
    },
    showingSlots() {
      try {
        const startIndex = this.indexStartTime;
        const endIndex = this.indexEndTime;

        return this.slots.slice(startIndex, endIndex + 1);
      } catch (e) {
        console.error(e);
      }

      return [];
    },
    doctor() {
      return this.$store.state.Schedule.selectedDoctor;
    },
    doctorId() {
      return this.doctor.id;
    },
    UTCChoices() {
      const choices = [];

      for (let i = -12; i <= 14; i++) {
        choices.push({
          id: i,
          title: formatTimeZone(i),
        });
      }

      return choices;
    },
  },
  async created() {
    if (this.templateProp) {
      const { startDate, endDate } = this.templateProp;
      this.template = {
        ...this.templateProp,
        startDate: startDate ? new Date(startDate) : null,
        endDate: endDate ? new Date(endDate) : null,
        slots: {
          ...this.templateProp.slots,
        },
        daysOfWeek: this.templateProp.daysOfWeek.map((day) => this.daysOfWeekValues[day]),
      };

      this.isEndTimeEnabled = !!this.templateProp.endDate;
      this.parseSlots();
    } else {
      this.createSlots();
      this.template.staffId = this.doctor.mainDoctorSpecialization.staffId;
      this.template.specializationId = this.doctor.doctorSpecializations.find((spec) => spec.isMain).specializationId;
      this.template.timeZone = getClientTimezoneOffset();
    }
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    findIndexSlot(title, position) {
      const index = this.slots.findIndex((slot) => slot.title === title);

      if (index !== -1) return index;

      if (title && position) {
        /*
          Код смотрит на position.
          Если дата окончания - position = "prev"
          Если дата начала - position = "next"
          Код будет проходить по досутпным слотам и искать ближайшее время доступного слота
          Next - будет искать каждый следующий слот с интервалом в 5 минут  (timePickerInterval)
          Prev - будет искать каждый предыдущий слот с интервалом в 5 минут  (timePickerInterval)
        */
        const [hours, minutes] = title.split(':');
        const currentSelectedTime = set(new Date(), { hours, minutes, seconds: 0 });
        let startingTime = currentSelectedTime;
        const quantityIntervalIterations = (24 * 60) / this.timePickerInterval;

        // eslint-disable-next-line no-restricted-syntax
        for (let i = 0; i < quantityIntervalIterations; i++) {
          if (position === 'next') startingTime = addMinutes(startingTime, this.timePickerInterval);
          if (position === 'prev') startingTime = sub(startingTime, { minutes: this.timePickerInterval });
          // eslint-disable-next-line
          const dateIndex = this.slots.findIndex((slot) => slot.title === format(startingTime, 'HH:mm'));

          if (dateIndex !== -1) return dateIndex;
        }
      }
    },
    isValidTimeRange(time) {
      return /[0-9]+:[0-9]+/gmi.test(time);
    },
    onInputEndTime({ displayTime: val }) {
      if (!this.isValidTimeRange(val)) return;
      const indexNewVal = this.findIndexSlot(val, 'prev');
      if ((this.indexStartTime > -1) && indexNewVal < this.indexStartTime) return;

      if (this.isValidStartTime) {
        let indexOldVal = this.indexEndTime;
        indexOldVal = indexOldVal > -1 ? indexOldVal : this.indexStartTime;

        const shouldEnableSlots = indexOldVal < indexNewVal;

        if (shouldEnableSlots) {
          for (let i = Math.min(indexOldVal, indexNewVal); i <= Math.max(indexOldVal, indexNewVal); i++) {
            this.slots[i].isSelected = shouldEnableSlots;
          }
        } else {
          for (let i = Math.max(indexOldVal, indexNewVal); i > Math.min(indexOldVal, indexNewVal); i--) {
            this.slots[i].isSelected = shouldEnableSlots;
          }
        }
      }

      this.endTime = val;
    },
    onInputStartTime({ displayTime: val }) {
      if (!this.isValidTimeRange(val)) return;
      const indexNewVal = this.findIndexSlot(val, 'next');
      if ((this.indexEndTime > -1) && indexNewVal > this.indexEndTime) return;

      if (this.isValidEndTime) {
        let indexOldVal = this.indexStartTime;
        indexOldVal = indexOldVal > -1 ? indexOldVal : this.indexEndTime;

        const shouldEnableSlots = indexOldVal > indexNewVal;

        if (shouldEnableSlots) {
          for (let i = Math.min(indexOldVal, indexNewVal); i <= Math.max(indexOldVal, indexNewVal); i++) {
            this.slots[i].isSelected = shouldEnableSlots;
          }
        } else {
          for (let i = Math.min(indexOldVal, indexNewVal); i < Math.max(indexOldVal, indexNewVal); i++) {
            this.slots[i].isSelected = shouldEnableSlots;
          }
        }
      }

      this.startTime = val;
    },
    disableAllSlots() {
      this.slots = this.slots.map((slot) => ({ ...slot, isSelected: false }));
    },
    createSlots() {
      const slots = [];
      let date = set(new Date(), { hours: 0, minutes: 0, seconds: 0 });
      const quantityIntervalIterations = (24 * 60) / this.minuteInterval;

      for (let i = 0; i < quantityIntervalIterations; i++) {
        slots.push({
          title: format(date, 'HH:mm'),
          isSelected: false,
          originalDate: date,
          // index: i % 32,
          // category: parseInt(i / 32, 10),
        });
        date = addMinutes(date, this.minuteInterval);
      }

      this.slots = slots;
    },
    parseSlots() {
      this.createSlots();

      const slots = this.template.slotTemplateTimes.map((slot) => ({
        // eslint-disable-next-line max-len
        title: `${slot.startTime.hours >= 10 ? slot.startTime.hours : `0${slot.startTime.hours}`}:${slot.startTime.minutes >= 10 ? slot.startTime.minutes : `0${slot.startTime.minutes}`}`,
        isSelected: true,
      }));

      if (slots.length) {
        this.startTime = slots[0].title;
        this.endTime = slots[slots.length - 1].title;
      }

      this.slots = this.slots.map((slot) => {
        const foundSlot = slots.find((s) => s.title === slot.title);
        return foundSlot || slot;
      });
    },
    async saveTemplate() {
      this.$v.$touch();
      this.checkValidation = true;

      if (this.$v.$error) {
        showValidationErrorMessage();
        return;
      }

      const slotTemplateTimes = this.slots.filter((slot) => slot.isSelected).map((slot) => {
        const times = slot.title.split(':');
        const date = new Date();
        date.setHours(times[0]);
        date.setMinutes(times[1]);

        const startTime = {
          hours: date.getHours(),
          minutes: date.getMinutes(),
        };

        date.setMinutes(date.getMinutes() + this.minuteInterval);

        const endTime = {
          hours: date.getHours(),
          minutes: date.getMinutes(),
        };

        return {
          startTime,
          endTime,
        };
      });

      const template = {
        ...this.template,
        startDate: this.template.startDate ? dateWithoutTime(formatISO(this.template.startDate)) : null,
        endDate: this.isEndTimeEnabled ? dateWithoutTime(formatISO(this.template.endDate)) : null,
        slotTemplateTimes,
      };

      delete template.isExternalSchedule;

      try {
        this.isSaving = true;

        if (this.templateProp) {
          await this.$store.dispatch(this.$types.SCHEDULE_TEMPLATE_UPDATE, template);
        } else {
          await this.$store.dispatch(this.$types.SCHEDULE_TEMPLATE_CREATE, template);
        }

        this.$store.commit(this.$types.TOGGLE_SCHEDULE_TRIGGER); // сделает тогл триггера и обновит расписание
        this.onClose();
      } catch (e) {
        console.warn(e);
        showCustomMessage('error', 'Ошибка', e.response?.data?.message);
      } finally {
        this.isSaving = false;
        if (this.$store.state.SchedulePage.schedule.length) {
          this.$store.dispatch(this.$types.SCHEDULE_PAGE_FETCH, true);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.schedule-edit {
  display: flex;
  flex-direction: column;
  height: 500px;
}
.schedule-edit-inner {
  padding: 0 10px;
  box-sizing: border-box;
}
.crm-date-checkbox {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  background-color: $blue-light;
  width: 45px;
  height: 45px;
  padding: 0;
  border: none;
  color: $blue-dark;
  font-weight: bold;
  font-size: 15px;
  line-height: 17px;
  transition: background-color 0.2s ease-in-out;
}
input[type="checkbox"]:checked + .crm-date-checkbox {
  color: $white;
  background-color: $blue-dark;
}
.schedule-buttons {
  display: flex;
  justify-content: space-around;
}

.slot {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F3F3F3;
  border-radius: 5px;
  width: 65px;
  height: 30px;
  padding: 0;
  border: none;
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
  color: $gray-shadow;

  &.selected {
    color: #fff;
    background-color: $blue-dark;
  }
}

.crm-form-block, .select-specialization {
  width: 100%;
}
</style>
