import { APP_HOST } from '@/app.config';

export default class AppealsTemplatesApi {
  constructor($axios) {
    this.api = $axios.instance;
  }

  async get(params) {
    const { data: { data } } = await this.api.get(`${APP_HOST}v3/appeal-text-template/list`, { params });
    return data;
  }

  async create(template) {
    return this.api.post(`${APP_HOST}v3/appeal-text-template`, template);
  }

  async update(template) {
    return this.api.put(`${APP_HOST}v3/appeal-text-template`, template);
  }

  async delete(id) {
    return this.api.delete(`${APP_HOST}v3/appeal-text-template`, { params: { id } });
  }
}
