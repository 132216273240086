import DoctorsApi from '@/services/doctors/doctors.api';

export default class DoctorsService {
  constructor($axios) {
    this.api = new DoctorsApi($axios);
  }

  getDoctorsHistory(id) {
    return this.api.getDoctorsHistory(id);
  }
}
