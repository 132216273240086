<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    :title="specializationId ? 'Редактирование специализации' : 'Добавление специализации'"
    size="xl"
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <loading
      v-if="isLoading"
      class="crm-loading-wrapper"
    >
      Загрузка специализации
    </loading>

    <div
      v-else
      class="specialization-form"
    >
      <div
        v-if="specializationId"
        class="specialization-id"
      >
        ID: {{ specializationId }}
      </div>
      <div class="crm-form-field">
        <b-form-checkbox
          v-model="specialization.isHidden"
          class="ml-1"
          switch
          size="sm"
        >
          Скрыть специализацию на сайте
        </b-form-checkbox>
      </div>
      <div class="crm-form-field">
        <base-input
          v-model.trim="$v.specialization.title.$model"
          :error="$v.specialization.title.$error"
          :errors="errorsValidation.title"
          placeholder="Введите Название специализации"
          label="Название специализации"
          fluid
          material
          required
          @blur="nameBlurHandle"
        />
      </div>
      <div class="crm-form-field">
        <base-input
          v-model.trim="$v.specialization.slug.$model"
          :error="$v.specialization.slug.$error"
          :errors="errorsValidation.slug"
          placeholder="Введите Slug - URL страницы"
          label="Название Slug - URL страницы"
          fluid
          material
          required
        />
      </div>
      <div class="crm-form-field">
        <base-input
          v-model="specialization.weight"
          placeholder="Введите приоритет"
          label="Приоритет специализации (приоритет выше у большего значения)"
          fluid
          material
        />
      </div>
      <div class="crm-form-field">
        <base-input
          v-model.trim="$v.specialization.seoTitle.$model"
          :error="$v.specialization.seoTitle.$error"
          :errors="errorsValidation.seoTitle"
          placeholder="Введите Название страницы"
          label="Название страницы"
          fluid
          material
        />
      </div>
      <div class="crm-form-field">
        <base-input
          v-model.trim="$v.specialization.h1.$model"
          :error="$v.specialization.h1.$error"
          :errors="errorsValidation.h1"
          placeholder="Введите Заголовок"
          label="Заголовок"
          fluid
          material
          required
        />
      </div>
      <div class="crm-form-field">
        <base-input
          v-model.trim="$v.specialization.description.$model"
          :error="$v.specialization.description.$error"
          :errors="errorsValidation.description"
          :input-style="{
            padding: '15px 10px',
            resize: 'none',
            height: '130px',
          }"
          placeholder="Введите Описание"
          label="Описание"
          type="textarea"
          fluid
          material
        />
      </div>
      <div class="crm-form-field">
        <div class="h5 font-weight-bold mt-4 mb-2">
          Блок с информацией о специализациях
        </div>
        <b-form-select
          v-model="seo.infoSpecializations.numberСolumns"
          :options="seo.numberСolumns"
          class="mt-3"
        />
        <div v-if="seo.infoSpecializations.numberСolumns">
          <div
            v-for="(card, index) in seo.infoSpecializations.cards"
            :key="card.id"
          >
            <div class="h6 font-weight-bold mt-3">
              Колонка {{ index + 1 }}
            </div>
            <div class="mt-3">
              <div class="d-flex align-items-center mb-2">
                <div class="mr-2">
                  Заголовок *
                </div>
              </div>
              <base-input
                v-model.trim="card.title"
                :error="card.errors?.title.error"
                :errors="card.errors?.title.errors"
                placeholder="Введите Заголовок"
                label="Заголовок"
                fluid
                material
              />
            </div>
            <div class="flex gap-x-4 items-end mt-3">
              <base-input
                v-model.trim="card.currentListItem"
                label="Пункт списка"
                placeholder="Пункт списка"
                fluid
                material
                class="mt-3"
              />
              <b-button
                variant="primary"
                :type="$const.PRIMARY_BUTTON"
                @click="addListItem(card.id, seo.infoSpecializations, card.currentListItem)"
              >
                Добавить пункт
              </b-button>
            </div>
            <ul
              v-if="card.listItems?.length"
              class="mt-3 grid gap-2"
            >
              <li
                v-for="listItem in card.listItems"
                :key="listItem.id"
                class="
                  flex
                  justify-start
                  items-center
                  min-h-6
                  p-2
                  note-12-reg-400
                  gap-2
                  rounded
                  text-v-ui-text-service-info-monochrome
                  bg-v-ui-light-service-info-monochrome
                "
              >
                <span>{{ listItem.text }}</span>
                <button
                  style="color: red"
                  class="text-sm"
                  @click="onClickRemoveListItem(listItem.id, index)"
                >
                  X
                </button>
              </li>
            </ul>
            <div class="mt-3">
              <div class="d-flex align-items-center mb-2">
                <div class="mr-2">
                  Описание *
                </div>
              </div>
              <base-input
                v-model.trim="card.text"
                :error="card.errors?.text.error"
                :errors="card.errors?.text.errors"
                :input-style="{
                  padding: '15px 10px',
                  resize: 'vertical',
                  height: '100px',
                }"
                placeholder="Описание"
                label="Описание"
                type="textarea"
                fluid
                material
                class="mt-3"
              />
            </div>
            <b-button
              variant="outline-danger"
              :type="$const.PRIMARY_BUTTON"
              class="mt-4"
              @click="removeColumn(card.id, seo.infoSpecializations, 'cards', 'numberСolumns')"
            >
              Удалить колонку
            </b-button>
          </div>
          <div
            class="mt-3"
          >
            <div class="h6 font-weight-bold mt-3">
              Пример
            </div>
            <div class="p-2 mt-3 rounded-lg bg-v-ui-root-monochrome-10">
              <v-seo-description
                :cards="seo.infoSpecializations.cards"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="crm-form-field">
        <div class="h5 font-weight-bold mt-4 mb-2">
          Что дает консультация с определенным специалистом
        </div>
        <b-form-select
          v-model="seo.benefitsСonsultation.numberСolumns"
          :options="seo.numberСolumns"
          class="mt-3"
        />
        <div v-if="seo.benefitsСonsultation.numberСolumns">
          <base-input
            v-model.trim="seo.benefitsСonsultation.title"
            placeholder="Введите Заголовок"
            label="Заголовок"
            fluid
            material
            class="mt-3"
          />
          <div
            v-for="(card, index) in seo.benefitsСonsultation.cards"
            :key="card.id"
          >
            <div class="h6 font-weight-bold mt-3">
              Колонка {{ index + 1 }}
            </div>
            <div class="mt-3">
              <div class="d-flex align-items-center mb-2">
                <div class="mr-2">
                  Заголовок
                </div>
              </div>
              <base-input
                v-model.trim="card.title"
                placeholder="Введите Заголовок"
                label="Заголовок"
                fluid
                material
              />
            </div>
            <div class="flex gap-x-4 items-end mt-3">
              <base-input
                v-model.trim="card.currentListItem"
                label="Пункт списка"
                placeholder="Пункт списка"
                fluid
                material
                class="mt-3"
              />
              <b-button
                variant="primary"
                :type="$const.PRIMARY_BUTTON"
                @click="addListItem(card.id, seo.benefitsСonsultation, card.currentListItem)"
              >
                Добавить пункт
              </b-button>
            </div>
            <ul
              v-if="card.listItems?.length"
              class="mt-3 grid gap-2"
            >
              <li
                v-for="listItem in card.listItems"
                :key="listItem.id"
                class="
                  flex
                  justify-start
                  items-center
                  min-h-6
                  p-2
                  note-12-reg-400
                  gap-2
                  rounded
                  text-v-ui-text-service-info-monochrome
                  bg-v-ui-light-service-info-monochrome
                "
              >
                <span>{{ listItem.text }}</span>
                <button
                  style="color: red"
                  class="text-sm"
                  @click="onClickBCRemoveListItem(listItem.id, index)"
                >
                  X
                </button>
              </li>
            </ul>
            <div class="mt-3">
              <div class="d-flex align-items-center mb-2">
                <div class="mr-2">
                  Описание *
                </div>
              </div>
              <base-input
                v-model.trim="card.text"
                :input-style="{
                  padding: '15px 10px',
                  resize: 'vertical',
                  height: '100px',
                }"
                :error="card.errors?.text.error"
                :errors="card.errors?.text.errors"
                placeholder="Описание"
                label="Описание"
                type="textarea"
                fluid
                material
                class="mt-3"
              />
            </div>
            <b-button
              variant="outline-danger"
              :type="$const.PRIMARY_BUTTON"
              class="mt-4"
              @click="removeColumn(card.id, seo.benefitsСonsultation, 'cards', 'numberСolumns')"
            >
              Удалить колонку
            </b-button>
          </div>
          <div
            class="mt-3"
          >
            <div class="h6 font-weight-bold mt-3">
              Пример
            </div>
            <div class="p-2 mt-3 rounded-lg bg-v-ui-root-monochrome-10">
              <v-seo-description
                :title="seo.benefitsСonsultation.title"
                :cards="seo.benefitsСonsultation.cards"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="crm-form-field">
        <div class="h5 font-weight-bold mt-4 mb-2">
          Блок Q&A
        </div>
        <b-form-select
          v-model="seo.QA.numberQuestions"
          :options="seo.numberQuestions"
          class="mt-3"
        />
        <div v-if="seo.QA.numberQuestions">
          <div
            v-for="(question, index) in seo.QA.items"
            :key="question.id"
          >
            <div class="h6 font-weight-bold mt-3">
              Вопрос {{ index + 1 }}
            </div>
            <b-form-select
              v-model="question.image"
              :options="seo.images"
              class="mt-3"
            />
            <div class="mt-3">
              <div class="d-flex align-items-center mb-2">
                <div class="mr-2">
                  Вопрос *
                </div>
              </div>
              <base-input
                v-model.trim="question.title"
                :error="question.errors?.title.error"
                :errors="question.errors?.title.errors"
                label="Вопрос"
                placeholder="Вопрос"
                fluid
                material
              />
            </div>
            <div class="mt-3">
              <div class="d-flex align-items-center mb-2">
                <div class="mr-2">
                  Ответ *
                </div>
              </div>
              <base-input
                v-model.trim="question.description"
                :input-style="{
                  padding: '15px 10px',
                  resize: 'vertical',
                  height: '100px',
                }"
                :error="question.errors?.description.error"
                :errors="question.errors?.description.errors"
                placeholder="Ответ"
                label="Ответ"
                type="textarea"
                fluid
                material
                class="mt-3"
              />
            </div>
            <b-button
              variant="outline-danger"
              :type="$const.PRIMARY_BUTTON"
              class="mt-4"
              @click="removeColumn(question.id, seo.QA, 'items', 'numberQuestions')"
            >
              Удалить вопрос
            </b-button>
          </div>
          <div class="h6 font-weight-bold mt-3">
            Пример
          </div>
          <div class="p-2 mt-3 rounded-lg bg-v-ui-root-monochrome-5">
            <v-seo-questions
              :image="{
                src: '/img/screen_q.png',
                alt: 'Описание картинки',
              }"
              :items="seo.QA.items"
            />
          </div>
        </div>
      </div>
      <div class="crm-form-field">
        <div class="h5 font-weight-bold mt-4 mb-2">
          Блок подготовки к консультации
        </div>
        <b-form-select
          v-model="seo.preparationСonsultation.numberСolumns"
          :options="seo.numberСolumns"
          class="mt-3"
        />
        <div v-if="seo.preparationСonsultation.numberСolumns">
          <b-form-select
            v-model="seo.preparationСonsultation.theme"
            :options="seo.themeOptions"
            class="mt-3"
            :class="{ 'select-error': seo.preparationСonsultation?.errors.theme.error }"
          />
          <div
            v-if="seo.preparationСonsultation?.errors.theme.errors"
            class="validation-errors"
          >
            <span
              v-for="(error, index) in seo.preparationСonsultation?.errors.theme.errors"
              :key="index"
              class="validation-error-text"
            >
              {{ error }}
            </span>
          </div>
          <div
            v-for="(column, index) in seo.preparationСonsultation.columns"
            :key="column.id"
          >
            <div class="h6 font-weight-bold mt-3">
              Колонка {{ index + 1 }}
            </div>
            <!-- <b-form-checkbox
              v-model="column.needBtn"
              class="ml-1 mt-3"
              switch
              size="sm"
            >
              Добавить кнопку
            </b-form-checkbox>
            <div
              v-show="column.needBtn"
              class="mt-3"
            >
              <div class="d-flex align-items-center mb-2">
                <div class="mr-2">
                  Название *
                </div>
              </div>
              <base-input
                v-model.trim="column.btn.label"
                label="Название"
                placeholder="Название"
                fluid
                material
              />
            </div>
            <div
              v-if="column.needBtn"
              class="mt-3"
            >
              <div class="d-flex align-items-center mb-2">
                <div class="mr-2">
                  Ссылка *
                </div>
              </div>
              <base-input
                v-model.trim="column.btn.link"
                label="Ссылка"
                placeholder="Ссылка"
                fluid
                material
              />
            </div> -->
            <b-form-select
              v-model="column.image"
              :options="seo.images"
              class="mt-3"
              :class="{ 'select-error': column.errors?.image.error }"
            />
            <div
              v-if="column.errors?.image.errors"
              class="validation-errors"
            >
              <span
                v-for="(error, index) in column.errors?.image.errors"
                :key="index"
                class="validation-error-text"
              >
                {{ error }}
              </span>
            </div>
            <div class="mt-3">
              <div class="d-flex align-items-center mb-2">
                <div class="mr-2">
                  Заголовок *
                </div>
              </div>
              <base-input
                v-model.trim="column.title"
                :error="column.errors?.title.error"
                :errors="column.errors?.title.errors"
                placeholder="Введите Заголовок"
                label="Заголовок"
                fluid
                material
              />
            </div>
            <div class="mt-3">
              <div class="d-flex align-items-center mb-2">
                <div class="mr-2">
                  Описание *
                </div>
              </div>
              <base-input
                v-model.trim="column.desc"
                :error="column.errors?.desc.error"
                :errors="column.errors?.desc.errors"
                :input-style="{
                  padding: '15px 10px',
                  resize: 'vertical',
                  height: '100px',
                }"
                placeholder="Описание"
                label="Описание"
                type="textarea"
                fluid
                material
                class="mt-3"
              />
            </div>
            <b-button
              variant="outline-danger"
              :type="$const.PRIMARY_BUTTON"
              class="mt-4"
              @click="removeColumn(column.id, seo.preparationСonsultation, 'columns', 'numberСolumns')"
            >
              Удалить колонку
            </b-button>
          </div>
        </div>
        <div
          v-if="seo.preparationСonsultation.numberСolumns"
          class="mt-3"
        >
          <div class="h6 font-weight-bold mt-3">
            Пример
          </div>
          <div class="p-2 mt-3 rounded-lg bg-v-ui-root-monochrome-10">
            <v-seo-color
              :theme="seo.preparationСonsultation.theme"
              :cards="seo.preparationСonsultation.columns"
            />
          </div>
        </div>
      </div>
      <div class="crm-form-field">
        <div class="h5 font-weight-bold mt-4 mb-2">
          Блок с тегами
        </div>
        <div class="flex gap-x-4 items-end mt-3">
          <base-input
            v-model.trim="seo.tags.numberBlocks"
            label="Количество блоков с тэгами"
            placeholder="Количество блоков с тэгами"
            :mask="[/^[0-9]+$/, /^[0-9]+$/]"
            fluid
            material
          />
          <b-button
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            @click="onClickAddBlock"
          >
            Добавить
          </b-button>
        </div>
        <div v-if="seo.tags.blocks?.length">
          <div
            v-for="(block, index) in seo.tags.blocks"
            :key="block.id"
            class="mt-3"
          >
            <div class="h6 font-weight-bold mt-3">
              Блок {{ index + 1 }}
            </div>
            <base-input
              v-model.trim="block.title"
              :error="block.errors?.title.error"
              :errors="block.errors?.title.errors"
              label="Заголовок *"
              placeholder="Заголовок"
              fluid
              material
              class="mt-3"
            />
            <div class="flex gap-x-4 items-end mt-3">
              <base-input
                v-model.trim="block.currentTag"
                :error="block.errors?.attributes.error"
                :errors="block.errors?.attributes.errors"
                label="Тэг *"
                placeholder="Тэг"
                fluid
                material
                class="mt-3"
              />
              <b-button
                variant="primary"
                :type="$const.PRIMARY_BUTTON"
                @click="onClickAddTag(block.id, block.currentTag)"
              >
                Добавить
              </b-button>
            </div>
            <ul
              v-if="block.tags?.length"
              class="mt-3 flex gap-2 flex-wrap"
            >
              <li
                v-for="tag in block.tags"
                :key="tag.id"
                class="
                  flex
                  justify-center
                  items-center
                  min-h-6
                  p-2
                  note-12-reg-400
                  gap-2
                  rounded
                  text-v-ui-text-service-info-monochrome
                  bg-v-ui-light-service-info-monochrome
                "
              >
                <span>{{ tag.tag }}</span>
                <button
                  style="color: red"
                  class="text-sm"
                  @click="onClickRemoveTag(tag.id, index)"
                >
                  X
                </button>
              </li>
            </ul>
            <b-button
              variant="outline-danger"
              :type="$const.PRIMARY_BUTTON"
              class="mt-4"
              @click="onClickRemoveBlock(block.id)"
            >
              Удалить блок
            </b-button>
          </div>
          <div class="p-2 mt-3 rounded-lg bg-v-ui-root-monochrome-10">
            <v-seo-attributes
              :blocks="seo.tags.blocks"
            />
          </div>
        </div>
      </div>
    </div>

    <template #modal-footer>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="onClose"
      >
        Отменить
      </b-button>

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="onClickSave"
      >
        Сохранить
        <b-spinner
          v-if="isSaving"
          variant="light"
          small
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import Bus from '@/eventBus';
import { mixinRoles } from '@/mixins';
import { showValidationErrorMessage } from '@/helpers/messages';
import { required } from 'vuelidate/lib/validators';
import Loading from '@/components/Loading';
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import { BaseInput } from '@/components/base';
import {
  VSeoColor,
  VSeoAttributes,
  VSeoDescription,
  VSeoQuestions,
} from '@doctis.front/doctis.designsystem';
import { SEO_COLOR_THEMES } from '@doctis.front/doctis.designsystem/stories/seoblocks/seo-color/options';
import { SEO_QUESTIONS_IMAGE } from '@doctis.front/doctis.designsystem/stories/seoblocks/seo-questions/options';
import {
  DESCRIPTION_TYPE,
  DESCRIPTION_VARIANT,
  DESCRIPTION_DEVICE,
} from '@doctis.front/doctis.designsystem/stories/components/description/options';

export default {
  name: 'SpecializationEditModal',
  components: {
    BaseInput,
    Loading,
    VSeoColor,
    VSeoAttributes,
    VSeoDescription,
    VSeoQuestions,
  },
  mixins: [mixinRoles],
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    specializationId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      isSaving: false,
      isOpen: true,
      specialization: {
        title: null,
        description: null,
        slug: null,
        isHidden: false,
        weight: 0,
        seoTitle: null,
        h1: null,
        SeoSection: {
          SpecializationDescription: [],
          BenefitsDescriptionSection: {
            BenefitsDescriptions: [],
            Header: null,
          },
          QuestionsAndAnswers: [],
          PreparationInstructionSection: {
            BackgroundColor: null,
            PreparationInstructions: [],
          },
          Tags: [],
        },
      },
      seo: {
        themeOptions: [
          { value: null, text: 'Цвет фона *' },
          { value: SEO_COLOR_THEMES.PRIMARY, text: 'Светло фиолетовая' },
          { value: SEO_COLOR_THEMES.SECONDARY, text: 'Фиолетовая' },
          { value: SEO_COLOR_THEMES.TERTIARY, text: 'Розовая' },
        ],
        themeBackendOptions: {
          [SEO_COLOR_THEMES.PRIMARY]: 'LightPurple',
          [SEO_COLOR_THEMES.SECONDARY]: 'Purple',
          [SEO_COLOR_THEMES.TERTIARY]: 'Pink',
        },
        themeFrontOptions: {
          LightPurple: SEO_COLOR_THEMES.PRIMARY,
          Purple: SEO_COLOR_THEMES.SECONDARY,
          Pink: SEO_COLOR_THEMES.TERTIARY,
        },
        numberСolumns: [
          { value: null, text: 'Количество колонок' },
          { value: 1, text: 'Одна' },
          { value: 2, text: 'Две' },
          { value: 3, text: 'Три' },
        ],
        numberQuestions: [
          { value: null, text: 'Количество вопросов' },
          { value: 1, text: 'Один' },
          { value: 2, text: 'Два' },
          { value: 3, text: 'Три' },
          { value: 4, text: 'Четыре' },
          { value: 5, text: 'Пять' },
          { value: 6, text: 'Шесть' },
          { value: 7, text: 'Семь' },
          { value: 8, text: 'Восемь' },
        ],
        images: [
          { value: null, text: 'Иконка *' },
          { value: '/img/colored-icons/relieved-face.png', text: '😌' },
          { value: '/img/colored-icons/nurse.png', text: '👩‍⚕️' },
          { value: '/img/colored-icons/doctor.png', text: '👨‍⚕️' },
          { value: '/img/colored-icons/attention.png', text: '⁉️' },
          { value: '/img/colored-icons/magnifying-glass.png', text: '🔍' },
          { value: '/img/colored-icons/memo.png', text: '📝' },
          { value: '/img/colored-icons/software.png', text: '🧑‍💻' },
          { value: '/img/colored-icons/writing-hand.png', text: '✍️' },
          { value: '/img/colored-icons/test-tube.png', text: '🧪' },
          { value: '/img/colored-icons/alarm-clock.png', text: '⏰' },
        ],
        infoSpecializations: {
          numberСolumns: null,
          cards: [],
        },
        benefitsСonsultation: {
          numberСolumns: null,
          title: null,
          cards: [],
        },
        QA: {
          SEO_QUESTIONS_IMAGE,
          numberQuestions: null,
          items: [],
        },
        preparationСonsultation: {
          theme: null,
          numberСolumns: null,
          columns: [],
          errors: {
            theme: {
              error: false,
              errors: [],
            },
          },
        },
        tags: {
          numberBlocks: null,
          blocks: [],
        },
      },
    };
  },
  validations() {
    const data = {
      specialization: {
        title: {
          required,
        },
        description: {},
        slug: {
          required,
        },
        seoTitle: {},
        h1: {
          required,
        },
      },
    };

    return data;
  },
  computed: {
    numberСolumns() {
      return this.seo.preparationСonsultation.numberСolumns;
    },
    numberСolumnsIS() {
      return this.seo.infoSpecializations.numberСolumns;
    },
    numberСolumnsBC() {
      return this.seo.benefitsСonsultation.numberСolumns;
    },
    numberQuestions() {
      return this.seo.QA.numberQuestions;
    },
    formattedSpecializationWeight() {
      return this.specialization.weight === '' ? 0 : this.specialization.weight;
    },
    errorsValidation() {
      const errors = {};

      errors.title = [];
      if (!this.$v.specialization.title.required) {
        errors.title.push('Поле не может быть пустым');
      }

      errors.slug = [];
      if (!this.$v.specialization.slug.required) {
        errors.slug.push('Поле не может быть пустым');
      }

      errors.h1 = [];
      if (!this.$v.specialization.h1.required) {
        errors.h1.push('Поле не может быть пустым');
      }

      errors.btnName = [];
      if (!this.$v.specialization.h1.required) {
        errors.h1.push('Поле не может быть пустым');
      }

      return errors;
    },
  },
  watch: {
    numberСolumns(value) {
      if (value) {
        const currentColumns = this.seo.preparationСonsultation.columns.length;

        if (currentColumns < value) {
          for (let index = 0; index < value; index += 1) {
            const newIndex = index;
            if (currentColumns < newIndex + 1) {
              this.seo.preparationСonsultation.columns.push(
                {
                  id: uuidv4(),
                  image: null,
                  title: null,
                  desc: null,
                  needBtn: false,
                  btn: {
                    label: null,
                    link: null,
                  },
                  errors: {
                    image: {
                      error: false,
                      errors: [],
                    },
                    title: {
                      error: false,
                      errors: [],
                    },
                    desc: {
                      error: false,
                      errors: [],
                    },
                  },
                },
              );
            }
          }
        } else {
          this.seo.preparationСonsultation.columns.splice(value);
        }
      }
    },
    numberСolumnsIS(value) {
      this.addColumn(this.seo.infoSpecializations, value);
    },
    numberСolumnsBC(value) {
      this.addColumnBenefitsСonsultation(this.seo.benefitsСonsultation, value);
    },
    numberQuestions(value) {
      if (value) {
        const currentQuestion = this.seo.QA.items.length;

        if (currentQuestion < value) {
          for (let index = 0; index < value; index += 1) {
            const newIndex = index;
            if (currentQuestion < newIndex + 1) {
              this.seo.QA.items.push(
                {
                  id: uuidv4(),
                  theme: 'primary',
                  image: null,
                  title: null,
                  description: '',
                  errors: {
                    title: {
                      error: false,
                      errors: [],
                    },
                    description: {
                      error: false,
                      errors: [],
                    },
                  },
                },
              );
            }
          }
        } else {
          this.seo.QA.items.splice(value);
        }
      }
    },
    seo: {
      handler(newValue) {
        this.seo = newValue;
      },
      deep: true,
    },
  },
  async created() {
    if (this.specializationId) {
      try {
        this.isLoading = true;

        const specialization = await this.$store.dispatch(this.$types.SPECIALIZATION_FETCH_BY_ID, this.specializationId);

        this.specialization = {
          ...specialization,
        };

        if (specialization.seoSection.specializationDescription.length) {
          this.seo.infoSpecializations.numberСolumns = specialization.seoSection.specializationDescription.length;
          this.seo.infoSpecializations.cards = specialization.seoSection.specializationDescription.map((item) => {
            const listItems = item.bulletList.map((listItem) => (
              {
                id: uuidv4(),
                text: listItem,
              }
            ));

            const list = listItems.map((listItem) => listItem.text);

            return {
              id: uuidv4(),
              currentListItem: null,
              text: item.textSection,
              title: item.header,
              list,
              listItems,
              type: DESCRIPTION_TYPE.GHOST,
              deviceType: DESCRIPTION_DEVICE.DESKTOP,
              errors: {
                title: {
                  error: false,
                  errors: [],
                },
                text: {
                  error: false,
                  errors: [],
                },
              },
            };
          });
        }

        if (specialization.seoSection.benefitsDescription.benefitsDescriptions.length) {
          this.seo.benefitsСonsultation.numberСolumns = specialization.seoSection.benefitsDescription.benefitsDescriptions.length;
          this.seo.benefitsСonsultation.title = specialization.seoSection.benefitsDescription.header;
          this.seo.benefitsСonsultation.cards = specialization.seoSection.benefitsDescription.benefitsDescriptions.map((item) => {
            const listItems = item.bulletList.map((listItem) => (
              {
                id: uuidv4(),
                text: listItem,
              }
            ));

            const list = listItems.map((listItem) => listItem.text);

            return {
              id: uuidv4(),
              text: item.textSection,
              title: item.header,
              list,
              listItems,
              type: DESCRIPTION_TYPE.GHOST,
              deviceType: DESCRIPTION_DEVICE.DESKTOP,
              errors: {
                text: {
                  error: false,
                  errors: [],
                },
              },
            };
          });
        }

        if (specialization.seoSection.questionsAndAnswers.length) {
          this.seo.QA.numberQuestions = specialization.seoSection.questionsAndAnswers.length;
          this.seo.QA.items = specialization.seoSection.questionsAndAnswers.map((item) => {
            const emoji = this.seo.images.filter((itemEmoji) => itemEmoji.text === item.emoji);

            return {
              id: uuidv4(),
              image: emoji[0].value,
              title: item.question,
              description: item.answer,
              theme: 'primary',
              errors: {
                title: {
                  error: false,
                  errors: [],
                },
                description: {
                  error: false,
                  errors: [],
                },
              },
            };
          });
        }

        if (specialization.seoSection.preparationInstructions.preparationInstructions.length) {
          this.seo.preparationСonsultation.theme = this.seo.themeFrontOptions[specialization.seoSection.preparationInstructions.backgroundColor];
          this.seo.preparationСonsultation.numberСolumns = specialization.seoSection.preparationInstructions.preparationInstructions.length;
          this.seo.preparationСonsultation.columns = specialization.seoSection.preparationInstructions.preparationInstructions.map((item) => {
            const emoji = this.seo.images.filter((itemEmoji) => itemEmoji.text === item.emoji);

            return {
              id: uuidv4(),
              image: emoji[0].value,
              title: item.header,
              desc: item.textSection,
              errors: {
                image: {
                  error: false,
                  errors: [],
                },
                title: {
                  error: false,
                  errors: [],
                },
                desc: {
                  error: false,
                  errors: [],
                },
              },
            };
          });
        }

        if (specialization.seoSection.tags.length) {
          this.seo.tags.blocks = specialization.seoSection.tags.map((item) => {
            const tags = item.tags.map((tag) => (
              {
                id: uuidv4(),
                tag,
              }
            ));

            return {
              id: uuidv4(),
              title: item.header,
              currentTag: null,
              attributes: item.tags,
              tags,
              errors: {
                title: {
                  error: false,
                  errors: [],
                },
                attributes: {
                  error: false,
                  errors: [],
                },
              },
            };
          });
        }
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;
      }
    }
  },
  methods: {
    getEmoji(image) {
      const emoji = this.seo.images.filter((item) => item.value === image);

      if (emoji.length) {
        return emoji[0];
      }

      return null;
    },
    onClickRemoveBlock(id) {
      this.seo.tags.blocks = this.seo.tags.blocks.filter((block) => block.id !== id);
    },
    onClickRemoveTag(id, index) {
      this.removeItem({
        id,
        index,
        nameList: this.seo.tags,
        nameItems: 'blocks',
        nameListDev: 'tags',
        nameListProd: 'attributes',
        nameItemProd: 'tag',
      });
    },
    onClickRemoveListItem(id, index) {
      this.removeItem({
        id,
        index,
        nameList: this.seo.infoSpecializations,
        nameItems: 'cards',
        nameListDev: 'listItems',
        nameListProd: 'list',
        nameItemProd: 'text',
      });
    },
    onClickBCRemoveListItem(id, index) {
      this.removeItem({
        id,
        index,
        nameList: this.seo.benefitsСonsultation,
        nameItems: 'cards',
        nameListDev: 'listItems',
        nameListProd: 'list',
        nameItemProd: 'text',
      });
    },
    onClickAddTag(id, text) {
      if (!text || !text.length) return;
      this.seo.tags.blocks.forEach((block) => {
        if (block.id === id) {
          block.tags.push(
            {
              id: uuidv4(),
              tag: block.currentTag,
            },
          );
          block.currentTag = '';
          block.attributes = [];
          block.tags.forEach((tag) => block.attributes.push(tag.tag));
        }
      });
    },
    onClickAddBlock() {
      const value = Number(this.seo.tags.numberBlocks);
      this.seo.tags.numberBlocks = null;

      if (value > 0) {
        for (let index = 0; index < value; index += 1) {
          this.seo.tags.blocks.push(
            {
              id: uuidv4(),
              title: null,
              attributes: [],
              currentTag: null,
              tags: [],
              errors: {
                title: {
                  error: false,
                  errors: [],
                },
                attributes: {
                  error: false,
                  errors: [],
                },
              },
            },
          );
        }
      }
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    onColorThemeInput(theme) {
      this.seo.selectorTheme.placeholder = theme.title;
    },
    async onClickSave() {
      let errors = 0;
      const resetErrors = (item, field) => {
        if (item.errors) {
          item.errors[field].errors = [];
          item.errors[field].error = false;
        }
      };
      const valueDynamicData = (blocks, items, field) => {
        if (!blocks) return;

        items.forEach((card) => {
          const hasErrors = card?.errors;

          if (hasErrors) {
            if (!card[field] || card[field] === '') {
              card.errors[field].errors = ['Поле не может быть пустым'];
              card.errors[field].error = true;
              errors += 1;
            } else {
              resetErrors(card, field);
            }
          }
        });
      };
      const validateDescriptionBlock = (block) => {
        if (this.seo[block].numberСolumns) {
          this.seo[block].cards.forEach((card) => {
            if (card.listItems.length) {
              resetErrors(card, 'text');
            } else {
              valueDynamicData(
                this.seo[block].numberСolumns,
                [card],
                'text',
              );
            }
          });
        }
      };

      if (this.seo.preparationСonsultation.numberСolumns) {
        if (!this.seo.preparationСonsultation.theme) {
          if (!this.seo.preparationСonsultation.errors.theme.length) {
            this.seo.preparationСonsultation.errors.theme.errors = ['Поле не может быть пустым'];
          }

          this.seo.preparationСonsultation.errors.theme.error = true;
          errors += 1;
        } else {
          this.seo.preparationСonsultation.errors.theme.errors = [];
          this.seo.preparationСonsultation.errors.theme.error = false;
        }
      }

      if (this.seo.tags.blocks.length) {
        this.seo.tags.blocks.forEach((block) => {
          if (!block.attributes.length) {
            if (!block.errors.attributes.errors.length) {
              block.errors.attributes.errors = ['Поле не может быть пустым'];
            }

            block.errors.attributes.error = true;
            errors += 1;
          } else {
            resetErrors(block, 'attributes');
          }
        });
      }

      validateDescriptionBlock('infoSpecializations');
      validateDescriptionBlock('benefitsСonsultation');

      valueDynamicData(
        this.seo.infoSpecializations.numberСolumns,
        this.seo.infoSpecializations.cards,
        'title',
      );

      valueDynamicData(
        this.seo.QA.numberQuestions,
        this.seo.QA.items,
        'title',
      );

      valueDynamicData(
        this.seo.QA.numberQuestions,
        this.seo.QA.items,
        'description',
      );

      valueDynamicData(
        this.seo.preparationСonsultation.numberСolumns,
        this.seo.preparationСonsultation.columns,
        'title',
      );

      valueDynamicData(
        this.seo.preparationСonsultation.numberСolumns,
        this.seo.preparationСonsultation.columns,
        'desc',
      );

      valueDynamicData(
        this.seo.preparationСonsultation.numberСolumns,
        this.seo.preparationСonsultation.columns,
        'image',
      );

      valueDynamicData(
        this.seo.tags.blocks,
        this.seo.tags.blocks,
        'title',
      );

      this.$v.$touch();
      if (this.$v.$error || errors > 0) {
        showValidationErrorMessage();
        return;
      }

      this.specialization.SeoSection = {
        SpecializationDescription: [],
        BenefitsDescriptionSection: {
          BenefitsDescriptions: [],
          Header: null,
        },
        QuestionsAndAnswers: [],
        PreparationInstructionSection: {
          BackgroundColor: null,
          PreparationInstructions: [],
        },
        Tags: [],
      };

      if (this.seo.infoSpecializations.cards.length) {
        this.specialization.SeoSection.SpecializationDescription = this.seo.infoSpecializations.cards.map((item) => (
          {
            TextSection: item.text,
            Header: item.title,
            BulletList: item.list,
          }
        ));
      }

      if (this.seo.benefitsСonsultation.cards.length) {
        this.specialization.SeoSection.BenefitsDescriptionSection.Header = this.seo.benefitsСonsultation.title;
        this.specialization.SeoSection.BenefitsDescriptionSection.BenefitsDescriptions = this.seo.benefitsСonsultation.cards.map((item) => (
          {
            TextSection: item.text,
            Header: item.title,
            BulletList: item.list,
          }
        ));
      }

      if (this.seo.QA.items.length) {
        this.specialization.SeoSection.QuestionsAndAnswers = this.seo.QA.items.map((item) => {
          const emoji = this.getEmoji(item.image);

          return {
            Emoji: emoji.text,
            Question: item.title,
            Answer: item.description,
            theme: 'primary',
          };
        });
      }

      if (this.seo.preparationСonsultation.columns.length) {
        const theme = this.seo.themeBackendOptions[this.seo.preparationСonsultation.theme];
        this.specialization.SeoSection.PreparationInstructionSection.BackgroundColor = theme;
        /* eslint-disable */
        this.specialization.SeoSection.PreparationInstructionSection.PreparationInstructions = this.seo.preparationСonsultation.columns.map((item) => {
          const emoji = this.getEmoji(item.image);

          return {
            Header: item.title,
            TextSection: item.desc,
            Emoji: emoji.text,
          };
        });
        /* eslint-enable */
      }

      if (this.seo.tags.blocks.length) {
        this.specialization.SeoSection.Tags = this.seo.tags.blocks.map((item) => (
          {
            Header: item.title,
            Tags: item.attributes,
          }
        ));
      }

      this.isSaving = true;

      this.specialization.weight = this.formattedSpecializationWeight;

      try {
        if (this.specializationId) {
          await this.$store.dispatch(this.$types.SPECIALIZATION_UPDATE, this.specialization);
        } else {
          await this.$store.dispatch(this.$types.SPECIALIZATION_CREATE, this.specialization);
        }

        Bus.$emit('specializations:update');
        this.onClose();
      } finally {
        this.isSaving = false;
      }
    },
    nameBlurHandle() {
      const cyrillicToTranslit = new CyrillicToTranslit();
      const translit = cyrillicToTranslit.transform(this.specialization.title, '-').toLowerCase();
      this.specialization.slug = translit;
      this.specialization.h1 = this.specialization.title;
    },
    addColumn(name, value) {
      if (value) {
        const currentCards = name.cards.length;

        if (currentCards < value) {
          for (let index = 0; index < value; index += 1) {
            const newIndex = index;
            if (currentCards < newIndex + 1) {
              name.cards.push(
                {
                  id: uuidv4(),
                  currentListItem: null,
                  title: null,
                  text: null,
                  list: [],
                  listItems: [],
                  type: DESCRIPTION_TYPE.GHOST,
                  variant: DESCRIPTION_VARIANT.MAIN,
                  deviceType: DESCRIPTION_DEVICE.DESKTOP,
                  errors: {
                    title: {
                      error: false,
                      errors: [],
                    },
                    text: {
                      error: false,
                      errors: [],
                    },
                  },
                },
              );
            }
          }
        } else {
          name.cards.splice(value);
        }
      }
    },
    addColumnBenefitsСonsultation(name, value) {
      if (value) {
        const currentCards = name.cards.length;

        if (currentCards < value) {
          for (let index = 0; index < value; index += 1) {
            const newIndex = index;
            if (currentCards < newIndex + 1) {
              name.cards.push(
                {
                  id: uuidv4(),
                  currentListItem: null,
                  title: null,
                  text: null,
                  list: [],
                  listItems: [],
                  type: DESCRIPTION_TYPE.GHOST,
                  variant: DESCRIPTION_VARIANT.MAIN,
                  deviceType: DESCRIPTION_DEVICE.DESKTOP,
                  errors: {
                    text: {
                      error: false,
                      errors: [],
                    },
                  },
                },
              );
            }
          }
        } else {
          name.cards.splice(value);
        }
      }
    },
    addListItem(id, nameList, text) {
      if (!text || !text.length) return;
      nameList.cards.forEach((card) => {
        if (card.id === id) {
          card.listItems.push(
            {
              id: uuidv4(),
              text: card.currentListItem,
            },
          );
          card.currentListItem = '';
          card.list = [];
          card.listItems.forEach((listItem) => card.list.push(listItem.text));
        }
      });
    },
    removeColumn(id, nameList, nameItems, nameNumber) {
      nameList[nameItems] = nameList[nameItems].filter((item) => item.id !== id);

      if (nameList[nameItems].length) {
        nameList[nameNumber] = nameList[nameItems].length;
      } else {
        nameList[nameNumber] = null;
      }
    },
    removeItem(params) {
      /**
       * [params is object]
       * @param  {[number]} id [id элемента для фильтрации]
       * @param  {[number]} index [index для блока, в котором нужно удалить элемент]
       * @param  {[object]} nameList [список элементов]
       * @param  {[string]} nameItems [название элементов]
       * @param  {[string]} nameListDev [список элементов для работы]
       * @param  {[string]} nameListProd [список элементов для прокидывания в пропс компонента]
       * @param  {[string]} nameItemProd [название свойства с текстом]
       * @return {[void]}
       */

      const {
        id, index, nameList, nameItems, nameListDev, nameListProd, nameItemProd,
      } = params;

      nameList[nameItems][index][nameListDev] = nameList[nameItems][index][nameListDev].filter((item) => item.id !== id); // eslint-disable-line
      nameList[nameItems][index][nameListProd] = [];

      if (nameList[nameItems][index][nameListDev].length) {
        nameList[nameItems][index][nameListDev].forEach((item) => nameList[nameItems][index][nameListProd].push(item[nameItemProd]));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.specialization-id {
  font-size: 14px;
  line-height: 14px;
  color: #908F8D;
  margin-bottom: 15px;
}
.specialization-form {
  width: 100%;

  .crm-form-field + .crm-form-field {
    margin-top: 15px;
  }
}
.crm-wrapper-buttons {
  margin-top: 50px;
}

.validation-errors {
  position: relative;
  top: 0;
  margin-top: 5px;
}

.select-error {
  border-color: #EB5757;
}

::v-deep .validation-errors {
  top: calc(100% + -1.5px);
}
::placeholder {
  font-size: 0.8em;
}

::v-deep .v-description {
  border-radius: 0 !important;
}
</style>
