import mutations from './mutations';
import getters from './getters';
import actions from './actions';

export default {
  namespaced: true,
  state: {
    token: null,
    user: {},
    role: null,
    login: null,
    isUserLoading: false,
  },
  actions,
  getters,
  mutations,
};
