<template>
  <b-modal
    v-model="isOpen"
    centered
    title="Консультации"
    hide-footer
    no-close-on-backdrop
    @hidden="onClose"
  >
    <div class="consultations-info">
      <div
        v-if="!totalCount && isAllItemsLoaded"
        class="no-consultations"
      >
        <span>Нет консультаций</span>
      </div>
      <async-list
        v-else
        :is-all-items-loaded="isAllItemsLoaded"
        :disable-toggle-header="true"
        class="consultations-list"
        @load-more-items="loadMoreItems"
      >
        <template>
          <div
            v-for="consultation in consultations"
            :key="consultation.id"
            class="crm-container container-consultations-info"
          >
            <doctor-consultations-info-item
              :consultation="consultation"
              class="consultations-info"
            />
          </div>
        </template>
      </async-list>
    </div>
  </b-modal>
</template>

<script>
import AsyncList from '@/components/AsyncList';

import DoctorConsultationsInfoItem from './DoctorConsultationsInfoItem';

const LIMIT = 15;

export default {
  name: 'DoctorConsultationsInfoModal',
  components: {
    AsyncList,
    DoctorConsultationsInfoItem,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    doctorId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isAllItemsLoaded: false,
      isLoading: false,
      limit: LIMIT,
    };
  },
  computed: {
    consultations() {
      return this.$store.state.Doctor.consultations;
    },
    totalCount() {
      return this.consultations.length;
    },
  },
  async created() {
    await this.consultationsFetch();
  },
  beforeDestroy() {
    this.$store.commit(this.$types.DOCTORS_CONSULTATIONS_SET, []);
  },
  methods: {
    async consultationsFetch() {
      this.isAllItemsLoaded = false;
      this.isLoading = true;

      const prevTotal = this.totalCount;

      try {
        await this.$store.dispatch(this.$types.DOCTORS_CONSULTATIONS_FETCH, {
          skip: prevTotal,
          take: this.limit,
          doctorId: this.doctorId,
        });

        if (prevTotal + this.limit > this.totalCount) {
          this.isAllItemsLoaded = true;
        }
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;
      }
    },
    loadMoreItems() {
      if (!this.isLoading) {
        this.consultationsFetch();
      }
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
  },
};
</script>

<style lang="scss" scoped>
.consultations-info {
  width: 100%;
}
.consultations-list {
  height: 589px;
}
.crm-container.container-consultations-info {
  margin-bottom: 10px;
}
.no-consultations {
  display: flex;
  justify-content: center;
}
:deep(.modal-dialog) {
      max-width: 700px;
    }
</style>
