<template>
  <div class="container">
    <div class="info">
      <div class="font-weight-bold">
        Дата переоткрытия
      </div>
      <div class="crm-info-text text-break">
        {{ formatDate(consultation.reopenDate) }}
      </div>
    </div>
    <div class="info-description">
      <div class="d-flex mb-1">
        <div class="font-weight-bold mr-2">
          Жалобы:
        </div>
        <div class="text-break">
          {{ consultation.protocolVersion.Anamnesis || '—' }}
        </div>
      </div>
      <div class="d-flex mb-1">
        <div class="font-weight-bold mr-2 ">
          Рекомендации:
        </div>
        <div class="text-break">
          {{ consultation.protocolVersion.Recommendation || '—' }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  format,
  parseISO,
  addHours,
} from '@evd3v/date-fns';

export default {
  name: 'ConsultationReopenInfo',
  props: {
    consultation: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  methods: {
    formatDate(date) {
      const dateAdded = addHours(parseISO(date), 3);
      return format(dateAdded, 'dd.MM.yyyy HH:mm');
    },
  },
};
</script>
<style lang="scss" scoped>
  .container {
    width: 100%;
    min-height: 70px;
    max-height: 100%;
    display: flex;
    background-color: white;
    box-shadow: 1px 1px 2px 1px rgba(0,0,0,0.14);
    padding: 5px;
    position: relative;
  }
  .info{
    display: flex;
    flex-direction: column;
    width: 200px;
    padding: 5px 10px;
    border-right: 1px solid rgb(233, 229, 229);
  }
  .info-description{
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
  }
</style>
