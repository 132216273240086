import { APP_HOST } from '@/app.config';

export default class AppealsApi {
  constructor($axios) {
    this.api = $axios.instance;
  }

  async createOld(appeal) {
    return this.api.post(`${APP_HOST}v2/appeal`, appeal);
  }

  async updateOld(appeal) {
    return this.api.put(`${APP_HOST}v2/appeal`, appeal);
  }

  // * in future rewrite on v3 api
  async getHistory(appealId) {
    const { data } = await this.api.get(`${APP_HOST}v2/appeal/getAppealHistory`, { params: { appealId } });
    return data;
  }

  // * v3 api
  async search(params) {
    const { data: { data } } = await this.api.get(`${APP_HOST}v3/appeal/search`, { params });
    return data;
  }

  async getById(appealId) {
    const { data: { data } } = await this.api.get(`${APP_HOST}v3/appeal`, { params: { appealId } });
    return data;
  }

  async getTypes(purposes) {
    const { data: { data } } = await this.api.get(`${APP_HOST}v3/appeal/types`, { params: { purposes } });
    return data;
  }

  async create(obj) {
    const { data: { data } } = await this.api.post(`${APP_HOST}v3/appeal`, obj);
    return data;
  }

  async export(params) {
    return this.api.post(`${APP_HOST}v3/appeal/export`, params, { responseType: 'blob' });
  }

  async update(appeal) {
    const { data: { data } } = await this.api.put(`${APP_HOST}v3/appeal`, appeal);
    return data;
  }
}
