export default {
  SET_CLINICS(state, payload) {
    state.clinics = payload;
  },
  SET_CLINIC_LIST_NAMES(state, payload) {
    state.clinicListNames = payload;
  },
  REMOVE_CLINIC_BY_ID(state, payload) {
    state.clinics = state.clinics.filter((clinic) => clinic.id !== payload);
  },
  ADD_CLINICS(state, payload) {
    state.clinics = [...state.clinics, ...payload];
  },
  SET_CLINICS_PINNED_DOCTORS(state, payload) {
    state.pinnedDoctors = payload;
  },
  SET_IS_CHANGED_CLINIC_INFO(state, payload) {
    state.isChangedClinicInfo = payload;
  },
  SET_IS_CHANGED_CLINIC_DOCTORS_INFO(state, payload) {
    state.isChangedClinicDoctorsInfo = payload;
  },
  SET_ONE_CLINIC(state, payload) {
    state.clinic = payload;
  },
  SET_CLINIC_IS_LOADING(state, payload) {
    state.isClinicLoading = payload;
  },
  SET_CLINIC_INFO_IS_EDIT(state, payload) {
    state.isEditClinicInfo = payload;
  },
  SET_CLINIC_DOCTORS_IS_EDIT(state, payload) {
    state.isEditClinicDoctors = payload;
  },
  SET_LIST_PRICES(state, payload) {
    state.pricesList = payload;
  },
  SET_LIST_PRICES_PAGINATION_COUNT(state, payload) {
    state.pricesListPaginationCount = payload;
  },
  SET_LIST_PRICES_LOADING(state, payload) {
    state.pricesListLoading = payload;
  },
};
