<template>
  <ymap-marker
    class="marker-wrapper"
    :coords="[clinic.latitude, clinic.longitude]"
    marker-type="placemark"
    :marker-id="id"
    :icon="{ content: `${clinic.title}` }"
    cluster-name="1"
    :hint-content="clinic.title"
    @balloonopen="bindListener"
    @balloonclose="unbindListener"
  >
    <template slot="balloon">
      <div class="ymap-balloon">
        <ClinicsBalloon
          :id="clinic.id"
          :clinic="clinic"
        />

        <b-button
          id="balloon-btn"
          variant="outline-primary"
          :type="$const.PRIMARY_BUTTON"
          class="ml-1 balloon-btn"
          size="sm"
          @click="bindListener"
        >
          <b-icon
            font-scale="1"
            shift-v="5"
            icon="eye"
          />
        </b-button>
      </div>
    </template>
  </ymap-marker>
</template>
<script>
import ClinicsBalloon from './ClinicsBalloon';

export default {
  name: 'ClinicsMapMarker',
  components: {
    ClinicsBalloon,
    ymapMarker: () => import('vue-yandex-maps').then(({ ymapMarker }) => ymapMarker),
  },
  props: {
    clinic: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
  }),
  methods: {
    bindListener() {
      document.addEventListener('click', this.onMarkerClickListener, this.clinic);
    },
    async onMarkerClickListener(e) {
      const ballonBtn = e.target.classList.contains('balloon-btn')
      || e.target.closest('.balloon-btn');

      if (!ballonBtn) return;

      // await this.$nextTick();
      this.$emit('clickToButton', this.clinic);
    },
    unbindListener() {
      document.removeEventListener('click', this.onMarkerClickListener, this.clinic);
    },
  },
};
</script>
<style lang="scss" scoped>
  .ymap-balloon {
    display: flex;
    padding-top: 2px;
    padding-right: 5px;
  }
  .balloon-btn{
    width: 35px;
    height: 18px;
    border: none;
  }
</style>
