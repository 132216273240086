import AppealsTemplatesApi from '@/services/appeals-templates/appeals-templates.api';

export default class AppealsTemplatesService {
  constructor($axios) {
    this.api = new AppealsTemplatesApi($axios);
  }

  get(obj) {
    return this.api.get(obj);
  }

  create(obj) {
    return this.api.create(obj);
  }

  update(obj) {
    return this.api.update(obj);
  }

  delete(id) {
    return this.api.delete(id);
  }
}
