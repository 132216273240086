<template>
  <div class="crm-loading">
    <div class="crm-loading-info">
      <p>
        <slot />
      </p>
      <preloader />
    </div>
  </div>
</template>

<script>
import Preloader from '@/components/Preloader';

export default {
  name: 'UserLoading',
  components: {
    Preloader,
  },
};

</script>

<style lang="scss" scoped>
.crm-loading {
  @include default-flex-prop;
  width: 100%;
  height: 100%;
}
.crm-loading-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
