import ClinicApi from '@/services/clinic/clinic.api';

export default class ClinicService {
  constructor($axios) {
    this.api = new ClinicApi($axios);
  }

  // * есть этот метод в закомменченном компоненте ScheduleSearchPanel
  getAdmin() {
    return this.api.getAdmin();
  }

  getHistory(id) {
    return this.api.getHistory(id);
  }

  sendPrice(obj) {
    const formData = this.prepareFile(obj.file);
    const params = {
      startDate: obj.startDate,
      clinicId: obj.clinicId,
      type: obj.type,
      importType: obj.importType,
    };
    return this.api.sendPrice(formData, params);
  }

  // eslint-disable-next-line class-methods-use-this
  prepareFile(file) {
    const formData = new FormData();
    formData.append('file', file);
    return formData;
  }

  getImportHistory(obj) {
    return this.api.getImportHistory(obj);
  }

  updateGeolocations() {
    return this.api.updateGeolocations();
  }

  getFile(obj) {
    return this.api.getFile(obj);
  }

  getPricesList(obj) {
    return this.api.getPricesList(obj);
  }

  getPricesListForOne(obj) {
    return this.api.getPricesListForOne(obj);
  }

  getPricesHistory(id) {
    return this.api.getPricesHistory(id);
  }

  createCity(params) {
    return this.api.createCity(params);
  }

  search(params) {
    return this.api.search(params);
  }

  getLaboratories(params) {
    return this.api.getLaboratories(params);
  }

  searchforMap(params) {
    return this.api.searchforMap(params);
  }

  getListNames(params = {}) {
    return this.api.getListNames(params);
  }

  searchNetworks(params) {
    return this.api.searchNetworks(params);
  }

  getNetworkList() {
    return this.api.getNetworkList();
  }

  async getLegalPersons(params) {
    try {
      const data = await this.api.getLegalPersons(params);
      return Array.isArray(data) ? data : [];
    } catch (error) {
      console.warn(error);
      return [];
    }
  }

  getOne(id) {
    return this.api.getOne(id);
  }

  remove(id) {
    return this.api.remove(id);
  }

  create(obj) {
    return this.api.create(obj);
  }

  update(clinic) {
    const obj = {
      ...clinic,
    };
    return this.api.update(obj);
  }

  // legacy code
  dismonitoringBindToggle(params) {
    return this.api.dismonitoringBindToggle(params);
  }
}
