<template>
  <div class="speciality">
    <span class="crm-title">
      {{ speciality.title }}
    </span>

    <icon-clear
      v-if="edit"
      class="crm-icon-cross"
      @click="onClickCross"
    />
  </div>
</template>

<script>
import IconClear from 'assets/images/clear_icon.svg';

export default {
  name: 'DoctorSpecialityItem',
  components: {
    IconClear,
  },
  props: {
    speciality: {
      type: Object,
      default: () => ({
        title: '',
      }),
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClickCross() {
      this.$emit('delete');
    },
  },
};
</script>

<style lang="scss" scoped>
.speciality {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 7px 8px 8px 15px;
  border: 1px solid $gray-shadow;
  border-radius: 20px;
}
.crm-title {
  font-weight: 300;
  font-size: 16px;
  width: fit-content;
  line-height: 20px;
  color: #707070;
  margin-right: 20px;
}
.crm-icon-cross {
  width: 17px;
  cursor: pointer;
}
</style>
