<template>
  <div class="doctors">
    <async-list
      :is-all-items-loaded="isAllItemsLoaded"
      class="doctor-list"
      @load-more-items="loadMoreItems"
    >
      <doctor-search
        :modal-name="modalName"
        :search-trigger="searchTrigger"
      >
        <template v-slot>
          <div class="crm-container-title mb-4">
            <span class="crm-title">
              Врачи
            </span>
            <b-button
              v-if="checkFeature(FEATURES_FOR_CHAT.exportData, FEATURES_FOR_DOCTORS.exportDoctors)"
              class="crm-button-doctor"
              :type="$const.PRIMARY_BUTTON"
              variant="primary"
              @click="openUnloadModal"
            >
              Выгрузка
            </b-button>
          </div>
        </template>

        <template v-slot:button>
          <b-button
            v-if="checkFeature(FEATURES_FOR_CHAT.newDoctor, FEATURES_FOR_DOCTORS.createDoctor)"
            :type="$const.PRIMARY_BUTTON"
            class="crm-button-doctor ml-2"
            variant="primary"
            @click="openEditModal"
          >
            <b-icon
              icon="plus"
              aria-hidden="true"
            />
            <span class="newDoctorButton">
              Новый врач
            </span>
          </b-button>
        </template>

        <template v-slot:doctors="{ doctors }">
          <DoctorsList
            v-if="activeDoctors(doctors).length"
            :doctors="activeDoctors(doctors)"
            :modal-name="modalName"
            :patient-id="patientId"
          >
            <template #label>
              Активные доктора
            </template>
          </DoctorsList>

          <DoctorsList
            v-if="deletedDoctors(doctors).length"
            :doctors="deletedDoctors(doctors)"
            :modal-name="modalName"
            class="mt-4"
          >
            <template #label>
              Удаленные доктора
            </template>
          </DoctorsList>
        </template>
      </doctor-search>
      <!--      <b-row>-->
      <!--        <b-col-->
      <!--          v-for="(item, index) in doctorsList"-->
      <!--          :key="index"-->
      <!--          cols="12"-->
      <!--          sm="4"-->
      <!--          class="my-1"-->
      <!--        >-->
      <!--          <b-card-->
      <!--            bg-variant="success"-->
      <!--            text-variant="white"-->
      <!--            :header="item.lastName"-->
      <!--            class="text-center"-->
      <!--          >-->
      <!--            <p class="card-text">-->
      <!--              {{ item.lastName }}-->
      <!--            </p>-->
      <!--          </b-card>-->
      <!--        </b-col>-->
      <!--      </b-row>-->
      <!--      <b-pagination-->
      <!--        v-model="currentPage"-->
      <!--        :total-rows="totalRows"-->
      <!--        :per-page="perPage"-->
      <!--        class="my-0 ml-4 mb-4 doctorsPagination"-->
      <!--        @change="onPageChanged"-->
      <!--      />-->
    </async-list>
  </div>
</template>

<script>
import Bus from '@/eventBus';
import { mixinRoles } from '@/mixins';

import DoctorSearch from '@/components/Doctors/DoctorSearch';
import DoctorsList from '@/components/Doctors/DoctorsList';
import AsyncList from '@/components/AsyncList';
import { FEATURES_FOR_CHAT, FEATURES_FOR_DOCTORS } from '@/helpers/consts';

import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

const LIMIT = 25;

const items = [
  {
    title: 'Primary',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    variant: 'primary',
  },
  {
    title: 'Secondary',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    variant: 'secondary',
  },
  {
    title: 'Success',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    variant: 'success',
  },
  {
    title: 'Info',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    variant: 'info',
  },
  {
    title: 'Warning',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    variant: 'warning',
  },
  {
    title: 'Danger',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    variant: 'danger',
  },
];

export default {
  name: 'Doctors',
  page: {
    title: 'CRM Doctis - Врачи',
  },
  components: {
    AsyncList,
    // BaseButton,
    DoctorSearch,
    // IconCross,
    DoctorsList,
  },
  mixins: [mixinRoles],
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    patientId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      items,
      paginatedItems: items,
      currentPage: 1,
      perPage: 2,
      limit: LIMIT,
      totalRows: items.length,
      doctorsList: null,

      searchTrigger: false,
      FEATURES_FOR_CHAT,
      FEATURES_FOR_DOCTORS,
    };
  },
  computed: {
    doctors() {
      return this.$store.state.Doctor.doctors;
    },
    isAllItemsLoaded: {
      get() {
        return this.$store.state.Doctor.isAllItemsLoaded;
      },
      set(newVal) {
        this.$store.state.Doctor.isAllItemsLoaded = newVal;
      },
    },
    query: {
      get() {
        return this.$store.state.Doctor.query;
      },
      set(newVal) {
        this.$store.state.Doctor.query = newVal;
      },
    },
    isDeleted: {
      get() {
        return this.$store.state.Doctor.isDeleted;
      },
      set(newVal) {
        this.$store.state.Doctor.isDeleted = newVal;
      },
    },
    isLoading: {
      get() {
        return this.$store.state.Doctor.isLoading;
      },
      set(newVal) {
        this.$store.state.Doctor.isLoading = newVal;
      },
    },
    totalCount() {
      return this.doctors.length;
    },
  },
  async mounted() {
    this.paginate(this.perPage, 0);
    Bus.$on('doctors:fetch', () => this.doctorsFetch(true));
  },
  beforeDestroy() {
    Bus.$off('doctors:fetch', this.doctorsFetch(true));
  },
  methods: {
    checkFeature(keyOne, keyTwo) {
      return this.modalName ? this.checkFeatureAccess({ name: keyOne.name, url: keyOne.url })
        : this.checkFeatureAccess({ name: keyTwo.name, url: keyTwo.url });
    },
    openEditModal() {
      uiService.showModal(MODALS.DOCTOR_EDIT_MODAL, {
        name: 'DoctorEditModal',
      });
    },
    paginate(pageSize, pageNumber) {
      const itemsToParse = this.items;
      this.paginatedItems = itemsToParse.slice(
        pageNumber * pageSize,
        (pageNumber + 1) * pageSize,
      );
    },
    onPageChanged(page) {
      this.paginate(this.perPage, page - 1);
    },
    async doctorsFetch(clear) {
      if (this.isLoading) return;

      this.isAllItemsLoaded = false;
      this.isLoading = true;

      const prevTotal = clear ? 0 : this.totalCount;
      const prevQuery = this.query;

      try {
        await this.$store.dispatch(this.$types.DOCTORS_FETCH, {
          skip: prevTotal,
          take: this.limit,
          query: prevQuery,
          clear: !!clear,
          isDeleted: this.isDeleted,
          save: true,
        });

        if (prevTotal + this.limit > this.totalCount) {
          this.isAllItemsLoaded = true;
        }
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;

        if (this.query !== prevQuery) this.doctorsFetch(true);
      }
    },
    loadMoreItems() {
      // this.doctorsFetch(false);

      // Изменил на триггер. В прошлой версии дублировался запрос на сервер в двух компонентах, теперь работает по триггеру
      this.searchTrigger = !this.searchTrigger;
    },
    updateDoctors(id) {
      if (this.doctors.find((doctor) => doctor.id === id)) {
        this.doctorsFetch(true);
      }
    },
    openUnloadModal() {
      this.$store.commit(this.$types.OPEN_MODAL, { name: 'DoctorUnloadModal' });
    },
    activeDoctors(doctors) {
      return doctors.filter((item) => !item.isDeleted);
    },
    deletedDoctors(doctors) {
      return doctors.filter((item) => item.isDeleted);
    },
  },
};
</script>

<style lang="scss" scoped>
.doctors {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.crm-container{
  padding: 0;
}
.crm-container-title {
  //margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
}
.crm-title {
  @extend .page-title;
  margin-right: 30px;
}
.add-button {
  padding: 17px 25px;
  border-radius: 50px;
  box-shadow: 0px 1px 2px $gray-shadow;
  width: auto;
  margin-left: 30px;
}
.crm-icon-cross {
  margin-right: 15px;
  fill: red;
}
.crm-container-info {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}
.crm-button-doctor {
  box-shadow: rgba(84, 89, 94, 0.2) 0px 8px 24px;
  flex-shrink: 0;
}
.doctorsPagination{
  //box-shadow: rgba(84, 89, 94, 0.2) 0px 8px 24px;
}
.newDoctorButton{
  font-size: 16px;
}
.doctor-list {
  height: 100%;
}
</style>
