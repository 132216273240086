import mutations from './mutations';
import getters from './getters';
import actions from './actions';

export default {
  namespaced: true,
  state: {
    companies: [],
    isCompaniesLoading: false,
    company: null,
    isCompanyLoading: false,

    programs: [],
    isProgramsLoading: false,
    program: null,
    isProgramLoading: false,

    subprograms: [],
    isSubprogramsLoading: false,
    subprogram: null,
    isSubprogramLoading: false,
    insuranceSubprogramsInfo: null,
    isLoading: false,
    documentData: {},
  },
  actions,
  getters,
  mutations,
};
