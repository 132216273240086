<template>
  <div class="crm-navbar shadow h-100 bg-white">
    <div class="crm-navbar-inner h-100 py-3 px-2 d-flex flex-column">
      <div class="px-3 mb-2">
        <img
          class="logo"
          :src="require(`assets/images/${logo}.svg?inline`)"
        >
        <p class="my-2 text-right font-weight-bold">
          {{ phone }}
        </p>
      </div>
      <b-nav
        vertical
        class="crm-navbar-hero mb-auto overflow-auto flex-nowrap"
      >
        <template
          v-for="link in links"
        >
          <navbar-item
            v-if="!link.pageId"
            :key="link.id"
            :link="link"
          />
        </template>
      </b-nav>

      <div
        class="crm-navbar-footer border-top mt-3 mb-0 pt-3 px-3"
      >
        <div
          class="align-items-center d-flex text-secondary"
        >
          {{ loggedUserName }}

          <div
            v-if="user.sipLogin"
            class="online-status"
            :class="onlineStatus(sipStatus )"
            @click="openStatusBar"
          >
            <div
              v-if="isDropdownOpen"
              v-click-outside="onClickOutside"
              class="dropdown"
            >
              <div
                v-for="(status, key) in filteredStatuses"
                :key="key"
                class="list-item"
                @click="selectStatus(status)"
              >
                <span>

                  {{ status }}
                </span>
                <div
                  class="online-status"
                  :class="onlineStatus(status)"
                />
              </div>
            </div>
          </div>

          <b-icon
            icon="box-arrow-right"
            class="ml-3 navbar-logout-icon"
            title="Выйти"
            @click="logout"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixinRoles } from '@/mixins';
import { DOMAIN_SETTINGS, USER_SIP_STATUS } from '@/helpers/consts';

import NavbarItem from '@/components/NavbarItem';
import { APP_SETTINGS } from '@/app.config';
import HubClientService from '@/services/core/hub';
import { sipUserService } from '@/services';
import vClickOutside from 'v-click-outside';

export default {
  name: 'Navbar',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    NavbarItem,
  },
  mixins: [mixinRoles],
  data() {
    return {
      isDropdownOpen: false,
      selectedStatus: null,
    };
  },
  computed: {
    domain() {
      return DOMAIN_SETTINGS[APP_SETTINGS || 'default'];
    },
    logo() {
      return this.domain.logo;
    },
    phone() {
      return this.domain.phone || '8 800 500 0299';
    },
    loggedUserName() {
      if (!this.user) return null;

      let name = this.user.lastName;
      if (this.user.firstName) name += ` ${this.user.firstName[0]}.`;
      if (this.user.middleName) name += ` ${this.user.middleName[0]}.`;
      return name;
    },
    user() {
      return this.$store.state.Auth.user;
    },
    links() {
      const links = this.user.roles[0].pages.filter((p) => p.isMenuItem);
      console.log('links: ', links);
      return links?.sort((a, b) => a.index - b.index).map((p) => this.formatPage(p)) || [];
    },
    filteredStatuses() {
      return Object.values(USER_SIP_STATUS).filter(
        (status) => status !== this.sipStatus,
      );
    },
    sipStatus() {
      if (this.selectedStatus !== null) return this.selectedStatus;
      return USER_SIP_STATUS[this.user.sipStatus];
    },
  },
  created() {
    this.setFavicon();
  },
  methods: {
    formatPage(page) {
      return {
        ...page,
        childPages: page?.childPages?.sort((a, b) => a.index - b.index).map((p) => this.formatPage(p)) || [],
      };
    },
    setFavicon() {
      const favicon = document.getElementById('favicon');
      const faviconUrl = `/${this.domain.favicon}.ico`;
      favicon.href = faviconUrl;
    },
    async logout() {
      await HubClientService.stop();
      this.$store.dispatch('Auth/userLogOut');

      if (localStorage.getItem('internal')) {
        window.location.reload();
      }
    },
    onlineStatus(status) {
      if (status === USER_SIP_STATUS[0]) return 'bg-v-ui-root-service-success';
      if (status === USER_SIP_STATUS[1]) return 'bg-v-ui-root-service-warning';
      if (status === USER_SIP_STATUS[2]) return 'bg-v-ui-root-service-info-colored';
      if (status === USER_SIP_STATUS[4]) return 'bg-v-ui-root-add-another-2';
      if (status === USER_SIP_STATUS[5]) return 'bg-v-ui-root-main-alt';
      return 'bg-v-ui-root-service-alarm';
    },
    openStatusBar() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    onClickOutside() {
      this.isDropdownOpen = false;
    },
    selectStatus(status) {
      const statusKey = Object.keys(USER_SIP_STATUS).find((key) => USER_SIP_STATUS[key] === status);
      sipUserService.setSipStatus({ status: statusKey });
      this.selectedStatus = status;
    },
  },
};
</script>

<!-- $scrol-collor-one: rgb(69, 69, 69); -->
<!-- $scrol-collor-two: rgb(224, 224, 224); -->
<style lang="scss" scoped>
$scrol-collor-one: #c9c9c9;
$scrol-collor-two: #fff;

.crm-navbar-hero {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: $scrol-collor-one $scrol-collor-two;
}
/* Works on Chrome, Edge, and Safari */
.crm-navbar-hero::-webkit-scrollbar {
  width: 6px;
}
.crm-navbar-hero::-webkit-scrollbar-track {
  background: $scrol-collor-two;
  border-radius: 20px;
}
.crm-navbar-hero::-webkit-scrollbar-thumb {
  visibility: hidden;
  background-color: $scrol-collor-one;
  border-radius: 20px;
  border: 2px solid $scrol-collor-two;
  transition: .3s;
}

.crm-navbar-hero:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

::v-deep.crm-navbar {
  width: 250px;
  flex-shrink: 0;
  transition: all 1s;
  position: relative;
  z-index: 10;

  &-inner {
    width: 250px;
    transition: all 1s;
  }

  &__logo-wrapper {
    position: relative;
  }

  .nav-link {
    color: inherit;

    &:hover {
      opacity: 0.7;
    }
  }

  .logo {
    max-width: 100%;
    max-height: 50px;
  }
}

.navbar-logout-icon {
  font-size: 1.4rem;
  cursor: pointer;
}

.online-status{
  width: 10px;
  height: 10px;
  border: 1px solid white;
  border-radius: 100%;
  margin: 0 6px;
  cursor: pointer;
  position: relative;
}

.dropdown {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  list-style: none;
  padding: 0;
  margin: 0;
  bottom: -6px;
  left: 22px;
  width: 156px;
  ul {
    margin: 0;
  }
  .list-item {
    display: flex;
    padding: 8px 5px 8px 12px;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background-color: #f0f0f0;
    }
  }
}
</style>
