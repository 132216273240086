<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="xl"
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <template #modal-header>
      <p class="h5">
        {{ title }}
      </p>

      <b-button
        size="sm"
        title="Закрыть"
        variant="outline-danger"
        :type="$const.PRIMARY_BUTTON"
        class="p-0"
        @click="onClose"
      >
        <b-icon icon="x" />
      </b-button>
    </template>

    <div class="">
      <div class="h6 font-weight-bold mt-3 mb-2">
        1) {{ isEdit ? 'Редактирование' : 'Создание' }} услуги
      </div>

      <div class="">
        <div class="mb-2">
          <div class="mb-1">
            Код
          </div>

          <b-input
            v-model.trim="$v.form.code.$model"
            placeholder="Код"
          />

          <div
            v-if="$v.form.code.$error"
            class="mt-2 text-danger small"
          >
            {{ errorsValidation.code[0] }}
          </div>
        </div>
        <div class="mb-2">
          <div class="mb-1">
            Название
          </div>

          <b-input
            v-model.trim="$v.form.name.$model"
            placeholder="Название"
          />

          <div
            v-if="$v.form.name.$error"
            class="mt-2 text-danger small"
          >
            {{ errorsValidation.name[0] }}
          </div>
        </div>
        <!--        <div class="mb-2">-->
        <!--          <div class="mb-1">-->
        <!--            Стоимость-->
        <!--          </div>-->

        <!--          <b-input-->
        <!--            v-model="form.cost"-->
        <!--            type="number"-->
        <!--            placeholder="Стоимость"-->
        <!--          />-->
        <!--        </div>-->
        <!--        <div class="mb-2">-->
        <!--          <div class="mb-1">-->
        <!--            Комиссия агентов-->
        <!--          </div>-->

        <!--          <b-input-->
        <!--            v-model="form.agentsCommission"-->
        <!--            type="number"-->
        <!--            placeholder="Комиссия агентов"-->
        <!--          />-->
        <!--        </div>-->
        <div class="mb-2">
          <div class="mb-1">
            Тип
          </div>

          <b-form-select
            v-model="form.type"
            :options="PRICE_TYPES"
            text-field="title"
            value-field="id"
            :disabled="isEdit"
            title="Выберите тип"
            size="sm"
            @change="changeSearchedServices"
          />
        </div>
        <b-form-checkbox v-model="form.isActive">
          Активен
        </b-form-checkbox>
      </div>
    </div>

    <div>
      <div class="h6 font-weight-bold mt-3 mb-2">
        2) Связка услуг
      </div>

      <div class="font-weight-bold mb-2">
        Поиск услуги
      </div>

      <b-input-group class="rounded">
        <b-input
          v-model="search.name"
          placeholder="Название"
          class="w-75"
          @keydown.enter="searchServices"
        />
        <b-input
          v-model="search.code"
          placeholder="Код"
          @keydown.enter="searchServices"
        />
        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          class="rounded-0 border-0"
          :disabled="searchServicesProcess"
          @click="searchServices"
        >
          <b-icon icon="search" />
        </b-button>
      </b-input-group>

      <div
        v-if="searchServicesProcess"
        class="d-flex justify-content-center mt-2"
      >
        <b-spinner
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          label="Spinning"
        />
      </div>

      <div
        v-if="!searchServicesProcess && searchServicesMade"
        class="mt-3"
      >
        <div
          v-if="!services.length"
          class="h6 font-weight-bold"
        >
          Ничего не найдено
        </div>

        <div v-else>
          <div class="h6 mb-2 font-weight-bold">
            Найденные услуги:
          </div>

          <TransitionGroup
            name="fade"
            class="d-flex flex-wrap service-list"
          >
            <ServiceItem
              v-for="serviceItem in services"
              :key="serviceItem.id"
              :service-data="serviceItem"
              :selected="serviceSelected(serviceItem.id)"
              class="mt-3 service-item"
              @add="selectService(serviceItem)"
              @remove="removeService(serviceItem.id)"
            />
          </TransitionGroup>
        </div>
      </div>

      <div
        v-if="selectedServices.length"
        class="mt-4 p-3 shadow rounded"
      >
        <div class="h-5 mb-3 font-weight-bold">
          Выбранные услуги:
        </div>

        <TransitionGroup
          name="fade"
          class="d-flex flex-wrap service-list"
        >
          <ServiceItem
            v-for="serviceItem in selectedServices"
            :key="'selected-' + serviceItem.id"
            :service-data="serviceItem"
            :selected="true"
            class="mt-3 service-item"
            @remove="removeService(serviceItem.id)"
          />
        </TransitionGroup>
      </div>
    </div>

    <template #modal-footer>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="onClose"
      >
        Отменить
      </b-button>

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="onClickSave"
      >
        Сохранить
        <b-spinner
          v-if="isSaving"
          variant="light"
          small
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import ServiceItem from '@/components/GluingServices/Modals/ServiceItem';

import { validationMixin } from 'vuelidate';
import { api } from '@/helpers/api';
import {
  showCustomMessage,
  showValidationErrorMessage,
  showErrorCustomMessage,
  showSuccessCustomMessage,
  showValidationErrorCustomMessage,
} from '@/helpers/messages';
import { PRICE_TYPES } from '@/helpers/consts';
import { required } from 'vuelidate/lib/validators';

import { clinicService } from '@/services';

export default {
  name: 'EditingServicesModal',
  components: {
    ServiceItem,
  },
  mixins: [validationMixin],

  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Добавление услуги',
    },
    searchData: {
      type: Object,
      default: () => ({}),
    },
    searchIsActive: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    PRICE_TYPES,
    isOpen: true,
    isLoading: false,
    isSaving: false,
    searchServicesProcess: false,
    searchServicesMade: false, // Произведен поиск
    search: {
      code: '',
      name: '',
    },
    services: [],
    selectedServices: [],
    form: {
      code: '',
      name: '',
      // cost: null,
      type: 0,
      // agentsCommission: null,
      isActive: true,
    },
    createdServiceData: {},
  }),
  validations() {
    return {
      form: {
        code: {
          required,
        },
        name: {
          required,
        },
      },
    };
  },
  computed: {
    isEdit() {
      if (Object.keys(this.searchData).length) return true;

      return false;
    },
    newSelectedServices() {
      return this.selectedServices.reduce((acc, newItem) => {
        if (this.isEdit) {
          const candidate = this.searchData.clinicPrices.some((startItem) => startItem.id === newItem.id);

          if (!candidate) acc.push(newItem.id);
        } else {
          acc.push(newItem.id);
        }

        return acc;
      }, []);
    },
    deletedServices() {
      if (!this.isEdit) return [];

      return this.searchData.clinicPrices.reduce((acc, startItem) => {
        const candidate = this.selectedServices.some((newItem) => newItem.id === startItem.id);

        if (!candidate) acc.push(startItem.id);
        return acc;
      }, []);
    },
    errorsValidation() {
      const errors = {};

      errors.code = [];
      if (!this.$v.form.code.required) {
        errors.code.push('Поле не может быть пустым');
      }

      errors.name = [];
      if (!this.$v.form.name.required) {
        errors.name.push('Поле не может быть пустым');
      }

      return errors;
    },
  },

  created() {
    if (Object.keys(this.searchData).length && this.searchData.clinicPrices) {
      this.startingChangeForm();

      this.selectedServices = JSON.parse(JSON.stringify(this.searchData.clinicPrices));
    }
  },

  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    startingChangeForm() {
      this.form = {
        code: this.searchData.code,
        name: this.searchData.name,
        // cost: this.searchData.cost,
        type: this.searchData.type,
        // agentsCommission: this.searchData.agentsCommission,
        isActive: this.searchData.isActive,
      };
    },
    async searchServices() {
      this.searchServicesProcess = true;
      this.searchServicesMade = true;

      try {
        const params = {
          code: this.search.code,
          name: this.search.name,
          type: this.searchData.type,
        };
        if (!this.isEdit) params.type = this.form.type;

        const { paginatedCollection } = await this.getClinicPrices(params);
        this.services = paginatedCollection;
      } catch (e) {
        console.error(e);
      } finally {
        this.searchServicesProcess = false;
      }
    },
    async getClinicPrices(params) {
      try {
        const data = await clinicService.getPricesListForOne({
          code: params.code,
          name: params.code,
          take: 10,
          type: params.code,
        });
        return data;
      } catch (err) {
        showErrorCustomMessage('Не удалось загрузить список прайсов.');
        console.warn(err);
        throw err;
      }
    },
    selectService(data) {
      const candidate = this.selectedServices.some((item) => item.id === data.id);

      if (!candidate) {
        this.selectedServices.push(data);
      }
    },
    removeService(id) {
      const candidateIndex = this.selectedServices.findIndex((item) => item.id === id);

      if (candidateIndex !== -1) this.selectedServices.splice(candidateIndex, 1);
    },
    async createService() {
      try {
        const { data } = await api.post('v2/price', this.form);

        this.createdServiceData = data.data;
      } catch (e) {
        console.error(e);
        showCustomMessage('warning', 'Ошибка при создании услуги', e.message);
      }
    },
    async updateService() {
      try {
        const response = await api.put('v2/price', {
          ...this.form,
          id: this.searchData.id,
        });
        console.log(response);
      } catch (e) {
        console.error(e);
        showCustomMessage('warning', 'Ошибка при обновлении услуги', e.message);
      }
    },
    async addingNewServices(priceId) {
      try {
        // eslint-disable-next-line no-restricted-syntax
        for await (const clinicPriceId of this.newSelectedServices) {
          const response = await api.post('v2/price/bind', {}, {
            params: {
              priceId,
              clinicPriceId,
            },
          });
          console.log(response);
        }
      } catch (e) {
        console.error(e);
      }
    },
    async removeOldServices() {
      try {
        // eslint-disable-next-line no-restricted-syntax
        for await (const clinicPriceId of this.deletedServices) {
          const response = await api.post('v2/price/unbind', {}, {
            params: {
              clinicPriceId,
            },
          });
          console.log(response);
        }
      } catch (e) {
        console.error(e);
      }
    },
    async onClickSave() {
      this.$v.$touch();
      if (this.$v.$error) {
        console.log(this.$v.error);

        showValidationErrorMessage();
        return;
      }

      this.isLoading = true;

      try {
        if (this.isEdit) {
          await this.updateService();

          await this.addingNewServices(this.searchData.id);
          await this.removeOldServices();
        } else {
          await this.createService();
          console.log(this.createdServiceData);

          await this.addingNewServices(this.createdServiceData.id);
          await this.removeOldServices();
        }

        await this.updatePricesList();
        showSuccessCustomMessage('Данные обновлены');

        this.onClose();
      } catch (err) {
        // eslint-disable-next-line max-len
        showValidationErrorCustomMessage('Возможно при склеивании одной или нескольких услуг произошла ошибка. Сверьте обновленные данные с вашими ожиданиями');
        console.warn(err);
        this.onClose();
      } finally {
        this.isLoading = false;
      }
    },
    async updatePricesList() {
      this.$store.commit('Clinic/SET_LIST_PRICES_LOADING', true);
      try {
        const data = await clinicService.getPricesList({
          code: '',
          name: '',
          skip: 0,
          take: 20,
          isActive: this.searchIsActive ? this.searchIsActive : true,
        });
        this.$store.commit('Clinic/SET_LIST_PRICES', data.paginatedCollection);
        this.$store.commit('Clinic/SET_LIST_PRICES_PAGINATION_COUNT', data.originalCount);
      } catch (err) {
        showErrorCustomMessage('Не удалось загрузить список услуг');
        console.warn(err);
        throw err;
      } finally {
        this.$store.commit('Clinic/SET_LIST_PRICES_LOADING', false);
      }
    },
    serviceSelected(id) {
      return this.selectedServices.some((item) => item.id === id);
    },
    async changeSearchedServices() {
      if (this.searchServicesMade) await this.searchServices();
    },
  },
};
</script>

<style lang="scss" scoped>
.service-list {
  margin-left: -20px;
  margin-top: -20px;
}

.service-item {
  margin-left: 20px;
  margin-top: 20px;
  width: calc(50% - 20px);
}

.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
}
</style>
