import { APP_HOST } from '@/app.config';

export default class ScheduleApi {
  constructor($axios) {
    this.api = $axios.instance;
  }

  async getHistoryScheduleTemplate(params) {
    const { data: { data } } = await this.api.get(`${APP_HOST}v3/schedule/slot-template/history`, { params });
    return data;
  }
}
