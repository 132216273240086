import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.SCHEDULE_DATE_GET](state) {
    return state.selectedDate;
  },
  [localTypes.SCHEDULE_DOCTOR_GET](state) {
    return state.selectedDoctor;
  },
  [localTypes.SCHEDULE_TRIGGER](state) {
    return state.scheduleTrigger;
  },
};
