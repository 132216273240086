<template>
  <div
    class="clinic-photo-edit-wrapper"
  >
    <div class="clinics-scroll-content">
      <custom-scrollbar>
        <div
          class="clinic-photo-edit"
        >
          <Loading
            v-if="isLoading"
            class="crm-loading-wrapper"
          >
            Загрузка клиники
          </Loading>

          <template v-else>
            <template v-if="!isEdit">
              <div class="crm-title">
                Редактирование фотографии
              </div>
              <div class="description">
                Вы можете загрузить изображение в формате JPG или PNG. <br>
                Если у Вас возникают проблемы с загрузкой, попробуйте выбрать фотографию меньшего размера.
              </div>
              <div class="photo">
                <img
                  v-if="!isDelete"
                  :src="clinicInfo.photoUrl"
                  class="clinic-photo source-photo source-photo-rounded"
                >
                <PhotoAvatar
                  v-else
                  class="photo-avatar"
                  @click="onPhotoClickEvent"
                />
              </div>

              <div
                v-if="checkFeatureAccess({ name: 'Редактирование фото клиники', url: '/clinics/:clinicId' })"
                class="d-flex"
              >
                <b-button
                  variant="primary"
                  :type="$const.PRIMARY_BUTTON"
                  size="sm"
                  class="crm-button"
                  @click="toNewPhoto"
                >
                  Изменить
                </b-button>

                <b-button
                  v-if="clinicInfo.photoUrl && !isDelete"
                  variant="danger"
                  :type="$const.PRIMARY_BUTTON"
                  size="sm"
                  class="crm-button ml-2"
                  @click="toNewPhotoEmpty"
                >
                  Удалить
                </b-button>
              </div>
            </template>
            <template v-else>
              <template v-if="!newPhoto">
                <div class="crm-title">
                  Загрузка фотографии
                </div>
                <div class="description">
                  Вы можете загрузить изображение в формате JPG или PNG. <br>
                  Если у Вас возникают проблемы с загрузкой, попробуйте выбрать фотографию меньшего размера.
                </div>
                <div class="photo">
                  <img
                    v-if="clinicInfo.photoUrl && !isDelete"
                    :src="clinicInfo.photoUrl"
                    :width="200"
                    :height="200"
                    class="source-photo source-photo-rounded"
                    @click="onPhotoClickEvent"
                  >
                  <PhotoAvatar
                    v-else
                    class="photo-avatar"
                    @click="onPhotoClickEvent"
                  />
                </div>
                <input
                  ref="inputFile"
                  type="file"
                  class="photo-input"
                  accept="image/*"
                  @change="onChoosePhoto"
                >

                <div
                  v-if="checkFeatureAccess({ name: 'Редактирование фото клиники', url: '/clinics/:clinicId' })"
                  class="d-flex"
                >
                  <b-button
                    variant="primary"
                    :type="$const.PRIMARY_BUTTON"
                    size="sm"
                    class="crm-button"
                    @click="onPhotoClickEvent"
                  >
                    Загрузить файл
                  </b-button>

                  <b-button
                    v-if="clinicInfo.photoUrl && !isDelete"
                    variant="danger"
                    :type="$const.PRIMARY_BUTTON"
                    size="sm"
                    class="crm-button ml-2"
                    @click="toNewPhotoEmpty"
                  >
                    Удалить
                  </b-button>
                </div>
              </template>
              <template v-else>
                <div class="crm-title">
                  Редактирование фотографии
                </div>
                <div class="description">
                  Выбранная область будет показываться на Вашей странице.
                </div>
                <div class="photo">
                  <cropper
                    class="cropper"
                    :src="newPhoto"
                    :stencil-props="{
                      aspectRatio: 1/1
                    }"
                    @change="setCropState"
                  />
                  <canvas
                    id="photo-canvas"
                    width="200"
                    height="200"
                  />
                </div>
              </template>
            </template>
          </template>
        </div>
      </custom-scrollbar>
    </div>

    <div
      v-if="isEdit"
      class="crm-wrapper-buttons crm-wrapper-buttons_clinic-photo-edit"
    >
      <b-button
        variant="outline-primary"
        :type="$const.PRIMARY_BUTTON"
        size="sm"
        class="crm-button"
        :class="{
          disabled: !(isDelete || newPhoto),
        }"
        @click="onClickClose"
      >
        Отменить
      </b-button>

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        size="sm"
        :loading="isSaving"
        class="crm-button"
        :class="{
          disabled: !(isDelete || newPhoto),
        }"
        @click="onClickSave"
      >
        Сохранить
      </b-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */

// import * as types from '@/store/types';

import Loading from '@/components/Loading';
import { api } from '@/helpers/api';
import { getFileExtension } from '@/helpers/utils';
import { mixinRoles } from '@/mixins';

import { clinicService } from '@/services';
import {
  showSuccessCustomMessage,
  showErrorCustomMessage,
} from '@/helpers/messages';

import PhotoAvatar from '@/assets/images/photo-avatar.svg';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { APP_HOST } from '@/app.config';

export default {
  name: 'ClinicPhotoEdit',
  components: {
    Loading,
    PhotoAvatar,
    Cropper,
  },
  mixins: [mixinRoles],
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isSaving: false,
      isEdit: true,
      isLoading: false,
      isDelete: false,
      newPhoto: null,
      newPhotoName: null,
      cropState: null,
    };
  },
  computed: {
    clinicInfo() {
      return this.$store.state.Clinic.clinic.info;
    },
  },
  methods: {
    onClickClose() {
      if (!confirm('Несохраненная информация будет удалена, продолжить?')) {
        return;
      }

      this.newPhoto = null;
      this.newPhotoName = null;
      this.cropState = null;
      // this.isEdit = false;
      this.isDelete = false;
    },
    async onClickSave() {
      try {
        this.isSaving = true;
        let clinicPhotoUrl = '';

        if (!this.isDelete) {
          const fileObject = await this.getImageFileFromCanvas(this.cropState.canvas, this.newPhotoName, 'image/png');

          const extension = getFileExtension(fileObject.name);
          const { data: { guid } } = await api.post(`File/${extension}`, fileObject, {
            headers: {
              'Content-Type': 'image/png',
            },
          });

          clinicPhotoUrl = `${APP_HOST === '/api/' ? 'https://crm.doctis.ru:6004/api/' : APP_HOST}File/${guid}`;
        }

        const preformattedClinicInfo = {
          ...this.clinicInfo,
          photoUrl: clinicPhotoUrl,
        };

        const clinicInfo = await this.clinicUpdateHandler(preformattedClinicInfo);
        this.$store.commit('Clinic/SET_ONE_CLINIC', { ...clinicInfo, info: clinicInfo });

        this.newPhoto = null;
        this.newPhotoName = null;
        this.cropState = null;
      } finally {
        this.isSaving = false;
        this.isDelete = false;
      }
    },
    async clinicUpdateHandler(obj) {
      try {
        const data = clinicService.update(obj);
        showSuccessCustomMessage('Клиника успешно изменена!');
        return data;
      } catch (err) {
        console.warn(err);
        showErrorCustomMessage('Не удалось изменить клинику');
        throw new Error(err);
      }
    },
    onPhotoClickEvent() {
      this.$refs.inputFile.click();
    },
    onChoosePhoto($event) {
      const file = $event.srcElement.files[0];
      const url = URL.createObjectURL(file);
      this.newPhoto = url;
      this.newPhotoName = file.name;
    },
    setCropState(data) {
      this.cropState = data;
    },
    toNewPhoto() {
      this.isEdit = true;
    },
    toNewPhotoEmpty() {
      this.isEdit = true;
      this.isDelete = true;
    },
    getImageFileFromCanvas(sourceCanvas, name, type) {
      const imageHeight = 200;
      const imageWidth = 200;
      const canvas = document.getElementById('photo-canvas');
      const ctx = canvas.getContext('2d');
      ctx.drawImage(sourceCanvas, 0, 0, imageWidth, imageHeight);

      const promise = new Promise((resolve) => {
        canvas.toBlob((blob) => {
          const file = new File([blob], name, { type });
          resolve(file);
        }, type);
      }).catch((err) => { throw err; });
      return promise;
    },
  },
};
</script>

<style lang="scss" scoped>
.clinics-scroll-content {
  height: calc(100vh - 310px);
  margin: 0 -10px;
}

.crm-button.disabled {
  pointer-events: none;
  color: #e0e0e0;
  border: 1px solid transparent;
  background-color: transparent;
}

.clinic-photo-edit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.crm-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #3A3A3A;
}

.description {
  margin: 30px auto;
  font-size: 16px;
  line-height: 20px;
  color: #505050;
  max-width: 550px;
  text-align: center;
}

.photo {
  margin-bottom: 30px;
  max-width: 500px;
}

.clinic-photo {
  width: 200px;
  height: 200px;
  object-fit: contain;
  border-radius: 10px;
}
.photo-avatar {
  cursor: pointer;
}

.photo-input {
  height: 0;
  width: 0;
  overflow: hidden;
}
#photo-canvas {
  display: none;
}

.cropper {
  height: 300px;
}
.source-photo {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}

.source-photo-rounded {
  border-radius: 50%;
}
</style>
