<template>
  <b-modal
    id="indemnity-modal"
    v-model="isOpen"
    scrollable
    centered
    size="xl"
    hide-footer
    no-close-on-backdrop
    header-class="indemnity-modal-header"
  >
    <template #modal-header>
      <b-button
        variant="link"
        :type="$const.PRIMARY_BUTTON"
        class="ml-auto"
        @click="onClickMinimize"
      >
        <icon-minimize
          class="crm-icon-minimize"
          width="20px"
          height="20px"
          viewBox="0 0 16 16"
        />
      </b-button>
    </template>

    <div class="idemnity-modal">
      <div
        v-if="currentStep <= 4"
        class="counter-wrapper"
      >
        <div
          v-for="counter in 4"
          :key="counter"
          :class="{ active: counter <= currentStep }"
          class="counter"
        />
      </div>

      <Loading
        v-if="isLetterLoading"
        class="crm-loading-wrapper"
      >
        Загрузка данных
      </Loading>

      <div
        v-else-if="isLetterLoaded"
        class="step-wrapper"
      >
        <component
          :is="steps[currentStep].component"
          ref="step"
          :is-referral="isReferral"
          :save-to-tab-trigger="saveToTabTrigger"
          :tab-selected-prices="selectedPrices"
          @change-step="onChangeStep"
          @cancel="onCancel"
          @close="closeModal"
          @updateSelectedPrices="$event => selectedPrices = $event"
        />
      </div>

      <div
        v-else
      >
        <p>
          Не удалось загрузить данные, попробуйте открыть заново
        </p>

        <div class="crm-button-wrapper">
          <base-button
            @click="closeModal"
          >
            Закрыть
          </base-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import Bus from '@/eventBus';
import Loading from '@/components/Loading';
import { BaseButton } from '@/components/base';
import IconMinimize from 'assets/images/minimize.svg';
import { preformatTableRows } from '../indemnityUtils';

import IndemnityModalStep1 from './IndemnityModalStep1';
import IndemnityModalStep2 from './IndemnityModalStep2';
import IndemnityModalStep3 from './IndemnityModalStep3';
import IndemnityModalStep4 from './IndemnityModalStep4';
import IndemnityModalStepAddTemplate from './IndemnityModalStepAddTemplate';
import IndemnityModalStepNewTemplate from './IndemnityModalStepNewTemplate';
import IndemnityModalStepAddNosologicTemplate from './IndemnityModalStepAddNosologicTemplate';
import IndemnityModalStepNewNosologicTemplate from './IndemnityModalStepNewNosologicTemplate';

export default {
  name: 'IndemnityModal',
  components: {
    IconMinimize,
    Loading,
    IndemnityModalStep1,
    IndemnityModalStep2,
    IndemnityModalStep3,
    IndemnityModalStep4,
    IndemnityModalStepAddTemplate,
    IndemnityModalStepNewTemplate,
    IndemnityModalStepAddNosologicTemplate,
    IndemnityModalStepNewNosologicTemplate,
    BaseButton,
  },
  props: {
    modalName: {
      type: String,
      default: null,
    },
    personId: {
      type: Number,
      default: null,
    },
    policyId: {
      type: Number,
      default: null,
    },
    subProgramId: {
      type: [String, Number],
      default: null,
    },
    clinicId: {
      type: [String, Number],
      default: null,
    },
    indemnityLetterId: {
      type: String,
      default: null,
    },
    isSent: {
      type: Boolean,
      default: false,
    },
    isReferral: {
      type: Boolean,
      default: false,
    },
    panelTabId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      currentStep: 1,
      isLetterLoaded: false,
      isLetterLoading: false,
      savedToTabPanel: false,
      saveToTabTrigger: false,
      selectedPrices: [],
      steps: {
        1: {
          component: 'IndemnityModalStep1',
        },
        2: {
          component: 'IndemnityModalStep2',
        },
        3: {
          component: 'IndemnityModalStep3',
        },
        31: {
          component: 'IndemnityModalStepAddTemplate',
        },
        32: {
          component: 'IndemnityModalStepNewTemplate',
        },
        33: {
          component: 'IndemnityModalStepAddNosologicTemplate',
        },
        34: {
          component: 'IndemnityModalStepNewNosologicTemplate',
        },
        4: {
          component: 'IndemnityModalStep4',
        },
      },
    };
  },
  computed: {
    letter() {
      return this.$store.state.Indemnity.letter;
    },
    isLetterEdited() {
      return this.$store.state.Indemnity.isLetterEdited;
    },
  },
  watch: {
    letter: {
      handler() {
        this.$store.commit(this.$types.INDEMNITY_IS_LETTER_EDITED_SET, true);
      },
      deep: true,
    },
  },
  async created() {
    this.isLetterLoading = true;

    try {
      if (this.panelTabId) {
        const { tabs } = this.$store.state.TabPanel;
        const { data: { currentStep, letter, selectedPrices } } = tabs.filter((tab) => tab.id === this.panelTabId)[0];

        this.currentStep = currentStep;
        this.selectedPrices = selectedPrices || [];
        this.$store.commit(this.$types.INDEMNITY_LETTER_SET, letter);
      } else if (this.indemnityLetterId) {
        const action = this.isSent ? 'INDEMNITY_CREATE_EDIT_LETTER' : 'INDEMNITY_GET_LETTER_BY_ID';
        await this.$store.dispatch(this.$types[action], {
          id: this.indemnityLetterId,
          isReferral: this.isReferral,
        });
      } else {
        await this.$store.dispatch(this.$types.INDEMNITY_LETTER_NEW_GET, {
          personId: this.personId,
          policyId: this.policyId,
          subProgramId: this.subProgramId,
          clinicId: this.clinicId,
          isReferral: this.isReferral,
        });
      }

      this.isLetterLoaded = true;
    } finally {
      this.isLetterLoading = false;
      this.$store.commit(this.$types.INDEMNITY_IS_LETTER_EDITED_SET, false);
    }

    Bus.$on('indemnity:save-to-tab', this.saveLetterDataToTabPanel);
  },
  beforeDestroy() {
    Bus.$off('indemnity:save-to-tab', this.saveLetterDataToTabPanel);
    this.$store.commit(this.$types.INDEMNITY_LETTER_SET, null);
    this.$store.state.Indemnity.letterIndemnityCity = null;
    this.$store.commit(this.$types.INDEMNITY_IS_LETTER_EDITED_SET, false);

    if (!this.savedToTabPanel && this.panelTabId) {
      this.$store.commit(this.$types.TAB_PANEL_ITEMS_FETCH_FROM_LOCALSTORAGE);
      this.$store.commit(this.$types.TAB_PANEL_ITEM_REMOVE_BY_ID, this.panelTabId);
    }
  },
  methods: {
    async onClickMinimize() {
      this.saveToTabTrigger = !this.saveToTabTrigger;
      await this.$nextTick();
      this.closeModal();
      Bus.$emit('indemnity:save-to-tab');
    },
    saveLetterDataToTabPanel() {
      try {
        this.$store.commit(this.$types.TAB_PANEL_ITEMS_FETCH_FROM_LOCALSTORAGE);
        const mutationName = this.panelTabId ? 'TAB_PANEL_ITEM_UPDATE_BY_ID' : 'TAB_PANEL_ITEM_ADD';

        const tabData = {
          id: this.panelTabId || Date.now(),
          title: this.letter.documentNumber ? `ГП ${this.letter.documentNumber}` : 'Новое ГП',
          type: 1, // indemnity type
          hideCloseButton: true,
          data: {
            letter: this.letter,
            currentStep: this.currentStep,
            selectedPrices: this.selectedPrices,
          },
        };

        this.$store.commit(this.$types[mutationName], tabData);
        this.savedToTabPanel = true;
      } catch (error) {
        console.error(error);
      }
    },
    closeModal() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    checkCurrentStepErrors() {
      this.$refs.step.checkHasValidateErrors();
    },
    async onChangeStep(change) {
      try {
        this.currentStep += change;

        if (change === 1 && this.currentStep === 3 && this.isLetterEdited) {
          this.isLetterLoading = true;

          const letterSaveData = { ...(this.letter.data || this.letter) };

          if (letterSaveData.isNew) {
            letterSaveData.additionalConditionString = letterSaveData.isReferral
              ? 'OOO "ДМО" оставляет за собой право взымать комиссию по агентскому договору в размере 20%.'
              // eslint-disable-next-line max-len
              : 'OOO "ДМО" оставляет за собой право проводить медико-экономическую экспертизу объемов, сроков и качества оказанных услуг, а так же удерживать денежные средства по результатам проведенной экспертизы';
          }

          const letter = await this.$store.dispatch(this.$types.INDEMNITY_LETTER_SAVE, letterSaveData);
          const { tableColumns, tableRows } = preformatTableRows(letter.tableRows);

          if (letterSaveData.isNew) letter.priceCategory = 0;

          this.$store.commit(this.$types.INDEMNITY_LETTER_SET, {
            ...letter,
            tableColumns,
            tableRows,
          });

          this.isLetterLoading = false;
          this.$store.commit(this.$types.INDEMNITY_IS_LETTER_EDITED_SET, false);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async onCancel() {
      if (this.subProgramId !== null) {
        try {
          if ((this.currentStep === 1 || this.currentStep === 2) && this.letter.isNew) {
            await this.$store.dispatch(this.$types.INDEMNITY_LETTER_DELETE, { id: this.letter.id, isReferral: this.isReferral });
          } else if (this.isLetterEdited) {
            await this.$store.dispatch(this.$types.INDEMNITY_LETTER_SAVE, {
              ...this.letter,
              clinicId: this.letter.clinic?.id,
            });
          } else {
            console.log('letter not edited');
          }
          this.closeModal();
        } catch {
          if (confirm('Изменения не будут сохранены, закрыть?')) {
            this.closeModal();
          }
        }
      } else {
        this.closeModal();
      }
    },
    setStep(counter) {
      this.currentStep = counter;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep #indemnity-modal {
  .indemnity-modal-header {
    padding: 0;
  }
}
.idemnity-modal {
  display: flex;
  flex-direction: column;
}
.counter-wrapper {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  margin-bottom: 15px;
}
.counter {
  flex-basis: 24%;
  height: 5px;
  border-radius: 5px;
  background-color: $gray;

  &.active {
    background-color: $blue;
  }
}
.crm-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
::v-deep.step-wrapper {
  .crm-scrollbar-wrapper {
    height: calc(100vh - 270px);
    margin: 0 -10px 10px;
  }

  .page-title {
    margin: 0;
  }

  .indemtity-wrapper {
    flex-direction: column;
  }

  .crm-form-fields {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    .crm-form-field_justify_end {
      justify-content: flex-end;
    }

    .crm-form-field_align-center {
      align-items: center;
    }
  }

  .program-name {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: $blue;
  }
}
</style>
