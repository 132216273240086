<template>
  <b-modal
    v-model="isOpen"
    centered
    hide-footer
    no-close-on-backdrop
    :title="'Изменение фото'"
    @hidden="onClose"
  >
    <base-photo-edit
      :photo-url-prop="photoUrlProp"
      :image-height="imageHeight"
      :image-width="imageWidth"
      :save-callback="saveCallback"
      :show-delete-button="true"
      @cancel="onFinished"
      @save="onFinished"
    />
  </b-modal>
</template>

<script>
import { BasePhotoEdit } from '@/components/base';

export default {
  name: 'InsuranceCompanyPhotoModal',
  components: {
    BasePhotoEdit,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    photoUrlProp: {
      type: String,
      default: '',
    },
    imageHeight: {
      type: Number,
      default: 200,
    },
    imageWidth: {
      type: Number,
      default: 200,
    },
    saveCallback: {
      type: Function,
      default: async () => {},
    },
  },
  data() {
    return {
      isOpen: true,
    };
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    onFinished() {
      this.onClose();
    },
  },
};
</script>

<style lang="scss" scoped>
    :deep(.modal-dialog) {
      max-width: 600px;
    }
</style>
