<template>
  <ymap-marker
    class="marker-wrapper"
    :coords="[laboratory.latitude, laboratory.longitude]"
    marker-type="placemark"
    :marker-id="id"
    :icon="{ content: `${laboratory.name}` }"
    cluster-name="1"
    :hint-content="laboratory.name"
  >
    <template slot="balloon">
      <div class="ymap-balloon">
        <LaboratoriesBaloon
          :id="laboratory.id"
          :laboratory="laboratory"
        />
      </div>
    </template>
  </ymap-marker>
</template>
<script>
import LaboratoriesBaloon from './LaboratoriesBaloon';

export default {
  name: 'LaboratoriesMapMarker',
  components: {
    LaboratoriesBaloon,
    ymapMarker: () => import('vue-yandex-maps').then(({ ymapMarker }) => ymapMarker),
  },
  props: {
    laboratory: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
  }),
};
</script>
<style lang="scss" scoped>
  .ymap-balloon {
    display: flex;
    padding-top: 2px;
    padding-right: 5px;
  }
  .balloon-btn{
    width: 35px;
    height: 18px;
    border: none;
  }
</style>
