<template>
  <div>
    <template>
      <div
        v-if="selectedItemsList.length"
        class="mb-2 h6"
      >
        {{ title }}
      </div>
      <div style="max-height: 200px; overflow-y: auto">
        <b-table-simple
          v-if="selectedItemsList.length"
          small
          bordered
          class="mb-0"
        >
          <b-tbody>
            <b-tr
              v-for="item in selectedItemsList"
              :key="item.id"
            >
              <b-td class="w-100 table-text">
                {{ item.name }}
              </b-td>
              <b-td>
                <b-button
                  variant="danger"
                  :type="$const.PRIMARY_BUTTON"
                  class="ml-auto"
                  size="sm"
                  @click="$emit('onRemove',item.id)"
                >
                  <b-icon icon="trash" />
                </b-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
    </template>

    <hr v-if="selectedItemsList.length">

    <template>
      <b-form-input
        v-model="itemSearchString"
        type="text"
        debounce="500"
        :placeholder="searchPlaceholder"
        size="sm"
        trim
        class="mb-2"
      />

      <div
        v-if="itemSearchString && filteredItemsList.length"
        style="max-height: 200px; overflow-y: auto"
      >
        <b-table-simple
          small
          bordered
          class="mb-0"
        >
          <b-tbody>
            <b-tr
              v-for="item in filteredItemsList"
              :key="item.id"
            >
              <b-td class="w-100 table-text">
                {{ item.name }}
              </b-td>
              <b-td>
                <b-button
                  variant="success"
                  :type="$const.PRIMARY_BUTTON"
                  class="ml-auto"
                  size="sm"
                  @click="$emit('onAdd',item.id)"
                >
                  <b-icon icon="plus" />
                </b-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>

      <div
        v-if="itemSearchString && !filteredItemsList.length"
        class="ml-2"
      >
        <slot name="not-found-text">
          <small>Элемент не найден</small>
        </slot>
      </div>
      <div
        v-if="!itemSearchString"
        class="ml-2"
      >
        <slot name="enter-text" />
      </div>
    </template>
  </div>
</template>
<script>

export default {
  name: 'CombinedTablesWithSearch',
  props: {
    selectedItemsList: {
      type: Array,
      default: () => ([]),
    },
    itemsList: {
      type: Array,
      default: () => ([]),
    },
    title: {
      type: String,
      default: 'Выбранные элементы',
    },
    searchPlaceholder: {
      type: String,
      default: 'Поиск по названию элемента',
    },
    uniqId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      itemSearchString: null,
    };
  },
  computed: {
    filteredItemsList() {
      if (!this.itemSearchString) return [];

      return this.itemsList.filter((item) => {
        if (!item.name.toLowerCase().includes(this.itemSearchString.toLowerCase())) return false;

        return !this.selectedItemsList.find((sItem) => sItem.id === item.id);
      });
    },
  },
  watch: {
    uniqId() {
      this.itemSearchString = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.table-text{
  vertical-align: middle;
}
</style>
