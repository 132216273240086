<template>
  <div class="rounded shadow p-2">
    <div>
      <div
        v-for="infoItem in serviceInfo"
        :key="infoItem.id"
        style="font-size: 14px;"
        class="d-flex mb-1"
      >
        <div class="font-weight-bold">
          {{ infoItem.key }}
        </div>

        <div
          :style="{color: infoItem.color}"
          class="ml-2 text-break"
        >
          {{ infoItem.value }}
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end mt-2">
      <b-button
        v-if="selected"
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        size="sm"
        @click="$emit('remove')"
      >
        Убрать из выбранных
      </b-button>

      <b-button
        v-else
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        size="sm"
        @click="$emit('add')"
      >
        Выбрать
      </b-button>
    </div>
  </div>
</template>

<script>
import { PRICE_TYPES } from '@/helpers/consts';

export default {
  name: 'ServiceItem',

  props: {
    serviceData: {
      type: Object,
      default: () => ({}),
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    PRICE_TYPES,
  }),

  computed: {
    serviceInfo() {
      const items = [
        { id: 2, key: 'Название', value: this.serviceData.name },
        { id: 3, key: 'Код', value: this.serviceData.code },
        { id: 4, key: 'Комиссия', value: `${this.serviceData.agentsCommission} %` },
        { id: 6, key: 'Стоимость', value: `${this.serviceData.cost} ₽` },
        {
          id: 7,
          key: 'Активность',
          value: this.serviceData.isActive ? 'Услуга активна' : 'Услуга не активна ',
          color: this.serviceData.isActive ? 'green' : 'red',
        },
        { id: 8, key: 'Тип', value: this.priceType(this.serviceData.type) },
      ];

      if (this.serviceData.clinic && this.serviceData.clinic.appTitle) {
        items.splice(3, 0, {
          id: 5, key: 'Название клиники', value: this.serviceData.clinic.appTitle,
        });
      } else if (this.serviceData.clinicName) {
        items.splice(3, 0, {
          id: 5, key: 'Название клиники', value: this.serviceData.clinicName,
        });
      }
      // console.log('this.serviceData.clinic', this.serviceData);
      return items;
    },
  },

  methods: {
    priceType(typeId) {
      const type = this.PRICE_TYPES.find((item) => item.id === typeId);
      if (type) return type.title;

      return '-';
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
