<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    :title="'Редактирование подготовки к анализу'"
    :hide-footer="isLoading"
    class="modal"
    @hidden="onClose"
    @ok="onClose"
  >
    <template v-if="isLoading">
      <preloader style="margin-top: 20px" />
    </template>
    <template v-else>
      <b-row>
        <b-col>
          <b-form-group
            label="Описание подготовки *"
          >
            <b-form-textarea
              v-model="preparingData.text"
              placeholder="Введите описание подготовки к анализам"
              rows="4"
              size="sm"
              trim
            />
            <span
              v-if="errors.text"
              class="modal__error"
            >{{ errors.text }}</span>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex">
            <b-form-file
              v-model="preparingData.logo"
              accept=".svg"
              placeholder="Выберите файл *"
              @input="updateLogo"
            />
            <b-button
              variant="danger"
              :type="$const.PRIMARY_BUTTON"
              class="w-75 ml-2"
              :disabled="!preparingData.logo"
              @click="onDeleteLogo"
            >
              Удалить иконку
            </b-button>
          </div>
          <span
            v-if="errors.logo"
            class="modal__error"
          >{{ errors.logo }}</span>
        </b-col>
      </b-row>
      <b-row
        v-if="preparingData.logo"
        class="mt-3"
      >
        <b-col>
          <b-img
            fluid
            :src="logoSrc"
            alt="Не удалось загрузить файл"
            class="modal__icon-preview"
          />
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col class="p-3 bg-v-ui-background-monochrome-5">
          <span class="block mb-2">Как будет на сайте</span>
          <preparation-analyses-card :icon="logoSrc" :text="preparingData.text" />
        </b-col>
      </b-row>
    </template>
    <template #modal-footer>
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        :disabled="isLoading"
        @click="onupdatePreparation"
      >
        Сохранить
      </b-button>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        class="float-right ml-2"
        :disabled="isLoading"
        @click="onClose"
      >
        Отмена
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Preloader from '@/components/Preloader';
import PreparationAnalysesCard from '@/components/Laboratories/PreparationAnalysesCard';
import { laboratoryService } from '@/services';
import {
  showValidationErrorCustomMessage,
  showSuccessCustomMessage,
  showErrorCustomMessage,
} from '@/helpers/messages';

export default {
  name: 'PreparingAnalysisEditModal',
  components: {
    Preloader,
    PreparationAnalysesCard,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    preparationId: {
      type: [String, Number],
      default: null,
    },
    afterSave: {
      type: [Function],
      default: () => {},
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      logoSrc: null,
      errors: {
        text: null,
        logo: null,
      },
      preparingData: {
        text: '',
        logo: null,
      },
    };
  },
  methods: {
    async onupdatePreparation() {
      if (this.checkingErrors()) {
        await this.updatePreparation();
        await this.afterSave();
        this.onClose();
      } else {
        showValidationErrorCustomMessage('Заполните обязательные поля');
      }
    },
    async updatePreparation() {
      try {
        const formData = new FormData();
        formData.append('text', this.preparingData.text);

        if (this.preparingData.logo?.name) {
          formData.append('logo', this.preparingData.logo);
        }
        await laboratoryService.updatePreparation(this.preparationId, formData);
        showSuccessCustomMessage('Подготвка к анализам успешна сохранена!');
      } catch (err) {
        showErrorCustomMessage('Не удалось сохранить подготовку к анализам');
        console.warn(err);
        throw err;
      }
    },
    async fetchPreparationInfo() {
      this.isLoading = true;
      const data = await laboratoryService.getPreparation(this.preparationId);
      this.preparingData.text = data.text;
      this.preparingData.logo = data.logoUrl;
      this.logoSrc = data.logoUrl;
      this.isLoading = false;
    },
    async updateLogo() {
      this.getUploadedImage();
    },
    async onDeleteLogo() {
      this.preparingData.logo = null;
      this.logoSrc = null;
    },
    getUploadedImage() {
      const file = this.preparingData.logo;

      if (file) {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.logoSrc = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        this.logoSrc = null;
      }
    },
    cleaningErrors() {
      this.errors.text = null;
      this.errors.logo = null;
    },
    checkingErrors() {
      this.cleaningErrors();

      let errors = false;

      if (!this.preparingData.text) {
        this.errors.text = 'Обязательное поле!';
        errors = true;
      }

      if (!this.preparingData.logo) {
        this.errors.logo = 'Обязательное поле!';
        errors = true;
      }

      return !errors;
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
  },
  async created() {
    await this.fetchPreparationInfo();
  },
};
</script>

<style lang="scss" scoped>
  .modal {

    &__icon-preview {
      border: 1px solid #dee2e6;
      padding: 0.25rem;
      background-color: #fff;
      border-radius: 0.25rem;
      width: 100px;
      height: 100px;
      object-fit: contain;
      object-position: center;
    }

    &__error {
      display: block;
      width: 100%;
      color: red;
      margin-top: 6px;
    }
  }

  .passwordBtn:focus {
    box-shadow: none;
  }
</style>
