<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="xl"
    title="Список клиник"
    hide-footer
    no-close-on-backdrop
    @hidden="onClose"
  >
    <custom-scrollbar>
      <Clinics
        :modal-name="modalName"
        class="clinics"
      />
    </custom-scrollbar>
  </b-modal>
</template>

<script>
import Clinics from '@/pages/Clinics';

export default {
  name: 'ClinicsModal',
  components: {
    Clinics,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },

  },
  data() {
    return {
      isOpen: true,
      isLoading: false,

    };
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
  },
};
</script>

<style lang="scss" scoped>
  :deep(.clinics-header) {
    padding-top: 10px !important;
  }
  .clinics {
    min-height: 90vh;
  }
</style>
