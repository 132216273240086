<template>
  <b-modal
    v-model="isOpen"
    centered
    size=""
    hide-footer
    no-close-on-backdrop
    :title="'Импорт файла'"
    @hidden="onClose"
  >
    <template v-if="isImportingState">
      <div class="crm-info-text">
        Идет импорт файла. Загрузка может занять до 15 мин. Не прерывайте загрузку
      </div>
      <preloader style="margin-top: 20px" />
    </template>

    <template v-if="isSuccessCompleteState">
      <div class="crm-info-text">
        Файл добавлен в очередь импорта.
        <!-- Импортирование успешно завершено. Загружено пользователей: {{ importedData }} из {{ totalData }} -->
      </div>
      <div class="crm-container-buttons">
        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          class="button-import px-4"
          @click="onFinished"
        >
          <span>ОК</span>
        </b-button>
      </div>
    </template>
    <template v-if="isErrorCompleteState">
      <div class="crm-info-text">
        Импорт частично завершен <br>
        <!-- Загружено пользователей: {{ importedData }} из {{ totalData }}<br/> -->
        <!-- <span style="color: #e76666;">Невозможно импортировать: {{ notImportedData }} из {{ totalData }}</span> -->
      </div>
      <div class="crm-container-buttons">
        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          size="sm"
          class="button-import mr-4"
          @click="downloadTable"
        >
          <span>Скачать таблицу</span>
        </b-button>
        <b-button
          variant="outline-danger"
          :type="$const.PRIMARY_BUTTON"
          size="sm"
          class="crm-button-file"
          @click="onClose"
        >
          <span>Отменить</span>
        </b-button>
      </div>
      <div
        class="additional-text"
        style="margin-top: 15px"
      >
        Таблица содержит данные <br>
        неимпортированных пользователей.
      </div>
    </template>

    <template v-if="isSelectImportTypeState">
      <div
        v-if="!hideModeSelector"
        class="crm-wrapper-radio"
      >
        <base-radio-button
          v-model="importType"
          class="crm-radio"
          :name="0"
        >
          Импортировать новые полисы
        </base-radio-button>

        <base-radio-button
          v-model="importType"
          class="crm-radio"
          :name="1"
        >
          Автоматически изменять полисы
        </base-radio-button>

        <base-radio-button
          v-model="importType"
          class="crm-radio"
          :name="3"
        >
          Открепление
        </base-radio-button>
        <base-radio-button
          v-model="importType"
          class="crm-radio"
          :name="4"
        >
          Изменение обьема услуг
        </base-radio-button>
        <base-radio-button
          v-model="importType"
          class="crm-radio"
          :name="5"
        >
          Импортировать новые полисы + СМС
        </base-radio-button>
        <base-radio-button
          v-model="importType"
          v-b-tooltip.hover.right
          class="crm-radio w-max"
          title="Удаление полиса по ID"
          :name="6"
        >
          Удаление полисов
        </base-radio-button>
      </div>

      <div class="crm-container-buttons">
        <b-button
          variant="outline-danger"
          :type="$const.PRIMARY_BUTTON"
          size="sm"
          class="crm-button-file mr-4"
          @click="onClose"
        >
          <span>Отменить</span>
        </b-button>

        <b-button
          variant="outline-primary"
          :type="$const.PRIMARY_BUTTON"
          size="sm"
          class="button-import"
          @click="startImport"
        >
          <span>Импортировать</span>
        </b-button>
      </div>
    </template>

    <template v-if="isSelectInsuranceDelete">
      <div class="crm-info-text">
        Удалить полисы из базы CRM?
      </div>
      <div class="crm-container-buttons">
        <b-button
          variant="outline-danger"
          :type="$const.PRIMARY_BUTTON"
          size="sm"
          class="crm-button-file mr-4"
          @click="onClose"
        >
          <span>Нет</span>
        </b-button>

        <b-button
          variant="outline-primary"
          :type="$const.PRIMARY_BUTTON"
          size="sm"
          class="button-import"
          @click="onAccept"
        >
          <span>Да</span>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BaseRadioButton,

} from '@/components/base';
import Preloader from '@/components/Preloader';
import { api } from '@/helpers/api';
import { saveFile } from '@/helpers/utils';
import { showCustomMessage } from '@/helpers/messages';

export default {
  name: 'InsuranceImportModal',
  components: {
    BaseRadioButton,
    Preloader,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    params: {
      type: Object,
      default: () => {},
    },
    saveFileCallback: {
      type: Function,
      default: () => {},
    },
    hideModeSelector: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: true,
      selectedClinicsForDetach: [],
      isImportingState: false,
      isSelectImportTypeState: true,
      isSuccessCompleteState: false,
      isErrorCompleteState: false,
      isSelectInsuranceDelete: false,
      isDeleteAccepted: false,

      importType: 0,
      importedData: 0,
      notImportedData: 0,
      totalData: 0,
      fileGUIDForDownload: '',
    };
  },
  methods: {
    deleteSelectedPackageClinic(clinic) {
      this.selectedClinicsForDetach = this.selectedClinicsForDetach.filter((item) => item.id !== clinic.id);
    },
    clearState() {
      this.isImportingState = false;
      this.isSelectImportTypeState = false;
      this.isSuccessCompleteState = false;
      this.isErrorCompleteState = false;
      this.isSelectInsuranceDelete = false;
      this.isDeleteAccepted = false;
    },
    async downloadTable() {
      const { data: fileName } = await api.get(`File/name/${this.fileGUIDForDownload}`);
      const { data: file } = await api.get(`File/${this.fileGUIDForDownload}`, { responseType: 'blob' });
      saveFile(file, { name: fileName });
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    onFinished() {
      this.onClose();
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
    onAccept() {
      this.isDeleteAccepted = true;
      this.startImport();
    },
    async startImport() {
      if (this.importType === 2 && this.selectedClinicsForDetach.length === 0) {
        showCustomMessage('warning', 'Ошибка валидации', 'Выберите клиники для открепления');
        return;
      }
      if (this.importType === 6 && !this.isDeleteAccepted) {
        this.isSelectInsuranceDelete = true;
        this.isSelectImportTypeState = false;
        return;
      }
      try {
        const params = {
          ...this.params,
          mode: this.importType,
        };

        if (this.importType === 2) params.clinicIdsForPolicies = this.selectedClinicsForDetach.map((item) => item.id);

        this.clearState();
        this.isImportingState = true;

        const {
          data: {
            allRowsCount, errorsCount, successCount, fileWithErrorsId,
          },
        } = await api.post('v2/policy/import', params);

        this.notImportedData = errorsCount;
        this.totalData = allRowsCount;
        this.importedData = successCount;

        this.clearState();
        if (errorsCount) {
          this.fileGUIDForDownload = fileWithErrorsId;
          this.isErrorCompleteState = true;
        } else {
          this.isSuccessCompleteState = true;
        }
      } catch (e) {
        this.clearState();
        this.isErrorCompleteState = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .crm-container-buttons {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  .crm-wrapper-radio {
    margin-left: 80px;
    & .crm-radio {
      margin: 16px 0px;
    }
    & .crm-radio:first-child {
      margin-top: 8px;
    }
  }

  .crm-info-text, .additional-text {
    color: #908F8D;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
  }
  .additional-text {
    font-size: 12px;
    line-height: 16px;
  }

.item-wrapper {
  margin-top: 10px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .crm-item {
    margin-right: 15px;
    margin-bottom: 10px;

    &:last-child {
      margin-right: auto;
    }
  }
}
::v-deep.clinics-filter {
  position: relative;
}

.crm-form-clinics-wrapper {
  height: 200px;
}

.width-170 {
  width: 170px !important;
}

</style>
