<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    title="Скачать статистику в формате Excel"
    @hidden="onClose"
    @ok="onClose"
  >
    <div class="content">
      <div>
        <div class="h6 mb-2">
          Выбор по периоду
        </div>

        <b-form-radio-group
          id="radio-group-PEREIODS"
          v-model="reportDateType"
          :options="PEREIODS"
          value-field="id"
          text-field="title"
          name="radio-options-PEREIODS"
        />
      </div>

      <template v-if="checkFeatureAccess({ name: 'Статистика - Страхование', url: '/reports'})">
        <div class="mt-4">
          <div class="h6 mb-2">
            Страхование
          </div>

          <b-form-radio-group
            id="radio-group-INSURANCE"
            v-model="medicalInsuranceType"
            :options="INSURANCE"
            :disabled="disabledFields.medicalInsuranceType"
            value-field="id"
            text-field="title"
            name="radio-options-INSURANCE"
          />
        </div>

        <div
          v-if="medicalInsuranceType === 1"
          class="mt-4"
        >
          <div class="h6 mb-2">
            Регион и тип прикрепления
          </div>

          <base-checkbox-select
            :container-class="'crm-info-region-container'"
            class="crm-checkbox-select"
            placeholder="Выберите регион"
            fluid
          >
            <template
              v-slot:default="{ query }"
            >
              <base-checkbox-list
                v-model="regions"
                :query="query"
                :choices="regionChoices"
              />
            </template>
          </base-checkbox-select>
          <!--  ОМС -->

          <div
            v-if="regions.length"
            class="mt-2"
          >
            <SelectedItem
              v-for="region in regions"
              :key="region.id"
              class="mb-1"
              @remove="deleteRegion(region)"
            >
              {{ region.title }}
            </SelectedItem>
          </div>
        </div>

        <div
          v-if="medicalInsuranceType === 0"
          class="mt-4"
        >
          <div
            class="h6 mb-2"
          >
            Страховая подпрограмма
          </div>
          <base-checkbox-select
            :container-class="'crm-info-insurance-container'"
            class="crm-checkbox-select"
            :disabled="!disabledSubprogramField"
            placeholder="Выберите страховую подпрограмму"
            fluid
          >
            <template
              v-slot:default="{ query }"
            >
              <base-checkbox-list
                v-model="insuranceProgramms"
                :query="query"
                :choices="insuranceProgrammsChoices"
              />
            </template>
          </base-checkbox-select>
          <!--  ОМС -->

          <div
            v-if="insuranceProgramms.length"
            class="mt-2"
          >
            <SelectedItem
              v-for="programm in insuranceProgramms"
              :key="programm.id"
              class="mb-1"
              @remove="deleteInsuranceProgramm(programm)"
            >
              {{ programm.title }}
            </SelectedItem>
          </div>
        </div>

        <div class="mt-4">
          <div class="h6 mb-2">
            Сети
          </div>

          <div>
            <base-async-select
              :value="defaultValue"
              class="crm-select"
              :fetch-function="fetchNetworks"
              option-title="title"
              loading-title="Загрузка сетей"
              no-options-title="Сети не найдены"
              server-paginated
              @change="selectNetwork"
            />

            <div
              v-if="clinicNetworks.length"
              class="mt-2"
            >
              <SelectedItem
                v-for="clinicNetwork in clinicNetworks"
                :key="clinicNetwork.id"
                class="mb-1"
                @remove="deleteClinicNetwork(clinicNetwork)"
              >
                {{ clinicNetwork.title }}
              </SelectedItem>
            </div>
          </div>
        </div>

        <div class="mt-4">
          <div class="h6 mb-2">
            Название клиники
          </div>

          <div>
            <base-async-select
              :value="defaultValue"
              :fetch-function="fetchClinic"
              class="crm-select"
              server-paginated
              @change="selectClinic"
            />

            <div
              v-if="clinics.length"
              class="mt-2"
            >
              <SelectedItem
                v-for="clinic in clinics"
                :key="clinic.id"
                class="mb-1"
                @remove="deleteClinic(clinic)"
              >
                {{ clinic.title }}
              </SelectedItem>
            </div>
          </div>
        </div>
      </template>
    </div>
    <template #modal-footer>
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        :disabled="isLoading"
        @click="onUnload"
      >
        Скачать

        <b-spinner
          v-if="isLoading"
          variant="light"
          small
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { BaseAsyncSelect, BaseCheckboxList, BaseCheckboxSelect } from '@/components/base';
import SelectedItem from '@/components/common/ui/SelectedItem';
import { mixinRoles } from '@/mixins';

import { clinicService } from '@/services';

const PEREIODS = [
  {
    id: 1,
    title: 'По дате создания',
  },
  {
    id: 2,
    title: 'По дате записи',
  },
  {
    id: 3,
    title: 'По дате закрытия',
  },
];

const INSURANCE = [
  {
    id: 0,
    title: 'ДМС',
  },
  {
    id: 1,
    title: 'ОМС',
  },
  {
    id: 2,
    title: 'Все',
  },
];

export default {
  name: 'DownloadExcelModal',
  components: {
    BaseAsyncSelect,
    BaseCheckboxSelect,
    BaseCheckboxList,
    SelectedItem,
  },
  mixins: [mixinRoles],

  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    dateFrom: {
      type: String,
      default: null,
    },
    dateTo: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    PEREIODS,
    INSURANCE,
    isOpen: true,
    isLoading: false,
    reportDateType: 2,
    reportPartTypes: [
      '1',
      '2',
      '3',
      '4',
      '5',
    ],
    medicalInsuranceType: 0,
    regions: [],
    insuranceProgramms: [],
    clinicNetworks: [],
    clinics: [],

    defaultValue: null,

    regionChoices: {
      title: 'Выбрать все',
      choices: [],
    },
    insuranceProgrammsChoices: {
      title: 'Выбрать все',
      choices: [],
    },
  }),
  computed: {
    isMoniki() {
      return this.$store.state.Auth.login === 'moniki';
    },
    disabledFields() {
      return {
        medicalInsuranceType: this.checkFeatureAccess({ name: 'Статистика. Страхование. Блокировка радиокнопок.', url: '/reports' }),
      };
    },
    disabledSubprogramField() {
      return this.checkFeatureAccess({ name: 'Статистика. Страхование. Подгружать страховые подпрограммы', url: '/reports' });
    },
  },
  async created() {
    if (!this.disabledSubprogramField) return;
    if (this.checkFeatureAccess({ name: 'Статистика. Страхование - тип страхования ОМС', url: '/reports' })) this.medicalInsuranceType = 1;

    this.$store.dispatch(this.$types.DOCTOR_FETCH_REGIONS).then((regions) => {
      this.regionChoices.choices = regions.map((region) => ({ ...region, title: region.name }));
    });

    this.$store.dispatch(this.$types.INSURANCE_SUBPROGRAMS_FETCH).then((programms) => {
      this.insuranceProgrammsChoices.choices = programms.map((programm) => ({ ...programm, title: programm.name }));
    });
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onUnload() {
      try {
        this.isLoading = true;
        await this.$store.dispatch(this.$types.STATISTICS_UNLOAD_FETCH, {
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          reportPartTypes: this.reportPartTypes,
          reportDateType: this.reportDateType,
          medicalInsuranceType: this.medicalInsuranceType,
          regions: this.medicalInsuranceType === 1 || this.medicalInsuranceType === 2 ? this.regions.map((region) => region.id) : null,
          insuranceProgramms: this.medicalInsuranceType === 0 || this.medicalInsuranceType === 2
            ? this.insuranceProgramms.map((programm) => programm.id) : null,
          clinicNetworks: this.clinicNetworks.map((network) => network.id),
          clinics: this.clinics.map((clinic) => clinic.id),
        });
        this.$emit('vuedals:close');
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;
      }
    },
    deleteRegion(region) {
      this.regions = this.regions.filter((item) => item.id !== region.id);
    },
    deleteInsuranceProgramm(programm) {
      this.insuranceProgramms = this.insuranceProgramms.filter((item) => item.id !== programm.id);
    },
    deleteClinicNetwork(clinicNetwork) {
      this.clinicNetworks = this.clinicNetworks.filter((item) => item.id !== clinicNetwork.id);
    },
    deleteClinic(clinic) {
      this.clinics = this.clinics.filter((item) => item.id !== clinic.id);
    },
    async fetchNetworks(params) {
      try {
        const data = await clinicService.searchNetworks(params);
        return Array.isArray(data) ? data : [];
      } catch (err) {
        console.log(err);
        return [];
      }
    },
    selectNetwork(value) {
      this.clinicNetworks = [...this.clinicNetworks, { ...value }];
    },
    async fetchClinic({ query, skip, take }) {
      const clinics = await this.$store.dispatch(this.$types.CLINICS_FETCH, {
        skip,
        take,
        filter: {
          query,
          network: this.clinicNetworks[0],
          city: null,
        },
      });
      return clinics.map((clinic) => ({ value: clinic.id, name: clinic.title }));
    },
    selectClinic(event) {
      this.clinics = [...this.clinics, {
        id: event.value,
        title: event.name,
      }];
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  min-height: 50vh;
}
</style>
