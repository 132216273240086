import { APP_HOST } from '@/app.config';

export default class SpecializationsApi {
  constructor($axios) {
    this.api = $axios.instance;
  }

  async getCheckupProfilesOld() {
    const { data } = await this.api.get(`${APP_HOST}v2/specializations/checkup_profiles`);
    return data;
  }
}
