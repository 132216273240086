import AuthApi from '@/services/auth/auth.api';
import jwtDecode from 'jwt-decode';
import store from '@/store';

export default class AuthService {
  constructor($axios) {
    this.api = new AuthApi($axios);
  }

  async login(loginData) {
    const token = await this.api.login(loginData);

    const { loginDecoded, role } = this.decodeToken(token);

    return { token, role, loginDecoded };
  }

  // eslint-disable-next-line class-methods-use-this
  decodeToken(token) {
    const decodedToken = jwtDecode(token);
    const loginDecoded = decodedToken.sub;
    const role = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

    return { loginDecoded, role };
  }

  async userFetch() {
    const { user, status } = await this.api.getUser();

    return { user, status };
  }

  // eslint-disable-next-line class-methods-use-this
  createImageUrl(url) {
    const { token } = store.state.Auth;
    const imageUrl = new URL(url);

    imageUrl.searchParams.append('access_token', token);
    return imageUrl.toString();
  }

  async getSignatureForAuth(id) {
    return this.api.getSignatureForAuth(id);
  }
}
