// Гарантийные письма
export const LETTERS_OF_GURANTEE_FEATURES = {
  GP_AbilityToDeleteTemplate: {
    name: 'Возможность удалить шаблон ГП',
    url: '/patients',
  },
};

// Страховые подпрограммы
export const SUBPROGRAMS_FEATURES = {
  SubprogramsAbilityToDeactivate: {
    name: 'Возможность деактивировать подпрограмму',
    url: '/insurance-companies',
  },
  SubprogramsAbilityToEditStepOne: {
    name: 'Возможность редактировать шаг 1',
    url: '/insurance-companies/:company/programs/:program/subprograms/:subprogram/edit',
  },
  SubprogramsAbilityToEditStepTwoo: {
    name: 'Возможность редактировать шаг 2',
    url: '/insurance-companies/:company/programs/:program/subprograms/:subprogram/edit',
  },
  SubprogramsAbilityToEditStepThree: {
    name: 'Возможность редактировать шаг 3',
    url: '/insurance-companies/:company/programs/:program/subprograms/:subprogram/edit',
  },
  SubprogramsAbilityToEditStepFour: {
    name: 'Возможность редактировать шаг 4',
    url: '/insurance-companies/:company/programs/:program/subprograms/:subprogram/edit',
  },
  SubprogramsOpenStepFour: {
    name: 'Открывать шаг 4 при редактировании',
    url: '/insurance-companies/:company/programs/:program/subprograms/:subprogram/edit',
  },
};

// Список клиник
export const CLINICS_FEATURES = {
  ClinicsGeolocationsSyncButton: {
    name: 'Значок синхронизации Ш.Д.',
    url: '/clinics',
  },
  ClinicsEditingGeolocation: {
    name: 'Редактирование Ш.Д.',
    url: '/clinics',
  },
  ClinicsMapButton: {
    name: 'Кнопка «Карта»',
    url: '/clinics',
  },
};

export const FEATURES = {
  ...LETTERS_OF_GURANTEE_FEATURES,
  ...SUBPROGRAMS_FEATURES,
  ...CLINICS_FEATURES,
};

export default FEATURES;
