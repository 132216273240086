import { z } from 'zod';

// eslint-disable-next-line import/prefer-default-export
export const userViewModalSchema = z.object({
  userData: z.object({
    snils: z.string().nullable().refine((val) => val === null || val.length === 14, {
      message: 'Данное поле обязательно*',
    }),
    phoneNumber: z.string().nullable().refine((value) => value !== null && value !== '', 'Данное поле обязательно*'),
    firstName: z.string().nullable().refine((value) => value !== null && value !== '', 'Данное поле обязательно*'),
    lastName: z.string().nullable().refine((value) => value !== null && value !== '', 'Данное поле обязательно*'),
    login: z.string().nullable().refine((value) => value !== null && value !== '', 'Данное поле обязательно*'),
    password: z.string().nullable().refine(
      (value) => value !== null && value !== '' && value.length >= 6, 'Данное поле обязательно*',
    ),
    sipLogin: z.string().nullable().refine((value) => value !== null && value !== '', 'Данное поле обязательно*'),
    sipPassword: z.string().nullable().refine(
      (value) => value !== null && value !== '' && value.length >= 6, 'Данное поле обязательно и должно быть не менее 6 символов*',
    ),
    sipNumber: z.string().nullable().refine((value) => value !== null && value !== '', 'Данное поле обязательно*'),
    clinicIds: z.array(z.object({ id: z.string().min(1, 'Данное поле обязательно*') })).min(1, 'Необходима как минимум 1 клиника'),
    powerOfAttorney: z.string().nullable().refine((value) => value !== null && value !== '', 'Данное поле обязательно*'),
    roleId: z.string().nullable().refine((value) => value !== null && value !== '', 'Данное поле обязательно*'),
  }),
});
