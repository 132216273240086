import { APP_HOST } from '@/app.config';

export default class AuthApi {
  constructor($axios) {
    this.api = $axios.instance;
  }

  async getUsersAccount(params) {
    const { data: responsibles } = await this.api.get(`${APP_HOST}v2/account/users`, { params });

    return responsibles;
  }
}
