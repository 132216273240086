import mutations from './mutations';
import getters from './getters';
import actions from './actions';

export default {
  namespaced: true,
  state: {
    isCreateNewTemplateValidate: false,
    isEditIndemnity: true,
    isCheckValidation: false,
    letter: null,
    letters: [],
    letterIndemnityCity: null,
    analysisLetters: [],
    templates: [],
    isLetterEdited: false,
    isLetterSaving: false,
    isLetterSending: false,
    isLettersLoading: false,
    isTemplateSaving: false,
    editTemplate: {
      rows: [],
    },
    personId: null,
    historyWithoutPolicy: false,
    annulmentReason: '',
  },
  actions,
  getters,
  mutations,
};
