<template>
  <div class="speciality">
    <span class="crm-title">
      {{ speciality.title }}
    </span>

    <icon-clear
      v-if="edit"
      class="crm-icon-cross"
      @click="onClickCross"
    />
  </div>
</template>

<script>
import IconClear from 'assets/images/cross-blue.svg';

export default {
  name: 'DoctorSpecialityItem',
  components: {
    IconClear,
  },
  props: {
    speciality: {
      type: Object,
      default: () => ({
        title: '',
      }),
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClickCross() {
      this.$emit('delete');
    },
  },
};
</script>

<style lang="scss" scoped>
.speciality {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  background: #F3F3F3;
  border-radius: 5px;
  min-height: 40px;
  box-sizing: border-box;
}

.crm-title {
  font-size: 16px;
  line-height: 20px;
}

.crm-icon-cross {
  margin-left: 5px;
  margin-right: -10px;
  cursor: pointer;
}
</style>
