<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="xl"
    title="Клиники"
    hide-footer
    no-close-on-backdrop
    @hidden="onClose"
  >
    <custom-scrollbar>
      <ClinicPage
        :modal-name="modalName"
        :clinic-id="clinicId"
      />
    </custom-scrollbar>
  </b-modal>
</template>

<script>
import ClinicPage from '../ClinicPage/ClinicPage.vue';

export default {
  name: 'ClinicPageModal',
  components: {
    ClinicPage,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    clinicId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,

    };
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
