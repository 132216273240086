<template>
  <div
    :class="[alignLeft ? 'left' : 'center', ...classes]"
    class="crm-header"
  >
    <span class="crm-header--title">
      {{ title }}
    </span>

    <div class="crm-buttons-container">
      <base-button
        v-if="minimizeButton"
        :type="$const.SECONDARY_BUTTON"
        class="crm-minimize-button"
        transparent
        @click="onClickMinimize"
      >
        <!-- Свернуть -->
        <icon-minimize
          class="crm-icon-minimize"
          width="20px"
          height="20px"
          viewBox="0 0 16 16"
        />
      </base-button>
      <base-button
        v-if="closeButton"
        :type="$const.SECONDARY_BUTTON"
        class="crm-close-button"
        transparent
        @click="onClickClose"
      >
        <!-- Закрыть -->
        <icon-cross
          class="crm-icon-cross"
          width="24px"
          height="24px"
          viewBox="0 0 16 16"
        />
      </base-button>
    </div>
  </div>
</template>

<script>
import { BaseButton } from '@/components/base';

import IconCross from 'assets/images/clear_icon.svg';
import IconMinimize from 'assets/images/minimize.svg';

export default {
  name: 'BaseModalHeader',
  components: {
    BaseButton,
    IconCross,
    IconMinimize,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    alignLeft: {
      type: Boolean,
      default: false,
    },
    closeButton: {
      type: Boolean,
      default: false,
    },
    minimizeButton: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: Array,
      default: () => [],
    },
    minimizeFunc: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    onClickClose() {
      this.$emit('vuedals:dismiss');
    },
    onClickMinimize() {
      this.$emit('vuedals:dismiss');
      this.minimizeFunc();
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-header {
  width: 100%;
  display: flex;
  background-color: $white;
  border-radius: 10px 10px 0 0;

  &.center {
    justify-content: center;
  }

  &.left {
    justify-content: space-between;
  }
}
.crm-header--title {
  color: $black;
  font-weight: 500;
  font-size: 26px;
  line-height: 32px;
}

::v-deep.crm-close-button, .crm-minimize-button {
  align-self: flex-start;
  width: unset;
  padding: 0;
  border: none;

  .crm-icon-cross {
    circle {
      fill: transparent;
      transition: all .3s;
    }

    path {
      fill: $blue;
    }
  }

  &:hover {
    circle {
      fill: $blue-light;
    }
  }
}

.crm-minimize-button {
  margin-right: 5px;
}

.crm-buttons-container {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
}
</style>
