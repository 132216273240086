import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.SCHEDULE_DATE_SET](state, payload) {
    state.selectedDate = payload;
  },
  [localTypes.SCHEDULE_DOCTOR_SET](state, payload) {
    state.selectedDoctor = payload;
  },
  [localTypes.SCHEDULE_LOADING_SET](state, payload) {
    state.isScheduleLoading = payload;
  },
  [localTypes.SCHEDULE_SET](state, payload) {
    state.schedule = payload.schedule;
    state.liveSchedule = payload.liveSchedule;
  },
  [localTypes.SCHEDULE_TEMPLATES_LOADING_SET](state, payload) {
    state.isScheduleTemplatesLoading = payload;
  },
  [localTypes.SCHEDULE_TEMPLATES_SET](state, payload) {
    state.scheduleTemplates = payload;
  },
  [localTypes.TOGGLE_SCHEDULE_TRIGGER](state) {
    state.scheduleTrigger = !state.scheduleTrigger;
  },
};
