import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.CONSULTATION_REVIEW_LIST_GET](state) {
    return state.consultationReviewList.data;
  },
};
