<template>
  <v-date-picker
    :min-date="minDate"
    :max-date="maxDate"
    :value="value"
    :popover="{
      visibility: 'hidden',
      placement: placement,
      positionFixed: positionFixed,
    }"
    :class="{ ['crm-fluid']: fluid }"
    @input="$emit('change', $event)"
  >
    <template v-slot:default="{ inputValue, inputEvents, togglePopover, hidePopover }">
      <div
        class="date-picker"
        :disabled="disabled"
      >
        <input
          :value="inputValue"
          v-on="inputEvents"

          :class="{
            'with-clear-button': showClearButton,
            error,
          }"
          :placeholder="placeholder"
          :disabled="disabled"
          class="crm-input"
        >
        <icon-close
          v-if="showClearButton"
          class="crm-close-icon"
          @click="onClickClose(hidePopover)"
        />
        <div
          :class="{
            error,
          }"
          class="crm-icon-wrapper"
          @click="onClickPopover(togglePopover)"
        >
          <icon-calendar />
        </div>

        <template v-if="error && errors.length">
          <div
            class="errors"
          >
            <span
              class="crm-error-text"
            >
              {{ errors[0] }}
            </span>
          </div>
        </template>
      </div>
    </template>
  </v-date-picker>
</template>

<script>
import IconCalendar from 'assets/images/calendar-2.svg';
import IconClose from 'assets/images/clear_icon.svg';

export default {
  name: 'BaseDatePicker',
  components: {
    IconCalendar,
    IconClose,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Date,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'дд.мм.гггг',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxDate: {
      type: Date,
      default: null,
    },
    minDate: {
      type: Date,
      default: null,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    fluid: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    positionFixed: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'bottom-start',
    },
  },
  computed: {
    showClearButton() {
      return this.value !== null && this.clearable;
    },
  },

  methods: {
    onClickClose(hidePopoverFn) {
      if (this.disabled) return;
      hidePopoverFn();
      this.$emit('change', null);
    },

    onClickPopover(togglePopover) {
      if (this.disabled) return;
      togglePopover();
    },
  },
};
</script>

<style lang="scss" scoped>
.date-picker {
  position: relative;
  display: flex;
  height: 38px;
  width: 100%;
  cursor: pointer;

  &[disabled] {
    cursor: not-allowed;
    background-color: $gray-disabled;
  }
}
.crm-fluid {
  width: 100%;
}
.crm-input {
  @include base-input;
  padding: 11px 15px 11px;
  width: calc(100% - 25px);
  &[disabled] {
    cursor: not-allowed;
    background-color: $gray-disabled;
  }
}
.crm-icon-wrapper {
  padding: 8px;
  background-color: $white;
  border-radius: 4px;
  border: 1px solid $gray;
  margin-left: -5px;
}
.crm-close-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  right: 46px;
}
.error {
  border-color: $red;
}
.errors {
  position: absolute;
  top: calc(100% + 10px);
  z-index: 1;

  .crm-error-text {
    display: block;
    font-size: 12px;
    color: $red;
    font-weight: 500;
  }
}
</style>
