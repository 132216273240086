import cloneDeep from 'lodash/cloneDeep';

import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

export const defaultState = {
  filterClinic: null,
  filterRegion: null,
  filterSpecializations: [],
  filterDoctors: [],

  date: new Date(),
  isLoading: false,
  isAllItemsLoaded: true,

  schedule: [],
  doctors: [],

  hoveredCell: null,
  draggableCell: null,
};

const localTypes = removeNamespaces(types);

export default {
  [localTypes.SCHEDULE_PAGE_FILTER_CLINIC_SET](state, payload) {
    state.filterClinic = payload;
  },
  [localTypes.SCHEDULE_PAGE_FILTER_REGION_SET](state, payload) {
    state.filterRegion = payload;
  },
  [localTypes.SCHEDULE_PAGE_FILTER_SPECIALIZATIONS_SET](state, payload) {
    state.filterSpecializations = payload;
  },
  [localTypes.SCHEDULE_PAGE_FILTER_SPECIALIZATIONS_SET](state, payload) {
    state.filterSpecializations = payload;
  },
  [localTypes.SCHEDULE_PAGE_FILTER_DOCTORS_SET](state, payload) {
    state.filterDoctors = payload;
  },
  [localTypes.SCHEDULE_PAGE_DATE_SET](state, payload) {
    state.date = payload;
  },
  [localTypes.SCHEDULE_PAGE_LOADING_SET](state, payload) {
    state.isLoading = payload;
  },
  [localTypes.SCHEDULE_PAGE_ALL_ITEMS_LOADED_SET](state, payload) {
    state.isAllItemsLoaded = payload;
  },
  [localTypes.SCHEDULE_PAGE_SCHEDULE_SET](state, payload) {
    state.schedule = payload;
  },
  [localTypes.SCHEDULE_PAGE_DOCTORS_SET](state, payload) {
    state.doctors = payload;
  },
  [localTypes.SCHEDULE_PAGE_NULL_STATE](state) {
    const defaultStateCopy = cloneDeep(defaultState);
    Object.keys(defaultStateCopy).forEach((key) => {
      state[key] = defaultStateCopy[key];
    });
  },
  [localTypes.SCHEDULE_PAGE_HOVERED_CELL_SET](state, payload) {
    state.hoveredCell = payload;
  },
  [localTypes.SCHEDULE_PAGE_DRAGGABLE_CELL_SET](state, payload) {
    state.draggableCell = payload;
  },
};
