import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.PARTNERS_SET](state, payload) {
    state.partnerList = payload;
  },
  [localTypes.LEGAL_PARTNERS_SET](state, payload) {
    state.legalPartnerList = payload;
  },
  [localTypes.BRANCHES_SET](state, payload) {
    state.branchesList = payload;
  },
  [localTypes.PARTNER_SERVICES_SET](state, payload) {
    state.partnerServiceList = payload;
  },
  [localTypes.CURRENT_PARTNER_SERVICE_SET](state, payload) {
    state.currentPartnerServiceData = payload;
  },
  [localTypes.NEXT_PARTNER_SERVICE_SET](state, payload) {
    state.nextPartnerServiceData = payload;
  },
  [localTypes.BIND_SERVICES_OFFSET_SET](state, payload) {
    state.servicesBindOffset = payload;
  },
  [localTypes.ORDER_ITEMS_SET](state, payload) {
    state.orderList = payload;
  },
  [localTypes.CURRENT_ORDER_SET](state, payload) {
    state.currentOrder = payload;
  },
  [localTypes.SERVICES_SET](state, payload) {
    state.serviceList = payload;
  },
  [localTypes.SIMILAR_SERVICES_SET](state, payload) {
    state.similarServiceList = payload;
  },
  [localTypes.PARTNERS_IS_LOADING](state, payload) {
    state.isLoading = payload.isLoading ?? false;
    state.isLoadingButton = payload.isLoadingButton ?? false;
  },
  [localTypes.LABORATORY_PROMOCODE_REMOVE_BY_ID](state, payload) {
    state.promocodes = state.promocodes.filter((promocode) => promocode.id !== payload);
  },
  [localTypes.LABORATORY_PROMOCODES_IS_LOADING_SET](state, payload) {
    state.isLoading = payload;
  },
  [localTypes.LABORATORY_PROMOCODES_IS_ALL_ITEMS_LOADED_SET](state, payload) {
    state.isAllItemsLoaded = payload;
  },
  [localTypes.LABORATORY_PROMOCODES_SET](state, payload) {
    state.promocodes = payload;
  },
  [localTypes.LABORATORY_PROMOCODES_COUNT_SET](state, payload) {
    state.promocodesTotalCount = payload;
  },
  [localTypes.TOGGLE_PARTNERS_LABORATORY_SERVICES_TRIGGER](state) {
    state.partnersServicesTrigger = !state.partnersServicesTrigger;
  },
  [localTypes.TOGGLE_OUR_LABORATORY_SERVICES_TRIGGER](state) {
    state.ourServicesTrigger = !state.ourServicesTrigger;
  },
  [localTypes.LABORATORY_INITIALIZE_ATTACHED_FILES](state, { orderId, oldFiles }) {
    const acutalOrder = state.attachedFiles.find((item) => item.orderId === orderId);

    if (acutalOrder) return;

    state.attachedFiles.push({
      orderId,
      files: [...oldFiles],
    });
  },
  [localTypes.LABORATORY_DESTORY_ATTACHED_FILES](state, orderId) {
    const acutalOrderIndex = state.attachedFiles.findIndex((item) => item.orderId === orderId);

    if (acutalOrderIndex !== -1) state.attachedFiles.splice(acutalOrderIndex, 1);
  },
  [localTypes.LABORATORY_ADD_ATTACHED_FILE](state, { orderId, field }) {
    const acutalOrder = state.attachedFiles.find((item) => item.orderId === orderId);

    if (acutalOrder) acutalOrder.files.push(field);
  },
  [localTypes.LABORATORY_REMOVE_ATTACHED_FILE](state, { orderId, fileId }) {
    const acutalOrder = state.attachedFiles.find((item) => item.orderId === orderId);
    const actualFileIndex = acutalOrder.files.findIndex((item) => item.id === fileId);

    if (actualFileIndex !== -1) acutalOrder.files.splice(actualFileIndex, 1);
  },
};
