export default {
  // * 'Clinic/GET_DOCTORS_PINNED'
  GET_DOCTORS_PINNED(state) {
    return state.pinnedDoctors;
  },
  // * 'Clinic/GET_IS_CHANGED_DOCTORS_INFO'
  GET_IS_CHANGED_DOCTORS_INFO(state) {
    return state.isChangedClinicDoctorsInfo;
  },
  // * 'Clinic/GET_IS_CHANGED_INFO'
  GET_IS_CHANGED_INFO(state) {
    return state.isChangedClinicInfo;
  },
  // * 'Clinic/PRICES_LIST'
  PRICES_LIST(state) {
    return state.pricesList;
  },
  // * 'Clinic/PRICES_LIST_PAGINATION_COUNT'
  PRICES_LIST_PAGINATION_COUNT(state) {
    return state.pricesListPaginationCount;
  },
  // * 'Clinic/PRICES_LIST_LOADING'
  PRICES_LIST_LOADING(state) {
    return state.pricesListLoading;
  },
};
