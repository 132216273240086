import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

import {
  Contract,
  Constituent,
} from '@/components/Clinics/clinicUtils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.CLINIC_DOCUMENTS_GET_ALL](state) {
    return state.documents.filter((doc) => doc.title.toLowerCase().indexOf(state.query.toLowerCase()) !== -1);
  },
  [localTypes.CLINIC_DOCUMENTS_GET_CONTRACTS](state, getters) {
    return getters[localTypes.CLINIC_DOCUMENTS_GET_ALL].filter((doc) => doc instanceof Contract);
  },
  [localTypes.CLINIC_DOCUMENTS_GET_CONSTITUENT](state, getters) {
    return getters[localTypes.CLINIC_DOCUMENTS_GET_ALL].filter((doc) => doc instanceof Constituent);
  },
};
