import { v4 as uuidv4 } from 'uuid';
import store from '@/store';

export default class UIService {
  // eslint-disable-next-line class-methods-use-this
  showModal(modal, options = {}) {
    const { modals } = store.state.UIStore;

    const isAlreadyExist = modals.findIndex((m) => m.id === modal.id) !== -1;

    if (isAlreadyExist) {
      return;
    }

    const modalObject = {
      id: uuidv4(),
      component: modal,
      props: options.props ?? {},
      actions: options.actions ?? {},
    };

    store.dispatch('UIStore/showModal', modalObject);
  }

  // eslint-disable-next-line class-methods-use-this
  closeModalById(modalId) {
    store.dispatch('UIStore/closeModalById', modalId);
  }

  // eslint-disable-next-line class-methods-use-this
  clearModals() {
    store.dispatch('UIStore/clearModals');
  }
}

export const uiService = new UIService(store);
