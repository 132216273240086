import SipUserApi from '@/services/sipUser/sipUser.api';

export default class SipUserService {
  constructor($axios) {
    this.api = new SipUserApi($axios);
  }

  setSipStatus(params) {
    return this.api.setSipStatus(params);
  }
}
