<template>
  <div
    :class="{ active, disabled }"
    class="base-switcher"
    @click.stop.prevent="onChangeStatus"
  >
    <span
      v-if="label"
      class="crm-label"
    >
      {{ label }}
    </span>

    <div class="switcher">
      <div
        :class="[!active ? 'left' : 'right']"
        class="bullet"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseSwitcher',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: this.status,
    };
  },
  watch: {
    value: {
      handler(newValue) {
        this.active = newValue;
      },
      immediate: true,
    },
  },
  methods: {
    onChangeStatus() {
      if (this.disabled) return;

      this.active = !this.active;
      this.$emit('change', this.active);
    },
  },
};
</script>

<style lang="scss" scoped>
.base-switcher {
  display: flex;
  align-items: center;

  &.active {
    .switcher {
      background-color: #DDE3FC;
    }

    .bullet {
      background-color: $blue-dark;
    }
  }
  &.disabled {
    opacity: 0.45;
    cursor: not-allowed;
  }
}
.crm-label {
  margin-bottom: 0;
  margin-right: 23px;
}
.switcher {
  box-sizing: border-box;
  flex-shrink: 0;
  flex-grow: 0;
  width: 34px;
  height: 14px;
  border-radius: 60px;
  background-color: #BDBDBD;
  position: relative;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.bullet {
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 10px);
  transition: background-color 0.2s ease-in-out;
  transition: left 0.2s ease-in-out;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.15);

  &.left {
    left: -3px;
  }
  &.right {
    left: calc(100% - 17px);
  }
}
</style>
