<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    hide-footer
    size="lg"
    :title="title"
    @hidden="onClose"
  >
    <Loading
      v-if="isLoading"
      class="crm-loading-wrapper"
    >
      Загрузка доктора
    </Loading>

    <div
      v-else
      class="doctor-info"
    >
      <div class="doctor-info--block">
        <div class="crm-form-fields">
          <div class="crm-form-field crm-info-block">
            <p class="crm-info-title">
              Логин
            </p>

            <span class="crm-info-text">
              {{ doctor.login || doctor.userInfo.userName || 'Не указано' }}
            </span>
          </div>

          <div class="crm-form-field crm-info-block">
            <p class="crm-info-title">
              Email
            </p>

            <span class="crm-info-text">
              {{ doctor.email || doctor.userInfo.email || 'Не указано' }}
            </span>
          </div>
        </div>

        <div class="crm-form-fields">
          <div class="crm-form-field crm-info-block">
            <p class="crm-info-title">
              Номер телефона
            </p>

            <span class="crm-info-text">
              {{ doctor.phoneNumber || 'Не указано' }}
            </span>
          </div>

          <div class="crm-form-field crm-info-block">
            <p class="crm-info-title">
              ID врача
            </p>

            <span class="crm-info-text">
              {{ doctor.id }}
            </span>
          </div>
        </div>

        <div class="crm-form-fields">
          <div class="crm-form-field crm-info-block">
            <p class="crm-info-title">
              Дата рождения
            </p>

            <span class="crm-info-text">
              {{ birthDate }}
            </span>
          </div>

          <div class="crm-form-field crm-info-block">
            <p class="crm-info-title">
              Пол
            </p>

            <span class="crm-info-text">
              {{ sexType }}
            </span>
          </div>
        </div>
        <div class="crm-form-fields">
          <div class="crm-form-field crm-info-block">
            <p class="crm-info-title">
              О себе
            </p>

            <div
              v-if="doctor.about"
              v-html="doctor.about"
            />
            <span
              v-else
              class="crm-info-text"
            >
              -
            </span>
          </div>
        </div>
        <div class="crm-form-field crm-info-block">
          <p class="crm-info-title">
            Комментарий
          </p>

          <div
            v-if="doctor.comment"
            v-html="doctor.comment"
          />
          <span
            v-else
            class="crm-info-text"
          >
            -
          </span>
        </div>
      </div>

      <div class="doctor-info--block">
        <div class="crm-form-fields">
          <div class="crm-form-field crm-info-block">
            <p class="crm-info-title">
              Название клиники
            </p>

            <span class="crm-info-text">
              {{ doctor.clinicName }}
            </span>
          </div>
          <div class="crm-form-field crm-info-block">
            <p class="crm-info-title">
              Признак врача
            </p>

            <span class="crm-info-text">
              {{ signType }}
            </span>
          </div>
        </div>

        <div class="crm-form-fields">
          <div class="crm-form-field crm-info-block">
            <p class="crm-info-title">
              Врач является тестовым
            </p>

            <span class="crm-info-text">
              {{ isTestDoctor }}
            </span>
          </div>
        </div>

        <div
          v-if="doctor.settings.currentPlaceOfWorkStartDate"
          class="doctor-info--block"
        >
          <div class="crm-form-fields">
            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Дата начала
              </p>
              <span class="crm-info-text">
                {{ dateFormated(doctor.settings.currentPlaceOfWorkStartDate) }}
              </span>
            </div>
          </div>

          <div
            v-if="doctor.placesOfWork.length"
            class="doctor-info--block"
          >
            <h5 class=" font-weight-bold">
              Места работы
            </h5>
            <div
              v-for="(item, idx) in doctor.placesOfWork"
              :key="idx"
              class="d-flex flex-column"
              style="gap: 0.5rem"
            >
              <div class="crm-form-fields">
                <div class="crm-form-field crm-info-block">
                  <span>
                    Название места работы
                  </span>
                  <span class="mt-2 font-weight-bold">
                    {{ item.organizationName }}
                  </span>
                </div>
                <div class="crm-form-field crm-info-block">
                  <span>
                    Специализация
                  </span>
                  <span
                    v-for="(specialization, index) in item.specializations"
                    :key="index"
                    class="mt-2 font-weight-bold"
                  >
                    {{ specialization.title }}
                  </span>
                </div>
              </div>
              <div class="crm-form-fields">
                <div class="crm-form-field crm-info-block">
                  <span>
                    Дата начала работы
                  </span>
                  <span class="mt-2 font-weight-bold">
                    {{ dateFormated(item.startDate) }}
                  </span>
                </div>
                <div class="crm-form-field crm-info-block">
                  <span>
                    Дата окончания работы
                  </span>
                  <span class="mt-2 font-weight-bold">
                    {{ dateFormated(item.endDate) }}
                  </span>
                </div>
              </div>
              <div
                v-if="item.cityName"
                class="d-flex flex-column my-2"
              >
                <span>
                  Город
                </span>
                <span class="mt-2 font-weight-bold">
                  {{ item.cityName }}
                </span>
              </div>
              <div
                v-if="idx < doctor.placesOfWork.length - 1"
                class="block-divider w-100 mt-1 mb-4 "
              />
            </div>
          </div>

          <div class="crm-form-fields">
            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Учетная запись врача является активной
              </p>

              <span class="crm-info-text">
                {{ activeStatus }}
              </span>
            </div>

            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Тип видимости
              </p>

              <span class="crm-info-text">
                {{ accessType }}
              </span>
            </div>
          </div>

          <div
            v-if="Number(doctor.settings.accessType) === 3 && subprograms.length"
          >
            <p class="crm-info-title">
              Подпрограммы
            </p>

            <b-table
              :fields="accessTypeFields"
              :items="subprograms"
              bordered
            />
          </div>

          <div
            v-if="!doctor.settings.isActive"
            class="crm-form-fields"
          >
            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Причина отключения учетной записи
              </p>

              <span class="crm-info-text">
                {{ deactivationReason || 'Не указано' }}
              </span>
            </div>

            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Комментарий отключения учетной записи
              </p>

              <span class="crm-info-text">
                {{ doctor.settings.deactivationComment || 'Не указано' }}
              </span>
            </div>
          </div>

          <div class="crm-form-fields">
            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                СНИЛС
              </p>

              <span class="crm-info-text">
                {{ doctor.snils || 'Не указано' }}
              </span>
            </div>

            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Дистанционный мониторинг
              </p>

              <span class="crm-info-text">
                {{ remoteMonitoringStatus }}
              </span>
            </div>
          </div>

          <div class="crm-form-fields">
            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Стоимость приема
              </p>

              <span class="crm-info-text">
                {{ doctor.settings.consultationPrice }} ₽
              </span>
            </div>

            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Стоимость депозита
              </p>

              <span class="crm-info-text">
                {{ doctor.settings.depositPrice ? doctor.settings.depositPrice + '₽' : 'Не указано' }}
              </span>
            </div>
          </div>

          <div class="crm-form-fields">
            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Доверенность
              </p>

              <span class="crm-info-text">
                {{ doctor.settings.powerOfAttorneyNumber || 'Не указано' }}
              </span>
            </div>
            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Среднее время консультации
              </p>

              <span class="crm-info-text">
                {{ consultationDurationInMinutes }}
              </span>
            </div>
          </div>

          <div
            v-if="doctor.settings.signType === 1"
            class="crm-form-fields"
          >
            <div class="crm-info-block">
              <p class="crm-info-title">
                Идентификатор МиД
                <span class="crm-info-title_subtitle">
                  (только для врачей МиД)
                </span>
              </p>

              <span class="crm-info-text">
                {{ doctor.settings.midServiceId }}
              </span>
            </div>
          </div>
        </div>

        <div class="doctor-info--block">
          <div
            v-for="(specialization, index) in specializations"
            :key="index"
            class="crm-form-fields"
          >
            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Специализация
              </p>

              <span class="crm-info-text">
                {{ specializationsNames[specialization.specializationId] }}
              </span>
            </div>

            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Опыт работы
                <span class="crm-info-text_subtext">
                  (начиная с...)
                </span>
              </p>

              <span class="crm-info-text">
                {{ specialization.experience }}
              </span>
            </div>
          </div>

          <div class="crm-form-fields">
            <div
              v-if="acceptsAge"
              class="crm-form-field crm-info-block"
            >
              <p class="crm-info-title">
                Принимает (по возрасту)
              </p>

              <span class="crm-info-text">
                {{ acceptsAge }}
              </span>
            </div>

            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Тип связи
              </p>

              <span class="crm-info-text">
                {{ linkTypes }}
              </span>
            </div>
          </div>

          <div class="crm-form-fields">
            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Прикрепленные очереди
              </p>

              <b-table-simple
                v-if="doctor.queues.length"
                bordered
                small
              >
                <b-tbody>
                  <b-tr
                    v-for="queue in doctor.queues"
                    :key="queue.id"
                  >
                    <b-td>
                      {{ queue.name }}
                    </b-td>
                    <b-td>
                      {{ queue.description || 'Нет описания' }}
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <div
                v-else
                class="text-secondary"
              >
                Очереди не добавлены
              </div>
            </div>
          </div>
        </div>
        <div class="crm-button-wrapper">
          <b-button
            v-if="checkFeature(FEATURES_FOR_CHAT.editDetailInfo, FEATURES_FOR_DOCTORS.editDoctorModal)"
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            @click="openEditModal"
          >
            Редактировать
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { format, parseISO } from '@evd3v/date-fns';
import { api } from '@/helpers/api';
import {
  DOCTOR_SIGN_TYPE,
  SEX_TYPE, POLICY_TYPE,
  DOCTOR_ACCESS_TYPE,
  DOCTOR_INACTIVE_REASONS,
  FEATURES_FOR_CHAT,
  FEATURES_FOR_DOCTORS,
} from '@/helpers/consts';
import { mixinRoles } from '@/mixins';

import Loading from '@/components/Loading';

import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

// import { BaseButton } from '@/components/base';

export default {
  name: 'DoctorInfoModal',
  components: {
    Loading,
    // BaseButton,
  },
  mixins: [mixinRoles],
  props: {
    doctorId: {
      type: Number,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    modalName: {
      type: String,
      default: null,
    },
    parentModalName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      doctor: {},
      accessTypeFields: [
        {
          key: 'companyName',
          label: 'Компания',
        },
        {
          key: 'programName',
          label: 'Программа',
        },
        {
          key: 'subprogramName',
          label: 'Подпрограмма',
        },
      ],
      subprograms: [],
      DOCTOR_INACTIVE_REASONS,
      FEATURES_FOR_CHAT,
      FEATURES_FOR_DOCTORS,
    };
  },
  computed: {
    isTestDoctor() {
      return this.doctor.isTest ? 'Да' : 'Нет';
    },
    activeStatus() {
      return this.doctor.settings.isActive ? 'Да' : 'Нет';
    },
    deactivationReason() {
      return DOCTOR_INACTIVE_REASONS[this.doctor.settings.deactivationReason]?.title || '';
    },
    acceptsAge() {
      if (!this.doctor.mainDoctorSpecialization) return false;

      const { isForAdults, isForChildren } = this.doctor.mainDoctorSpecialization;

      if (isForAdults && isForChildren) {
        return 'Взрослых и детей';
      }
      if (isForAdults) {
        return 'Взрослых';
      }
      if (isForChildren) {
        return 'Детей';
      }

      return false;
    },
    remoteMonitoringStatus() {
      return this.doctor.settings.remoteMonitoringAllowed ? 'Да' : 'Нет';
    },
    birthDate() {
      return format(parseISO(this.doctor.birthDate), 'dd.MM.yyyy');
    },
    signType() {
      return DOCTOR_SIGN_TYPE[this.doctor.settings.signType];
    },
    sexType() {
      return SEX_TYPE[this.doctor.sex];
    },
    accessType() {
      return DOCTOR_ACCESS_TYPE[this.doctor.settings.accessType];
    },
    specializations() {
      return this.doctor.doctorSpecializations.map((specialization) => ({
        ...specialization,
        experience: format(parseISO(specialization.experience), 'dd.MM.yyyy'),
      }));
    },
    specializationsNames() {
      return this.doctor.specializations.reduce((acc, specialization) => ({ ...acc, [specialization.id]: specialization.title }), {});
    },
    linkTypes() {
      const types = [];
      if (this.doctor.settings.isTextEnabled) types.push('Текст');
      if (this.doctor.settings.isAudioEnabled) types.push('Аудио');
      if (this.doctor.settings.isVideoEnabled) types.push('Видео');

      return types.join(', ');
    },
    regions() {
      return this.doctor.regions.map((region) => region.name).join(', ') || '—';
    },
    medicalInsuranceTypes() {
      const decodedInsuranceType = POLICY_TYPE.decodeType(this.doctor.settings.medicalInsuranceType);
      let insuranceTypeArray = [];

      if (decodedInsuranceType === 'All') {
        insuranceTypeArray = ['ОМС', 'ДМС'];
      } else if (decodedInsuranceType === 'OMS') {
        insuranceTypeArray = ['ОМС'];
      } else if (decodedInsuranceType === 'DMS') {
        insuranceTypeArray = ['ДМС'];
      }

      return insuranceTypeArray.join(', ') || '—';
    },
    consultationDurationInMinutes() {
      return this.doctor.settings.consultationDurationInMinutes || 'Не указано';
    },
  },
  async created() {
    try {
      this.isLoading = true;

      const { data: doctor } = await api.get(`v2/doctors/${this.doctorId}`);

      this.doctor = doctor;

      if (
        this.doctor.settings.subprogramsIds
          && this.doctor.settings.subprogramsIds.length
      ) {
        /* eslint-disable-next-line */
        for (const subprogramId of doctor.settings.subprogramsIds) {
          /* eslint-disable no-await-in-loop */
          const { data } = await api.get(`v2/insuranceCompanies/programs/subprogram/${subprogramId}`);
          this.subprograms.push({
            id: data.subProgram.id,
            subprogramName: data.subProgram.name,
            programName: data.program.name,
            companyName: data.company.name,
          });
        }
      }
    } catch (e) {
      console.warn(e);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    checkFeature(keyOne, keyTwo) {
      return this.parentModalName ? this.checkFeatureAccess({ name: keyOne.name, url: keyOne.url })
        : this.checkFeatureAccess({ name: keyTwo.name, url: keyTwo.url });
    },
    openEditModal() {
      this.onClose();
      uiService.showModal(MODALS.DOCTOR_EDIT_MODAL, {
        name: 'DoctorEditModal',
        props: {
          doctorId: this.doctorId,
          title: 'Редактирование врача',
        },
      });
    },
    dateFormated(date) {
      if (!date) return '-';
      return format(parseISO(date), 'dd.MM.yyyy');
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
  },
};
</script>

<style lang="scss" scoped>
.doctor-info-wrapper {
  height: calc(100vh - 152px);
}
.doctor-info,
.doctor-info--block {
  width: 100%;
}
.doctor-info {
  margin-top: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}
.doctor-info--block {
  padding-bottom: 15px;
  & + & {
    border-top: 1px solid $gray-border;
    padding-top: 25px;
  }

}
.crm-form-fields {
  &:not(:last-child) {
    margin-bottom: 30px;
  }
}
.crm-info-block {
  flex-direction: column;

  .crm-info-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    &_subtext {
      font-weight: normal;
      color: $gray-dark;
    }
  }

  .crm-info-title {
    margin: 0 0 10px 0;
    font-size: 14px;
    line-height: 17px;

    &_subtitle {
      color: $gray-border;
    }
  }
}

.crm-button-wrapper {
  display: flex;
  justify-content: center;
}

.block-divider {
  display: block;
  height: 1px;
  background: rgb(199, 199, 199);
}
</style>
