<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="md"
    :title="`Аннулирование ${isReferral ? 'направления' : 'гарантийного письма'}`"
    hide-footer
    @hidden="onClose"
    @ok="onClose"
  >
    <div class="policy-delete">
      <custom-scrollbar>
        <div
          class="crm-container policy-container"
        >
          <base-input
            v-model="reason"
            :input-style="{ padding: '11px 10px 12px', height: '100px' }"
            class="crm-form-field"
            type="textarea"
            label="Причина аннулирования"
            placeholder="Введите причину письма"
          />

          <div class="crm-wrapper-buttons">
            <b-button
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              :loading="isLoading"
              class="crm-button"
              @click="onClickAction"
            >
              Отправить
            </b-button>
          </div>
        </div>
      </custom-scrollbar>
    </div>
  </b-modal>
</template>

<script>
import { mixinRoles } from '@/mixins';
import Bus from '@/eventBus';

import {
  showMessage,
} from '@/helpers/utils';

import {
  BaseInput,
} from '@/components/base';

export default {
  name: 'IndemnityLetterAnnulmentModal',
  components: {
    BaseInput,
  },
  mixins: [mixinRoles],
  props: {
    indemnityLetterId: {
      type: String,
      default: null,
    },
    policyId: {
      type: Number,
      default: null,
    },
    clinicId: {
      type: String,
      default: '',
    },
    isReferral: {
      type: Boolean,
      default: false,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.Auth.user;
    },
    reason: {
      get() {
        return this.$store.state.Indemnity.annulmentReason;
      },
      set(newVal) {
        this.$store.commit(this.$types.INDEMNITY_ANNULMENT_REASON_SET, newVal);
      },
    },
  },
  created() {
    this.reason = '';
  },
  methods: {
    async onClickAction() {
      if (!this.reason) return showMessage({ type: 'warning', message: 'Укажите причину аннулирования' });

      this.isLoading = true;

      try {
        const letter = await this.$store.dispatch(this.$types.INDEMNITY_LETTER_ANNULMENT, {
          id: this.indemnityLetterId,
          reason: this.reason,
          isReferral: this.isReferral,
          executorName: `${this.user.lastName} ${this.user.firstName[0]}.` || null,
          executorPosition: 'исполнитель',
        });

        this.$store.commit(this.$types.INDEMNITY_LETTER_SET, {
          ...letter,
        });

        await this.$store.dispatch(this.$types.INDEMNITY_LETTER_SEND, { letter, annulment: true });

        showMessage({
          type: 'success',
          title: 'Успешно',
          message: `${this.isReferral ? 'Направление' : 'Гарантийное письмо'} аннулировано!`,
        });
      } catch (e) {
        console.log(e);
        showMessage({
          type: 'error',
          title: 'Ошибка',
          message: `${this.isReferral ? 'Направление' : 'Гарантийное письмо'} не удалось аннулировать!`,
        });
      } finally {
        this.isLoading = false;
        this.onClose();

        if (this.$store.state.Indemnity.letters.length) Bus.$emit('indemnity-history-modal:fetch-letters');

        if (this.clinicId) {
          await this.$store.dispatch(this.$types.INDEMNITY_LETTERS_GET_BY_CLINIC, this.clinicId);
        } else {
          await this.$store.dispatch(this.$types.INDEMNITY_LIST_NAMES_FETCH, {
            personId: this.$store.state.Indemnity.personId,
            policyId: this.policyId,
          });
        }
      }
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
  },
};
</script>

<style lang="scss" scoped>
.policy-container {
  flex-direction: column;
  align-items: center;
}
.crm-wrapper-buttons {
  margin-top: 5px;
}
::v-deep.crm-form-field {
  width: 100%;

  .crm-label {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
  }
}
</style>
