import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    tabs: [
      // example tab object
      // {
      //   id: null, // tab id
      //   title: '', // tab title
      //   type: 'appeal', // tab type
      //   data: {}, // modal data
      //   onOpenFunc: () => {}, // open modal function
      // },
    ],
  },
  mutations,
};
