<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="xl"
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <template #modal-header>
      <p class="h5">
        {{ title }}
      </p>

      <b-button
        size="sm"
        title="Закрыть"
        variant="outline-danger"
        :type="$const.PRIMARY_BUTTON"
        class="p-0"
        @click="onClose"
      >
        <b-icon icon="x" />
      </b-button>
    </template>

    <div
      v-if="isLoading"
      class="d-flex justify-content-center w-100"
    >
      <b-spinner variant="primary" />
    </div>

    <div v-else>
      <div class="mb-3">
        <div class="font-weight-bold h5 mb-2">
          Услуга
        </div>
        <div class="pl-2">
          <div>
            <span class="font-weight-bold">Код услуги:</span> {{ service.code }}
          </div>
          <div>
            <span class="font-weight-bold">Название:</span> {{ service.name }}
          </div>
          <div>
            <span class="font-weight-bold">Комментарий:</span> {{ service.description }}
          </div>
          <div>
            <span class="font-weight-bold">Цена:</span> {{ service.cost }}
          </div>
          <div>
            <span class="font-weight-bold">Активен:</span>
            <span :style="{color: service.isActive ? 'green' : 'red'}">
              {{ service.isActive ? 'Да' : 'Нет' }}
            </span>
          </div>
        </div>
      </div>
      <div>
        <div class="font-weight-bold h5 mb-2">
          Склеить с
        </div>

        <div
          v-if="selectedServiceId"
          class="pl-2"
        >
          <div>
            <span class="font-weight-bold">Название:</span> {{ selectedService.name }}
          </div>
          <div>
            <span class="font-weight-bold">Код:</span> {{ selectedService.code }}
          </div>
          <div>
            <span class="font-weight-bold">Эта услуга связана с партнерами:</span>
            <!-- <b-table
              v-if="selectedService.partnerServices.length"
              :fields="partnerServicesFields"
              :items="selectedService.partnerServices"
              bordered
              fixed
              hover
              responsive
              :small="true"
              class="bg-white crm-table w-100"
            >
              <template v-slot:cell(id)="row">
                <div class="break-word">
                  {{ row.item.id }}
                </div>
              </template>
              <template v-slot:cell(code)="row">
                <div class="break-word">
                  {{ row.item.code }}
                </div>
              </template>
              <template v-slot:cell(name)="row">
                <div class="break-word">
                  {{ row.item.name }}
                </div>
              </template>
              <template v-slot:cell(legalPartnerName)="row">
                <div class="break-word">
                  {{ row.item.legalPartner?.legalName || '-' }}
                </div>
              </template>
            </b-table> -->
            <div v-if="selectedService.partnerServices.length">
              <div
                v-for="service in selectedService.partnerServices"
                :key="service.id"
              >
                {{ service.legalPartner.legalName }}
              </div>
            </div>
            <div v-else>
              -
            </div>
          </div>

          <b-button
            variant="danger"
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            class="mt-2"
            @click="selectedServiceId = null"
          >
            Выбрать другое
          </b-button>
        </div>
        <template v-else>
          <div class="mt-2 mb-4 d-flex">
            <b-input-group>
              <b-form-input
                v-model="searchData.name"
                size="sm"
                placeholder="По названию  услуги"
                @keyup.native.enter="clearFetchOurServices"
              />
              <b-form-input
                v-model="searchData.code"
                size="sm"
                placeholder="По коду услуги"
                @keyup.native.enter="clearFetchOurServices"
              />
            </b-input-group>

            <b-button
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="ml-2"
              @click="clearFetchOurServices"
            >
              <b-icon icon="search" />
            </b-button>
          </div>

          <div v-if="ourServicesSearched && !ourServicesLoading">
            <template v-if="ourServices.length">
              <b-table
                :fields="ourServicesFields"
                :items="ourServices"
                bordered
                fixed
                hover
                responsive
                :small="true"
                class="bg-white crm-table w-100"
              >
                <template v-slot:cell(id)="row">
                  <div class="break-word">
                    {{ row.item.id }}
                  </div>
                </template>
                <template v-slot:cell(name)="row">
                  <div class="break-word">
                    {{ row.item.name }}
                  </div>
                </template>
                <template v-slot:cell(isActive)="row">
                  <b-badge
                    v-if="row.item.isActive"
                    class="p-2"
                    pill
                    variant="success"
                  >
                    Активирована
                  </b-badge>
                  <b-badge
                    v-else
                    class="p-2"
                    pill
                    variant="danger"
                  >
                    Не активирована
                  </b-badge>
                </template>
                <template v-slot:cell(actions)="row">
                  <b-button
                    variant="primary"
                    :type="$const.PRIMARY_BUTTON"
                    size="sm"
                    @click="selectService(row.item.id)"
                  >
                    Выбрать
                  </b-button>
                </template>
              </b-table>
              <b-pagination
                v-model="ourServicesCurrentPage"
                pills
                :total-rows="+ourServicesTotalCount"
                :per-page="LIMIT"
                style="padding-top: 16px"
                @input="fetchOurServices"
              />
            </template>

            <div
              v-else
              class="font-weight-bold"
            >
              Ничего не найдено
            </div>
          </div>

          <b-skeleton-table
            v-if="ourServicesLoading"
            :rows="3"
            :columns="ourServicesFields.length"
            :table-props="{ bordered: true, striped: true }"
          />
        </template>
      </div>
    </div>

    <template #modal-footer>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="onClose"
      >
        Отменить
      </b-button>

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isLoading || isSaving || !selectedService"
        class="float-right"
        @click="onClickSave"
      >
        Склеить
        <b-spinner
          v-if="isSaving"
          variant="light"
          small
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { showCustomMessage } from '@/helpers/messages';

const LIMIT = 6;

export default {
  name: 'GluePartnerServiceModal',

  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Склеить услугу',
    },
    partnerServiceId: {
      type: [Number, String],
      default: null,
    },
  },
  data: () => ({
    isOpen: true,
    isLoading: false,
    isSaving: false,
    service: null,
    ourServices: [],
    ourServicesCurrentPage: 1,
    ourServicesTotalCount: null,
    ourServicesSearched: false,
    ourServicesLoading: false,
    ourServicesFields: [
      {
        key: 'code',
        label: 'Код',
        sortable: true,
        tdClass: 'align-middle',
        class: 'text-center',
      },
      {
        key: 'name',
        label: 'Название',
        sortable: true,
        tdClass: 'align-middle',
        class: 'text-center',
      },
      {
        key: 'isActive',
        label: 'Статус услуги',
        tdClass: ['align-middle'],
        class: 'text-center',
      },
      {
        key: 'actions',
        label: 'Действия',
        tdClass: ['align-middle'],
        class: 'text-center',
      },
    ],
    partnerServicesFields: [
      {
        key: 'code',
        label: 'Код',
        sortable: true,
        tdClass: 'align-middle',
        class: 'text-center',
      },
      {
        key: 'name',
        label: 'Название',
        sortable: true,
        tdClass: 'align-middle',
        class: 'text-center',
      },
      {
        key: 'legalPartnerName',
        label: 'Юр.лицо',
        sortable: true,
        tdClass: 'align-middle',
        class: 'text-center',
      },
    ],
    searchData: {
      name: null,
      code: null,
    },
    selectedServiceId: null,
    LIMIT,
  }),
  computed: {
    selectedService() {
      return this.ourServices.find((item) => item.id === this.selectedServiceId);
    },
  },

  async mounted() {
    this.isLoading = true;

    try {
      await this.fetchPartnerService();
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async glueService(id) {
      try {
        const params = {
          partnerServiceGuid: this.partnerServiceId,
          serviceGuid: id,
        };
        await this.$store.dispatch(this.$types.LABORATORY_PARTNER_SERVICE_MAP, params);
        this.$store.commit(this.$types.TOGGLE_PARTNERS_LABORATORY_SERVICES_TRIGGER);
        this.onClose();
      } catch (e) {
        console.error(e);
        showCustomMessage('warning', 'Ошибка', 'Что-то пошло не так');
      }
    },
    onClickSave() {
      this.$store.commit(
        this.$types.OPEN_MODAL,
        {
          name: 'AgreeModal',
          props: {
            title: 'Вы уверены?',
            cb: this.glueService,
            cbArgs: [this.selectedServiceId],
          },
        },
      );
    },
    async fetchPartnerService() {
      const { data } = await this.$store.dispatch(this.$types.GET_LABORATORY_PARTNER_SERVICES_FOR_ID, this.partnerServiceId);
      this.service = data;
    },
    clearFetchOurServices() {
      this.ourServicesCurrentPage = 1;
      this.fetchOurServices();
    },
    async fetchOurServices() {
      this.ourServicesLoading = true;

      try {
        const params = {
          limit: LIMIT,
          offSet: (this.ourServicesCurrentPage - 1) * LIMIT,
        };

        const { code, name } = this.searchData;
        if (code) params.Code = code;
        if (name) params.Name = name;

        const { data: { data }, xTotalCount } = await this.$store.dispatch(this.$types.GET_LABORATORY_SERVICES, params);

        this.ourServices = data;
        this.ourServicesTotalCount = xTotalCount;
      } catch (e) {
        console.error(e);
      } finally {
        this.ourServicesSearched = true;
        this.ourServicesLoading = false;
      }
    },
    selectService(id) {
      this.selectedServiceId = id;
    },
  },
};
</script>

<style lang="scss">
</style>
