import Vue from 'vue';
import JanusPlugin from '@doctis.front/library.vuecomponent.doctorcall';
import AxiosClient from '@/services/core/axios';
import HubClientService from '@/services/core/hub';
import { APP_HOST } from '@/app.config';

const apiClient = {
  client: AxiosClient.instance,
  options: {
    baseUrl: `${APP_HOST}v3`,
  },
};

Vue.use(JanusPlugin, {
  apiClient,
  hubConnection: HubClientService,
});
