<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="xl"
    title="Лабораторные отделения"
    hide-footer
    no-close-on-backdrop
    @hidden="onClose"
  >
    <custom-scrollbar>
      <LaboratoryBranches
        :modal-name="modalName"
        class="laboratory-branches"
      />
    </custom-scrollbar>
  </b-modal>
</template>

<script>
import LaboratoryBranches from '@/pages/LaboratoryBranches';

export default {
  name: 'LaboratoryBranchesModal',
  components: {
    LaboratoryBranches,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },

  },
  data() {
    return {
      isOpen: true,
    };
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
  },
};
</script>

<style lang="scss" scoped>
  :deep(.containerLabsPage) {
    max-width: 100%;
    padding: 10px 10px 0 !important;
  }
  .laboratory-branches {
    min-height: 90vh;
  }
</style>
