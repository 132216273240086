import RegistryApi from '@/services/registry/registry.api';

export default class RegistryService {
  constructor($axios) {
    this.api = new RegistryApi($axios);
  }

  getImportHistory(obj) {
    return this.api.getImportHistory(obj);
  }

  getFile(obj) {
    return this.api.getFile(obj);
  }
}
