v<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="s"
    :title="'Импорт услуг'"
    @hidden="onClose"
    @ok="onClose"
  >
    <loading v-if="isLoading">
      Загрузка данных
    </loading>
    <div v-else>
      <b-row>
        <b-col>
          <b-form-group
            label="Источник услуги"
          >
            <b-form-select
              v-model="serviceSource"
              :options="optionsServiceSource"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-file
            v-model="importFile"
            class="mt-3"
            plain
          />
        </b-col>
      </b-row>
    </div>
    <template #modal-footer>
      <div>
        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          :disabled="!importFile"
          @click="onClickImport"
        >
          Импорт
        </b-button>
        <b-button
          variant="danger"
          :type="$const.PRIMARY_BUTTON"
          class="ml-2"
          @click="onClose"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Loading from '@/components/Loading';

export default {
  name: 'ImportServices',
  components: {
    Loading,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    params: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      importFile: null,
      optionsServiceSource: [
        { value: 0, text: 'По умолчанию' },
        { value: 1, text: 'Из федерального реестра' },
        { value: 2, text: 'Латеус тест' },
      ],
      serviceSource: 0,
    };
  },
  computed: {},
  created() {},
  methods: {
    async onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onClickImport() {
      const params = {
        serviceSource: this.serviceSource,
        importFile: this.importFile,
      };

      await this.$store.dispatch(this.$types.LABORATORY_SERVICE_IMPORT, params);
      await this.$store.dispatch(this.$types.GET_LABORATORY_SERVICES, this.params);

      this.onClose();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
