<template>
  <div class="map-container">
    <div class="d-flex justify-content-between mb-2">
      <b-input-group class="bg-white rounded">
        <b-form-input
          v-model="title"
          placeholder="Поиск по названию клиники"
          class="map-input map-select"
          :style="{ width: '65%'}"
          @keyup.native.enter="searchClinic"
        />
        <b-form-input
          v-model.trim="serviceTitle"
          placeholder="Поиск по прайсу"
          class="map-input map-select"
          :style="{ width: '35%'}"
          @keyup.native.enter="searchClinic"
        />
      </b-input-group>
      <b-button
        variant="light"
        :type="$const.PRIMARY_BUTTON"
        class="map-button"
        @click="searchClinic"
      >
        <b-icon icon="search" />
      </b-button>
      <v-select
        v-model="network"
        :reduce="network => network.id"
        label="title"
        placeholder="Сеть клиник"
        :options="networks"
        class="map-select ml-2"
        :style="{ width: '30%'}"
      >
        <template #no-options>
          Ничего не найдено
        </template>
      </v-select>
      <b-button
        ref="labBtn"
        v-b-tooltip.hover.bottomleft.v-light
        variant="light"
        :type="$const.PRIMARY_BUTTON"
        title="Перейти на карту лабораторий"
        class="map-btn ml-2"
        @click="click"
      >
        Labs
      </b-button>
    </div>
    <div class="map-wrapper">
      <div
        v-if="network"
        class="map-wrapper-branches fade"
      >
        <span class="title ml-2">
          Список клиник
        </span>
        <custom-scrollbar>
          <div
            v-for="clinic in clinics"
            :key="clinic.id"
            class="networks"
          >
            <div
              class="networks-item"
              :class="{highlighted: selectedItem === clinic.id}"
            >
              <div
                ref="clinic"
                @click="clickedToClinic(clinic)"
              >
                {{ clinic.title }}
              </div>
            </div>
          </div>
          <p
            v-if="!clinics.length"
            class="ml-2 mt-3"
          >
            Список клиник пуст
          </p>
        </custom-scrollbar>
      </div>
      <div
        class="map"
        :class="{'map-full': network === null}"
      >
        <yandex-map
          ref="myMap"
          :key="key"
          :coords="coords"
          :zoom="10"
          :scroll-zoom="true"
          :cluster-options="clusterOptions"
          @click="onClick"
        >
          <clinics-map-marker
            v-for="clinic in clinics"
            :id="clinic.id"
            :key="clinic.id"
            :clinic="clinic"
            @clickToButton="openClinicPageModal"
          />
        </yandex-map>
      </div>
    </div>
  </div>
</template>
<script>
import { clinicService } from '@/services';
import { showErrorCustomMessage } from '@/helpers/messages';
import ClinicsMapMarker from '../map/ClinicsMapMarker';

const MOSCOW_LATITUDE = 55.755826;
const MOSCOW_LONGITUDE = 37.6172999;

export default {
  name: 'ClinicsrMap',
  components: {
    ClinicsMapMarker,
    yandexMap: () => import('vue-yandex-maps').then(({ yandexMap }) => yandexMap),
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      isLoadClinics: false,
      coords: [],
      title: null,
      serviceTitle: null,
      network: null,
      key: 0,
      networks: [],
      clinics: [],
      selectedItem: null,
      clusterOptions: {
        1: {
          clusterDisableClickZoom: false,
          clusterOpenBalloonOnClick: true,
          clusterBalloonContentLayout: 'cluster#balloonTwoColumns',
          clusterBalloonPagerType: 'marker',
          clusterBalloonItemContentLayout: '',
          clusterBalloonLeftColumnWidth: 250,
        },
      },
    };
  },
  computed: {
    params() {
      return {
        title: this.title,
        serviceTitle: this.serviceTitle,
        networkId: this.network,
      };
    },
  },
  watch: {
    async network(e) {
      if (e) {
        await this.fetchClinics();
      } else {
        this.onNetworkChange();
        await this.fetchClinics();
        this.coords = [MOSCOW_LATITUDE, MOSCOW_LONGITUDE];
      }
    },
  },
  async created() {
    this.isLoading = true;
    this.coords = [MOSCOW_LATITUDE, MOSCOW_LONGITUDE];
    await this.fetchNetworks();
    await this.fetchClinics({});
    this.isLoading = false;
  },
  methods: {
    onNetworkChange() {
      this.key += 1;
    },
    async fetchClinics() {
      this.isLoadClinics = false;
      this.clinics = await this.getClinicsForMap(this.params);
      this.isLoadClinics = true;
    },
    async getClinicsForMap(obj) {
      try {
        const data = await clinicService.searchforMap(obj);
        return data;
      } catch (err) {
        showErrorCustomMessage('Не удалось получить список клиник');
        console.warn(err);
        throw err;
      }
    },
    async fetchNetworks() {
      this.networks = (await this.getList()).sort((a, b) => a.title.localeCompare(b.title));
    },
    async getList() {
      try {
        const data = await clinicService.getNetworkList();
        return Array.isArray(data) ? data : [];
      } catch (err) {
        console.log(err);
        this.networks = [];
      }
    },
    async searchClinic() {
      if (this.title || this.serviceTitle) {
        await this.fetchClinics();
        if (this.isLoadClinics) {
          if (this.clinics.length === 1) {
            const { latitude, longitude } = this.clinics[0];
            this.coords = [latitude, longitude];
          }
        }
      } else {
        await this.fetchClinics();
        this.coords = [MOSCOW_LATITUDE, MOSCOW_LONGITUDE];
      }
    },
    getClinic(item) {
      if (!item) return;
      const { latitude, longitude } = item;
      this.coords = [latitude, longitude];
    },
    toggleItemSelection(index) {
      this.selectedItem = index;
    },
    clickedToClinic(clinic) {
      this.getClinic(clinic);
      this.toggleItemSelection(clinic.id);
    },
    openClinicPageModal(clinic) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ClinicPageModal',
        props: {
          clinicId: clinic.id,
        },
      });
    },
    onClick(e) {
      this.coords = e.get('coords');
    },
    click() {
      this.$emit('click');
    },
  },
};
</script>
<style lang="scss" scoped>
.map-container{
  width: 100%;
  height: 100%;
  .map-input{
    border-right: none;
    border-radius: 5px 0 0 5px;
    outline: none;
    &:focus {
      box-shadow: none;
      border: none;
      outline: 3px solid rgb(231, 226, 226);
    }
  }
  .map-button {
    border: 1px solid rgb(231, 226, 226);
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, .5);
    border-radius: 0 5px 5px 0;
    height: 38px;
  }
  .map-btn {
    box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
  }

  .map-wrapper {
    display: flex;
    width: 100%;
    height: 73vh;
    &-branches {
      display: flex;
      flex-direction: column;
      background-color: rgb(248, 248, 248);
      width: 30%;
      height: 73vh;
      border-right: 1px solid rgb(225, 225, 230);
      .title {
        background-color: white;
      }
    }
    .map {
      width: 70%;
    }
    .map-full {
      width: 100%;
      box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
    }
  }
  .networks{
    display: flex;
    flex-direction: column;
    padding: 0 5px;
    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgb(235, 230, 230);
      cursor: pointer;
      padding: 10px;
      word-wrap: break-word;
      &:hover{
        background-color: rgb(243, 238, 238);
      }
    }
  }
}
.map-select{
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, .5);
}
.highlighted{
  background: rgb(245, 243, 243);
  box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
}
.fade {
  animation: fade 1s forwards;
}
@keyframes fade {
  0% {opacity: 0;}
  100%{opacity: 1;}
}
.ymap-container{
  height: 73vh;
}
</style>
