<template>
  <div
    ref="chatInfo"
    class="chat-info"
  >
    <template v-if="historyAppeals.length">
      <template v-if="!isLoading">
        <div
          class="appeal-wrapper"
        >
          <div
            v-for="(appeal) in filteredAllAppeals"
            :ref="`appeal-${appeal.id}`"
            :key="appeal.id"
            class="appeal-item"
          >
            <div class="appeal-item-data">
              <span>{{ isDayAndMonth(appeal.created) }}</span>
            </div>
            <ChatMessageContainer
              v-for="(message, index) in appeal.messages"
              :key="message.id"
              :index="index"
              :message="message"
              :appeal-id="appeal.id"
              :filtered-messages="appeal.messages"
            />
            <hr>
          </div>
        </div>
      </template>
      <template v-else>
        <preloader style="margin-top: 20px" />
      </template>
    </template>
    <div
      v-else
      class="no-chat"
    >
      <span>Нет чата</span>
    </div>
  </div>
</template>
<script>
import Preloader from '@/components/Preloader';
import ChatMessageContainer from '@/components/Chat/ChatMessageContainer';
import {
  format, parseISO,
  getMonth,
} from '@evd3v/date-fns';
import { MONTHS, FORMATS_FOR_PREVIEW } from '@/helpers/consts';

import { chatService } from '@/services';

import { showErrorCustomMessage } from '@/helpers/messages';

export default {
  name: 'PatientDetailChat',
  components: {
    ChatMessageContainer,
    Preloader,
  },
  props: {
    patient: {
      type: Object,
      default: () => ({}),
    },
    chatAppealId: {
      type: [Number, String],
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      historyAppeals: [],
      messagesForAppeal: [],
      allAppeals: [],
      isAllLoading: false,
    };
  },
  computed: {
    filteredAllAppeals() {
      return this.modalName ? this.allAppeals.filter((appeal) => appeal.id === this.chatAppealId)
        : this.allAppeals;
    },
  },
  watch: {
    async isAllLoading(value) {
      if (value) {
        await this.$nextTick();
        if (this.chatAppealId) {
          const elementToFind = this.$refs[`appeal-${this.chatAppealId}`][0];
          this.scrollToAppeal(elementToFind);
        }
      }
    },
  },
  async created() {
    this.isLoading = true;
    try {
      await this.getAllMessagesFromAllAppeals();
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    scrollToAppeal(id) {
      this.$nextTick(() => {
        if (id) {
          setTimeout(() => {
            id.scrollIntoView(
              {
                block: 'start',
                inline: 'center',
                behavior: 'smooth',
              },
            );
          }, 200);
        }
      });
    },
    formatData(date) {
      return format(parseISO(date), 'dd');
    },
    getMonthByIndex(date) {
      return MONTHS[getMonth(new Date(date))].ru.slice(0, 3);
    },
    isDayAndMonth(date) {
      return `${this.formatData(date)} ${this.getMonthByIndex(date)}`;
    },
    async getHistoryAppeals() {
      try {
        this.historyAppeals = await chatService.getHistory(this.patient.id);
      } catch (err) {
        showErrorCustomMessage('Не удалось получить историю обращений');
        console.warn(err);
        throw err;
      }
    },
    async fetchFileBlob(chatAppealId, fileName) {
      const data = await chatService.getFile({ chatAppealId, fileName });
      return data;
    },
    async getImageForMessage(messageItem, fileName, chatAppealId) {
      try {
        const previewData = await this.fetchFileBlob(chatAppealId, fileName);
        messageItem.imageUrl = URL.createObjectURL(previewData);
      } catch (e) {
        console.error(e);
      }
    },
    getMessageWithUrl(message, chatAppealId) {
      if (message.messageType === 'File') {
        const parserdPayload = JSON.parse(message.payload);
        const { filesInformation } = parserdPayload;
        if (FORMATS_FOR_PREVIEW.includes(filesInformation[0].extension)) {
          const messageImage = {
            ...message,
            imageUrl: null,
          };
          this.getImageForMessage(messageImage, filesInformation[0].name, chatAppealId);
          return messageImage;
        }
      }
      return message;
    },
    async getAllMessagesFromAllAppeals() {
      await this.getHistoryAppeals(this.patient.id);
      if (this.historyAppeals) {
        await Promise.all(this.historyAppeals?.map(async (appeal) => {
          try {
            const data = await chatService.getOneHistoryMessage({
              chatAppealId: appeal.id,
              patientId: this.patient.id,
            });
            this.messagesForAppeal = data.map((item) => this.getMessageWithUrl(item, appeal.id));
            this.allAppeals.push({
              id: appeal.id,
              created: appeal.createdAt,
              messages: this.messagesForAppeal,
            });
          } catch (err) {
            showErrorCustomMessage('Не удалось получить историю сообщений обращения');
            console.warn(err);
            throw err;
          }
        }));
        this.allAppeals = this.allAppeals.sort((a, b) => new Date(b.created) - new Date(a.created));
        this.isAllLoading = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-info {
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  overflow: auto;
  padding: 10px;
}
.appeal-item{
  &-data {
    display: flex;
    background: #e4e4e1;
    border-radius: 10px;
    color: #908F8D;
    font-weight: 700;
    width: 90px;
    padding-left: auto;
    padding-right: auto;
    margin: 10px auto;
    span {
      margin: 0 auto;
    }
  }
}
.appeal-wrapper{
  display: flex;
  flex-direction: column;
}
.no-chat {
  color: #908F8D;
  font-size: 16px;
  padding: 30px 15px 30px 30px;
  line-height: 20px;
}
</style>
