<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    title="Export консультаций"
    :hide-footer="isLoading"
    @hidden="onClose"
    @ok="onClose"
  >
    <template v-if="isLoading">
      <preloader style="margin-top: 20px" />
    </template>
    <template v-else>
      <b-row style="height: 350px;">
        <b-col>
          <b-form-group
            label="От"
          >
            <base-date-picker
              v-model="dateFrom"
              :error="!dateFrom"
            />
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
            label="До"
          >
            <base-date-picker
              v-model="dateTo"
              :error="!dateTo"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>

    <template #modal-footer>
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        :disabled="isLoading || !dateFrom || !dateTo"
        @click="onAction"
      >
        Экспортировать
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { formatISO } from '@evd3v/date-fns';
import Preloader from '@/components/Preloader';

import { dateWithoutTime } from '@/helpers/utils';
import { BaseDatePicker } from '@/components/base';

export default {
  name: 'ConsultationControlExportModal',
  components: {
    Preloader,
    BaseDatePicker,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      dateFrom: null,
      dateTo: null,
    };
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onAction() {
      this.isLoading = true;

      try {
        const dateFrom = this.dateFrom ? dateWithoutTime(formatISO(new Date(this.dateFrom))) : null;
        const dateTo = new Date(this.dateTo.setHours(23, 59, 59, 0)).toISOString();
        await this.$store.dispatch(this.$types.CONSULATION_CONTROL_EXPORT, { dateFrom, dateTo });
        this.isLoading = false;

        this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
