/* eslint-disable max-len */
/* eslint-disable no-restricted-syntax */
import NProgress from 'nprogress/nprogress';

import * as types from '@/store/types';
import {
  removeNamespaces,
  // showMessage,
} from '@/helpers/utils';
import { api } from '@/helpers/api';

const localTypes = removeNamespaces(types);

export default {
  async [localTypes.REGISTRY_EXPORT](ctx, registr) {
    NProgress.start();

    try {
      const data = await api.post('v2/registry/export', registr, { responseType: 'blob' });

      return data;
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
};
