import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.SPECIALIZATIONS_SET](state, payload) {
    state.specializations = payload;
  },
};
