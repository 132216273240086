v<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="s"
    :title="'Привязывание партнерской услуги к номенклатурной услуге'"
    @hidden="onClose"
    @ok="onClose"
  >
    <loading v-if="isLoading">
      Загрузка данных
    </loading>
    <div v-else>
      <b-row>
        <b-col>
          <b-form-group label="Номенклатурная услуга">
            <b-form-select
              v-model="serviceId"
              value-field="id"
              text-field="name"
              label="name"
              trim
              :options="optionsServices"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <template #modal-footer>
      <div>
        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          :disabled="!serviceId"
          @click="onClickMap"
        >
          Привязать
        </b-button>
        <b-button
          variant="danger"
          :type="$const.PRIMARY_BUTTON"
          class="ml-2"
          @click="onClose"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Loading from '@/components/Loading';
import { api } from '@/helpers/api';

export default {
  name: 'PartnerServiceMap',
  components: {
    Loading,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    partnerServiceGuid: {
      type: [String, Number],
      default: null,
    },
    serviceGuid: {
      type: [String, Number],
      default: null,
    },
    params: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      importFile: null,
      optionsServices: [],
      serviceId: null,
    };
  },
  computed: {
    serviceListStore() {
      return this.$store.state.Laboratories.serviceList;
    },
  },
  async created() {
    this.isLoading = true;
    this.serviceId = this.serviceGuid;
    await this.$store.dispatch(this.$types.GET_LABORATORY_SERVICES, {});
    this.optionsServices = this.serviceListStore.data;
    this.isLoading = false;
  },
  methods: {
    async onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onClinicReestrImport() {
      const params = {
        ...this.params,
      };
      await api.post('v2/clinic/services/import', params);
      this.onClose();
    },
    async onClickMap() {
      const params = {
        partnerServiceGuid: this.partnerServiceGuid,
        serviceGuid: this.serviceId,
      };
      await this.$store.dispatch(this.$types.LABORATORY_PARTNER_SERVICE_MAP, params);
      await this.$store.dispatch(this.$types.GET_LABORATORY_PARTNER_SERVICES, this.params);
      this.onClose();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
