<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="md"
    :title="'Деперсонализация пациента'"
    hide-footer
    @hidden="onClose"
    @ok="onClose"
  >
    <div class="policy-delete">
      <custom-scrollbar>
        <div
          class="crm-container policy-container"
        >
          <div class="description">
            Вы уверены, что хотите деперсонализировать пациента?
          </div>
          <div class="crm-wrapper-buttons">
            <b-button
              variant="outline-primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              @click="onClickClose"
            >
              Отменить
            </b-button>

            <b-button
              variant="danger"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="ml-3"
              @click="onClickDelete"
            >
              Деперсонализировать
            </b-button>
          </div>
        </div>
      </custom-scrollbar>
    </div>
  </b-modal>
</template>

<script>
import { mixinRoles } from '@/mixins';
import Bus from '@/eventBus';

export default {
  name: 'PatientDepersonalizateModal',
  mixins: [mixinRoles],
  props: {
    patientId: {
      type: Number,
      default: null,
    },
    callback: {
      type: Function,
      default: () => {},
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isDeleting: false,
    };
  },
  methods: {
    onClickClose() {
      this.onClose();
    },
    async onClickDelete() {
      try {
        this.isDeleting = true;
        await this.$store.dispatch(this.$types.DELETE_USER_DEVICE, this.patientId);
        await this.$store.dispatch(this.$types.PATIENT_DEPERSONALIZATE, this.patientId);

        Bus.$emit('patients:update');

        const modalNumber = this.$store.state.Modals.openedModals.length - 2;
        const { modalName } = this.$store.state.Modals.openedModals[modalNumber];
        this.$store.commit(this.$types.CLOSE_MODAL, { modalName });
        this.onClose();
      } catch (e) {
        console.log(e);
      } finally {
        this.isDeleting = false;
      }
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
  },
};
</script>

<style lang="scss" scoped>
.policy-container {
  flex-direction: column;
  align-items: center;
}
.crm-wrapper-buttons {
  margin-top: 35px;
}
.description {
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
}
// .crm-button--depersonalizate {
//   width: unset;
//   padding-left: 20px;
//   padding-right: 20px;
// }
</style>
