import Vue from 'vue';
import VueRouter from 'vue-router';

import VueMeta from 'vue-meta';

import NProgress from 'nprogress/nprogress';

Vue.use(VueRouter);
Vue.use(VueMeta, {
  keyName: 'page',
});

const routes = [
  {
    path: '/403',
    name: '403',
    component: () => import('@/pages/ErrorPage403.vue'),
    meta: {
      authRequired: false,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/Login.vue'),
    meta: {
      authRequired: false,
    },
    props: (route) => ({ redirectFrom: route.query.redirectFrom }),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((routeTo, routeFrom, next) => {
  function redirectToLogin() {
    next({ name: 'login', query: { redirectFrom: routeTo.fullPath } });
  }

  if (routeFrom.name !== null) {
    NProgress.start();
  }

  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);
  const token = localStorage.getItem('token') || sessionStorage.getItem('token');
  // const role = localStorage.getItem('role') || sessionStorage.getItem('role');

  // if (routeTo.path === '/login' && token) return next('/');
  if (!authRequired) return next();

  if (token) return next();

  return redirectToLogin();
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
