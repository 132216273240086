<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="md"
    :title="'Удалить клинику?'"
    hide-footer
    @hidden="onClose"
    @ok="onClose"
  >
    <div
      class="d-flex justify-content-center"
    >
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        :disabled="isLoading"
        @click="onAction"
      >
        Да
      </b-button>

      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        class="float-right ml-2"
        :disabled="isLoading"
        @click="onClose"
      >
        Нет
      </b-button>
    </div>
  </b-modal>
</template>

<script>
// import {
//   // formatISO,
//   format,
// } from '@evd3v/date-fns';

export default {
  name: 'UserClinicDeleteModal',
  components: {
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
    };
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onAction() {
      this.callback();
      this.onClose();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
