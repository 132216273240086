import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.TAB_PANEL_ITEMS_FETCH_FROM_LOCALSTORAGE](state) {
    const tabs = JSON.parse(localStorage.getItem('panel-tabs'));
    if (tabs) state.tabs = [...tabs];
  },
  [localTypes.TAB_PANEL_ITEMS_SAVE_TO_LOCALSTORAGE](state) {
    localStorage.setItem('panel-tabs', JSON.stringify(state.tabs));
  },
  [localTypes.TAB_PANEL_ITEM_ADD](state, payload) {
    state.tabs = [...state.tabs, payload];
  },
  [localTypes.TAB_PANEL_ITEM_REMOVE_BY_ID](state, id) {
    state.tabs = state.tabs.filter((tab) => tab.id !== id);
  },
  [localTypes.TAB_PANEL_ITEM_UPDATE_BY_ID](state, payload) {
    state.tabs = state.tabs.map((tab) => {
      if (tab.id !== payload.id) return tab;

      return { ...tab, ...payload };
    });
  },
};
