v<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="s"
    :title="'Загрузка документа'"
    @hidden="onClose"
    @ok="onClose"
  >
    <loading v-if="isLoading">
      Загрузка данных
    </loading>
    <div v-else>
      <b-row>
        <b-col>
          <b-form-file
            v-model="importFile"
            accept=".pdf"
            plain
          />
        </b-col>
      </b-row>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <div class="float-right">
          <b-button
            class="mr-2"
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            :disabled="!importFile"
            @click="onClickUpload"
          >
            Загрузить
          </b-button>
          <b-button
            variant="danger"
            :type="$const.PRIMARY_BUTTON"
            @click="onClose"
          >
            Отмена
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Loading from '@/components/Loading';

export default {
  name: 'InsuranceSubprogramUploadFile',
  components: {
    Loading,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    subProgramId: {
      type: [String, Number],
      default: null,
    },
    docType: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      importFile: null,
    };
  },
  methods: {
    async onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onClickUpload() {
      const params = {
        subProgramId: this.subProgramId,
        docType: this.docType,
        importFile: this.importFile,
      };

      const data = await this.$store.dispatch(this.$types.INSURANCE_SUBPROGRAM_UPLOAD_FILE, params);

      const docData = {
        fileName: this.importFile.name,
        fileKey: data,
        docType: this.docType,
      };

      this.$store.commit(this.$types.SUBPROGRAM_DOC_DATA_SET, docData);

      this.onClose();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
