const actions = {
  showModal({ commit }, modal) {
    commit('ADD_MODAL', modal);
  },
  closeModal({ state, commit }, modal) {
    const idx = state.modals.findIndex((m) => m.id === modal.id);
    if (idx !== -1) {
      commit('REMOVE_MODAL', idx);
    }
  },
  closeModalById({ state, commit }, modalId) {
    const idx = state.modals.findIndex((m) => m.id === modalId);
    if (idx !== -1) {
      commit('REMOVE_MODAL', idx);
    }
  },
  clearModals({ commit }) {
    commit('CLEAR_MODALS');
  },
};

const mutations = {
  ADD_MODAL(state, modal) {
    state.modals.push(modal);
  },
  REMOVE_MODAL(state, modalIdx) {
    state.modals.splice(modalIdx, 1);
  },
  CLEAR_MODALS(state) {
    state.modals = [];
  },
};

export default {
  namespaced: true,
  state: {
    modals: [],
  },
  actions,
  mutations,
};
