<template>
  <div class="patient-policies">
    <custom-scrollbar>
      <div class="policies-inner">
        <div class="policies-header-buttons">
          <b-button
            v-if="checkFeature(FEATURES_FOR_CHAT.createReferralNoPolicy, FEATURES_FOR_PATIENTS.createReferralNoPolicy)"
            variant="outline-primary"
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            class="crm-button letter-history-btn without-border transparent w-25"
            @click="() => openIndemnityModal({isReferral: true })"
          >
            Создать направление без полиса
          </b-button>
          <b-button
            v-if="checkFeature(FEATURES_FOR_CHAT.createIndemnityNoPolicy, FEATURES_FOR_PATIENTS.createIndemnityNoPolicy)"
            variant="outline-primary"
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            class="crm-button letter-history-btn without-border transparent ml-3 w-25"
            @click="openIndemnityModal"
          >
            Создать ГП без полиса
          </b-button>
          <b-button
            v-if="checkFeature(FEATURES_FOR_CHAT.historyReferralNoPolicy, FEATURES_FOR_PATIENTS.historyReferralNoPolicy)"
            variant="outline-primary"
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            class="crm-button letter-history-btn without-border transparent ml-3 w-25"
            @click="() => openIndemnityHistoryWithoutPolicyModal({isReferral: true })"
          >
            История направления без полиса
          </b-button>
          <b-button
            v-if="checkFeature(FEATURES_FOR_CHAT.historyIndemnityNoPolicy, FEATURES_FOR_PATIENTS.historyIndemnityNoPolicy)"
            variant="outline-primary"
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            class="crm-button letter-history-btn without-border transparent ml-3  w-25"
            @click="openIndemnityHistoryWithoutPolicyModal"
          >
            История ГП без полиса
          </b-button>
        </div>
        <div class="active-policies">
          <PatientDetailPolicy
            v-for="(policy, index) in newPolicies"
            :key="policy.id + index"
            :policy="policy"
            :person-id="personId"
            :modal-name="modalName"
          />

          <PatientDetailPolicy
            v-for="(policy, index) in activePolicies"
            :key="policy.id + index"
            :policy="policy"
            :person-id="personId"
            :modal-name="modalName"
          />

          <PatientDetailPolicy
            v-for="(policy, index) in pendingPolicies"
            :key="policy.id + index"
            :policy="policy"
            :person-id="personId"
            :modal-name="modalName"
          />
          <div
            v-if="activePolicies.length === 0 && pendingPolicies.length === 0 && newPolicies.length === 0"
            class="not-found mt-3"
          >
            Нет полисов
          </div>
        </div>
        <div
          v-if="inactivePolicies.length"
          class="inactive-policies"
        >
          <div class="redline" />
          <div class="redline-text">
            Неактивные полисы
          </div>
          <PatientDetailPolicy
            v-for="(policy, index) in inactivePolicies"
            :key="policy.id + index"
            :policy="policy"
            :person-id="personId"
            :modal-name="modalName"
          />
        </div>
        <div
          v-if="!isPatientDop"
          class="d-flex"
        >
          <b-button
            v-if="false && newPolicies.length === 0"
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            class="add-policy-button"
            @click="addNewPolicy"
          >
            <icon-cross class="crm-icon-cross" />
            Добавить полис
          </b-button>

          <b-button
            v-if="checkFeature(FEATURES_FOR_CHAT.attachPolicy, FEATURES_FOR_PATIENTS.attachPolicy)"
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            class="add-policy-button"
            @click="openAttachPolicyModal"
          >
            Прикрепить полис
          </b-button>
        </div>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import { mixinRoles } from '@/mixins';
import { FEATURES_FOR_CHAT, FEATURES_FOR_PATIENTS } from '@/helpers/consts';
import PatientDetailPolicy from '@/components/Patients/PatientDetailPolicy';
import IconCross from 'assets/images/cross.svg';

export default {
  name: 'PatientDetailPolicies',
  components: {
    PatientDetailPolicy,
    IconCross,
  },
  mixins: [mixinRoles],
  props: {
    policies: {
      type: Array,
      default: null,
    },
    personId: {
      type: Number,
      default: null,
    },
    patientInfo: {
      type: Object,
      default: () => ({}),
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      policyStatuses: {
        inactive: [0, 1, 4, 5],
        pending: [2],
        active: [3],
      },
      newPolicies: [],
      FEATURES_FOR_CHAT,
      FEATURES_FOR_PATIENTS,
    };
  },
  computed: {
    activePolicies() {
      const array = this.policies.filter((policy) => this.policyStatuses.active.includes(policy.policyStatus));
      return array;
    },
    pendingPolicies() {
      const array = this.policies.filter((policy) => this.policyStatuses.pending.includes(policy.policyStatus));
      return array;
    },
    inactivePolicies() {
      const array = this.policies.filter((policy) => this.policyStatuses.inactive.includes(policy.policyStatus));
      return array;
    },
    isPatientDop() {
      if (this.patientInfo.person) {
        const { isMain, parentId } = this.patientInfo.person;
        return parentId && !isMain;
      }
      const { parentId } = this.patientInfo;
      return parentId;
    },
  },
  methods: {
    checkFeature(keyOne, keyTwo) {
      return this.modalName ? this.checkFeatureAccess({ name: keyOne.name, url: keyOne.url })
        : this.checkFeatureAccess({ name: keyTwo.name, url: keyTwo.url });
    },
    addNewPolicy() {
      const newPolicy = {
        id: 0,
        subProgramV2Id: null,
        buyDate: null,
        startDate: null,
        endDate: null,
        personId: this.personId,
      };
      this.newPolicies.push(newPolicy);
    },
    openIndemnityModal({ isReferral }) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'IndemnityModal',
        props: {
          personId: this.personId,
          clinicId: null, // Денис, вместо null необходимо вставить id выбранной клиники (если Id нет, от можно оставить null)
          isReferral,
        },
      });
    },
    openIndemnityHistoryWithoutPolicyModal({ isReferral }) {
      this.$store.commit(this.$types.INDEMNITY_HISTORY_WITHOUT_POLICY_SET, true);
      this.$store.commit(this.$types.INDEMNITY_PERSON_ID_SET, this.personId);
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'IndemnityHistoryModal',
        props: {
          personId: this.personId,
          isReferral,
        },
      });
    },
    openAttachPolicyModal() {
      this.$store.commit(this.$types.OPEN_MODAL, { name: 'AttachPolicyModal', props: { personId: this.personId } });
    },
  },
};
</script>

<style lang="scss" scoped>

.patient-policies {
  background-color: #fff;
  padding: 30px 15px 30px 30px;
  border-radius: 10px;
  border: 1px solid #E0E0E0;
  height: calc(100vh - 250px);
  overflow: auto;
  box-sizing: border-box;
}
.policies-inner {
  padding-right: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.not-found {
  font-size: 16px;
  line-height: 20px;
  color: #908F8D;
}

.inactive-policies {
  position: relative;
  margin-top: 20px;
  padding-top: 40px;
}

.redline {
  position: absolute;
  top: 10px;
  right: 0;
  left: 0;
  background-color: #EB5757;
  height: 0.5px;

  &-text {
    position: absolute;
    top: 10px;
    left: 0;
    font-size: 16px;
    line-height: 20px;
    transform: translateY(-50%);
    color: #EB5757;
    background-color: #ffffff;
    padding-right: 30px;
  }
}

.add-policy-button {
  margin-top: 30px;
  margin-left: auto;
  padding-left: 30px;
  padding-right: 30px;
  width: unset;
  z-index: 10;

  .crm-icon-cross {
    margin-right: 15px;
  }
}

.letter-history-btn {
  width: unset;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  & + & {
    margin-left: 0;
  }
}

.policies-header-buttons {
  display: flex;
  margin-bottom: 10px;
}
</style>
