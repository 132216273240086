<template>
  <div class="modal-step">
    <div class="crm-scrollbar-wrapper">
      <custom-scrollbar>
        <div class="crm-container indemtity-wrapper">
          <div class="crm-form-fields">
            <div class="crm-form-field">
              <indemnity-arow-left-button @click="clickPrev" />
              <p class="page-title">
                Добавление нозологического сервиса
              </p>
            </div>
          </div>

          <div class="d-flex w-100">
            <b-input
              v-model="nosologicalSearch.code"
              placeholder="Код сервиса"
              class="mr-2"
              size="sm"
              @keyup.native.enter="onNosologicalSearch"
            />

            <b-input
              v-model="nosologicalSearch.name"
              placeholder="Название сервиса"
              class="mr-2"
              size="sm"
              @keyup.native.enter="onNosologicalSearch"
            />

            <b-button
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              :disabled="nosologicalSearch.isLoading"
              @click="onNosologicalSearch"
            >
              <b-icon icon="search" />
            </b-button>

            <b-button
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="ml-2 text-nowrap"
              @click="addNewTemplate"
            >
              Новый сервис
            </b-button>
          </div>

          <b-table
            v-if="nosologicalSearch.data.length && !nosologicalSearch.isLoading"
            :fields="nosologicalSearch.fields"
            :items="nosologicalSearch.data"
            class="mt-3 nosological-service-table"
            bordered
            size="sm"
          >
            <template #cell(prices)="row">
              <div
                v-for="price in row.item.prices"
                :key="price.code"
                class="mb-1"
              >
                {{ price.code }}, {{ price.name }}, {{ price.cost }} р.
              </div>
            </template>

            <template #cell(actions)="row">
              <div class="d-flex">
                <b-button
                  size="sm"
                  variant="success"
                  :type="$const.PRIMARY_BUTTON"
                  @click="onNosologicalPricesAdd(row.item.prices)"
                >
                  Выбрать
                </b-button>
                <b-button
                  size="sm"
                  variant="primary"
                  :type="$const.PRIMARY_BUTTON"
                  class="ml-2"
                  title="Изменить"
                >
                  <b-icon icon="pencil" />
                </b-button>
                <b-button
                  size="sm"
                  variant="danger"
                  :type="$const.PRIMARY_BUTTON"
                  class="ml-2"
                  title="Удалить"
                >
                  <b-icon icon="trash" />
                </b-button>
              </div>
            </template>
          </b-table>

          <div
            v-else-if="!nosologicalSearch.isLoading"
            class="mt-3"
          >
            Нозологические сервисы не найдены
          </div>

          <div
            v-else
            class="mt-3 text-center"
          >
            <b-spinner variant="primary" />
          </div>
        </div>
      </custom-scrollbar>
    </div>
  </div>
</template>

<script>
// import { api } from '@/helpers/api';

import { mapGetters } from 'vuex';
import * as types from '@/store/types';
import { preformatTableRows } from '../indemnityUtils';
import IndemnityArowLeftButton from './IndemnityArowLeftButton';

export default {
  name: 'IndemnityModalAddTemplateStep',
  components: {
    IndemnityArowLeftButton,
  },
  data() {
    return {
      isLoading: false,
      isSearched: false,
      query: '', // still empty
      activeTemplateId: null,
      nosologicalSearch: {
        code: '',
        name: '',
        isLoading: false,
        fields: [
          {
            key: 'code',
            label: 'Код сервиса',
          },
          {
            key: 'name',
            label: 'Название сервиса',
          },
          {
            key: 'prices',
            label: 'Услуги',
          },
          {
            key: 'actions',
            label: '',
            tdClass: 'nosologic-action-td',
            thClass: 'nosologic-action-td',
          },
        ],
        data: [],
      },
    };
  },
  validations() {
    return {
    };
  },
  computed: {
    letter() {
      return this.$store.state.Indemnity.letter;
    },
    totalCount() {
      return this.templates.length;
    },
    ...mapGetters({
      templates: types.GET_TEMPLATES,
    }),
  },
  beforeDestroy() {
    this.$store.commit(this.$types.INDEMNITY_TEMPLATE_SET, []);
  },
  methods: {
    async onNosologicalSearch() {
      this.nosologicalSearch.isLoading = true;

      const data = await this.$store.dispatch(this.$types.NOSOLOGICAL_SERVICE_FETCH, {
        code: this.nosologicalSearch.code,
        name: this.nosologicalSearch.name,
        take: 100,
      });

      this.nosologicalSearch.data = data;

      this.nosologicalSearch.isLoading = false;
    },
    clickPrev() {
      this.$emit('change-step', -30);
    },
    onNosologicalPricesAdd(prices) {
      prices.forEach((price) => {
        const isPriceNotAdded = !this.letter.nosologicalServices?.find((p) => p.id === price.id);
        if (isPriceNotAdded) {
          if (this.letter.nosologicalServices) {
            this.letter.nosologicalServices.push(price);
          } else {
            this.letter.nosologicalServices = [price];
          }
        }
      });
    },
    applyTemplate(template) {
      this.activeTemplateId = template.id;
      const letter = { ...this.$store.state.Indemnity.letter };
      let { tableColumns, tableRows } = preformatTableRows(template.rows);

      const updatedTableRows = tableRows.map((row) => {
        if ((typeof row) !== 'string') {
          const newRow = { ...row };
          Object.keys(newRow).forEach((key) => {
            if (key === 'code' || key === 'name') {
              newRow[key].isVisible = true;
              return;
            }
            newRow[key].isVisible = false;
          });
          return newRow;
        }
        return row;
      });
      const updateTableColumns = { ...tableColumns };
      Object.keys(tableColumns).forEach((key) => {
        if (key === 'Код услуги' || key === 'Наименование услуги') {
          updateTableColumns[key] = true;
          return;
        }
        updateTableColumns[key] = false;
      });
      tableColumns = updateTableColumns;
      tableRows = updatedTableRows;

      const array = tableRows.map((row) => {
        const newRow = row;
        if (typeof row === 'string') return newRow;

        newRow.sum.value = +newRow.price.value * +newRow.count.value;
        return newRow;
      });
      const sumStringValue = array.reduce((sum, row) => (typeof row === 'string' ? +sum : +sum + +row.sum.value), 0);

      this.$store.commit(this.$types.INDEMNITY_LETTER_SET, {
        ...letter,
        tableRows,
        tableColumns,
        sumString: sumStringValue,
        templateId: template.id,
      });
      this.$store.dispatch(this.$types.INDEMNITY_LETTER_NUMBERS_UPDATE);
      this.clickPrev();
    },
    addNewTemplate() {
      this.$emit('change-step', 1);

      const row = {
        number: {
          isVisible: true,
          value: 0,
        },
        code: {
          isVisible: true,
          value: '',
        },
        name: {
          isVisible: true,
          value: '',
        },
        count: {
          isVisible: true,
          value: 0,
        },
        price: {
          isVisible: true,
          value: 0,
        },
        sum: {
          isVisible: true,
          value: 0,
        },
      };
      this.$store.commit(this.$types.INDEMNITY_EDIT_TEMPLATE_SET, {
        // id: null,
        name: '',
        columns: null,
        program: null,
        rows: [row],
        previewImage: null,
      });
      this.$store.dispatch(this.$types.INDEMNITY_EDIT_TEMPLATE_NUMBERS_UPDATE);
    },
    editTemplate(template) {
      this.$store.commit(this.$types.INDEMNITY_EDIT_TEMPLATE_SET, { ...template });
      this.$store.dispatch(this.$types.INDEMNITY_EDIT_TEMPLATE_NUMBERS_UPDATE);
      this.$emit('change-step', 1);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.nosological-service-table {
  .nosologic-action-td {
    width: 100px;
  }
}

::v-deep.nosological-service-table-2 {
  .nosologic-action-td {
    width: 50px;
  }
}
</style>
