import SpecializationsApi from '@/services/specializations/specializations.api';

export default class SpecializationsService {
  constructor($axios) {
    this.api = new SpecializationsApi($axios);
  }

  getCheckupProfilesOld() {
    return this.api.getCheckupProfilesOld();
  }
}
