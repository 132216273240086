class NotificationService {
  constructor(VueCtx) {
    this.VueCtx = VueCtx;
  }

  showMessage(option) {
    let defaultDuration = 3000;

    switch (option.type) {
      case 'error':
        defaultDuration = -1;
        break;
      case 'warning':
        defaultDuration = 6000;
        break;
      default:
    }

    this.VueCtx.notify({
      group: option.group || 'global-notifications',
      ignoreDuplicates: true,
      closeOnClick: false,
      type: option.type,
      title: option.title,
      text: option.message,
      duration: option.duration || defaultDuration,
    });
  }
}

export default NotificationService;
