<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="md"
    :title="'Восстановить специализацию?'"
    hide-footer
    @hidden="onClose"
    @ok="onClose"
  >
    <div
      class="d-flex justify-content-center"
    >
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        :disabled="isLoading"
        @click="onSave"
      >
        Восстановить
      </b-button>

      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        class="float-right ml-2"
        :disabled="isLoading"
        @click="onClose"
      >
        Отменить
      </b-button>
    </div>
  </b-modal>
</template>

<script>
// import {
//   // formatISO,
//   format,
// } from '@evd3v/date-fns';
import Bus from '@/eventBus';

export default {
  name: 'SpecialisationReestablishModal',
  components: {
  },
  props: {
    specialisationId: {
      type: [String, Number],
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
    };
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onSave() {
      await this.$store.dispatch(this.$types.SPECIALIZATION_RESTORE, this.specialisationId);

      Bus.$emit('specializations:update');
      this.onClose();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
