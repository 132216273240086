/* eslint-disable max-len */
/* eslint-disable no-restricted-syntax */
import NProgress from 'nprogress/nprogress';
import { clinicService } from '@/services';
import { showErrorCustomMessage } from '@/helpers/messages';

import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';
import { api } from '@/helpers/api';

const localTypes = removeNamespaces(types);

export default {
  // ? вызывается модалка PricesHistoryModal, где происходит запрос
  // ? вызов модалки происходит в компоненте ClinicPrice
  // ? кнопка вызывающая модалку закоменчена (возможно легаси)
  async [localTypes.CLINIC_PRICES_LIST_FETCH](_, params) {
    NProgress.start();
    let searchString = '';
    if (params.search) searchString = params.search;
    try {
      const { data } = await api.get(`v2/clinic/services/list?clinicId=${params.clinicId}&searchString=${searchString}`);
      NProgress.done();
      return data;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.CLINICS_FETCH]({ commit }, {
    skip,
    take,
    filter,
    clear,
    save,
    isDeleted,
  }) {
    if (clear) {
      commit('SET_CLINICS', []);
    }

    try {
      const clinics = await clinicService.search({
        searchString: filter.query,
        clinicNetworkId: filter.network && filter.network.id,
        cityId: filter.city && filter.city.id,
        services: filter.services,
        inn: filter.inn,
        // пока не исправили на массив - отправляю один
        clinicAttribute: filter.clinicAttribute,
        skip,
        take,
        isDeleted,
      });

      if (save) {
        commit('ADD_CLINICS', clinics);
      }
      return clinics;
    } catch (err) {
      showErrorCustomMessage('Не удалось получить список клиник');
      console.log(err);
    }
  },

  [localTypes.CLINICS_PINNED_DOCTORS_TOGGLE]({ state, commit }, doctor) {
    if (state.pinnedDoctors.find((doc) => doc.id === doctor.id)) {
      commit('SET_CLINICS_PINNED_DOCTORS', state.pinnedDoctors.filter((doc) => doc.id !== doctor.id));
    } else {
      commit('SET_CLINICS_PINNED_DOCTORS', [...state.pinnedDoctors, doctor]);
    }
  },
};
