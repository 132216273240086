<template>
  <div class="modal-step">
    <div class="crm-scrollbar-wrapper">
      <custom-scrollbar>
        <div class="crm-container indemtity-wrapper">
          <div class="crm-form-fields">
            <div class="crm-form-field crm-form-field_align-center">
              <indemnity-arow-left-button @click="clickPrev" />
              <p class="page-title">
                Добавление шаблона
              </p>
            </div>
          </div>

          <div class="guarantee-template-wrapper">
            <b-form-group
              label="Название шаблона"
              label-for="input-1"
            >
              <b-form-input
                v-model="$v.templateName.$model"
                label-for="input-1"
                placeholder="Введите название шаблона"
                :state="validateState('templateName')"
                trim
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >
                Данное поле обязательно*
              </b-form-invalid-feedback>
            </b-form-group>

            <indemnity-new-template-table class="mt-4" />
          </div>
        </div>
      </custom-scrollbar>
    </div>

    <div class="crm-wrapper-buttons">
      <b-button
        variant="outline-primary"
        :type="$const.PRIMARY_BUTTON"
        class="mr-2"
        @click="clickPrev"
      >
        Отменить
      </b-button>

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        @click="onClickNext"
      >
        Продолжить
      </b-button>
    </div>
  </div>
</template>

<script>

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import IndemnityArowLeftButton from './IndemnityArowLeftButton';
import IndemnityNewTemplateTable from '../IndemnityNewTemplateTable';

export default {
  name: 'IndemnityModalNewTemplateStep',
  components: {
    IndemnityNewTemplateTable,
    IndemnityArowLeftButton,
  },
  mixins: [validationMixin],
  data() {
    return {
    };
  },
  validations() {
    return {
      templateName: {
        required,
      },
      template: {
        rows: {
          $each: {
            code: {
              value: {
                required,
              },
            },
            name: {
              value: {
                required,
              },
            },
            count: {
              value: {
                required,
              },
            },
            sum: {
              value: {},
            },
            price: {
              value: {},
            },
            number: {
              value: {},
            },
          },
        },
      },
    };
  },
  computed: {
    template() {
      return this.$store.state.Indemnity.editTemplate;
    },
    isTemplateSaving() {
      return this.$store.state.Indemnity.isTemplateSaving;
    },
    errorsValidation() {
      const errors = {};

      errors.templateName = [];
      if (!this.$v.templateName.required) {
        errors.templateName.push('Поле не может быть пустым');
      }

      return errors;
    },
    isValidate() {
      return this.$store.state.Indemnity.isCreateNewTemplateValidate;
    },
    templateName: {
      get() {
        return this.template.name;
      },
      set(newValue) {
        this.$store.commit(this.$types.INDEMNITY_EDIT_TEMPLATE_SET, { ...this.template, name: newValue });
      },
    },
  },
  methods: {
    onCheckValidation() {
      this.$v.templateName.$touch();
      if (this.$v.templateName.$anyError
      ) {
        return false;
      }
      return true;
    },
    async onClickNext() {
      await this.$store.commit(this.$types.INDEMNITY_CHECKVALIDATION);
      this.onCheckValidation();
      if (this.onCheckValidation() && this.isValidate) {
        try {
          if (this.template.id) {
            await this.$store.dispatch(this.$types.INDEMNITY_EDIT_TEMPLATE_UPDATE);
          } else {
            await this.$store.dispatch(this.$types.INDEMNITY_EDIT_TEMPLATE_SAVE);
          }

          this.$emit('change-step', -1);
        } catch (error) {
          console.error(error);
        }
      }
    },
    toTemplates() {
      this.$emit('change-step', 28);
    },
    clickPrev() {
      this.$emit('change-step', -1);
    },
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    isEditToggle() {
      this.isEdit = !this.isEdit;
    },
    addTableRow(index) {
      const row = {
        number: {
          isVisible: true,
          value: index,
        },
        code: {
          isVisible: true,
          value: '',
        },
        name: {
          isVisible: true,
          value: '',
        },
        count: {
          isVisible: true,
          value: '',
        },
        price: {
          isVisible: true,
          value: 0,
        },
        sum: {
          isVisible: true,
          value: 0,
        },
      };

      const array = [...this.template.rows.slice(0, index), row, ...this.template.rows.slice(index, this.template.rows.length)];
      this.$store.commit(this.$types.INDEMNITY_EDIT_TEMPLATE_SET, { ...this.template, rows: array });
      this.$store.dispatch(this.$types.INDEMNITY_EDIT_TEMPLATE_NUMBERS_UPDATE);
    },
    removeTableRow(index) {
      const array = [...this.template.rows.slice(0, index), ...this.template.rows.slice(index + 1, this.template.rows.length)];
      this.$store.commit(this.$types.INDEMNITY_EDIT_TEMPLATE_SET, { ...this.template, rows: array });
      this.$store.dispatch(this.$types.INDEMNITY_EDIT_TEMPLATE_NUMBERS_UPDATE);
    },
    templateRowDataInputHandler() {
      const array = this.template.rows.map((row) => {
        const newRow = row;
        if (typeof row === 'string') return newRow;

        newRow.sum.value = +newRow.price.value * +newRow.count.value;
        return newRow;
      });

      this.$store.commit(this.$types.INDEMNITY_EDIT_TEMPLATE_SET, { ...this.template, rows: array });
    },
    templateRowStringInputHandler(value, index) {
      const array = this.template.rows.map((row, i) => (index === i ? value : row));

      this.$store.commit(this.$types.INDEMNITY_EDIT_TEMPLATE_SET, { ...this.template, rows: array });
    },
  },
};
</script>

<style lang="scss" scoped>
.guarantee-template-wrapper {
  width: 100%;
}

::v-deep.crm-icon-edit {
  cursor: pointer;

  &.active {
    border-radius: 4px;
    background: $blue;

    rect {
      stroke: $blue;
    }

    path {
      fill: #fff;
    }
  }
}

::v-deep.crm-button {
  font-weight: 400;

  &.width-unset {
    width: unset;
    padding-left: 20px;
    padding-right: 20px;
  }

  .crm-icon-cross {
    margin-right: 15px;
  }

  &.button_transparent {
    background-color: transparent;
    color: $blue;
    font-weight: 600;

    .crm-icon-cross {
      path {
        fill: $blue;
      }
    }
  }
  &.without-border {
    border: none;
  }
  &.w300 {
    width: 300px;
  }
  &.gray {
    color: $gray-border;
  }
}

.sheet-row {
  overflow: visible;
  position: relative;

  .plus-icon {
    display: none;
    position: absolute;
    left: 5px;
    bottom: 0;
    transform: translateY(50%);
    z-index: 1;
    cursor: pointer;
  }

  &:hover {
    .plus-icon {
      display: block;
    }
  }
}

.crm-icon-add-wrapper {
  overflow: visible;
  position: relative;
  top: 20px;

  .plus-icon {
    display: block;
    left: 5px;
    position: absolute;
    bottom: 0;
    transform: translateY(50%);
    z-index: 1;
    cursor: pointer;
  }
}

.crm-icon-delete {
  cursor: pointer;
}

.guarantee-template-sheet {
  overflow: visible !important;
  margin-top: 30px;
  margin-bottom: 10px;
}

::v-deep.sheet {
  width: 100%;
  overflow: hidden;
  font-size: 16px;
  line-height: 20px;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  &-row {
    display: flex;
    background-color: #fff;
    & + & {
      border-top: 1px solid $gray-shadow;
    }

    &--header {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      background-color: #f3f3f3;

      .sheet-cell {
        padding: 15px 10px;

        &--service-count {
          width: 90px;
          text-align: center;
          padding-left: 0;
          padding-right: 0;
        }

        &:last-child {
          border: none;
        }
      }
    }
  }

  &-cell {
    padding: 10px 0;
    align-items: center;
    display: flex;
    justify-content: center;
    & + & {
      border-left: 1px solid $gray-shadow;
    }

    &--number {
      width: 40px;
      text-align: center;
    }

    &--code {
      width: 200px;
      text-align: center;
      padding: 10px;
    }

    &--name {
      flex: 1;
      padding: 10px;
      word-break: break-word;
    }

    &--service-count {
      width: 90px;
      text-align: center;
    }

    &--price {
      width: 100px;
      text-align: center;
    }

    &--sum {
      width: 100px;
      text-align: center;
    }

    &--del {
      width: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
    }

    &.guarantee-template-cell-field {
      padding: 0;

      .crm-base-input {
        box-sizing: border-box;
        padding: 10px 5px;
        border: none;
        text-align: center;
      }

      &.sheet-cell--name,
      &.sheet-cell--text {
        .crm-wrap-input {
        width: 100%;
        }
        .crm-base-input {
          padding: 10px;
        }
      }

      &.sheet-cell--name {
        .crm-base-input {
          text-align: left;
        }
      }

      &.sheet-cell--text {
        width: 100%;

        .crm-base-input {
          text-align: center;
        }
      }

    }
  }
}

::v-deep.guarantee-template-field {
  margin-top: 30px;

  .crm-label {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 18px;
  }

  .crm-base-input {
    padding: 10px 10px 10px 20px;
    font-size: 16px;
    line-height: 20px;
    resize: none;
  }
}

.sheet-cell {
  border: 0.5px solid $gray-shadow !important;
}
</style>
