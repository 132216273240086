<template>
  <div>
    <b-form-checkbox
      :value="page?.id"
      class="mr-0"
      :class="{
        'font-weight-bold': !page.pageId || page.childPages?.length,
      }"
    >
      {{ page.name }}
    </b-form-checkbox>

    <div
      v-if="page.childPages?.length"
      class="ml-4"
    >
      <RolePageItem
        v-for="childPage in page.childPages"
        :key="childPage.id"
        :page="childPage"
      />
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-self-import
import RolePageItem from '@/components/Roles/RolePageItem';

export default {
  name: 'RolePageItem',
  components: {
    RolePageItem,
  },
  props: {
    page: {
      type: Object,
      default: null,
    },
  },
};
</script>
